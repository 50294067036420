// @/stores/application/index.ts
import {
    acceptHMRUpdate,
    defineStore,
} from 'pinia'

import {
    DEFAULT_HMLR_DOCUMENT_COST,
    DEFAULT_SCOTLIS_DOCUMENT_COST,
} from '@/consts/application'

import actions from './actions'
import getters from './getters'

/**
 * Represents the state of the application store.
 */
export interface IApplicationState {
    documentCosts: {
        Upload: number,
        HMLR: number,
        LIS: number
    }
}

export const applicationOptions = {
    state: (): IApplicationState => {
        return {
            documentCosts: {
                Upload: DEFAULT_HMLR_DOCUMENT_COST,
                HMLR: DEFAULT_HMLR_DOCUMENT_COST,
                LIS: DEFAULT_SCOTLIS_DOCUMENT_COST,
            },
        }
    },
    actions: { ...actions},
    getters: { ...getters },
}

/**
 * The `useApplicationStore` variable is a store created using the `defineStore` function.
 * It is used to manage state relating to the application
 */
export const useApplicationStore = defineStore('application', applicationOptions)

// Enables Hot Module Replacement in Pinia
if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useApplicationStore, import.meta.hot))
}
