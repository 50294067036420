import booleanPointInPolygon from '@turf/boolean-point-in-polygon'
import distance from '@turf/distance'
import { getCenter } from 'ol/extent'
import Feature from 'ol/Feature'
import GeoJSON from 'ol/format/GeoJSON'
import Point from 'ol/geom/Point'
import { transform } from 'ol/proj'
import point from 'turf-point'

import PlanningApi from '@/api/planning.api'
import {
    planningSortingOptions,
    RURAL_MAX_EXTENT,
    URBAN_MAX_EXTENT,
} from '@/consts/planning'
import { StatusCode } from '@/consts/status-code'
import { CoordinateSystemCode } from '@/enums/coordinate-systems'
import {
    NPS_GET_FEATURES_BY_TITLE_NUMBERS,
    NPS_LOAD_FEATURES_FOR_TITLE_NUMBERS,
} from '@/store/modules/nps/types'
import { initialState } from '@/store/modules/planning/state'
import {
    PLANNING_CLEAR,
    PLANNING_EXPORT_CSV,
    PLANNING_HIDE_LAYERS,
    PLANNING_MUTATE_DISTANCE,
    PLANNING_MUTATE_FILTERED_RESULTS,
    PLANNING_MUTATE_INIT_LAYERS,
    PLANNING_MUTATE_LOADING_RESULTS,
    PLANNING_MUTATE_MAX_DISTANCE,
    PLANNING_MUTATE_PROMPT_FOR_RETRY,
    PLANNING_MUTATE_RATE_LIMITED_SECONDS,
    PLANNING_MUTATE_RESULTS,
    PLANNING_MUTATE_SEARCHED_TITLE_NUMBERS,
    PLANNING_MUTATE_SELECTED_PLANNING_DECISIONS,
    PLANNING_MUTATE_SELECTED_SORT_OPTION,
    PLANNING_MUTATE_SELECTED_TITLE_FEATURES,
    PLANNING_MUTATE_TEXT_FILTER,
    PLANNING_MUTATE_TEXT_FILTER_KEYWORDS,
    PLANNING_MUTATE_UNAVAILABLE_APPLICATIONS,
    PLANNING_RETRY_CURRENT_SEARCH,
    PLANNING_SEARCH_BY_TITLE_NUMBERS,
    PLANNING_UPDATE_FILTERED_RESULTS,
    PLANNING_V2_SEARCH_BY_TITLE_NUMBERS,
} from '@/store/modules/planning/types'
import { USER_SHOW_POPUP } from '@/store/mutation-types'
import { exportAsCsv } from '@/utils/csv-utils'
import { bufferFeatures } from '@/utils/map-utils'
import { isNullOrWhitespace } from '@/utils/string-utils'

const sortPlanningApplicationsByDate = (current, next) => {
    const timeMsA = (new Date(current.getProperties().receivedDate)).getTime()
    const timeMsB = (new Date(next.getProperties().receivedDate)).getTime()

    return timeMsA < timeMsB ? -1 : 1
}

const sortPlanningApplicationsByDistance = (current, next) => {
    return current.getProperties().distanceFromCentre - next.getProperties().distanceFromCentre
}

const getPointToArrayOfPointsMinDistance = (point, points) => {
    return Math.min(points.map(p => distance(point, p)))
}

export default {

    async [PLANNING_V2_SEARCH_BY_TITLE_NUMBERS]({ state, commit, getters, dispatch }, {titleNumbers, map}) {
        // Set loading state
        commit(PLANNING_MUTATE_LOADING_RESULTS, true)

        state.distanceLayer = null

        // Initialise the planning layers if needed
        commit(PLANNING_MUTATE_INIT_LAYERS, map)

        // Get title boundary features features
        commit(PLANNING_MUTATE_SEARCHED_TITLE_NUMBERS, titleNumbers)
        await dispatch(NPS_LOAD_FEATURES_FOR_TITLE_NUMBERS, titleNumbers)
        const newFeatures = getters[NPS_GET_FEATURES_BY_TITLE_NUMBERS](titleNumbers)
        commit(PLANNING_MUTATE_SELECTED_TITLE_FEATURES, newFeatures)

        // Re-apply distance buffer
        commit(PLANNING_MUTATE_DISTANCE, state.inputs.selectedDistance)

        // Clear the current results layer
        // eslint-disable-next-line
        state.resultsLayer?.getSource().clear()

        // Search based on the maximum extent the user is going to select, to refine it later.
        const bufferedFeatures = bufferFeatures(state.inputs.selectedTitleFeatures, state.inputs.maxDistance)

        const isMultipolygon = bufferedFeatures.getGeometry().getType() !== 'Polygon'
        const polygons = isMultipolygon
            ? bufferedFeatures.getGeometry().getPolygons()
            : [bufferedFeatures.getGeometry()]

        const extents = polygons
            .map(p => p.getExtent())

        // Diagnostic info, useful for testing purposes
        // console.info('using bounding box', extents)

        /* Get a cached search result if available from client side caching, otherwise the API,
         * to reduce number of requests; later to be server-side via cache profile or similar.
         * */
        let response = state.resultsCache.find(r => r.titleNumbers.toString() === titleNumbers.toString())?.response
        if (!response) {
            if (state.resultsCache.length > 10) {
                state.resultsCache.shift()
            }

            response = await PlanningApi.search(extents, state.forcePlanningDataProvider)
        }

        if (response.status === StatusCode.SERVICE_UNAVAILABLE) {
            const retryAfterHeaderValue = parseInt(response.headers['retry-after']) ?? 1
            if (retryAfterHeaderValue) {
                commit(PLANNING_MUTATE_RATE_LIMITED_SECONDS, retryAfterHeaderValue)
                commit(PLANNING_MUTATE_PROMPT_FOR_RETRY, true)
            }
        } else if (response.ok) {
            if (response.status === StatusCode.PARTIAL_CONTENT) {
                const unavailableApplications = response.data.totalRecords - response.data.planningApplications.length
                commit(PLANNING_MUTATE_UNAVAILABLE_APPLICATIONS, unavailableApplications)
            }

            state.resultsCache.push({
                titleNumbers,
                response,
            })

            const resultFeatures = []
            const titleBoundaryLonLatCentres = state.inputs.selectedTitleFeatures
                .map(feature => point(transform(getCenter(feature.getGeometry().computeExtent()), CoordinateSystemCode.EPSG27700, CoordinateSystemCode.EPSG4326)))

            response.data.planningApplications.forEach((planningApplication, i) => {
                const newFeature = new Feature({
                    geometry: new Point(transform([planningApplication.longitude, planningApplication.latitude], CoordinateSystemCode.EPSG4326, CoordinateSystemCode.EPSG27700)),
                    index: i,
                    distanceFromCentre: getPointToArrayOfPointsMinDistance([
                        planningApplication.longitude,
                        planningApplication.latitude,
                    ], titleBoundaryLonLatCentres) * 1000, // convert km to m
                    ...planningApplication,
                })
                resultFeatures.push(newFeature)
            })

            // eslint-disable-next-line
            state.resultsLayer?.getSource().addFeatures(resultFeatures)

            commit(PLANNING_MUTATE_RESULTS, resultFeatures)
        } else {
            dispatch(USER_SHOW_POPUP, {
                title: 'Error',
                icon: '$error',
                contentHTML: `<p>${ response.message || 'Something went wrong. Please try again later.' }</p>`,
            })

            commit(PLANNING_MUTATE_RESULTS, [])
        }

        // Setup results layer.
        commit(PLANNING_MUTATE_LOADING_RESULTS, false)
        dispatch(PLANNING_UPDATE_FILTERED_RESULTS)
        return response
    },

    async [PLANNING_SEARCH_BY_TITLE_NUMBERS]({ state, commit, getters, rootState, dispatch }, {
        titleNumbers = [],
        isUrban = false,
    }) {
        // Set loading state
        commit(PLANNING_MUTATE_LOADING_RESULTS, true)

        // Initialise the planning layers if needed
        commit(PLANNING_MUTATE_INIT_LAYERS, rootState.map.map)

        // Get title boundary features features
        commit(PLANNING_MUTATE_SEARCHED_TITLE_NUMBERS, titleNumbers)
        await dispatch(NPS_LOAD_FEATURES_FOR_TITLE_NUMBERS, titleNumbers)
        const newFeatures = getters[NPS_GET_FEATURES_BY_TITLE_NUMBERS](titleNumbers)
        commit(PLANNING_MUTATE_SELECTED_TITLE_FEATURES, newFeatures)

        // Re-apply distance buffer
        commit(PLANNING_MUTATE_DISTANCE, state.inputs.selectedDistance)

        // Clear the current results layer
        // eslint-disable-next-line
        state.resultsLayer?.getSource().clear()

        const maxDistance = isUrban ? URBAN_MAX_EXTENT : RURAL_MAX_EXTENT

        // Search based on the maximum extent the user is going to select, to refine it later.
        const bufferedFeatures = bufferFeatures(state.inputs.selectedTitleFeatures, maxDistance)

        const isMultipolygon = bufferedFeatures.getGeometry().getType() !== 'Polygon'
        const polygons = isMultipolygon
            ? bufferedFeatures.getGeometry().getPolygons()
            : [bufferedFeatures.getGeometry()]

        const extents = polygons.map(p => p.getExtent())

        // Diagnostic info, useful for testing purposes
        // console.info('using bounding box', extents, maxDistance)

        /* Get a cached search result if available from client side caching, otherwise the API,
         * to reduce number of requests; later to be server-side via cache profile or similar.
         * */
        let response = state.resultsCache.find(r => r.titleNumbers.toString() === titleNumbers.toString())?.response
        if (!response) {
            if (state.resultsCache.length > 10) {
                state.resultsCache.shift()
            }

            response = await PlanningApi.search(extents, state.forcePlanningDataProvider)
        }

        if (response.status === StatusCode.SERVICE_UNAVAILABLE) {
            const retryAfterHeaderValue = parseInt(response.headers['retry-after']) ?? 1
            if (retryAfterHeaderValue) {
                commit(PLANNING_MUTATE_RATE_LIMITED_SECONDS, retryAfterHeaderValue)
                commit(PLANNING_MUTATE_PROMPT_FOR_RETRY, true)
            }
        } else if (response.ok) {
            if (response.status === StatusCode.PARTIAL_CONTENT) {
                const unavailableApplications = response.data.totalRecords - response.data.planningApplications.length
                commit(PLANNING_MUTATE_UNAVAILABLE_APPLICATIONS, unavailableApplications)
            }

            state.resultsCache.push({
                titleNumbers,
                response,
            })

            const resultFeatures = []
            const titleBoundaryLonLatCentres = state.inputs.selectedTitleFeatures
                .map(feature => point(transform(getCenter(feature.getGeometry().computeExtent()), CoordinateSystemCode.EPSG27700, CoordinateSystemCode.EPSG4326)))

            response.data.planningApplications.forEach((planningApplication, i) => {
                const newFeature = new Feature({
                    geometry: new Point(transform([planningApplication.longitude, planningApplication.latitude], CoordinateSystemCode.EPSG4326, CoordinateSystemCode.EPSG27700)),
                    index: i,
                    distanceFromCentre: getPointToArrayOfPointsMinDistance([
                        planningApplication.longitude,
                        planningApplication.latitude,
                    ], titleBoundaryLonLatCentres) * 1000, // convert km to m
                    ...planningApplication,
                })
                resultFeatures.push(newFeature)
            })

            // eslint-disable-next-line
            state.resultsLayer?.getSource().addFeatures(resultFeatures)

            commit(PLANNING_MUTATE_RESULTS, resultFeatures)
        } else {
            dispatch(USER_SHOW_POPUP, {
                title: 'Error',
                icon: '$error',
                contentHTML: `<p>${ response.message || 'Something went wrong. Please try again later.' }</p>`,
            })

            commit(PLANNING_MUTATE_RESULTS, [])
        }

        // Setup results layer.
        commit(PLANNING_MUTATE_LOADING_RESULTS, false)
        dispatch(PLANNING_UPDATE_FILTERED_RESULTS)
        return response
    },

    [PLANNING_UPDATE_FILTERED_RESULTS]({ state, commit }) {
        const format = new GeoJSON()
        const turfFeature = format.writeFeatureObject(state.distanceFeature)

        // Determine keywords to be searched
        const keywords = state.inputs.textFilter?.toLowerCase().split(',')
            .map(keyword => keyword.trim())
            .filter(keyword => !isNullOrWhitespace(keyword)) ?? []

        const selectedPlanningDecisionsInt = state.selectedPlanningDecisions.map(planningDecision => parseInt(planningDecision.value))

        // Determine the new results
        const newFilteredResults = []
        state.results.forEach(feature => {
            const properties = feature.getProperties()
            let show = false

            // Filter by planning decision
            const isFilteredByPlanningDecision = selectedPlanningDecisionsInt.includes(properties.decision)

            // Filter by distance
            const withinDistance = booleanPointInPolygon(feature.getGeometry().getCoordinates(), turfFeature)

            if (withinDistance && isFilteredByPlanningDecision) {
                // Filter by text
                // Find items in some way matching at least one keyword
                if (keywords.length === 0 || (keywords.some(keyword =>
                    properties.address?.toLowerCase().includes(keyword) ||
                    properties.description?.toLowerCase().includes(keyword) ||
                    properties.identifier?.toLowerCase().includes(keyword)))) {
                    // Filtering done, include the item in the result
                    newFilteredResults.push(feature)
                    show = true
                }
            }

            // Update feature visibility based on filtering
            feature.setProperties({ show })
        })

        switch (state.selectedSortOption) {
            case planningSortingOptions.DISTANCE_ASC:
                newFilteredResults.sort(sortPlanningApplicationsByDistance)
                break

            case planningSortingOptions.DISTANCE_DESC:
                newFilteredResults.sort(sortPlanningApplicationsByDistance).reverse()
                break

            case planningSortingOptions.DATE_RECEIVED_ASC:
                newFilteredResults.sort(sortPlanningApplicationsByDate)
                break

            case planningSortingOptions.DATE_RECEIVED_DESC:
                newFilteredResults.sort(sortPlanningApplicationsByDate).reverse()
                break
        }

        commit(PLANNING_MUTATE_TEXT_FILTER_KEYWORDS, keywords)
        commit(PLANNING_MUTATE_FILTERED_RESULTS, newFilteredResults)
        // eslint-disable-next-line
        state.resultsLayer?.getSource().changed()
    },

    [PLANNING_HIDE_LAYERS]({ state }) {
        /* eslint-disable no-unused-expressions */
        state.distanceLayer?.setVisible(false)
        state.resultsLayer?.setVisible(false)
        /* eslint-enable no-unused-expressions */
    },

    [PLANNING_RETRY_CURRENT_SEARCH]({ dispatch, commit, state }) {
        commit(PLANNING_MUTATE_PROMPT_FOR_RETRY, false)
        dispatch(PLANNING_SEARCH_BY_TITLE_NUMBERS, state.inputs.searchedTitleNumbers)
    },

    [PLANNING_CLEAR]({ dispatch, commit }) {
        dispatch(PLANNING_HIDE_LAYERS)

        commit(PLANNING_MUTATE_RESULTS, initialState.results)
        commit(PLANNING_MUTATE_TEXT_FILTER, initialState.textFilter)
        commit(PLANNING_MUTATE_MAX_DISTANCE, initialState.maxDistance)
        commit(PLANNING_MUTATE_DISTANCE, initialState.selectedDistance)
        commit(PLANNING_MUTATE_LOADING_RESULTS, initialState.loadingResults)
        commit(PLANNING_MUTATE_FILTERED_RESULTS, initialState.filteredResults)
        commit(PLANNING_MUTATE_SELECTED_SORT_OPTION, initialState.selectedSortOption)
        commit(PLANNING_MUTATE_SELECTED_TITLE_FEATURES, initialState.selectedTitleFeatures)
        commit(PLANNING_MUTATE_UNAVAILABLE_APPLICATIONS, initialState.unavailableApplications)
        commit(PLANNING_MUTATE_SELECTED_PLANNING_DECISIONS, initialState.selectedPlanningDecisions)
        commit(PLANNING_MUTATE_SELECTED_PLANNING_DECISIONS, initialState.selectedPlanningDecisions)
        commit(PLANNING_MUTATE_RATE_LIMITED_SECONDS, initialState.isRateLimitedRemainingSeconds)
        commit(PLANNING_MUTATE_PROMPT_FOR_RETRY, initialState.promptForRetry)
        commit(PLANNING_MUTATE_SEARCHED_TITLE_NUMBERS, initialState.searchedTitleNumbers)
    },

    [PLANNING_EXPORT_CSV]({ state }) {
        // Generate an array of data representing the CSV we want to download.
        const rows = []
        const headers = ['Identifier', 'Address', 'Type', 'Web Link', 'Description', 'Decision', 'Postcode', 'Decision Date', 'Updated Date', 'No hyperlink URL']
        const data = state.filteredResults
            .map(result => result.getProperties())
            .map(result => {
                const formattedDecisionDate = result.decisionDate !== '0001-01-01T00:00:00' ? result.decisionDate?.substr(0, 10) : 'N/A'
                const formattedUpdatedDate = result.updatedDate !== '0001-01-01T00:00:00' ? result.updatedDate?.substr(0, 10) : 'N/A'
                const hyperlinkedUrl = result.url ? `=HYPERLINK("${ result.url }")` : 'N/A'
                return [result.identifier, result.address, result.typeText, hyperlinkedUrl, result.description, result.decisionText, result.postcode, formattedDecisionDate, formattedUpdatedDate, result.url]
            })
        rows.push(headers, ...data)
        // Generate a useful filename
        let filename = `planning-data-export-${ new Date().toISOString().split('T')[0] }`
        if (state.inputs.searchedTitleNumbers.length === 1) {
            filename += `-${ state.inputs.searchedTitleNumbers[0] }`
        }

        exportAsCsv(rows, filename)
    },
}
