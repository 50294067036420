<template>
    <v-expansion-panels v-model="expanded"
                        :multiple="multiple">
        <slot />
    </v-expansion-panels>
</template>

<script setup lang="ts">
    const expanded = defineModel()
    withDefaults(defineProps<{
        multiple?: boolean
    }>(), {
        multiple: true,
    })
</script>