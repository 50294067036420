// @/stores/matter/index.ts

import {
    acceptHMRUpdate,
    defineStore,
} from 'pinia'

import { IMatter } from '@/interfaces/matter.interface'

import actions from './actions'
import getters from './getters'
/**
 * Represents the state of the matter store.
 */
export interface IMatterState {
    currentMatter: IMatter
}

export const matterOptions = {
    state: (): IMatterState => {
        return {
            currentMatter: null,
        }
    },
    getters: { ...getters },
    actions: { ...actions },
}

/**
 * The `useMatterStore` variable is a store created using the `defineStore` function.
 * It is used to manage state relating to the matter
 */
export const useMatterStore = defineStore('matter', matterOptions)

// Enables Hot Module Replacement in Pinia
if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useMatterStore, import.meta.hot))
}
