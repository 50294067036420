import { SEARCH_GET_ALL_RESULTS } from '@/store/modules/search/types'
import { ISearchState } from '@/store/modules/search/types/search-state-interface'

export default {
    [SEARCH_GET_ALL_RESULTS](state: ISearchState) {
        let companies = []
        let places = []
        let titleNumbers = []
        let uprns = []

        if (state.allResults?.addresses?.length > 0) {
            places = [
                ...places,
                ...state.allResults.addresses,
            ]
        }

        if (state.allResults?.companies?.length > 0) {
            companies = [...state.allResults.companies]
        }

        if (state.allResults?.places?.length > 0) {
            places = [
                ...places,
                ...state.allResults.places,
            ]
        }

        if (state.allResults?.postcodes?.length > 0) {
            places = [
                ...places,
                ...state.allResults.postcodes,
            ]
        }

        if (state.allResults?.titleNumbers?.length > 0) {
            titleNumbers = [...state.allResults.titleNumbers]
        }

        if (state.allResults?.uprns?.length > 0) {
            uprns = [...state.allResults.uprns]
        }

        return {
            companies,
            places,
            titleNumbers,
            uprns,
        }
    },
}
