import { Layer } from 'ol/layer'

import { matterTypes } from '@/consts/matter.js'
import { SketchesUnit } from '@/enums/sketches-enums'
import { TitleOrganiserCurrentService } from '@/store/modules/matter/title-organiser/services/title-organiser-current'
import { TitleOrganiserOwnerService } from '@/store/modules/matter/title-organiser/services/title-organiser-owner'
import { TitleOrganiserTenureService } from '@/store/modules/matter/title-organiser/services/title-organiser-tenure'
import { ITitleOrganiserService } from '@/store/modules/matter/title-organiser/title-organiser-service.interface'

import actions from './actions'
import getters from './getters'
import mutations from './mutations'
import { TitleOrganiserAdvancedService } from './title-organiser/services/title-organiser-advanced'
import { TitleOrganiserComplexityService } from './title-organiser/services/title-organiser-complexity'

export interface IMatterState {
    loading: boolean
    loadingList: boolean
    pendingLoadingMatterId: number | null
    creatingMatter: boolean
    collapsePanel: boolean
    showDetailsPanel: boolean
    updateMatterStateError: string | null
    debounceUpdateTitleLayer: number | null // used to debounce rendering the title layer.
    zoomToExtentOnTitleBoundariesLoaded: boolean
    debounceUpdateCharges: number | null // used to debounce refreshing charges associated with the matter.
    pendingChangesToLinkShareSettings: boolean
    pendingRequestToShowTitleList: boolean
    loadingState: {
        loadingCurrentMatter: boolean
        updateMatterName: boolean
        deleteMatter: boolean
        updateMatterDetails: boolean
    }
    prompts: {
        create: {
            show: boolean
            asFirst: boolean
        }
        update: {
            matter: any | null
            show: boolean
            group: any | null
        }
        current: {}
        createGroup: {
            show: boolean
            titles: any[]
            callback: any
        }
        updateGroup: {
            group: any | null
            show: boolean
        }
        deleteGroup: {
            group: any | null
            show: boolean
        }
        export: {
            show: boolean
            titles: Array<any>
            purchase: boolean
        }
        importowner: {
            show: boolean
        }
        linkShare: {
            show: boolean
        }
        removeFromMatter: {
            show: boolean
            titles: Array<any>
        }
    }
    matterTypes: Array<string>
    matters: Array<any>
    currentMatter:{
        // Charges related
        loadingCharges: boolean
        axiosCancelChargesRequest: any
        totalDispursableChargesUpdated: number // whether the last check of matter charges determined the totalDispursableCharges changed, used to visually indicate cost incrementing
        charges: {
            totalDispursableCharges: number
            softwareProviderCharges: number
            hmlrCharges: number
        },
        state: any
        clientCode: string
        code: string
        type: string
        created: string
        createdByMe: boolean
        createdByUserEmail: string
        groups: Array<any>
        id: number
        isCurrent: boolean
        lastAccessed: string
        name: string
        selectedTitles: Array<any>
        addresses: Record<string, Array<any>>,

        // Permissions TODO: - better variable names.
        sharedByMe: boolean
        sharedWithMe: boolean
        sharedWithOrganisation: boolean
        sharedWithSpecificUsers: boolean

        // Matter link share
        linkShare: any
        linkShareDirty: boolean
        walkthroughId: number

        // Loading states of the current matter
        loading: {
            linkShare: boolean
        },

        // Sketches
        sketchesVisibleMap: boolean
        sketchesUnit: string
    }

    // Titles layer
    titlesLayer: Layer,
    titlesLayerSource: any,
    titlesLayerShowBoundaryOnHover: boolean,

    // Settings
    showDuplicateMatterDialog: boolean,
    duplicateMatterId: number | null,
}

const state = {
    loading: true,
    loadingList: false,
    pendingLoadingMatterId: null,
    creatingMatter: false,
    collapsePanel: false,
    showDetailsPanel: false,
    updateMatterStateError: null,
    debounceUpdateTitleLayer: null, // used to debounce rendering the title layer.
    zoomToExtentOnTitleBoundariesLoaded: false,
    debounceUpdateCharges: null, // used to debounce refreshing charges associated with the matter.
    pendingChangesToLinkShareSettings: false,
    pendingRequestToShowTitleList: false,

    loadingState: {
        loadingCurrentMatter: false, // shows a full loading skeleton
        showLoadingBar: false, // shows an progress bar at the bottom of the header
        updateMatterName: false,
        deleteMatter: false,
        updateMatterDetails: false,
    },

    prompts: {
        create: {
            show: false,
            asFirst: false,
        },
        update: {
            matter: null,
            show: false,
            group: null,
        },
        current: {},
        createGroup: {
            show: false,
            titles: [],
            callback: null,
        },
        updateGroup: {
            group: null,
            show: false,
        },
        deleteGroup: {
            group: null,
            show: false,
        },
        export: {
            show: false,
            titles: [],
            purchase: true,
        },
        importowner: {
            show: false,
        },
        linkShare: {
            show: false,
        },
        removeFromMatter: {
            show: false,
            titles: [],
        },
        removeDocumentsFromMatter: {
            show: false,
            matterId: null,
            documentsToRemove: null,
        },
    },

    matterTypes,

    matters: [],

    currentMatter: {
        // Charges related
        loadingCharges: false,
        axiosCancelChargesRequest: null,
        totalDispursableChargesUpdated: false, // whether the last check of matter charges determined the totalDispursableCharges changed, used to visually indicate cost incrementing
        charges: {
            totalDispursableCharges: null,
            softwareProviderCharges: null,
            hmlrCharges: null,
        },
        state: null,
        clientCode: null,
        code: null,
        type: null,
        created: null,
        createdByMe: null,
        createdByUserEmail: null,
        groups: [],
        id: null,
        isCurrent: null,
        lastAccessed: null,
        name: 'Loading...',
        selectedTitles: [],
        notes: [],

        // Permissions TODO: - better variable names.
        sharedByMe: false,
        sharedWithMe: false,
        sharedWithOrganisation: false,
        sharedWithSpecificUsers: false,

        // Matter link share
        linkShare: null,
        linkShareDirty: null,
        walkthroughId: null,

        // Loading states of the current matter
        loading: {
            linkShare: true,
        },

        sketchesVisibleMap: false,
        sketchesUnit: SketchesUnit.Metric,
    },

    titleOrganiser: {
        enable: false, // Controls whether or not the title organiser panel and associated matter state (i.e. other things are disabled/hidden) is enabled.
        applyQuickStyling: false,
        selectedField: null,
        availableServices: [
            new TitleOrganiserCurrentService(),
            new TitleOrganiserOwnerService(),
            new TitleOrganiserTenureService(),
            new TitleOrganiserComplexityService(),
            new TitleOrganiserAdvancedService(),
        ] as Array<ITitleOrganiserService>,
        selectedService: null as ITitleOrganiserService,
        titles: [],
        groups: [],
        contentRevisionCounter: 0,
        loading: false,
    },

    bulkOrderRegisters: {
        loading: false,
        failedToOrderTitleNumbers: [],
        titleNumbersOrdered: [],
        errorMessages: [],
        filename: '',
    },

    largeMatterTitleCount: 25, // How many titles should be considered a large matter - use to determine loading strategies.
    styleTitles: null, // Matter titles currently being styled via the style prompt - to be removed when rebuilding sketches.
    updateMainMapOnStylePromptChanges: false, // Whether the update of styles in the style prompt should trigger updates to the styles on the main map. Temporary workaround prior to sketch refactoring.

    titlesBeingAddedToCurrentMatter: [], // To prevent multiple async requests
    currentMatterPermissions: [],

    updateMatterTitles: {
        throttle: null,
        titles: [],
    },

    titlesLayer: null,
    titlesLayerSource: null,
    titlesLayerShowBoundaryOnHover: true,
    highlightFeatures: [],
    highlightFeatureStyle: null,
    hatchStyleFunction: null,
    titlesToAddAfterMatterCreate: null,
    showDuplicateMatterDialog: false,
    duplicateMatterId: null,
}

export default {
    namespaced: false,
    state,
    getters,
    mutations,
    actions,
}
