<template>
    <ow-base-icon :width="width"
                  :height="height"
                  viewBox="0 0 60.5 67.9"
                  desc="Copilot Logo Positive">
        <path :style="{
                  fill: Colors.LogoBlue,
              }"
              d="M31.7,36l-1.6,0.9L28.6,36L0,19.4v31.1l30.2,17.4l30.3-17.4V19.4L31.7,36z" />
        <polygon :style="{
                     fill: Colors.LogoOrange,
                 }"
                 points="30.2,0 6.2,13.9 30.2,27.8 " />
    </ow-base-icon>
</template>

<script lang="ts">
    import OwBaseIcon from '@/components/core/icons/ow-base-icon.vue'
    import { Colors } from '@/enums/colors.enum'

    export default {
        name: 'IconOwLogo',
        components: {
            OwBaseIcon,
        },
        props: {
            width: {
                type: String,
                default: '30px',
            },
            height: {
                type: String,
                default: '50px',
            },
        },
        computed: {
            Colors() {
                return Colors
            },
        },
    }
</script>
