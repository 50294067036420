export enum MatterHubEvents {
    DocumentUploadStatusUpdated = 'DocumentUploadStatusUpdated',
    LeasesUpdated = 'LeasesUpdated',
    SnapshotStatusChanged = 'SnapshotStatusChanged',
    MatterShared = 'MatterShared',
    AssetMonitoringNotification = 'AssetMonitoringNotification',
    TitleAnalysisReportGenerated = 'TitleAnalysisReportGenerated',
    TitleReportGenerated = 'TitleReportGenerated'
}

export enum MatterHubActions {
    Subscribe = 'SubscribeToMatter',
    Unsubscribe = 'UnsubscribeFromMatter',
}
