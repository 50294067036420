<template>
    <li class="body-regular-small"
        data-test="companies-house-changed">
        {{ t('assetMonitoring.summaryCard.companiesHouseChange', numberOfChanges) }}

        <ul v-for="(diff, index) in differences"
            :key="index"
            v-dompurify-html="getDiffText(diff)"
            data-test="companies-house-changed-list"
            class="sub-list" />
    </li>
</template>

<script lang="ts" setup>
    import { computed } from 'vue'
    import { useI18n } from 'vue-i18n'

    import { IAssetMonitoringNotificationDetail } from '@/components/asset-monitoring/asset-monitoring-notification.interface'
    import { CompaniesHouseDatapoint } from '@/components/asset-monitoring/datapoint-interfaces'
    import type {
        Difference,
        UpdatedProperty,
    } from '@/components/asset-monitoring/difference-generic'

    const { t } = useI18n()

    const props = defineProps<{
        items: IAssetMonitoringNotificationDetail[]
    }>()

    const differences = computed(() => {
        const differences: Difference<CompaniesHouseDatapoint>[] = []
        props.items?.forEach((item) => {
            if (item.difference) {
                differences.push(JSON.parse(item.difference))
            }
        })

        return differences
    })
    const numberOfChanges = computed<number>(() => {
        return differences.value.reduce((acc, diff) => {
            return acc + diff.added.length + diff.removed.length + diff.updated.length
        }, 0)
    })

    const getDiffText = (diff: Difference<CompaniesHouseDatapoint>): string => {
        const { added, removed, updated } = diff
        const output = []

        added.forEach((item: CompaniesHouseDatapoint) => {
            output.push(`<li>${ t('label.added') }: <span class="changes__to">${ item.companyName }</span> (${ item.companyRegistrationNumber }) - ${ item.companyStatus } - ${ item.displayAddress }</li>`)
        })
        removed.forEach((item: CompaniesHouseDatapoint) => {
            output.push(`<li>${ t('label.removed') }: <span class="changes__from">${ item.companyName }</span> (${ item.companyRegistrationNumber }) - ${ item.companyStatus } - ${ item.displayAddress }</li>`)
        })

        updated.forEach((item: UpdatedProperty<CompaniesHouseDatapoint>) => {
            const { before, after } = item

            const hasStatusChanged = before.companyStatus !== after.companyStatus
            const statusFrom = hasStatusChanged ? `<span class="changes__from">${ before.companyStatus }</span>` : before.companyStatus
            const statusTo = hasStatusChanged ? `<span class="changes__to">${ after.companyStatus }</span>` : after.companyStatus

            const hasAddressChanged = before.displayAddress !== after.displayAddress
            const addressFrom = hasAddressChanged ? `<span class="changes__from">${ before.displayAddress }</span>` : before.displayAddress
            const addressTo = hasAddressChanged ? `<span class="changes__to">${ after.displayAddress }</span>` : after.displayAddress

            const hasNameChanged = before.companyName !== after.companyName
            const nameFrom = hasNameChanged ? `<span class="changes__from">${ before.companyName }</span>` : before.companyName
            const nameTo = hasNameChanged ? `<span class="changes__to">${ after.companyName }</span>` : after.companyName

            const hasNatureOfBusinessChanged = before.displayNatureOfBusiness !== after.displayNatureOfBusiness
            const businessNatureFrom = hasNatureOfBusinessChanged ? `<span class="changes__from">${ before.displayNatureOfBusiness }</span>` : before.displayNatureOfBusiness
            const businessNatureTo = hasNatureOfBusinessChanged ? `<span class="changes__to">${ after.displayNatureOfBusiness }</span>` : after.displayNatureOfBusiness

            output.push(`<li>${ t('label.updated') }: <span class="changes__to">${ hasNameChanged ? `${ nameFrom } ${ nameTo }` : after.companyName }</span> (${ after.companyRegistrationNumber }) ${ hasStatusChanged ? `${ statusFrom } ${ statusTo }` : `<span class="changes__to">${ after.companyStatus }</span>` } ${ hasAddressChanged ? `${ addressFrom } ${ addressTo }` : after.displayAddress } ${ hasNatureOfBusinessChanged ? `${ businessNatureFrom } ${ businessNatureTo }`  : '' }</li>`)

        })

        return output.join('')
    }
</script>
