<template>
    <div class="base-row">
        <div class="d-flex justify-space-between align-center w-100">
            <div class="d-flex justify-space-between align-center w-100">
                <span class="caption-highlight ml-4">{{ title }}</span>
                <ow-document-availability-label :hmlr-availability-code="documentAvailabilityCode"
                                                :is-ordered="false"
                                                class="mr-4" />
            </div>
            <v-divider vertical />
            <div>
                <ow-button :disabled="disableOrderButton"
                           class="base-row__action"
                           color="primary"
                           data-test="order-title-plan-button"
                           data-track="TITLE-DETAILS-PANEL - Order plan"
                           full-height
                           is-primary
                           is-secondary
                           @click="orderDocument()">
                    <v-progress-circular v-if="isOrderTitlePlanLoading"
                                         :size="20"
                                         :width="3"
                                         color="primary"
                                         indeterminate />
                    <span v-else>{{ $t('action.order') }} ({{ documentCharge }})</span>
                </ow-button>
                <ow-tooltip v-if="orderTooltipText"
                            :content="orderTooltipText"
                            activator="parent"
                            max-width="300px">
                    {{ orderTooltipText }}
                </ow-tooltip>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
    import {
        computed,
        watch,
    } from 'vue'
    import { useI18n } from 'vue-i18n'
    import { useStore } from 'vuex'

    import OwButton from '@/components/core/ow-button-ds.vue'
    import OwDocumentAvailabilityLabel from '@/components/core/ow-document-availability-label.vue'
    import OwTooltip from '@/components/core/ow-tooltip.vue'
    import { HmlrDocumentAvailabilityCode } from '@/consts/document-availability'
    import { DocumentOrderStatus } from '@/consts/document-order-status'
    import { DOCUMENT_SOURCE } from '@/consts/document-source'
    import { DocumentOrderRequest } from '@/models/store/document-ordering/document-order-request.model'
    import { ORDER_DOCUMENTS } from '@/store/modules/document-ordering/types'
    import { TITLE_MUTATE_TITLE_PLAN_ORDER_LOADING } from '@/store/modules/titles/types'
    import {
        LOGGING_HEAP_TRACK_EVENT,
    } from '@/store/mutation-types'
    import { useApplicationStore } from '@/stores/application'

    const props = defineProps<{
        title: string
        isOrderingAllowed: boolean
        selectedTitleNumber?: string
        selectedTitle?: any
        currentMatterId?: number
    }>()

    const store = useStore()
    const { t } = useI18n()
    const applicationStore = useApplicationStore()

    const documentAvailabilityCode = computed(() => titlePlanAvailability.value?.hmlrAvailabilityCode)
    const titlePlanAvailability = computed(() => props.selectedTitle?.officialCopiesAvailability?.results?.titlePlan)
    const isDocumentUnderInvestigation = computed(() => documentAvailabilityCode.value === (DocumentOrderStatus.UNDER_INVESTIGATION).toString())
    const disableOrderButton = computed(() => (documentAvailabilityCode.value === HmlrDocumentAvailabilityCode.Unavailable || isDocumentUnderInvestigation.value) || !props.isOrderingAllowed)
    const isBackdated = computed(() => titlePlanAvailability.value?.hmlrAvailableVersionIsBackdated)
    const isOrderTitlePlanLoading = computed(() => titlePlanAvailability.value?.loadingOrder)
    const orderTooltipText = computed(() => {
        if (!props.isOrderingAllowed) {
            return t('documents.message.addTitleToMatter')
        }
        return null
    })

    const documentCharge = computed(() => applicationStore.getDocumentCostAsCurrencyString(DOCUMENT_SOURCE.HMLR))

    const orderDocument = async () => {
        store.commit(TITLE_MUTATE_TITLE_PLAN_ORDER_LOADING, true)

        const request = DocumentOrderRequest.createTitlePlanRequest(
            props.selectedTitleNumber,
            props.currentMatterId,
            false,
            isBackdated.value,
        )

        await store.dispatch(`documentOrdering/${ ORDER_DOCUMENTS }`, [ request ])

        await store.dispatch(LOGGING_HEAP_TRACK_EVENT, {
            type: 'Title Plan Ordered',
            metadata: {
                titleNumber: props.selectedTitleNumber,
                matterId: props.currentMatterId,
            },
        })
    }
</script>

<style scoped lang="scss">
@import './base-row.scss';
</style>
