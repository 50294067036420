import httpClient from '@/api/http-client'
import { IHttpClientResponse } from '@/interfaces/http-client-response.interface'

export type IGetTitleNumbersForUprnData = {
    titleNumber: string
    tenureShort: string
    estateInterest: string
    registeredStatus: string
}

export default class UprnApi {
    static END_POINT = 'uprn'

    /**
     * Search for an os uprn record by its uprn
     * @param uprn
     * @param controller
     * @returns Promise
     */
    static getOsRecordForUprn(uprn: string, controller = new AbortController()): Promise<any> {
        return httpClient.get(`${ this.END_POINT }/${ uprn }`, {
            signal: controller.signal,
        })
    }

    /**
     * Get the title numbers for a given uprn
     * @param uprn
     */
    static getTitleNumbersForUprn(uprn, controller = new AbortController()): Promise<IHttpClientResponse<IGetTitleNumbersForUprnData[]>> {
        return httpClient.get(`${ this.END_POINT }/${ uprn }/titles`, {
            signal: controller.signal,
        })
    }
}
