<template>
    <div class="title-page">
        <div class="title-page__header">
            <title-search-box show-single-result />
        </div>
        <div class="title-page__content">
            <div class="title-page__content-header">
                <h3 class="title-page__content-header--title content__heading">
                    <span class="title-page__content-header--title-number">
                        {{ selectedTitleNumber }}
                    </span>
                </h3>
                <ow-button v-if="!isLoading"
                           data-test="title-page-add-to-matter"
                           is-primary
                           large
                           @click="$emit('add-to-matter')">
                    Add to matter
                </ow-button>
            </div>
            <div class="title-page__content-tabs">
                <title-panel-tabs v-model="activeTab"
                                  :is-full-screen="true"
                                  :is-loading="isLoading"
                                  :is-ordering-allowed="false"
                                  :order-with-checkout="true"
                                  :show-title-complexity="false"
                                  scroll-element-container=".title-page__content"
                                  :map="map"
                                  @set-selected-tab="setSelectedTab">
                    <ow-title-boundary-map v-show="hasMap"
                                           :boundary-width="5"
                                           :enable-map-interactions="false"
                                           :geoserver-nps-layer-name="npsLayer"
                                           :layer-attribution="layerAttributionText"
                                           :title-number="selectedTitleNumber"
                                           boundary-colour="#f44336"
                                           class="title-page__map" />
                </title-panel-tabs>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    import {
        mapActions,
        mapState,
    } from 'vuex'

    import OwTitleBoundaryMap from '@/components/core/maps/ow-title-boundary-map.vue'
    import OwButton from '@/components/core/ow-button-ds.vue'
    import TitleSearchBox from '@/components/search/title-search-box.vue'
    import TitlePanelTabs from '@/components/title-panel/tabs/title-panel-tabs.vue'
    import { useTitlePanelTabs } from '@/composables/use-title-panel-tabs'
    import { TitlePanelTabName } from "@/enums/title-panel-tab-name"
    import { IState } from '@/interfaces/store/state.interface'
    import { getBoundaryDataAttribution } from '@/store/modules/map/layers/hmlr'
    import {
        PLANNING_HIDE_LAYERS,
        PLANNING_SEARCH_BY_TITLE_NUMBERS,
    } from '@/store/modules/planning/types'
    import { TITLE_CLEAR } from '@/store/modules/titles/types'

    export default {
        name: 'TitlePage',

        components: {
            OwButton,
            OwTitleBoundaryMap,
            TitleSearchBox,
            TitlePanelTabs,
        },

        props: {
            selectedTitleNumber: {
                type: String,
                required: true,
            },
            isLoading: {
                type: Boolean,
                required: false,
            },
        },

        emits: [
            'add-to-matter',
        ],

        setup() {
            const { activeTab, setSelectedTab } = useTitlePanelTabs()
            return {
                activeTab,
                setSelectedTab,
            }
        },

        computed: {
            ...mapState({
                monthlyHMLRDataVersion: (state: IState) => state.config.settings.monthlyHMLRDataVersion,
                npsLayer: (state: IState) => state.config.settings.npsLayer,
                map: (state: IState) => state.map.map,
            }),

            hasMap() {
                return Boolean(this.activeTab === TitlePanelTabName.Summary)
            },

            layerAttributionText() {
                return getBoundaryDataAttribution(this.monthlyHMLRDataVersion)
            },
        },

        unmounted() {
            this.clearTitle()
        },

        methods: {
            ...mapActions({
                clearTitle: TITLE_CLEAR,
            }),
        },
    }
</script>

<style lang="scss">
    @import '@/components/title-panel/title-page/title-page.scss';
</style>
