// The style guide colour palette is defined in src\ui\src\styles\variables.scss:
// Make these colours available to the Javascript through this enum.
export enum Colors {
    Primary = '#1d73ad',
    Secondary = '#131516',
    Accent = '#e98d45',
    Warning = '#b56100',
    Danger = '#d61d42',
    NpsLayerFreehold = '#f89801',
    NpsLayerLeasehold = '#00a4dd',
    NpsLayerOther = '#5a5a5a',
    CautionAgainstFirstRegistrationLayer = '#ff0000',
    EmphasiseUnregisteredColour = '#000000',


    Darks100 = '#e1e4e6',
    Darks200 = '#cfd2d5',
    Darks300 = '#b5b9bd',
    Darks400 = '#838b91',
    Darks500 = '#6a747b',
    Darks600 = '#515d65',
    Darks700 = '#1f2e39',

    Lights0 = '#ffffff',
    Lights100 = '#fefefe',
    Lights200 = '#f9f8f8',
    Lights300 = '#f5f5f5',
    Lights400 = '#f4f8fb',
    Lights500 = '#e4eef5',
    Lights600 = '#ddeaf3',
    Lights700 = '#d2e3ef',

    Blue300 = '#73c5e6',
    // Blue500= '#1d73ad', This is the same as Primary
    Blue900 = '#052153',

    Red700 = '#9a0e45',

    RedBoundary = '#EA0B12',
    BlueBoundary = '#0B77D5',
    GreyBoundary = '#7D8B98',
    GreenBoundary = '#099f03',

    Success250 = '#099f03',

    LogoWhite = '#ffffff',
    LogoBlue = '#006a87',
    LogoOrange = '#ff6e30',
}
