<template>
    <span :title="hint"
          class="ow-checkbox">
        <label v-if="label"
               :class="classesForLabel"
               :for="id"
               class="caption-regular">
            <input :id="id"
                   v-model="model"
                   :indeterminate.prop="indeterminate"
                   class="dd-privacy-allow"
                   :class="{ 'disabled': disabled }"
                   :aria-checked="modelValue"
                   :aria-labelledby="id"
                   :disabled="disabled"
                   role="checkbox"
                   type="checkbox" />
            <slot name="pre-label" />
            <span>{{ label }}</span>
            <ow-icon-key v-if="shortcut"
                         :label="shortcut" />
        </label>

        <input v-else
               :id="id"
               v-model="model"
               :class="{ 'disabled': disabled }"
               :indeterminate.prop="indeterminate"
               :aria-checked="modelValue"
               :aria-labelledby="id"
               :disabled="disabled"
               role="checkbox"
               type="checkbox" />
    </span>
</template>

<script lang="ts">
    import OwIconKey from '@/components/core/icons/ow-icon-key.vue'
    import { isNullOrWhitespace } from '@/utils/string-utils'

    export default {
        name: 'OwCheckbox',

        components: {
            OwIconKey,
        },

        props: {
            label: {
                type: String,
                required: false,
            },
            id: {
                type: String,
                required: true,
            },
            modelValue: {
                type: Boolean,
                required: false,
                default: false,
            },
            disabled: {
                type: Boolean,
                required: false,
                default: false,
            },
            indeterminate: {
                type: Boolean,
                default: false,
            },
            labelClassName: {
                type: String,
                required: false,
                default: '',
            },
            shortcut: {
                type: String,
                required: false,
            },
            hint: {
                type: String,
                required: false,
            },
        },

        emits: ['update:modelValue', 'input'],

        computed: {
            model: {
                get(): boolean {
                    return this.modelValue
                },
                set(newValue: boolean) {
                    // NOTE: The new update:modelValue event from Vue might get changed, relying on input won't break the component later
                    this.$emit('update:modelValue', newValue)
                    this.$emit('input', newValue)
                },
            },

            classesForLabel(): string {
                const classes = ['not-selectable']
                if (!isNullOrWhitespace(this.labelClassName)) {
                    classes.push(this.labelClassName)
                }

                if (this.modelValue && !this.disabled) {
                    classes.push('selected')
                }

                return classes.join(' ')
            },
        },
    }
</script>

<style lang="scss"
       scoped>
    @import './ow-checkbox';
</style>
