<template>
    <div class="document-library-table d-flex flex-column gr-3"
         :class="{
             '--no-header': hideColumnHeaders,
         }">
        <ow-data-grid v-model="selected"
                      v-model:items-per-page="pageSize"
                      v-model:active-page="activePage"
                      v-model:sort-by="sortBy"
                      v-model:items="items"
                      :show-pagination="false"
                      :has-pagination="paging"
                      item-key="id"
                      theme="light"
                      :hide-no-data="hideNoData"
                      :initial-items-per-page="50"
                      :headers="internalHeaders">
            <template #[`item.details`]="{ item } : { item: IDocumentRowData }">
                <document-library-table-row-detail :item="item" />
            </template>
            <template #[`item.actions`]="{ item } : { item: IDocumentRowData }">
                <document-library-table-row-detail-button-group :item="item" />
            </template>
            <template #[`item.entryNumbers`]="{ item } : { item: IDocumentRowData }">
                <span class="field field__entry-no">{{ item.entryNumbers ?? '-' }}</span>
            </template>
            <template #[`item.documentName`]="{ item } : { item: IDocumentRowData }">
                <div class="d-flex align-center gc-1"
                     :class="{
                         '--disabled-field': !showViewButton(item),
                     }">
                    <document-library-icon :item="item" />
                    <span :title="item.documentName"
                          @click="handleDocumentNameClick(item)">{{ item.documentName }}</span>
                </div>
            </template>
            <template #[`item.orderDate`]="{ item } : { item: IDocumentRowData }">
                <span class="field">{{ format(item.orderDate) ?? '-' }}</span>
            </template>
            <template #[`item.groupName`]="{ item } : { item: IDocumentRowData }">
                <span>{{ item.groupName ?? '-' }}</span>
            </template>
            <template #[`item.titleNumber`]="{ item } : { item: IDocumentRowData }">
                <div v-if="item?.titleNumber?.includes('+')"
                     :class="{
                         '--disabled-field': true,
                     }">
                    {{ item?.titleNumber ?? '' }}
                </div>
                <ow-title-number-tooltip v-else
                                         small
                                         :title-number="item?.titleNumber ?? '' " />
            </template>
            <template #[`item.type`]="{ item } : { item: IDocumentRowData }">
                <span>{{ getDocumentType(item) }}</span>
            </template>
            <template #noData>
                <document-library-no-data v-if="items.length === 0"
                                          @clear-filter="store.clearFilter()" />
            </template>
        </ow-data-grid>
    </div>
</template>

<script setup lang="ts">
    import {
        onMounted,
        ref,
    } from 'vue'
    import type { VDataTable } from 'vuetify/components'

    import OwDataGrid from '@/components/core/ow-data-grid.vue'
    import OwTitleNumberTooltip from '@/components/core/ow-title-number-tooltip.vue'
    import DocumentLibraryIcon from '@/components/document-library/document-library-icon.vue'
    import DocumentLibraryNoData from '@/components/document-library/document-library-no-data.vue'
    import DocumentLibraryTableRowDetail from '@/components/document-library/document-library-table-row-detail/document-library-table-row-detail.vue'
    import DocumentLibraryTableRowDetailButtonGroup
        from '@/components/document-library/document-library-table-row-detail/document-library-table-row-detail-button-group.vue'
    import { inject as useDocument } from '@/composables/use-document'
    import { inject as useDocumentLibrary } from '@/composables/use-document-library'
    import { IDocumentRowData } from '@/interfaces/document-row-data.interface'
    import { useDocumentLibraryStore } from '@/stores/document-library'
    import { format } from '@/utils/date-utils'

    withDefaults(defineProps<{
        paging?: boolean,
        hideColumnHeaders?: boolean,
        hideNoData?: boolean,
    }>(), {
        hideColumnHeaders: false,
        paging: true,
        hideNoData: false,
    })

    const pageSize = defineModel<number>('pageSize')
    const activePage = defineModel<number>('activePage')
    const items = defineModel<IDocumentRowData[]>('items')
    const store = useDocumentLibraryStore()

    const internalHeaders = ref<VDataTable['$props']['headers']>([])
    const { viewDocument, getDocumentType, showViewButton } = useDocument()
    const { getTableHeaders } = useDocumentLibrary()

    const selected = defineModel<any[]>('selected')
    const sortBy = defineModel('sortBy', {
        default: [{ key: 'orderDate', order: 'desc' }],
    })

    onMounted(() => {
        internalHeaders.value = getTableHeaders()
    })

    const handleDocumentNameClick = (item: IDocumentRowData) => {
        if (!showViewButton(item)) {
            return
        }
        viewDocument(item)
    }

</script>

<style lang="scss">
    @import './document-library-table.scss';
</style>
