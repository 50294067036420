// NOTE: Extend with additional report types as needed
export enum MAIN_API_REPORT_ENUM {
    TAR = 0,
    TAR_UNMERGED = 1
}

// TODO: AHHHHHHH
export enum DOC_TYPE_ENUM {
    EXCEL = 'EXCEL',
    DOC = 'DOC'
}
