<template>
    <div class="ow-pagination d-flex align-center"
         data-test="ow-pagination">
        <ow-button v-if="!allOptionSelected"
                   :color="actionButtonColor"
                   :disabled="currentPage === 1"
                   class="ow-pagination__button--prev"
                   data-test="pagination-prev-btn"
                   data-track="pagination-prev-btn"
                   icon
                   is-borderless
                   small
                   tile
                   @click="setCurrentPage(currentPage - 1)">
            <v-icon>$chevron-left</v-icon>
        </ow-button>

        <span v-for="pageNumber in pagesToShow"
              v-if="!allOptionSelected"
              :key="pageNumber"
              class="ow-pagination__page-number-wrapper">

            <ow-button v-if="showFinalEllipsis(pageNumber)"
                       :disabled="true"
                       class="ow-pagination__page-number-button body-copy"
                       icon
                       is-borderless
                       small
                       tile>...</ow-button>

            <ow-button :class="{
                           current: currentPage === pageNumber,
                       }"
                       :color="buttonColor(pageNumber)"
                       :data-test="`pagination-page-${ pageNumber }`"
                       :data-track="`pagination-page-${ pageNumber }`"
                       :outlined="pageNumber === currentPage"
                       class="ow-pagination__button--page-number body-copy"
                       icon
                       is-borderless
                       small
                       tile
                       @click="setCurrentPage(pageNumber)">
                {{ pageNumber }}
            </ow-button>

            <ow-button v-if="showStartEllipsis(pageNumber)"
                       :disabled="true"
                       class="ow-pagination__page-number-button body-copy"
                       icon
                       is-borderless
                       small
                       tile>...</ow-button>
        </span>

        <ow-button v-if="!allOptionSelected"
                   :color="actionButtonColor"
                   :disabled="currentPage === totalPages"
                   class="ow-pagination__button--next"
                   data-test="pagination-next-btn"
                   data-track="pagination-next-btn"
                   icon
                   is-borderless
                   small
                   tile
                   @click="setCurrentPage(currentPage + 1)">
            <v-icon>$chevron-right</v-icon>
        </ow-button>

        <ow-select :items="internalPageSizes"
                   :selected-value="itemsPerPage"
                   :disabled="disableItemsPerPageSelect"
                   is-dense
                   is-flat
                   is-single-line
                   hide-details
                   class="ow-pagination__items-per-page"
                   data-test-attribute="ow-pagination-page-size-select"
                   label="Results per page..."
                   @change="onPageSizeChange" />
    </div>
</template>

<script lang="ts">
    import owButton from '@/components/core/ow-button-ds.vue'
    import owSelect from '@/components/core/ow-select.vue'
    import { INITIAL_ITEMS_PER_PAGE } from '@/consts/pagination'
    import { OwButtonColor } from '@/enums/ow-button-color'
    import { useCoreComponentStore } from "@/stores/core"
    import { isNullOrEmpty } from '@/utils/array-utils'

    const EVENTS = {
        PAGE_CHANGE: 'page-change',
        PAGE_SIZE_CHANGE: 'page-size-change',
    }

    export default {
        name: 'OwPagination',

        components: {
            owButton,
            owSelect,
        },

        props: {
            resultCount: {
                type: Number,
                required: true,
            },
            includeAll: {
                type: Boolean,
                default: false,
            },
            externalItemsPerPage: {
                type: Number,
                required: false,
                default: INITIAL_ITEMS_PER_PAGE,
            },
            disableItemsPerPageSelect: {
                type: Boolean,
                required: false,
                default: false,
            },
            pageSizes: {
                type: Array,
                required: false,
                default: null,
            },
        },

        emits: Object.values(EVENTS),

        setup() {
            const coreComponentStore = useCoreComponentStore()
            return {
                coreComponentStore,
            }
        },

        data() {
            return {
                itemsPerPage: this.externalItemsPerPage,
                currentPage: 1,
                paginationRange: 5,
            }
        },

        computed: {
            internalPageSizes() {
                if (!isNullOrEmpty(this.pageSizes)) {
                    const result = this.pageSizes.map((pageSize: number) => {
                        return {
                            text: `${ pageSize } per page`,
                            value: pageSize,
                        }
                    })
                    if (this.includeAll) {
                        result.push({
                            text: 'All',
                            value: Number.MAX_SAFE_INTEGER,
                        })
                    }
                    return result
                }
                const result = [{
                    text: '3 per page',
                    value: 3,
                },{
                    text: '10 per page',
                    value: 10,
                }, {
                    text: '25 per page',
                    value: 25,
                }, {
                    text: '50 per page',
                    value: 50,
                }]
                if (this.includeAll) {
                    result.push({
                        text: 'All',
                        value: Number.MAX_SAFE_INTEGER,
                    })
                }
                return result
            },

            totalPages(): number {
                return this.getTotalPages(this.resultCount, this.itemsPerPage)
            },

            paginationRangeWithFirstAndLast(): number {
                // the `+2` is because we show the pagination range of 5, plus the first and last page numbers
                return this.paginationRange + 2
            },

            pagesToShow(): number[] {
                if (!this.hasMorePagesThanShown) {
                    return this.generatePageNumberArray(1, this.totalPages)
                }

                // Find current start position
                let start = this.currentPage - Math.floor(this.paginationRange / 2)
                start = Math.max(start, 1)
                start = Math.min(start, (this.totalPages - this.paginationRange))

                if (start < 1) {
                    start = 1
                }

                return this.generatePageNumberArray(start, this.paginationRange)
            },

            hasMorePagesThanShown(): boolean {
                return this.totalPages > this.paginationRangeWithFirstAndLast
            },

            actionButtonColor(): OwButtonColor {
                return OwButtonColor.DarkGrey
            },

            allOptionSelected() {
                return this.itemsPerPage === Number.MAX_SAFE_INTEGER
            },
        },

        watch: {
            externalItemsPerPage: {
                immediate: true,
                handler(val) {
                    this.itemsPerPage = val
                },
            },

            resultCount(val, oldVal) {
                let page = 1
                if (!this.coreComponentStore.pagination.resetPageAfterUpdate) {
                    const currentPageCount = this.getTotalPages(oldVal, this.itemsPerPage)
                    const newPageCount = this.getTotalPages(val, this.itemsPerPage)
                    page = this.currentPage == currentPageCount && (currentPageCount !== newPageCount)
                        ? newPageCount
                        : this.currentPage

                    this.coreComponentStore.updateResetPageAfterUpdate(true)
                }
                this.setCurrentPage(page)
            },
        },

        methods: {
            setCurrentPage(pageNumber: number): void {
                this.currentPage = pageNumber
                this.$emit(EVENTS.PAGE_CHANGE, this.currentPage)
            },

            buttonColor(page: any): OwButtonColor {
                return page === this.currentPage ? OwButtonColor.White : OwButtonColor.Secondary
            },

            showFinalEllipsis(pageNumber: number): boolean {
                // Only need to add in the ellipsis if more pages than range + 2 (first and last are the extra 2)
                if (this.hasMorePagesThanShown) {
                    const currentPageOutsideLastRange = this.currentPage <= this.totalPages - (this.paginationRange - 1)
                    return pageNumber === this.totalPages && currentPageOutsideLastRange
                }
                return false
            },

            showStartEllipsis(pageNumber: number): boolean {
                if (this.hasMorePagesThanShown) {
                    return pageNumber === 1 &&
                        this.currentPage >= this.paginationRange
                }
                return false
            },

            generatePageNumberArray(start: number, range: number): number[] {
                // Set the pageNumbers for the range in the array
                let pageRangeArray = Array.from({length: range}, (_, i) => start + i)

                // Always want to show first
                if (!pageRangeArray.includes(1)) {
                    pageRangeArray = [1, ...pageRangeArray]
                }

                // Always want to show last
                if (!pageRangeArray.includes(this.totalPages)) {
                    pageRangeArray = [...pageRangeArray, this.totalPages]
                }

                return pageRangeArray
            },

            onPageSizeChange(newPageSize: any): void {
                this.setCurrentPage(1)
                this.itemsPerPage = newPageSize.value
                this.$emit(EVENTS.PAGE_SIZE_CHANGE, this.itemsPerPage)
            },

            getTotalPages(resultCount: number, itemsPerPage: number): number {
                return Math.ceil(resultCount / itemsPerPage)
            },
        },
    }
</script>

<style lang="scss">
@import './ow-pagination';
</style>
