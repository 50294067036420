import GeoJSON from 'ol/format/GeoJSON'

import ApplicationEnquiryApi from '@/api/application-enquiry.api'
import BusinessGatewayApi from '@/api/business-gateway.api'
import CopiesAvailabilityApi from '@/api/copies-availability.api'
import LandRegistryApi from '@/api/land-registry.api'
import MatterApi from '@/api/matter.api'
import TitleApi from '@/api/title.api'
import TitleInformationApi from '@/api/title-information.api'
import TitlesApi from '@/api/titles.api'
import UprnApi from '@/api/uprn-api'
import { HighLevelDocumentType as HighLevelDocType } from '@/consts/document-high-level-type'
import { DocumentOrderStatus } from '@/consts/document-order-status'
import { DOCUMENT_SOURCE } from '@/consts/document-source'
import { MAX_ZOOM_TO_TITLE_ZOOM_LEVEL,
    PADDING } from '@/consts/map'
import { pricingPlans } from '@/consts/pricing-plans.js'
import { UploadedDocumentStatus } from '@/enums/uploaded-document-status.enum'
import { TrackedTitleListener } from '@/interfaces/store/organisation-hub/tracked-title'
import router from '@/router'
import { REFRESH_DOCUMENTS } from '@/store/modules/documents/documents-types'
import { LINK_SHARED_CLIENT_GET_IS_SHARED_LINK_VIEW } from '@/store/modules/link-share-client/types'
import { TitleSelectionLayer } from '@/store/modules/map/layers/title-selection-layer'
import { MAP_VALIDATE_NPS_DISPLAY } from '@/store/modules/map/types'
import {
    MATTER_ADD_TITLE,
    MATTER_MUTATE_TITLE,
    MATTER_UPDATE_CURRENT_MATTER_CHARGES,
} from '@/store/modules/matter/types'
import { ACTION_FROM_MATTER_HUB_DOCUMENT_UPLOAD_STATUS_UPDATED_MESSAGE } from '@/store/modules/matter-hub/types'
import { NPS_GET_FEATURES_BY_TITLE_NUMBER,
    NPS_LOAD_FEATURES_FOR_TITLE_NUMBERS } from '@/store/modules/nps/types'
import {
    ACTION_FROM_ORGANISATION_HUB_ORDER_MESSAGE,
    ORGANISATION_HUB_MUTATE_ADD_TRACKED_TITLE,
    ORGANISATION_HUB_MUTATE_REMOVE_TRACKED_TITLES_BY_LISTENER,
} from '@/store/modules/organisation-hub/types'
import {
    SITE_VISIT_LOOK_AT_SELECTED_TITLE,
    SITE_VISIT_UPDATE_TITLE_BOUNDARY_LAYER,
} from '@/store/modules/site-visit/types'
import {
    TITLE_CLEAR,
    TITLE_DAYLIST_EXPORT_CSV,
    TITLE_FETCH_BACKDATED_REGISTER,
    TITLE_FETCH_BACKDATED_TITLE_PLAN,
    TITLE_FETCH_NEW_REGISTER_STRING,
    TITLE_FETCH_NEW_TITLE_PLAN_STRING,
    TITLE_FETCH_OFFICIAL_COPIES_AVAILABILITY,
    TITLE_FETCH_REGISTER_STRING,
    TITLE_FETCH_TITLE_ADDRESSES,
    TITLE_FETCH_TITLE_PLAN_STRING,
    TITLE_FETCH_TITLE_SUMMARY_BY_NUMBER,
    TITLE_GET_INFORMATION_BY_TITLE_NUMBERS,
    TITLE_HIDE_MULTI_TITLE_SELECTION_PANEL,
    TITLE_LOOKUP_AND_ZOOM_TO_TITLE,
    TITLE_LOOKUP_TITLE,
    TITLE_LOOKUP_TITLE_BG,
    TITLE_LOOKUP_TITLE_DAYLIST,
    TITLE_MUTATE_ADDRESSES,
    TITLE_MUTATE_COLLAPSE_PANEL,
    TITLE_MUTATE_DAYLIST,
    TITLE_MUTATE_DAYLIST_ERROR,
    TITLE_MUTATE_DAYLIST_LOADING,
    TITLE_MUTATE_END_LOOKUP_TITLE,
    TITLE_MUTATE_ERROR_MESSAGE,
    TITLE_MUTATE_INFORMATION,
    TITLE_MUTATE_LOADING,
    TITLE_MUTATE_LOADING_OFFICIAL_COPIES_AVAILABILITY,
    TITLE_MUTATE_LOADING_STATE,
    TITLE_MUTATE_LOADING_SUMMARY_TITLE,
    TITLE_MUTATE_LOOKUP_TITLE_ERROR,
    TITLE_MUTATE_MULTI_TITLE_SELECTION_PANEL,
    TITLE_MUTATE_OC2_DATA_FROM_ORDER_MESSAGE,
    TITLE_MUTATE_OWNER_DATA_SOURCE_ON_SUMMARY,
    TITLE_MUTATE_REGISTER_DATA_FROM_ORDER_MESSAGE,
    TITLE_MUTATE_REGISTER_ORDER_LOADING,
    TITLE_MUTATE_SELECTED_SUMMARY_TITLE,
    TITLE_MUTATE_SELECTED_TITLE,
    TITLE_MUTATE_SELECTED_TITLE_LOADING,
    TITLE_MUTATE_SELECTED_TITLE_NUMBER,
    TITLE_MUTATE_SELECTED_TITLE_OFFICIAL_COPIES_AVAILABILITY,
    TITLE_MUTATE_SELECTED_TITLE_RECORD,
    TITLE_MUTATE_SELECTED_TITLE_RECORD_AFTER_BACKDATED_TITLE_PLAN,
    TITLE_MUTATE_SELECTED_TITLE_RECORD_AFTER_REGISTER,
    TITLE_MUTATE_SELECTED_TITLE_RECORD_AFTER_TITLE_PLAN,
    TITLE_MUTATE_SET_TITLES,
    TITLE_MUTATE_START_LOOKUP_DAYLIST,
    TITLE_MUTATE_START_LOOKUP_TITLE,
    TITLE_MUTATE_TITLE_PLAN_DATA_FROM_ORDER_MESSAGE,
    TITLE_MUTATE_TITLE_PLAN_ORDER_LOADING,
    TITLE_PANEL_COLLAPSE,
    TITLE_SELECT_BY_UPRN,
    TITLE_SET_SELECTED_TITLE_NUMBER,
    TITLE_SET_SELECTED_TITLE_RECORD,
    TITLE_SET_TITLES,
    TITLE_SHOW_MULTI_TITLE_SELECTION_PANEL,
    TITLE_SHOW_TITLE_BOUNDARY,
    TITLE_SHOW_USER_POPUP_AFTER_BG_FETCH,
    TITLE_ZOOM_TO_TITLE_AND_SHOW_BOUNDARY,
    TITLE_ZOOM_TO_TITLE_USING_UPRN,
} from '@/store/modules/titles/types'
import { LOGGING_REMOVE_STATE_PROPERTY,
    LOGGING_SET_STATE_PROPERTY,
    USER_SHOW_POPUP } from '@/store/mutation-types'
import { useScotlandStore } from '@/stores/scotland'
import { exportAsCsv } from '@/utils/csv-utils'
import { getHighLevelDocumentTypeFromDocumentType } from '@/utils/document-ordering-utils'
import { isNullOrWhitespace } from '@/utils/string-utils'

import defaultTitleStatus from './default-title-status'

export default {
    [TITLE_SHOW_MULTI_TITLE_SELECTION_PANEL]({ commit }) {
        commit(TITLE_MUTATE_MULTI_TITLE_SELECTION_PANEL, true)
        commit(TITLE_MUTATE_SELECTED_TITLE, defaultTitleStatus)
    },

    [TITLE_HIDE_MULTI_TITLE_SELECTION_PANEL]({ commit }) {
        commit(TITLE_MUTATE_MULTI_TITLE_SELECTION_PANEL, false)
    },

    [TITLE_SET_TITLES]({
        commit,
        dispatch,
    }, titles) {
        const mappedTitles = titles
            .map(t => {
                return {
                    titleNumber: t.titleNumber,
                    tenure: t.tenure,
                    addresses: null,
                    selected: false,
                }
            })

        commit(TITLE_MUTATE_SET_TITLES, mappedTitles)
        dispatch(TITLE_FETCH_TITLE_ADDRESSES, mappedTitles)
        dispatch(TITLE_GET_INFORMATION_BY_TITLE_NUMBERS, mappedTitles)

        if (mappedTitles.length > 0) {
            dispatch(TITLE_SHOW_MULTI_TITLE_SELECTION_PANEL)
        } else {
            dispatch(TITLE_HIDE_MULTI_TITLE_SELECTION_PANEL)
        }
    },

    async [TITLE_GET_INFORMATION_BY_TITLE_NUMBERS]({
        commit,
    }, titles) {
        const mappedTitles = titles.map(title => {
            return title.titleNumber
        })

        const response = await TitlesApi.getInformationByTitleNumbers(mappedTitles)
        commit(TITLE_MUTATE_INFORMATION, response)
    },

    async [TITLE_FETCH_TITLE_ADDRESSES]({
        commit,
    }, titles) {
        commit(TITLE_MUTATE_LOADING, true)
        const mappedTitles = titles.map(title => {
            return title.titleNumber
        })
        const addresses = await MatterApi.getTitleAddresses(mappedTitles)
        commit(TITLE_MUTATE_ADDRESSES, addresses)
    },

    // Called when selecting a title and displaying information about it in a title panel.
    async [TITLE_LOOKUP_TITLE]({
        commit,
        dispatch,
        getters,
        rootState,
        state,
    }, titleNumber) {
        if (titleNumber === undefined || titleNumber === null) {
            return
        }

        commit(`organisationHub/${ ORGANISATION_HUB_MUTATE_REMOVE_TRACKED_TITLES_BY_LISTENER }`, TrackedTitleListener.TitlePanel)
        commit(TITLE_MUTATE_LOADING_STATE, { lookupTitle: true })
        const oldTitleNumber = state.selectedTitleNumber
        commit(TITLE_MUTATE_SELECTED_TITLE, defaultTitleStatus)

        commit(TITLE_MUTATE_START_LOOKUP_TITLE)

        await dispatch(TITLE_SET_SELECTED_TITLE_NUMBER, titleNumber)

        const title = await TitleApi.getByTitleNumber(titleNumber, rootState.matter.currentMatter.id)

        if (title.ok) {
            await dispatch(TITLE_SET_SELECTED_TITLE_RECORD, title)
            commit(TITLE_MUTATE_END_LOOKUP_TITLE)

            if (title.titleInformation.titleMetadata.bgDataLoaded) {
                commit(TITLE_MUTATE_OWNER_DATA_SOURCE_ON_SUMMARY, false)
            }

            const isSharedLinkView = getters[`linkShareClient/${ LINK_SHARED_CLIENT_GET_IS_SHARED_LINK_VIEW }`]
            if (isSharedLinkView) {
                await dispatch(TITLE_FETCH_TITLE_SUMMARY_BY_NUMBER, {
                    titleNumber,
                    matterId: rootState.matter.currentMatter.id,
                })
            } else {
                await dispatch(TITLE_FETCH_OFFICIAL_COPIES_AVAILABILITY, {
                    titleNumber,
                    matterId: rootState.matter.currentMatter.id,
                })

                commit(`organisationHub/${ ORGANISATION_HUB_MUTATE_ADD_TRACKED_TITLE }`, {
                    titleNumber,
                    listener: TrackedTitleListener.TitlePanel,
                })
                // This data is not required at present for the read-only link share view
                dispatch(TITLE_LOOKUP_TITLE_DAYLIST)
            }

            if (rootState.user.pricingModelId === pricingPlans.PAY_PER_CLICK) {
                dispatch(MATTER_UPDATE_CURRENT_MATTER_CHARGES)
            }
        } else {
            await dispatch(USER_SHOW_POPUP, {
                title: 'Missing Title',
                icon: '$info',
                contentHTML: '<p>' +
                        'Sorry, this title number does not exist in our database, if you believe this to ' +
                        'be an error please email <a href=\'mailto:support@orbitalwitness.com\'>support@orbitalwitness.com</a>' +
                        '</p>',
            })
            commit(TITLE_MUTATE_LOOKUP_TITLE_ERROR, title.errorMessage)

            if (oldTitleNumber !== titleNumber) {
                router.back()
            }
        }

        commit(TITLE_MUTATE_LOADING_STATE, { lookupTitle: false })
        return title
    },

    async [TITLE_LOOKUP_TITLE_DAYLIST]({
        commit,
        state,
    }) {
        if (isNullOrWhitespace(state.selectedTitleNumber)) {
            return
        }
        commit(TITLE_MUTATE_START_LOOKUP_DAYLIST)

        try {
            //const response = await ApplicationEnquiryApi.getDayListByTitleNumbers(state.selectedTitleNumber)
            const response = await ApplicationEnquiryApi.getDayListByTitleNumbers(state.selectedTitleNumber, state.selectedTitle.record.source)
            if (response.ok) {
                commit(TITLE_MUTATE_DAYLIST, response)
            } else {
                console.error('Unable to get daylist')
                commit(TITLE_MUTATE_DAYLIST_ERROR, `Unable to get daylist for ${ state.selectedTitleNumber }: ${ response.message }`)
                commit(TITLE_MUTATE_ERROR_MESSAGE, `Unable to get daylist for ${ state.selectedTitleNumber }: ${ response.message }`)
            }
        } catch (e) {
            console.error('Unable to get daylist', e)
            commit(TITLE_MUTATE_DAYLIST_ERROR, `Unable to get daylist for ${ state.selectedTitleNumber }: ${ e.message }`)
            commit(TITLE_MUTATE_ERROR_MESSAGE, `Unable to get daylist for ${ state.selectedTitleNumber }: ${ e.message }`)
        }

        commit(TITLE_MUTATE_DAYLIST_LOADING, false)
    },

    async [TITLE_LOOKUP_TITLE_BG]({
        commit,
        dispatch,
        rootState,
    }, request) {
        commit(TITLE_MUTATE_SELECTED_TITLE_LOADING, true)
        commit(TITLE_MUTATE_OWNER_DATA_SOURCE_ON_SUMMARY, true)

        // Highlight the title in question
        dispatch(TITLE_SET_SELECTED_TITLE_NUMBER, request.titleNumber)

        const data = {
            titleNo: request.titleNumber,
            forceNew: request.forceNew !== undefined ? request.forceNew : false, // whether to get new data regardless
            forceNewIfNotToday: request.forceNewIfNotToday !== undefined ? request.forceNewIfNotToday : true, // whether to get new data if it hasn't already been loaded today
            allowBackdated: request.getBackdated !== undefined ? request.getBackdated : true, // whether backdated data
            matterId: rootState.matter.currentMatter.id,
            // is OK or not.
        }

        const response = await BusinessGatewayApi.getTitleInformation(data)
        if (response.ok) {
            dispatch(TITLE_SET_SELECTED_TITLE_RECORD, response.titleInformation)

            if (response.titleInformation.titleMetadata.isBackOrderCopy) {
                dispatch(USER_SHOW_POPUP, {
                    title: 'Pending Application',
                    icon: '$info',
                    contentHTML: '<p>' +
                        'This title has an application(s) pending against it and so we cannot provide the latest ' +
                        'view of the register. Backdated title information has been loaded instead.' +
                        '</p>',
                })
            }

            if (response.titleInformation.messages && response.titleInformation.messages.length > 0) {
                const fullMessageText = response.titleInformation.messages.join(' ')
                if (/\S/.test(fullMessageText || '')) {
                    dispatch(USER_SHOW_POPUP, {
                        title: 'Not available',
                        icon: '$info',
                        contentHTML: fullMessageText,
                    })
                }
            }

            commit(TITLE_MUTATE_SELECTED_TITLE_LOADING, false)

            // Add to current matter
            dispatch(MATTER_ADD_TITLE, {
                titleNumber: request.titleNumber,
                show: true,
            })

            // Update charges
            dispatch(MATTER_UPDATE_CURRENT_MATTER_CHARGES)

            // If user's organisation has a view title summary charge, then the PDF register will also now be available
            // from the document library
            dispatch(REFRESH_DOCUMENTS, null, { root: true })

            if (response.titleInformation.titleMetadata.bgDataLoaded) {
                commit(TITLE_MUTATE_OWNER_DATA_SOURCE_ON_SUMMARY, false)
            }
        } else {
            dispatch(USER_SHOW_POPUP, {
                title: '',
                icon: '$warning',
                contentHTML: '<p>' + response.data.errorMessage + '</p>',
            })
        }

        commit(TITLE_MUTATE_SELECTED_TITLE_LOADING, false)
    },

    [TITLE_CLEAR]({ dispatch, state, commit }, clearBoundary = true) {
        if (clearBoundary) {
            state.selectionLayer?.getSource().clear()
            state.selectionLayer?.getSource().changed()
            state.selectedTitleFeatures = []
        }
        commit(`organisationHub/${ ORGANISATION_HUB_MUTATE_REMOVE_TRACKED_TITLES_BY_LISTENER }`, TrackedTitleListener.TitlePanel)
        dispatch(TITLE_SET_SELECTED_TITLE_NUMBER, null)
        dispatch(TITLE_SET_SELECTED_TITLE_RECORD, null)
        commit(TITLE_MUTATE_SELECTED_SUMMARY_TITLE, null)
    },

    [TITLE_PANEL_COLLAPSE]({ commit }, collapse) {
        if (typeof collapse === 'undefined') {
            collapse = true
        }

        commit(TITLE_MUTATE_COLLAPSE_PANEL, collapse)
    },

    async [TITLE_SELECT_BY_UPRN]({ dispatch, rootState }, uprn) {
        // get title number for uprn
        // Depending on location start the flow for title lookup
        const response = await UprnApi.getOsRecordForUprn(uprn)
        if (response?.data) {
            const coordinate = [response.data.x, response.data.y]
            if (rootState?.map?.extents?.isScotland) {
                const scotlandStore = useScotlandStore()
                await scotlandStore.matchClickedCoordinate(coordinate)
            } else {
                const titleNumber = await LandRegistryApi.getTitleNumberByUprn(uprn)
                if (titleNumber) {
                    dispatch(TITLE_LOOKUP_TITLE, titleNumber)
                }
            }
        }
    },

    async [TITLE_ZOOM_TO_TITLE_AND_SHOW_BOUNDARY]({
        rootState,
        dispatch,
        state,
    }, titleNumber) {
        await dispatch(TITLE_SHOW_TITLE_BOUNDARY, titleNumber)
        if (titleNumber === null) {
            return
        }
        const extent = state.selectionLayer.getSource().getExtent()
        const view = rootState?.map?.map?.getView()
        if (view) {
            view.fit(extent,
                {
                    maxZoom: MAX_ZOOM_TO_TITLE_ZOOM_LEVEL,
                    duration: 200,
                    padding: PADDING,
                })
        }
    },

    async [TITLE_ZOOM_TO_TITLE_USING_UPRN]({ rootState }, uprn) {
        const view = rootState?.map?.map?.getView()
        if (view) {
            try {
                const response = await UprnApi.getOsRecordForUprn(uprn)
                if (response?.data) {
                    view.setCenter([response.data.x, response.data.y])
                    view.setZoom(MAX_ZOOM_TO_TITLE_ZOOM_LEVEL)
                }
            } catch {
                console.error(`Could not find os record for uprn: ${ uprn }`)
            }
        }
    },

    async [TITLE_LOOKUP_AND_ZOOM_TO_TITLE]({
        dispatch,
        rootState,
        state,
    }, titleNumber) {
        const title = await dispatch(TITLE_LOOKUP_TITLE, titleNumber)

        // If title is found, then carry on.
        // There are cases where the title number doesn't exist in our DB
        if (title?.titleInformation?.bbox) {
            const bbox = (new GeoJSON()).readFeature(JSON.parse(title.titleInformation.bbox))
            const extent = bbox.getGeometry().getExtent()
            const view = rootState.map.map.getView()
            dispatch(MAP_VALIDATE_NPS_DISPLAY)
            view.fit(extent,
                {
                    maxZoom: MAX_ZOOM_TO_TITLE_ZOOM_LEVEL,
                    duration: 200,
                    padding: PADDING,
                })
            dispatch(TITLE_SHOW_TITLE_BOUNDARY, state.selectedTitleNumber)
        }
    },

    async [TITLE_SHOW_TITLE_BOUNDARY]({
        dispatch,
        rootState,
        state,
        getters,
    }, titleNumber) {
        // initialize layer if required
        if (state.selectionLayer == null) {
            // TODO: the entire title selection layer needs refactoring/removing. Consider implementing as part of title boundary layer.
            const titleLayerInternal = new TitleSelectionLayer({
                getSelectedTitleFeaturesFn: () => state.selectedTitleFeatures,
                targetMap: rootState.map.map,
            })
            state.selectionLayer = titleLayerInternal.getLayer()

            state.selectedTitleFeatures = []
            rootState.map.map?.addLayer(state.selectionLayer)
            state.selectionLayer.getSource().on('addfeature', function(ft) {
                state.selectionLayer.getSource().changed()
                state.selectedTitleFeatures.push(ft.feature)

                // Update GSV if required
                dispatch(SITE_VISIT_UPDATE_TITLE_BOUNDARY_LAYER, titleNumber)
                dispatch(SITE_VISIT_LOOK_AT_SELECTED_TITLE)
            })
        }

        state.selectionLayer.getSource().clear()
        if (titleNumber !== null) { // passing a null title number subsequently clears the selection layer only.
            await dispatch(NPS_LOAD_FEATURES_FOR_TITLE_NUMBERS, [titleNumber])
            const features = getters[NPS_GET_FEATURES_BY_TITLE_NUMBER](titleNumber)
            state.selectionLayer.getSource().clear()

            if (features) {
                state.selectionLayer.getSource().addFeatures(features)
                state.selectionLayer.getSource().changed()
            }
        }
        state.selectionLayer.getSource().changed()
    },

    [TITLE_SET_SELECTED_TITLE_NUMBER]({
        commit,
        dispatch,
        state,
    }, titleNumber) {
        const hasChanged = titleNumber !== state.selectedTitleNumber
        if (!hasChanged) {
            return
        }
        commit(TITLE_MUTATE_SELECTED_TITLE_NUMBER, titleNumber)

        // Include the title number in the logging metadata
        if (titleNumber != null) {
            dispatch(LOGGING_SET_STATE_PROPERTY, {
                property: 'Selected Title - Number',
                value: titleNumber.toString(),
            })
        } else {
            dispatch(LOGGING_REMOVE_STATE_PROPERTY, 'Selected Title - Number')
        }

        dispatch('_updateNPSStyle')
        if (state.selectionLayer) {
            state.selectionLayer.changed()
        }
    },

    [TITLE_SET_SELECTED_TITLE_RECORD]({
        commit,
        dispatch,
        state,
    }, val) {
        // Assign colour coding to the indicators/flags
        if (val != null) {
            if (val?.titleInformation?.bgProprietorshipData?.linkedIndicators) {
                val.titleInformation.bgProprietorshipData.linkedIndicators.forEach(function(indicator, index) {
                    indicator.colour = state.registerFlagColourPalette[index]
                })
            }
        }

        // update state
        commit(TITLE_MUTATE_SELECTED_TITLE_RECORD, val)

        // Update logging properties about the title

        // Remove current title information from logging
        dispatch(LOGGING_REMOVE_STATE_PROPERTY, 'Selected Title - Downloaded from LR')
        dispatch(LOGGING_REMOVE_STATE_PROPERTY, 'Selected Title - Downloaded from LR Date')
        dispatch(LOGGING_REMOVE_STATE_PROPERTY, 'Selected Title - Has Applications Pending')
        dispatch(LOGGING_REMOVE_STATE_PROPERTY, 'Selected Title - Tenure')
        dispatch(LOGGING_REMOVE_STATE_PROPERTY, 'Selected Title - Owner Type')
        // Remove indicators/flags
        state.selectedTitle.indicatorNames.forEach(function(name) {
            dispatch(LOGGING_REMOVE_STATE_PROPERTY, 'Selected Title - Flag - ' + name)
        })

        // Add title information to logging if present
        const record = state.selectedTitle.record
        if (record != null) {
            dispatch(LOGGING_SET_STATE_PROPERTY, {
                property: 'Selected Title - Downloaded from LR',
                value: record.titleMetadata.bgDataLoaded,
            })
            dispatch(LOGGING_SET_STATE_PROPERTY, {
                property: 'Selected Title - Downloaded from LR Date',
                value: record.titleMetadata.dateBGDataDownloaded,
            })
            dispatch(LOGGING_SET_STATE_PROPERTY, {
                property: 'Selected Title - Has Applications Pending',
                value: record.titleMetadata.isBackOrderCopy,
            })
            dispatch(LOGGING_SET_STATE_PROPERTY, {
                property: 'Selected Title - Tenure',
                value: record.tenure,
            })

            // Owner types e.g. 'Limited Liability Partnership' as displayed under 'Proprietorship' on title summary
            // tab.
            if (record.lrProprietorshipData != null && record.titleMetadata.lrDataLoaded) {
                const ownerTypes = record.lrProprietorshipData.owners.map(function(owner) {
                    return owner.proprietorship
                })
                if (ownerTypes.length > 0) {
                    dispatch(LOGGING_SET_STATE_PROPERTY, {
                        property: 'Selected Title - Owner Type(s)',
                        value: ownerTypes.join(', '),
                    })
                }
            }

            // Indicators
            record.bgProprietorshipData.indicators.forEach(function(indicator) {
                if (indicator.val === true) {
                    dispatch(LOGGING_SET_STATE_PROPERTY, {
                        property: 'Selected Title - Flag - ' + indicator.label,
                        value: indicator.val.toString(),
                    })
                }
            })
        }
    },

    async [TITLE_FETCH_TITLE_PLAN_STRING]({
        commit,
        dispatch,
        rootState,
    }, titleNumber) {
        commit(TITLE_MUTATE_LOADING_STATE, { orderTitlePlan: true })

        const response = await BusinessGatewayApi.getTitlePlanPdfStringByTitle(titleNumber, rootState.matter.currentMatter.id)
        dispatch(MATTER_UPDATE_CURRENT_MATTER_CHARGES, { root: true })

        if (response.ok) {
            commit(TITLE_MUTATE_LOADING_STATE, { orderTitlePlan: false })
            // Want to reload the title, so clear the existing entry
            commit(TITLE_MUTATE_SELECTED_TITLE_NUMBER, null)
            dispatch(TITLE_LOOKUP_TITLE, titleNumber)
            return response.matterDocumentId
        }

        commit(TITLE_MUTATE_SELECTED_TITLE_RECORD_AFTER_TITLE_PLAN, response)
        dispatch(TITLE_SHOW_USER_POPUP_AFTER_BG_FETCH, response)

        commit(TITLE_MUTATE_LOADING_STATE, { orderTitlePlan: false })
    },

    async [TITLE_FETCH_NEW_TITLE_PLAN_STRING]({
        commit,
        dispatch,
        rootState,
    }, titleNumber) {
        commit(TITLE_MUTATE_LOADING_STATE, { orderTitlePlan: true })

        const response = await BusinessGatewayApi.getNewTitlePlanPdfStringByTitle(titleNumber, rootState.matter.currentMatter.id)
        dispatch(MATTER_UPDATE_CURRENT_MATTER_CHARGES, { root: true })

        if (response.ok) {
            commit(TITLE_MUTATE_LOADING_STATE, { orderTitlePlan: false })
            return {
                ok: true,
                data: response?.matterDocumentId ? response.matterDocumentId : response.data?.filename,
            }
        }

        commit(TITLE_MUTATE_SELECTED_TITLE_RECORD_AFTER_TITLE_PLAN, response)
        dispatch(TITLE_SHOW_USER_POPUP_AFTER_BG_FETCH, response)

        commit(TITLE_MUTATE_LOADING_STATE, { orderTitlePlan: false })

        return {
            ok: false,
            data: response.data,
        }
    },

    async [TITLE_FETCH_REGISTER_STRING]({
        commit,
        dispatch,
        rootState,
    }, titleNumber) {
        commit(TITLE_MUTATE_LOADING_STATE, { orderRegister: true })

        const response = await BusinessGatewayApi.getRegisterPdfStringByTitle(titleNumber, rootState.matter.currentMatter.id)
        dispatch(MATTER_UPDATE_CURRENT_MATTER_CHARGES, { root: true })

        if (response.ok) {
            commit(TITLE_MUTATE_LOADING_STATE, { orderRegister: false })
            return {
                ok: true,
                data: response?.matterDocumentId ? response.matterDocumentId : response.data?.filename,
            }
        }

        commit(TITLE_MUTATE_SELECTED_TITLE_RECORD_AFTER_REGISTER, response)
        dispatch(TITLE_SHOW_USER_POPUP_AFTER_BG_FETCH, response)

        commit(TITLE_MUTATE_LOADING_STATE, { orderRegister: false })

        return {
            ok: false,
            data: response.data,
        }
    },

    async [TITLE_FETCH_NEW_REGISTER_STRING]({
        commit,
        dispatch,
        rootState,
    }, titleNumber) {
        commit(TITLE_MUTATE_LOADING_STATE, { orderRegister: true })

        const response = await BusinessGatewayApi.getNewRegisterPdfStringByTitle(titleNumber, rootState.matter.currentMatter.id)
        dispatch(MATTER_UPDATE_CURRENT_MATTER_CHARGES, { root: true })

        if (response.ok) {
            commit(TITLE_MUTATE_LOADING_STATE, { orderRegister: false })
            return response.matterDocumentId
        }

        commit(TITLE_MUTATE_SELECTED_TITLE_RECORD_AFTER_REGISTER, {
            ...response,
            updateMessage: true,
        })

        dispatch(TITLE_SHOW_USER_POPUP_AFTER_BG_FETCH, response)
        commit(TITLE_MUTATE_LOADING_STATE, { orderRegister: false })
    },

    async [TITLE_FETCH_BACKDATED_REGISTER]({
        commit,
        dispatch,
        rootState,
    }, titleNumber) {
        commit(TITLE_MUTATE_LOADING_STATE, { orderRegister: true })

        const response = await BusinessGatewayApi.getBackDatedRegisterByTitle(titleNumber, rootState.matter.currentMatter.id)
        dispatch(MATTER_UPDATE_CURRENT_MATTER_CHARGES, { root: true })

        if (response.ok) {
            commit(TITLE_MUTATE_LOADING_STATE, { orderRegister: false })

            return {
                ok: true,
                data: response?.matterDocumentId ? response.matterDocumentId : response.data.filename,
            }
        }

        dispatch(TITLE_SHOW_USER_POPUP_AFTER_BG_FETCH, response)

        commit(TITLE_MUTATE_LOADING_STATE, { orderRegister: false })

        return {
            ok: false,
            data: response.data,
        }
    },

    async [TITLE_FETCH_BACKDATED_TITLE_PLAN]({
        commit,
        dispatch,
        rootState,
    }, titleNumber) {
        commit(TITLE_MUTATE_LOADING_STATE, { orderTitlePlan: true })

        const response = await BusinessGatewayApi.getBackDatedTitlePlanByTitle(titleNumber, rootState.matter.currentMatter.id)
        dispatch(MATTER_UPDATE_CURRENT_MATTER_CHARGES, { root: true })

        if (response.ok) {
            commit(TITLE_MUTATE_LOADING_STATE, { orderTitlePlan: false })
            return {
                ok: true,
                data: response?.matterDocumentId ? response.matterDocumentId : response.data.filename,
            }
        }

        commit(TITLE_MUTATE_SELECTED_TITLE_RECORD_AFTER_BACKDATED_TITLE_PLAN, response)
        dispatch(TITLE_SHOW_USER_POPUP_AFTER_BG_FETCH, response)

        commit(TITLE_MUTATE_LOADING_STATE, { orderTitlePlan: false })

        return {
            ok: false,
            data: response.data,
        }
    },

    [TITLE_SHOW_USER_POPUP_AFTER_BG_FETCH]({ dispatch }, response) {
        if (response.showAlternative) {
            dispatch(USER_SHOW_POPUP, {
                title: 'Pending Application',
                icon: '$info',
                contentHTML: '<p>This title has an application(s) pending against it and so we cannot provide ' +
                    'the latest Official Copies. However, you can still access backdated documents.</p>',
            }, { root: true })
        } else {
            if (response.message) {
                const contentHTML = `<p>${ response.message }</p>`
                let title = ''
                if (response.message === 'This title is too large for the Land Registry to provide a digitised copy' +
                    ' of the Title Plan. A paper copy will be sent to your address in the post.') {
                    title = 'Document to be sent in the post'
                }

                dispatch(USER_SHOW_POPUP, {
                    title,
                    icon: '$warning',
                    contentHTML,
                }, { root: true })
            }
        }
    },

    async [TITLE_FETCH_TITLE_SUMMARY_BY_NUMBER]({ commit }, {
        matterId,
        titleNumber,
        titleLabel,
    }) {
        if (isNullOrWhitespace(titleNumber)) {
            return
        }

        commit(TITLE_MUTATE_LOADING_SUMMARY_TITLE, true)
        try {
            commit(TITLE_MUTATE_SELECTED_SUMMARY_TITLE, null)

            const response = await TitleInformationApi.getTitleInformationByTitleNumber(matterId, titleNumber)
            // The API request returns null if nothing found, although that shouldn't happen as the
            // title is part of the matter, so should return something.
            if (response) {
                commit(TITLE_MUTATE_SELECTED_SUMMARY_TITLE, {
                    ...response,
                    label: titleLabel ?? titleNumber,
                })
            }
        } catch (e) {
            console.error('Unable to get title summary', e)
        } finally {
            commit(TITLE_MUTATE_LOADING_SUMMARY_TITLE, false)
        }
    },

    async [TITLE_FETCH_OFFICIAL_COPIES_AVAILABILITY]({ commit }, {
        matterId,
        titleNumber,
    }) {
        commit(TITLE_MUTATE_LOADING_OFFICIAL_COPIES_AVAILABILITY, true)

        const ocAvailabilityResponse = await CopiesAvailabilityApi.officialCopiesAvailability(titleNumber, matterId)
        if (ocAvailabilityResponse) {
            commit(TITLE_MUTATE_SELECTED_TITLE_OFFICIAL_COPIES_AVAILABILITY, ocAvailabilityResponse)
        }
        commit(TITLE_MUTATE_LOADING_OFFICIAL_COPIES_AVAILABILITY, false)

        return ocAvailabilityResponse
    },

    [TITLE_DAYLIST_EXPORT_CSV]({ state }) {

        // Check if a title is selected
        if (!state?.selectedTitle) {
            throw new Error('No title selected')
        }

        // Check if there is daylist data to export
        const dayListData = state.selectedTitle?.daylist?.data
        if (!dayListData) {
            throw new Error('No daylist data to export')
        }

        // Get the source of the document
        const documentSource = state.selectedTitle.record?.source

        // Define the field map
        let fieldMap = []
        switch (documentSource) {
            case DOCUMENT_SOURCE.SCOTLAND:
                fieldMap = [
                    { key: 'mockField', title: 'Mock until integration' },
                ]
                break
            default: {
                fieldMap = [
                    { key: 'applicationReference', title: 'Application Id' },
                    { key: 'applicationType', title: 'Application Type' },
                    { key: 'priorityDateTime', title: 'Priority Date Time' },
                    { key: 'lodgedBy', title: 'Logged By' },
                    { key: 'propertyDescription', title: 'Property Description' },
                    { key: 'customerReference', title: 'Customer Reference' },
                    { key: 'applicant', title: 'Applicant' },
                    { key: 'depositReason', title: 'Deposit Reason' },
                    { key: 'searchCertificateNumber', title: 'Search Certificate Number' },
                    { key: 'searchInterest', title: 'Search Interest' },
                    { key: 'oLALodgedFor', title: 'OLA Logged For' },
                    { key: 'applicationProgress', title: 'Application Progress - Description' },
                ]
            }
        }

        // Generate the CSV data
        const headers = fieldMap.map((field) => field.title)
        const data = dayListData.map((enquiry) => {
            return fieldMap.map((field) => {

                // Add custom field logic here
                switch (field.key) {
                    case 'applicationProgress':
                        return enquiry.applicationProgress?.description || ''
                    case 'priorityDateTime': {
                        return `${ enquiry.priorityDate } ${ enquiry.priorityTime }`
                    }
                    default:
                        return enquiry[field.key] || ''
                }
            })
        })
        const rows = [headers, ...data]

        // remove empty columns
        for (let columnIndex = rows[0].length - 1; columnIndex >= 0; columnIndex--) {
            let empty = true
            for (let currentRow = 1; currentRow < rows.length; currentRow++) {
                if (!isNullOrWhitespace(rows[currentRow][columnIndex])) {
                    empty = false
                    break
                }
            }
            if (empty) {
                for (let j = 0; j < rows.length; j++) {
                    rows[j].splice(columnIndex, 1)
                }
            }
        }

        // Generate the filename
        let filename = `pending-applications-data-export-${ new Date().toISOString().split('T')[0] }`
        filename += `-${ state.selectedTitleNumber }`

        // Export the CSV
        exportAsCsv(rows, filename)
    },

    async [ACTION_FROM_ORGANISATION_HUB_ORDER_MESSAGE]({ state, dispatch, commit }, orderMessage) {
        const update = orderMessage.message
        if (update.titleNumber !== state.selectedTitleNumber) {
            // We're not showing this title number nor anything related to it, nothing needs updating.
            return
        }
        const highLevelDocType = getHighLevelDocumentTypeFromDocumentType(update.notificationData.documentType)
        // Update title register related properties
        switch (highLevelDocType) {
            case HighLevelDocType.Register:
                // Indicate if the order is in progress
                commit(TITLE_MUTATE_REGISTER_ORDER_LOADING, update.status === DocumentOrderStatus.ORDERING)
                switch (update.status) {
                    // The digital register should be available, reload everything for the selected title.
                    // Ideally this would be consolidated into one action; but this is how it's being done elsewhere.
                    case DocumentOrderStatus.ORDERED:
                    case DocumentOrderStatus.DERIVED:
                        commit(MATTER_MUTATE_TITLE, {
                            titleNumber: update.titleNumber,
                            isRegisterOrdered: true,
                        })
                        commit(TITLE_MUTATE_SELECTED_TITLE_NUMBER, null)
                        await dispatch(TITLE_LOOKUP_TITLE, update.titleNumber)
                        break
                    default:
                        // Update the state properties for the selected title.
                        commit(TITLE_MUTATE_REGISTER_DATA_FROM_ORDER_MESSAGE, update)
                        break
                }
                break
            case HighLevelDocType.OC2Document:
                // Update the state properties for the selected title's OC2 documents.
                commit(TITLE_MUTATE_OC2_DATA_FROM_ORDER_MESSAGE, update)
                break
            case HighLevelDocType.TitlePlan:
                commit(TITLE_MUTATE_TITLE_PLAN_ORDER_LOADING, update.status === DocumentOrderStatus.ORDERING)
                // Update the state properties for the selected title's title plan.
                commit(TITLE_MUTATE_TITLE_PLAN_DATA_FROM_ORDER_MESSAGE, update)
                break
        }
    },

    async [ACTION_FROM_MATTER_HUB_DOCUMENT_UPLOAD_STATUS_UPDATED_MESSAGE]({ dispatch, state, commit }, messageData) {
        if (messageData?.status === UploadedDocumentStatus.complete && messageData?.titleNumber === state?.selectedTitleNumber) {
            commit(TITLE_MUTATE_SELECTED_TITLE_NUMBER, null)
            await dispatch(TITLE_LOOKUP_TITLE, messageData?.titleNumber)
        }
    },
}
