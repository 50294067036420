import {     computed,
    ComputedRef,
    Ref,
    ref,
    watch } from "vue"
import { useStore } from "vuex"

import { useCopilot } from "@/components/copilot/use-copilot"
import { ApplicationRoles } from "@/enums/application-roles.enum"
import i18n from "@/plugins/i18n"

export type AppNavItem = {
    id: string,
    title: string,
    subtitle: string,
    icon: string,
    url?: string,
}

const useAppNav = (args: {
    clientCode?: string,
    matterCode?: string,
    matterId?: number,
}): {
    navItems: Ref<AppNavItem[]>,
    selected: string,
    enabled: Ref<boolean>,
} => {
    const store = useStore()
    const { copilotUrl } = useCopilot({
        clientCode: args?.clientCode ?? '',
        matterCode: args?.matterCode ?? '',
        matterId: args?.matterId ?? 0,
        position: 'App Navigation',
    })

    const selected = computed(() => 'witness')
    const internalNavItems = ref<AppNavItem[]>([])

    watch(() => store.state.config.featureFlags, (flags) => {
        const result: AppNavItem[] = []
        result.push({
            id: 'witness',
            title: i18n.global.t('appNav.witness.title'),
            subtitle: i18n.global.t('appNav.witness.subtitle'),
            icon: 'ow-icon-ow-logo',
        })

        // add copilot
        let hasCopilotRole = false
        if (store.state.user?.roles?.length) {
            hasCopilotRole = store.state.user.roles.includes(ApplicationRoles.Copilot)
        }
        if (hasCopilotRole) {
            result.push({
                id: 'copilot',
                title: i18n.global.t('appNav.copilot.title'),
                subtitle: i18n.global.t('appNav.copilot.subtitle'),
                icon: 'ow-icon-copilot-logo',
                url: copilotUrl.value,
            })
        }
        internalNavItems.value = result
    }, {
        immediate: true,
        deep: true,
    })
    return {
        selected: selected.value,
        navItems: internalNavItems,
        enabled: computed(() => internalNavItems.value.length > 1),
    }
}

export default useAppNav
