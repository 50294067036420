import developmentConfig from '@/config/dev.json'
import localhostConfig from '@/config/local.json'
import productionConfig from '@/config/production.json'

const PRODUCTION_HOST_NAME = 'app.orbitalwitness.com'
const NEW_PRODUCTION_HOST_NAME = 'witness.orbital.tech'
const PRODUCTION_RAW_HOST_NAME = 'white-bush-0eb8a3e03.westeurope.4.azurestaticapps.net'
const DEV_HOST_NAME = 'app-dev.intow.tech'
const DEV_RAW_HOST_NAME = 'jolly-beach-0636c2803-2712.westeurope.azurestaticapps.net'

export const ENVIRONMENT = {
    LOCALHOST: 'localhost',
    DEVELOPMENT: 'Development',
    PRE_PRODUCTION: 'Pre-production',
    PRODUCTION: 'Production',
}

export const getAppUri = () => {
    return window.location.origin
}

export const isProductionEnvironment = () => {
    const uri = getAppUri()
    return uri.includes(PRODUCTION_HOST_NAME) ||
        uri.includes(PRODUCTION_RAW_HOST_NAME) ||
        uri.includes(NEW_PRODUCTION_HOST_NAME)
}

export const isPreProductionEnvironment = () => {
    return getAppUri().match(/white-bush-([0-9a-f]{5,40}-[0-9]*)/g)
}

export const isDevEnvironment = () => {
    return getAppUri().includes(DEV_HOST_NAME) ||
        getAppUri().includes(DEV_RAW_HOST_NAME)
}

export const isLocalDevEnvironment = () => {
    return getAppUri().includes('localhost')
}

export const getCurrentEnvironmentName = () => {
    if (isProductionEnvironment()) {
        return ENVIRONMENT.PRODUCTION
    }

    if (isPreProductionEnvironment()) {
        return ENVIRONMENT.PRE_PRODUCTION
    }

    if (isDevEnvironment()) {
        return ENVIRONMENT.DEVELOPMENT
    }

    return ENVIRONMENT.LOCALHOST
}

export const currentConfiguration = () => {
    switch (getCurrentEnvironmentName()) {
        case ENVIRONMENT.PRODUCTION:
        case ENVIRONMENT.PRE_PRODUCTION:
            return productionConfig

        case ENVIRONMENT.DEVELOPMENT:
            return developmentConfig

        default:
            return localhostConfig
    }
}

export const getApiUri = () => {
    return currentConfiguration().legalApiUri
}

export const getGraphQlUri = () => {
    return currentConfiguration().graphQlUri
}

export const getGeoserverApiUri = () => {
    return currentConfiguration().geoserverApiUri
}

export const getDocumentOrderingAPIUri = () => {
    return currentConfiguration().documentOrderingApiUri
}

export const getDocumentUploadingApiUri = () => {
    return currentConfiguration().documentUploadingApiUri
}

export const getDocumentManagerUri = () => {
    return currentConfiguration().documentManagerUri
}

export const getReportingApiUri = () => {
    return currentConfiguration().reportingApiUri
}

export const getEpcAPIUri = () => {
    return currentConfiguration().epcUri
}

export const getSnapshotApiUri = () => {
    return currentConfiguration().snapshotApiUri
}

export const getLaunchDarklyKey = () => {
    return currentConfiguration().launchDarklyKey
}

export const getNotificationHubUri = () => {
    return currentConfiguration().notificationHubUri
}

export const getMatterHubUri = () => {
    return `${ getNotificationHubUri() }/notification-hub/matterhub`
}

export const getOrganisationHubUri = () => {
    return `${ getNotificationHubUri() }/notification-hub/organisationhub`
}

export const getOrganisationSharedLogoURL = (organisationId) => {
    return `${ currentConfiguration().uploadedBlobContentUri }/organisation-public-media/${ organisationId }/shared.png`
}

export const getOSDataHubAPIKey = () => {
    return currentConfiguration().osMapApiKey
}

export const getDatadogConfig = () => {
    return currentConfiguration().datadog
}

export const isWatchersLoggingEnabled = () => {
    return currentConfiguration().isWatchersLoggingEnabled
}

export const getCopilotURL = () => {
    return currentConfiguration().copilotURL
}

export const getOverlaysApiUri = () => {
    return currentConfiguration().overlaysApiUri
}

export const getSearchesApiUri = () => {
    return currentConfiguration().searchesApiUri
}
