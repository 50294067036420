<template>
    <ow-full-width-layout data-test="reporting-page"
                          header-icon="$reporting"
                          header-title="Reporting"
                          page-name="reporting-page"
                          inline-step-flow>
        <h3 v-if="origin !== 'REPORTING-HOMEPAGE'"
            class="reporting-page__subheader">
            <router-link :to="backLink"
                         class="reporting-page__subheader__previous"
                         data-track="REPORTING-HOMEPAGE - Navigate back">
                {{ backNavigation.text }}
            </router-link>
            <v-icon>$chevron-right</v-icon>
            <span class="reporting-page__subheader__current">
                {{ backNavigation.current }}
            </span>
        </h3>

        <div class="reporting-page__content"
             :class="{'--subheader': origin !== 'REPORTING-HOMEPAGE'}">
            <div class="reporting-page__content--template-select">
                <template v-if="isLoading">
                    <div class="reporting-page__template-container">
                        <ow-loading-skeleton v-for="i in [0, 1, 2, 3, 4]"
                                             :key="i"
                                             class="reporting-page__template-card reporting-page__template-card-loading-skeleton"
                                             style="min-height: 345px; width: 360px" />
                    </div>
                </template>
                <div v-else
                     class="reporting-page__template-container">
                    <template v-for="template in templates">
                        <template-card v-if="!template.is_request"
                                       :key="template.name"
                                       :data-track="`REPORTING - Select template ${ template.name }`"
                                       :loading="!template.is_available && templateInterestRegistering"
                                       :template="template"
                                       :document-icon="getDocumentType(template)"
                                       class="reporting-page__template-card"
                                       data-test="reporting-template-card"
                                       @export="handleExportSelected">
                            <span v-dompurify-html="template.description" />
                        </template-card>
                    </template>
                </div>
            </div>
        </div>

        <exporting-status-modal v-model="showExportStatus"
                                :error="error"
                                :exporting="exporting"
                                :is-async-exporting="isExportAsync"
                                :ordering="ordering"
                                data-test-attribute="exporting-status-dialog"
                                @request-retry="handleExport" />

        <exporting-menu v-show="selectedExportOption"
                        v-model="showExportingMenu"
                        :exporting="ordering || exporting"
                        :preselected-title-numbers="selectedTitleNumbers"
                        :template="selectedExportOption"
                        :title-summary-charge="titleSummaryCharge"
                        :titles="titles"
                        :document-icon="getDocumentType(selectedExportOption)"
                        @cancel="hideExportingMenu"
                        @export="handleExport" />
    </ow-full-width-layout>
</template>

<script lang="ts">
    import pluralize from 'pluralize'
    import {
        mapActions,
        mapGetters,
        mapMutations,
        mapState,
        useStore,
    } from 'vuex'

    import { useCopilot } from '@/components/copilot/use-copilot'
    import OwFullWidthLayout from '@/components/core/layout/full-width.vue'
    import OwLoadingSkeleton from '@/components/core/ow-loading-skeleton.vue'
    import ExportingMenu from '@/components/reporting/exporting-menu.vue'
    import ExportingStatusModal from "@/components/reporting/exporting-status-modal-with-teleport.vue"
    import TemplateCard from '@/components/reporting/template-card.vue'
    import { DATA_PROVIDER } from '@/consts/data-provider'
    import {Template} from '@/consts/reporting'
    import {
        DOC_TYPE_ENUM,
        MAIN_API_REPORT_ENUM,
    } from "@/enums/reporting-api.enum"
    import {Route} from '@/enums/route.enum'
    import flagsMixin from '@/feature-flags/feature-flags-mixin'
    import { IExportTemplateOptions } from '@/interfaces/export-template-options.interface'
    import {CheckoutItemReportOrder} from '@/models/checkout/checkout-item-report-order.model'
    import {DocumentOrderRequest} from '@/models/store/document-ordering/document-order-request.model'
    import {
        ADD_ITEMS_TO_CART,
    } from '@/store/modules/checkout/types'
    import {ORDER_DOCUMENTS} from '@/store/modules/document-ordering/types'
    import {
        CANCEL_LAST_DOWNLOAD_REQUEST,
        DOCUMENTS_ASYNC_GENERATE_REPORT,
        DOCUMENTS_ASYNC_GENERATE_TAR,
        DOCUMENTS_MUTATE_ADD_TITLE_ANALYSIS_REPORT_REQUEST,
        GENERATE_DOCX_AND_DOWNLOAD,
        GENERATE_REPORT_DOCX_AND_DOWNLOAD,
        GENERATE_REPORT_DOCX_AND_DOWNLOAD_ASYNC,
    } from '@/store/modules/documents/documents-types'
    import {
        MATTER_EXPORT_TITLE_ANALYSIS_REPORT,
        MATTER_GET_MATTER_CONTENTS,
    } from '@/store/modules/matter/types'
    import {
        GET_EXPORT_OPTIONS,
        GET_EXPORT_OPTIONS_LOADING,
        GET_SELECTED_EXPORT_OPTION,
        GET_TITLE_AVAILABILITY,
        LOAD_EXPORT_OPTIONS,
        LOAD_TITLE_AVAILABILITY,
        MUTATE_SELECTED_EXPORT_OPTION,
    } from '@/store/modules/reports/types'
    import { LOGGING_HEAP_TRACK_EVENT } from '@/store/mutation-types'
    import {isNullOrEmpty} from '@/utils/array-utils'
    import {isNullOrWhitespace} from '@/utils/string-utils'

    export default {
        name: 'Reports',

        components: {
            ExportingStatusModal,
            TemplateCard,
            ExportingMenu,
            OwLoadingSkeleton,
            OwFullWidthLayout,
        },

        mixins: [flagsMixin],

        beforeRouteEnter(_, from, next) {
            next((vm: any) => {
                if (!from.path.includes('reports/select-titles')) {
                    // Set where the user has come from for the back link when the page loads.
                    // If coming from the processing page, then don't reset it
                    vm.entryPoint = from.fullPath
                }
            })
        },
        setup() {
            const store = useStore()
            const titleNumbers = store.state.matter.currentMatter.selectedTitles.filter(title => title.selected).map(title => title.titleNumber)
            const { copilotUrl, setReport, trackClick } = useCopilot({
                matterId: store.state.matter.currentMatter.id,
                clientCode: store.state.matter.currentMatter.clientCode,
                matterCode: store.state.matter.currentMatter.code,
                titleNumbers,
                position: 'Reporting',
            })
            const handleCopilotExport = (template: IExportTemplateOptions) => {
                setReport(template?.copilotReport)
                window.open(copilotUrl.value, '_blank')
                trackClick()
                return
            }
            return {
                handleCopilotExport,
            }
        },

        data() {
            return {
                error: false,
                exporting: false,
                ordering: false,
                showExportingMenu: false,
                showExportStatus: false,
                currentReport: {},
                entryPoint: '',
                isExportAsync: false,
            }
        },

        computed: {
            ...mapState({
                matterName: state => state.matter.currentMatter.name,
                matterId: state => state.matter.currentMatter.id,
                titleSummaryCharge: state => state.user.titleSummaryCharge,
                successfulOrders: state => state.documentOrdering.successfulOrders,
                errorOrders: state => state.documentOrdering.errorOrders,
                disabledReportingFeatures: state => state.reports.disabledFeatures,
                selectedTitles: state => state.matter.currentMatter.selectedTitles,
                asyncReportingEnabled: state => state.config.featureFlags?.asyncTitleReports,
                errorMsg: state => state.documentOrdering.error,
            }),

            ...mapGetters({
                matterContents: MATTER_GET_MATTER_CONTENTS,
            }),

            ...mapGetters('reports', {
                isLoading: GET_EXPORT_OPTIONS_LOADING,
                templates: GET_EXPORT_OPTIONS,
                selectedExportOption: GET_SELECTED_EXPORT_OPTION,
                titleAvailability: GET_TITLE_AVAILABILITY,
            }),

            titles() {
                return this.sortedTitles.map(title => ({
                    ...title,
                    isAvailable: this.titleAvailability[title.titleNumber],
                    isReportingSupported: this.supportsReporting(title),
                })).sort(({ titleNumber }) => this.selectedTitleNumbers.includes(titleNumber) ? -1 : 0)
            },

            sortedTitles() {
                const groupSortOrders: Map<any, any> = new Map(this.matterContents.groups.map(group => [group.id, group.sortOrder]))
                const groupedTitles = this.matterContents.titles.filter(title => title.matterGroupId !== null)
                const ungroupedTitles = this.matterContents.titles.filter(title => title.matterGroupId === null)

                groupedTitles.sort((a: any, b: any) => groupSortOrders.get(a.matterGroupId) - groupSortOrders.get(b.matterGroupId) || a.sortOrder - b.sortOrder)
                ungroupedTitles.sort((a: any, b: any) => a.sortOrder - b.sortOrder)

                return [
                    ...groupedTitles,
                    ...ungroupedTitles,
                ]
            },

            allTitleNumbers() {
                return this.sortedTitles.map(title => title.titleNumber)
            },

            selectedTitleNumbers() {
                return this.$route.query.selectedTitleNumbers ?? []
            },

            selectedTitles() {
                return this.$route.query.selectedTitles ?? []
            },

            origin() {
                return this.$route.query.origin ?? 'REPORTING-HOMEPAGE'
            },

            backLink() {
                return isNullOrWhitespace(this.entryPoint) ? '/' : this.entryPoint
            },

            isTopLevel() {
                return this.$route.name === Route.Reports
            },

            backNavigation() {
                const current = this.selectedTitleNumbers.length === 1
                    ? `${ this.selectedTitleNumbers[0] }`
                    : `${ this.selectedTitleNumbers.length } ${ pluralize('title', this.selectedTitleNumbers.length) } selected`

                let text = ''
                if (this.entryPoint.includes('/documents')) {
                    text = 'Documents'
                }

                if (this.entryPoint.includes('/map') ||
                    this.entryPoint.includes('/search')) {
                    text = 'Title'
                }

                if (this.entryPoint.includes('/analysis')) {
                    text = 'Analysis'
                }

                if (this.entryPoint.includes('/review')) {
                    text = 'Review'
                }

                return {
                    text,
                    current,
                }
            },
        },

        watch: {
            successfulOrders: {
                handler(value) {
                    if (!isNullOrEmpty(this.currentReport.titleNumbersToOrder)) {
                        const successfulOrdersKeys = value.map(order => order.keyValue)
                        if (this.currentReport.titleNumbersToOrder.every(titleNumber => successfulOrdersKeys.includes(titleNumber))) {
                            this.ordering = false
                            this.handleExport(this.currentReport.allTitleNumbers)
                        }
                    }
                },
                deep: true,
            },
            errorOrders: {
                handler(value) {
                    if (!isNullOrEmpty(this.currentReport.titleNumbersToOrder)) {
                        if (value.some(order => this.currentReport.titleNumbersToOrder.includes(order.keyValue))) {
                            this.ordering = false
                            this.error = true
                        }
                    }
                },
                deep: true,
            },

            showExportStatus(value) {
                if (!value
                    && !this.isExportAsync
                ) {
                    // When the dialog is dismissed, cancel the last download request.
                    this.cancelLastDownloadRequest()
                }
            },

            showExportingMenu(value) {
                if (value) {
                    this.loadTitleAvailability({
                        matterId: this.matterId,
                        titleNumbers: this.allTitleNumbers,
                    })
                }
            },
        },

        created() {
            this.loadExportOptions()
        },

        methods: {
            ...mapActions({
                cancelLastDownloadRequest: CANCEL_LAST_DOWNLOAD_REQUEST,
                generateDocxAndDownload: GENERATE_DOCX_AND_DOWNLOAD,
                generateReportDocxAndDownload: GENERATE_REPORT_DOCX_AND_DOWNLOAD,
                generateReportDocxAndDownloadAsync: GENERATE_REPORT_DOCX_AND_DOWNLOAD_ASYNC,
                logHeapEvent: LOGGING_HEAP_TRACK_EVENT,
                exportTitleAnalysisReport: MATTER_EXPORT_TITLE_ANALYSIS_REPORT,
                showTitleAnalysisReportNotification: DOCUMENTS_ASYNC_GENERATE_TAR,
                showAsyncReportNotification: DOCUMENTS_ASYNC_GENERATE_REPORT,
            }),

            ...mapActions('checkout', {
                addItemsToCart: ADD_ITEMS_TO_CART,
            }),

            ...mapActions('documentOrdering', {
                orderDocuments: ORDER_DOCUMENTS,
            }),

            ...mapActions('reports', {
                loadExportOptions: LOAD_EXPORT_OPTIONS,
                loadTitleAvailability: LOAD_TITLE_AVAILABILITY,
            }),

            ...mapMutations('reports', {
                setSelectedExportOption: MUTATE_SELECTED_EXPORT_OPTION,
            }),

            ...mapMutations({
                updateReportRequests: DOCUMENTS_MUTATE_ADD_TITLE_ANALYSIS_REPORT_REQUEST,
            }),

            getDocumentType(template): DOC_TYPE_ENUM {
                if (template) {
                    switch (template?.reportingAPIEnum) {
                        case (MAIN_API_REPORT_ENUM.TAR):
                        case (MAIN_API_REPORT_ENUM.TAR_UNMERGED):
                            return DOC_TYPE_ENUM.EXCEL
                        default:
                            return DOC_TYPE_ENUM.DOC
                    }
                }
                return DOC_TYPE_ENUM.DOC
            },

            supportsReporting(title): boolean {
                return [DATA_PROVIDER.HMLR].includes(title.dataProvider)
            },

            async handleExportSelected(template: IExportTemplateOptions) {
                // copilot export handling
                if (template?.copilot) {
                    this.handleCopilotExport(template)
                    return
                }

                // normal export handling
                this.setSelectedExportOption(template)

                if (this.isTopLevel) {
                    // No titles selected, so redirect to select some
                    if (isNullOrEmpty(this.selectedTitles)) {
                        await this.$router.push({
                            name: Route.ReportsSelectTitles,
                        })
                    } else {
                        // Coming from homepage title search -> reporting options -> select report
                        // Already selected the title number, so just add into the checkout
                        this.addItemsToCart([
                            new CheckoutItemReportOrder(template.name, 0, this.selectedTitleNumbers, template),
                        ])

                        await this.$router.push({ name: Route.CheckoutReview })
                    }
                } else {
                    this.showExportingMenu = true
                }
            },

            async handleReportGeneration(titleNumbers) {
                try {
                    switch (this.selectedExportOption.reportingAPIEnum) {
                        case (MAIN_API_REPORT_ENUM.TAR):
                        case (MAIN_API_REPORT_ENUM.TAR_UNMERGED): {
                            this.isExportAsync = true
                            const shouldMergeCellsByTitleNumber = this.selectedExportOption.reportingAPIEnum != MAIN_API_REPORT_ENUM.TAR_UNMERGED
                            await this.logHeapEvent({
                                type: 'Title Analysis Report',
                                metadata: {
                                    matterId: this.matterId,
                                    titleNumberCount: titleNumbers.length,
                                    location: "REPORTING_PANEL",
                                },
                            })
                            const result = await this.exportTitleAnalysisReport({ titleNumbers, shouldMergeCellsByTitleNumber })
                            if (result) {
                                await this.updateReportRequests(result)
                                await this.showTitleAnalysisReportNotification()
                            }
                            break
                        }
                        default: {
                            if (this.selectedExportOption.template) {
                                if (this.asyncReportingEnabled) {
                                    this.isExportAsync = true
                                    const result = await this.generateReportDocxAndDownloadAsync({
                                        template: this.selectedExportOption,
                                        matterId: this.matterId,
                                        titleNumbers,
                                        disabledFeatures: this.disabledReportingFeatures,
                                    })
                                    if (result) {
                                        await this.updateReportRequests(result)
                                        await this.showAsyncReportNotification()
                                    }
                                } else {
                                    await this.generateReportDocxAndDownload({
                                        template: this.selectedExportOption,
                                        matterId: this.matterId,
                                        titleNumbers,
                                        disabledFeatures: this.disabledReportingFeatures,
                                    })
                                }
                            } else {
                                await this.generateDocxAndDownload(titleNumbers)
                            }
                        }
                    }
                } catch (e) {
                    this.error = true
                }
                switch (this.selectedExportOption.template) {
                    case Template.TITLE_REVIEW_REPORT:
                    case Template.CERTIFICATE_OF_TITLE:
                        await this.logHeapEvent({
                            type: 'REPORTING-PANEL - Export title register reports',
                            metadata: {
                                matterId: this.matterId,
                                matterName: this.matterName,
                                titleNumber: titleNumbers.join(', '),
                                template: this.selectedExportOption.template,
                                origin: this.origin,
                            },
                        })
                        break
                    default:
                        await this.logHeapEvent({
                            type: 'REPORTING-PANEL - Export word document',
                            metadata: {
                                matterId: this.matterId,
                                matterName: this.matterName,
                                titleNumber: titleNumbers.join(', '),
                                docFormat: '.docx',
                                origin: this.origin,
                            },
                        })
                        break
                }
            },

            async handleExport(titleNumbers = this.currentReport.allTitleNumbers) {
                this.hideExportingMenu()
                this.resetModalStates()
                this.currentReport = { allTitleNumbers: titleNumbers }
                await this.loadTitleAvailability({
                    matterId: this.matterId,
                    titleNumbers: this.allTitleNumbers,
                })
                this.currentReport.titleNumbersToOrder = titleNumbers.filter(titleNumber => !this.titleAvailability[titleNumber])
                if (!isNullOrEmpty(this.currentReport.titleNumbersToOrder)) {
                    this.ordering = true
                    this.showExportStatus = true
                    const orderRequests = this.currentReport.titleNumbersToOrder.map(titleNumber => DocumentOrderRequest.createRegisterRequest(titleNumber, this.matterId))
                    await this.orderDocuments(orderRequests)
                    if (!isNullOrWhitespace(this.errorMsg)) {
                        this.ordering = false
                        this.showExportStatus = false
                    }
                } else {
                    this.exporting = true
                    this.showExportStatus = true
                    await this.handleReportGeneration(titleNumbers)
                    this.exporting = false
                }
            },
            resetModalStates() {
                this.error = false
                this.isExportAsync = false
                this.showExportStatus = false
                this.ordering = false
                this.exporting = false
            },
            hideExportingMenu() {
                this.showExportingMenu = false
            },
        },
    }
</script>
<style lang="scss">
    @import './reporting';
</style>
