<template>
    <div class="d-block align-center gc-2">
        <ow-colored-label v-if="documentStatusText && !sentInPost"
                          is-extra-small
                          class="mr-2"
                          theme="warning">
            {{ documentStatusText }}
        </ow-colored-label>
        <span v-dompurify-html="orderedDate"
              class="body-regular-small" />
    </div>
</template>

<script setup lang="ts">
    import { computed } from 'vue'
    import { useI18n } from 'vue-i18n'

    import OwColoredLabel from '@/components/core/ow-colored-label.vue'
    import { inject as useDocument } from '@/composables/use-document'
    import { IDocumentRowData } from '@/interfaces/document-row-data.interface'
    import { format } from '@/utils/date-utils'
    import { isNullOrWhitespace } from '@/utils/string-utils'
    const { t } = useI18n()

    const props = defineProps<{
        item?: IDocumentRowData
    }>()

    const { documentStatus, isSentInPost } = useDocument()

    const documentStatusText = computed(() => documentStatus(props.item))
    const sentInPost = computed(() => isSentInPost(props.item))

    const orderedDate = computed(() => {
        return t('documentLibrary.table.rowDetail.orderedDate', {
            prefix: isSentInPost(props.item) ? t('documentLibrary.table.rowDetail.postalOrder') : '',
            date: format(props.item?.orderDate),
            user: !isNullOrWhitespace(props.item?.userName) ? props.item?.userName : 'N/A',
        })
    })
</script>

<style scoped>

</style>
