import httpClient from '@/api/http-client'
import { MAX_DOCUMENTS_TO_ORDER } from '@/consts/application'
import { DocumentOrderStatus } from '@/consts/document-order-status'
import { StatusCode } from '@/consts/status-code.js'
import { IHttpClientResponse } from '@/interfaces/http-client-response.interface'
import { DocumentOrderRequest } from '@/models/store/document-ordering/document-order-request.model'
import { isNullOrEmpty } from '@/utils/array-utils'
import { getDocumentOrderingAPIUri } from '@/utils/environment.utils'

export interface IDocOrderingResponse {
    userId: string,
    organisationId: number,
    documentOrderId: string,
    documentType: DocumentType,
    keyValue: string,
    matterId: number,
    documentOrderStatus: DocumentOrderStatus,
    createdBy: string,
    createdOn: Date,
    updatedBy: string,
    updatedOn: Date,
}

export default class DocumentOrderingApi {
    static END_POINT = '/documentordering'

    /**
     * Order a document
     * @param data
     * @returns {Promise<T | *>}
     */
    static async orderDocuments(data: DocumentOrderRequest[]): Promise<IHttpClientResponse> {
        // Block any requests for more than the max allowed number of doc orders
        if (!isNullOrEmpty(data)
            && data.length > MAX_DOCUMENTS_TO_ORDER) {
            const errorMsg = `The number of documents to order exceeds the maximum allowed: ${ MAX_DOCUMENTS_TO_ORDER }`
            console.error(errorMsg)
            return {
                ok: false,
                message: errorMsg,
            }
        }

        // Checking for existence this way for testing purposes
        if (globalThis.getMockDocumentOrdering && globalThis.getMockDocumentOrdering() === true) {
            data.forEach((order) => {
                order.useMockBehaviours(true)
            })
        }

        const uri = `${ getDocumentOrderingAPIUri() }${ this.END_POINT }/order-documents`
        return httpClient
            .post(uri, data)
            .then(response => {
                switch (response.status) {
                    case StatusCode.SUCCESS:
                        return {
                            data: response.data,
                            ok: true,
                        }

                    case StatusCode.NO_CONTENT:
                        return {
                            data: '', // no content
                            ok: true,
                        }

                    default:
                        console.error(`Received unexpected status code following document ordering request: ${ response?.status }`)
                        return {
                            ok: false,
                        }
                }
            })
            .catch(error => {
                console.error(`Received error following document ordering request: ${ error?.message }`)
                return {
                    message: error.message,
                    ok: false,
                }
            })
    }

    /**
     * Get the status of a document order
     * @param params
     * @returns {Promise<T | *>}
     */
    static getDocumentOrderStatus(params): Promise<IHttpClientResponse> {
        const uri = `${ getDocumentOrderingAPIUri() }${ this.END_POINT }`

        return httpClient
            .get(uri, { params })
            .then(response => {
                switch (response.status) {
                    case StatusCode.SUCCESS:
                        return response.data

                    case StatusCode.NO_CONTENT:
                        return '' // no content
                }
            })
    }
}
