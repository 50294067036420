<template>
    <div class="planning-actions-advanced-container">
        <ow-multiselect v-model="selectedPlanningDecisions"
                        :items="planningDecisionItems"
                        class="planning-decision-select"
                        data-track="TITLE-DETAILS-PANEL - Filter by planning decision"
                        label="Planning decision"
                        variant="outlined" />

        <div class="radius-picker">
            <span class="body-copy radius-picker__label">Radius</span>
            <ow-textfield v-model="selectedDistance"
                          :max="maxDistance"
                          :min="0"
                          :step="5"
                          type="number">
                <template #iconSuffix>
                    m
                </template>
            </ow-textfield>
            <ow-slider v-model="selectedDistance"
                       :max="maxDistance"
                       :min="0"
                       :step="5"
                       class="radius-picker__slider"
                       color="primary"
                       data-test-attribute="planning-actions-advanced-selected-distance"
                       data-track="TITLE-DETAILS-PANEL - Filter by distance"
                       label="Radius"
                       simple
                       unit="m" />
        </div>
    </div>
</template>

<script lang="ts">
    import { computed } from 'vue'
    import {
        mapActions,
        mapMutations,
        mapState,
    } from 'vuex'

    import OwMultiselect from '@/components/core/ow-multiselect.vue'
    import OwSlider from '@/components/core/ow-slider.vue'
    import OwTextfield from '@/components/core/ow-textfield.vue'
    import { inject as useMap } from '@/composables/use-map'
    import { inject as useUser } from '@/composables/use-user'
    import {
        PlanningDecisionText,
        RURAL_MAX_EXTENT,
        URBAN_MAX_EXTENT,
    } from '@/consts/planning'
    import {
        PLANNING_MUTATE_DISTANCE,
        PLANNING_MUTATE_SELECTED_PLANNING_DECISIONS,
        PLANNING_UPDATE_FILTERED_RESULTS,
    } from '@/store/modules/planning/types'

    export default {
        components: {
            OwTextfield,
            OwMultiselect,
            OwSlider,
        },

        setup() {
            const { isUrban } = useMap()
            const { isPremiumUser } = useUser()
            const maxDistance = computed(() => {
                // The rural distance is only available to premium users
                if (isUrban.value || !isPremiumUser.value) {
                    return URBAN_MAX_EXTENT
                } else {
                    return RURAL_MAX_EXTENT
                }
            })
            return {
                maxDistance,
            }
        },

        computed: {
            ...mapState({
                selectedDistanceState: state => state.planning.inputs.selectedDistance,
                selectedPlanningDecisionsState: state => state.planning.selectedPlanningDecisions,
            }),

            selectedDistance: {
                get() {
                    return this.selectedDistanceState
                },
                set(val) {
                    this.setSelectedDistance(val)
                },
            },

            selectedPlanningDecisions: {
                get() {
                    return this.selectedPlanningDecisionsState
                },
                set(val) {
                    this.setSelectedPlanningDecisions(val)
                    this.updateFilteredResults()
                },
            },

            planningDecisionItems() {
                return Object.entries(PlanningDecisionText).map(([ key, value ]) => {
                    return {
                        title: value,
                        value: key,
                    }
                })
            },
        },

        methods: {
            ...mapActions({
                updateFilteredResults: PLANNING_UPDATE_FILTERED_RESULTS,
            }),

            ...mapMutations({
                setSelectedDistance: PLANNING_MUTATE_DISTANCE,
                setSelectedPlanningDecisions: PLANNING_MUTATE_SELECTED_PLANNING_DECISIONS,
            }),
        },
    }

</script>
<style lang="scss">
@import './planning-actions-advanced';
</style>
