<template>
    <li class="body-regular-small"
        data-test="edition-date-mismatch-changed">
        {{ t('assetMonitoring.summaryCard.editionDateMismatchChange', numberOfChanges) }}

        <ul v-for="(diff, index) in differences"
            :key="index"
            v-dompurify-html="getDiffText(diff)"
            class="sub-list"
            data-test="edition-date-mismatch-changed-list" />
    </li>
</template>

<script lang="ts" setup>
    import { computed } from 'vue'
    import { useI18n } from 'vue-i18n'

    import { IAssetMonitoringNotificationDetail } from '@/components/asset-monitoring/asset-monitoring-notification.interface'
    import { OcdaDatapoint } from '@/components/asset-monitoring/datapoint-interfaces'
    import type {
        Difference,
        UpdatedProperty,
    } from '@/components/asset-monitoring/difference-generic'
    import { format } from '@/utils/date-utils'

    const { t } = useI18n()

    const props = defineProps<{
        items: IAssetMonitoringNotificationDetail[]
    }>()

    const differences = computed(() => {
        const differences: Difference<OcdaDatapoint>[] = []
        props.items?.forEach((item) => {
            if (item.difference) {
                differences.push(JSON.parse(item.difference))
            }
        })

        return differences
    })
    const numberOfChanges = computed<number>(() => {
        return differences.value.reduce((acc, diff) => {
            return acc + diff.added.length + diff.removed.length + diff.updated.length
        }, 0)
    })

    const getDiffText = (diff: Difference<OcdaDatapoint>): string => {
        const { added, removed, updated } = diff
        const output = []

        added.forEach((item: OcdaDatapoint) => {
            output.push(`<li>${ t('label.added') }: <span class="changes__to">${ item.type }</span> (${ item.entryNumbers.join(', ') }) ${ format(item.date) } ${ item.availability }</li>`)
        })
        removed.forEach((item: OcdaDatapoint) => {
            output.push(`<li>${ t('label.removed') }: <span class="changes__from">${ item.type }</span> (${ item.entryNumbers.join(', ') }) ${ format(item.date) }</li>`)
        })

        updated.forEach((item: UpdatedProperty<OcdaDatapoint>) => {
            const { before, after } = item

            const hasAvailabilityChanged = before.availability !== after.availability
            const availabilityFrom = hasAvailabilityChanged ? `<span class="changes__from">${ before.availability }</span>` : before.availability
            const availabilityTo = hasAvailabilityChanged ? `<span class="changes__to">${ after.availability }</span>` : after.availability

            const haveEntriesChanged = before.entryNumbers.join(', ') !== after.entryNumbers.join(', ')
            const entriesFrom = haveEntriesChanged ? `<span class="changes__from">${ before.entryNumbers.join(', ') }</span>` : before.entryNumbers.join(', ')
            const entriesTo = haveEntriesChanged ? `<span class="changes__to">${ after.entryNumbers.join(', ') }</span>` : after.entryNumbers.join(', ')

            const hasDateChanged = before.date !== after.date
            const dateFrom = hasDateChanged ? `<span class="changes__from">${ format(before.date) }</span>` : format(before.date)
            const dateTo = hasDateChanged ? `<span class="changes__to">${ format(after.date) }</span>` : format(after.date)

            output.push(`<li>${ t('assetMonitoring.summaryCard.updated', {
                from: `<span class="changes__to">${ before?.type }</span> (${ entriesFrom }) ${ dateFrom } ${ availabilityFrom }`,
                to:  `<span class="changes__to">${ after?.type }</span> (${ entriesTo }) ${ dateTo } ${ availabilityTo }`,
            }) }</li>`)
        })

        return output.join('')
    }
</script>

