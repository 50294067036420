// @/stores/document-library/actions.ts

import { toRaw } from 'vue'

import DocumentUploadApi from '@/api/document-upload.api'
import DocumentsApi from '@/api/documents.api'
import { UploadedDocumentStatus } from '@/enums/uploaded-document-status.enum'
import { UploadedDocumentType } from '@/enums/uploaded-document-type.enum'
import { IDocument } from '@/interfaces/document.interface'
import { IUploadedDocument } from '@/interfaces/uploaded-document.interface'
import { IUploadedDocumentStatusUpdatedNotification } from '@/store/modules/matter-hub/interfaces/uploaded-document-status-updated-notification.interface'
import { ACTION_FROM_MATTER_HUB_DOCUMENT_UPLOAD_STATUS_UPDATED_MESSAGE } from '@/store/modules/matter-hub/types'
import { useCoreComponentStore } from '@/stores/core'
import { useMatterStore } from '@/stores/matter'
import {
    dynamicSort,
    isNullOrEmpty,
} from '@/utils/array-utils'
import { isNullOrWhitespace } from '@/utils/string-utils'

export default {

    /**
     * Fetches the uploaded documents associated with the current matter in the matter store.
     * The retrieved documents are stored in the `uploadedDocuments` property of the library object.
     *
     * @return Resolves when the operation is complete. Logs an error if the fetching operation fails.
     */
    async fetchUploadedDocuments() {
        const matterStore = useMatterStore()
        try {
            const response = await DocumentUploadApi.getByMatterId(matterStore.currentMatterId)
            if (response.ok) {
                this.library.uploadedDocuments = response.data
            }
        } catch (e) {
            const message = `Unable to get uploaded documents for matter ${ matterStore.currentMatterId }`
            console.error(message, e)
        }

    },

    /**
     * Refreshes the documents by fetching updated data from the API. Can perform a partial or full refresh
     * based on whether a specific set of documents is provided.
     *
     * @param {IDocument[]} [documents] - An optional array of documents to refresh. If provided, only these documents will be updated. If omitted, all documents will be refreshed.
     * @return Resolves when the document refresh operation is completed.
     */
    async refreshDocuments(documents?: IDocument[]) {
        const matterStore = useMatterStore()
        const partialRefresh = !isNullOrEmpty(documents)
        let result: Array<any>

        if (partialRefresh) {
            const matterDocumentIds = documents.map((document: any) => document.matterDocumentId)
            result = await DocumentsApi.getDocumentsByMatterDocumentIds(matterDocumentIds, matterStore.currentMatterId)
        } else {
            result = await DocumentsApi.getAll(matterStore.currentMatterId)
        }
        if (partialRefresh) {
            this.setUpdatedDocuments(result)
        } else {
            // Temp code to add additional properties not yet populated by API
            result.forEach(doc => {
                doc.groupId = null
                doc.groupName = null
                doc.documentPending = doc.documentStatus === 'Pending...'
            })
            this.library.documents = result
        }
    },

    /**
     * Updates the documents in the current document library with the provided updated documents.
     *
     * @param {IDocument[]} updatedDocuments - An array of updated document objects containing the latest information to be applied to the current document library.
     */
    setUpdatedDocuments(updatedDocuments: IDocument[]) {
        updatedDocuments.forEach(updatedDocument => {
            // Update documents in the document library
            const staleLibraryDocument = this.library.documents.find(document => document.documentId === updatedDocument.documentId)
            if (staleLibraryDocument) {
                for (const prop in updatedDocument) {
                    staleLibraryDocument[prop] = updatedDocument[prop]
                }
            }
        })
    },

    /**
     * Updates the list of uploaded documents by either modifying existing entries
     * or adding new documents to the library.
     *
     * @param {IUploadedDocument[]} uploadedDocuments - Array of newly uploaded documents to be compared and updated in the library.
     */
    updateUploadedDocuments(uploadedDocuments: IUploadedDocument[]) {
        uploadedDocuments.forEach(doc => {
            const exists = this.library.uploadedDocuments.find(d => d.id === doc.id)
            if (exists) {
                exists.status = doc.status
                if (!isNullOrWhitespace(doc.titleNumber)) {
                    exists.titleNumber = doc.titleNumber
                }
                if (doc.type) {
                    exists.type = doc.type
                }
            } else {
                this.library.uploadedDocuments.push(doc)
            }
        })
        this.refresh()
    },

    /**
     * Updates the uploaded documents based on the provided message notification.
     *
     * @param {IUploadedDocumentStatusUpdatedNotification} message - A notification message containing updated status and other properties for an uploaded document.
     * @return A promise that resolves when the operation is complete.
     */
    async uploadUploadedDocumentsByMessage(message:  IUploadedDocumentStatusUpdatedNotification) {
        const doc = this.library.uploadedDocuments.find(doc => doc.id === message.documentId)
        if (!doc) {
            // Probably isn't already loaded, so ignore the message
            return
        }

        // Save the message too
        if (isNullOrEmpty(doc.messages)) {
            doc.messages = []
        }

        doc.status = message.status
        if (message.status === UploadedDocumentStatus.complete) {
            this.library.lastUploadedDocument = doc
            // await this.fetchUploadedDocuments()
        }

        if (!isNullOrWhitespace(message.titleNumber)) {
            doc.titleNumber = message.titleNumber
        }

        if (message.documentType !== null) {
            doc.type = typeof message.documentType === 'number'
                ? Object.keys(UploadedDocumentType)[message.documentType]
                : message.documentType
        }

        doc.messages.push(message)
        this.library.uploadedDocuments = this.library.uploadedDocuments.sort(dynamicSort('-updatedOn'))
        this.refresh()
    },

    async removeSingleDocumentFromMatter({ matterId, documentId, documentType }: { matterId: string, documentId: string, documentType: string }) {
        if (!matterId || !documentId || !documentType) {
            return
        }
        await DocumentsApi.removeDocumentFromMatter(matterId, documentId, documentType)
        const index = this.library.documents.findIndex(doc => doc.documentId === documentId)
        if (index > -1) {
            this.library.documents.splice(index, 1)

            const selectedDocIndex = this.selected.findIndex(n => n === documentId)
            if (selectedDocIndex > -1) {
                this.selected.splice(selectedDocIndex, 1)
            }
        }

        const coreComponentStore = useCoreComponentStore()
        coreComponentStore.updateResetPageAfterUpdate(false)

        this.refresh()
    },

    async removeUploadedDocumentsFromMatter({ matterId, documentIds }: { matterId: string, documentIds: string[] }) {
        if (!matterId || isNullOrEmpty(documentIds)) {
            return
        }
        await DocumentUploadApi.removeUploadedDocumentsFromMatter(matterId, documentIds)
        documentIds.forEach(docId => {
            const index = this.library.uploadedDocuments.findIndex(doc => doc.id === docId)
            if (index > -1) {
                this.library.uploadedDocuments.splice(index, 1)

                const selectedDocIndex = this.selected.findIndex(n => n === docId)
                if (selectedDocIndex > -1) {
                    this.selected.splice(selectedDocIndex, 1)
                }
            }
        })
        const coreComponentStore = useCoreComponentStore()
        coreComponentStore.updateResetPageAfterUpdate(false)

        this.refresh()
    },

    refresh() {
        this.setData(this.childNavName)
    },

    /**
     * Sets the data property based on the provided navigation name and clears the filter.
     *
     * @param childNavName - The name of the child navigation determining which data to set. Possible values: 'owned', 'unpurchased', 'exports', or others.
     */
    setData(childNavName: string) {
        this.childNavName = childNavName

        switch (childNavName) {
            case 'owned':
                this.data = this.ownedDocuments
                break
            case 'unpurchased':
                this.data = this.unpurchasedDocuments
                break
            case 'exports':
                this.data = this.exportedDocuments
                break
            default:
                this.data = []
                break
        }
    },

    /**
     * Clears all applied filters by resetting the filter-related properties to their default values.
     * This includes clearing filter text, selected titles, types, groups,
     * and resetting grouped filters to an empty object.
     */
    clearFilter() {
        this.selected = []
        this.filterOptions = {
            filter: '',
            selectedTitles: new Set<string>(),
            selectedTypes: new Set<string>([]),
            selectedGroups: new Set<string>(),
            selectedStatuses: new Set<string>(),
            groupFilters: {},
            groupByGroup: false,
            groupByTitle: false,
        }
    },


    /**
     * Clears all applied filters by resetting the filter-related properties to their default values.
     * This includes clearing filter text, selected titles, types, groups,
     * and resetting grouped filters to an empty object.
     */
    clearGroupFilter() {
        const oldFilter = toRaw(this.filterOptions)
        this.filterOptions = {
            ...oldFilter,
            selectedTitles: new Set<string>(),
            selectedTypes: new Set<string>([]),
            selectedGroups: new Set<string>(),
            selectedStatuses: new Set<string>(),
        }
    },

    /**
     * Initializes the library by loading and refreshing documents.
     * This method sets the library's loading state, refreshes the document list,
     * fetches uploaded documents, and marks the library as initialized.
     *
     * @return A promise that resolves when the initialization process is complete.
     */
    async initialise() {
        this.library.loading = true
        try {
            await this.refreshDocuments()
            await this.fetchUploadedDocuments()
            this.library.initialised = true
        } finally {
            this.library.loading = false
        }
    },

    /**
     * Handles incoming notification messages from the Matter Hub.
     *
     * @param message - The type of notification message received.
     * @param messageData - The data associated with the notification, containing details about the document upload status.
     * @return A promise that resolves when the notification is handled, or an early return if no action is needed.
     */
    async handleNotification(message: string, messageData: IUploadedDocumentStatusUpdatedNotification) {
        switch (message) {
            case ACTION_FROM_MATTER_HUB_DOCUMENT_UPLOAD_STATUS_UPDATED_MESSAGE: {
                if (isNullOrEmpty(this.library.uploadedDocuments)) {
                    return
                }

                if (this.filterApplied) {
                    this.clearFilter()
                }

                // Is message for any documents currently being uploaded?
                const documentsForThisMessage: IUploadedDocument[] = this.library.uploadedDocuments.filter((d: IUploadedDocument) => d.documentUploadRequestId === messageData.uploadId)
                if (isNullOrEmpty(documentsForThisMessage)) {
                    return
                }
                await this.uploadUploadedDocumentsByMessage(messageData)
            }
        }
    },
}