import { shallowRef } from 'vue'

import LandRegistryApi from '@/api/land-registry.api'
import SearchApi from '@/api/search.api'
import {
    MAX_ZOOM_TO_TITLE_ZOOM_LEVEL,
    PADDING,
} from '@/consts/map'
import { IState } from '@/interfaces/store/state.interface'
import { SearchResultsLayer } from '@/store/modules/map/layers/search-results-layer'
import { MAP_VALIDATE_NPS_DISPLAY } from '@/store/modules/map/types'
import {
    SEARCH_ADD_OWNER_TITLES_SHORTLIST_TO_MAP,
    SEARCH_ALL,
    SEARCH_CLEAR_ALL,
    SEARCH_HIGHLIGHT_TITLE_NUMBERS_ON_MAP,
    SEARCH_INITIALISE_SEARCH_RESULTS_LAYER,
    SEARCH_MUTATE_ALL_RESULTS,
    SEARCH_MUTATE_CLEAR_ALL_RESULTS,
    SEARCH_MUTATE_IS_OWNER_TITLES_PANEL_OPEN,
    SEARCH_MUTATE_IS_RESULTS_PANEL_OPEN,
    SEARCH_MUTATE_LOADING,
    SEARCH_MUTATE_LOADING_TITLES,
    SEARCH_MUTATE_OWNER_TITLES,
    SEARCH_MUTATE_SEARCH_TEXT,
    SEARCH_MUTATE_SELECTED_TITLE_NUMBERS,
    SEARCH_MUTATE_SET_MAP_SELECTED_TITLE_NUMBER,
    SEARCH_OWNER_TITLES,
    SELECT_SEARCH_RESULT,
} from '@/store/modules/search/types'
import { ISearchResultOwner } from '@/store/modules/search/types/search-result-interface'
import { ISearchState } from '@/store/modules/search/types/search-state-interface'
import {
    TITLE_CLEAR,
    TITLE_LOOKUP_AND_ZOOM_TO_TITLE,
    TITLE_SELECT_BY_UPRN,
} from '@/store/modules/titles/types'
import { LOGGING_LOG_FEATURE_USAGE } from '@/store/mutation-types'
import { isNullOrEmpty } from '@/utils/array-utils'
import {
    openPopupOnMap,
    setMapCentre,
} from '@/utils/map-utils'
import { isNullOrWhitespace } from '@/utils/string-utils'

import { searchResultHandler } from './search-results-handler'

export default {
    /**
     * Select a search result
     * @param commit
     * @param dispatch
     * @param rootState
     * @param state
     * @param val
     */
    async [SELECT_SEARCH_RESULT]({
        commit,
        dispatch,
        rootState,
        state,
    }, val: any) {
        let extent = null

        // clear any selected titles
        dispatch(TITLE_CLEAR, null, { root: true })

        if (val != null) {
            const view = rootState.map.map.getView()
            let centre: string[]

            switch (val.type) {
                case 'companies':
                    commit(SEARCH_MUTATE_SELECTED_TITLE_NUMBERS, val.value)
                    break
                case 'titlenumber':
                    await dispatch(TITLE_LOOKUP_AND_ZOOM_TO_TITLE, val.value, { root: true })
                    await dispatch(LOGGING_LOG_FEATURE_USAGE, {
                        type: 'search-title-number-select',
                        description: val.value,
                    }, { root: true })
                    break
                case 'uprn':
                    const uprn = val.value
                    setMapCentre(val.point, rootState.map.map)
                    openPopupOnMap('searchPopupContainer', `<p>UPRN: ${ uprn }</p>`, val.point, this)
                    await dispatch(LOGGING_LOG_FEATURE_USAGE, {
                        type: 'search-uprn-select',
                        description: `uprn: "${ uprn }"`,
                    }, { root: true })
                    break
                case 'place':
                    extent = val.bbox.getGeometry()
                    await dispatch(LOGGING_LOG_FEATURE_USAGE, {
                        type: 'search-place-select',
                        description: val.value,
                    }, { root: true })
                    break
                case 'address':
                    setMapCentre(val.coordinates, rootState.map.map)
                    openPopupOnMap('searchPopupContainer', `<p>${ val.text }</p>`, val.coordinates, this)

                    if (val.uprn != null) {
                        if (val.uprn.length > 0) {
                            dispatch(TITLE_SELECT_BY_UPRN, val.uprn, { root: true })
                        }
                    }
                    await dispatch(LOGGING_LOG_FEATURE_USAGE, {
                        type: 'search-address-select',
                        description: val.value,
                    }, { root: true })
                    break
                case 'postcode':
                    centre = val.coordinates
                    view.setCenter(centre)
                    view.setZoom(MAX_ZOOM_TO_TITLE_ZOOM_LEVEL)
                    await dispatch(LOGGING_LOG_FEATURE_USAGE, {
                        type: 'search-postcode-select',
                        description: val.value,
                    }, { root: true })
                    break
            }

            if (extent != null) {
                window.setTimeout(function() {
                    view.fit(extent, {
                        maxZoom: MAX_ZOOM_TO_TITLE_ZOOM_LEVEL,
                        duration: 200,
                        padding: PADDING,
                    })

                    // if result was title number, ensure nps is visible
                    if (val.type === 'titlenumber') {
                        window.setTimeout(function() {
                            dispatch(MAP_VALIDATE_NPS_DISPLAY, view, { root: true })
                        }, 100)
                    }
                }, 100)
            }

            window.setTimeout(function() {
                if (view.getZoom() > MAX_ZOOM_TO_TITLE_ZOOM_LEVEL) {
                    view.setZoom(MAX_ZOOM_TO_TITLE_ZOOM_LEVEL)
                }
            }, 500)

            // log selection
            const data = {
                selection: {
                    type: val.type,
                    value: val.value,
                },
            }
            await SearchApi.logSelection(data)
        }
    },

    /**
     * Search across all the available search types (address, company, place, postcode & title number)
     * Any results are committed to state.allResults
     * @param commit
     * @param searchText
     */
    async [SEARCH_ALL]({ commit }, searchText: string) {
        commit(SEARCH_MUTATE_LOADING, true)

        commit(SEARCH_MUTATE_CLEAR_ALL_RESULTS)
        const searchResponse: any = await SearchApi.searchAll(searchText)
        const results = searchResultHandler(searchResponse)
        if (!isNullOrEmpty(results)) {
            commit(SEARCH_MUTATE_CLEAR_ALL_RESULTS)
            commit(SEARCH_MUTATE_ALL_RESULTS, results)
        }
        commit(SEARCH_MUTATE_LOADING, false)
    },

    /**
     * Fetch the titles belonging to the array of selected owners.
     * @example
     *  The selected owners data should be:
     *      [{
     *          name: 'owner name',
     *          regNumber: 'company registration number',
     *      }]
     * @param commit
     * @param dispatch
     * @param data {Array}
     */
    async [SEARCH_OWNER_TITLES]({
        commit,
        dispatch,
    }, data: any[]) {
        commit(SEARCH_MUTATE_LOADING_TITLES, true)

        try {
            commit(SEARCH_MUTATE_IS_OWNER_TITLES_PANEL_OPEN, true)
            const response = await LandRegistryApi.getTitlesForOwners(data)
            commit(SEARCH_MUTATE_OWNER_TITLES, response)
            await dispatch(LOGGING_LOG_FEATURE_USAGE, {
                type: 'map-search-owner-select',
                description: data.length,
            }, { root: true })
        } finally {
            commit(SEARCH_MUTATE_LOADING_TITLES, false)
        }
    },

    [SEARCH_CLEAR_ALL]({ commit }) {
        commit(SEARCH_MUTATE_CLEAR_ALL_RESULTS)
        commit(SEARCH_MUTATE_IS_OWNER_TITLES_PANEL_OPEN, false)
        commit(SEARCH_MUTATE_SEARCH_TEXT, '')
        commit(SEARCH_MUTATE_IS_RESULTS_PANEL_OPEN, false)
    },

    async [SEARCH_ADD_OWNER_TITLES_SHORTLIST_TO_MAP]({ dispatch, state }: { commit: any, dispatch: any, rootState: IState, state: ISearchState }, ownersShortlistData: any) {
        const companyNames = Array.from(new Set(ownersShortlistData.map(o => o.owner)))
        const filteredTitleNumbers = ownersShortlistData.map(o => o.titleNumber)
        const selectedTitleNumbers = ownersShortlistData.filter(o => o.selected).map(o => o.titleNumber)
        if (!isNullOrEmpty(companyNames)) {
            // NOTE: Deep copy to not modify the original companies object
            const ownersData = JSON.parse(JSON.stringify(state.allResults.companies.filter(c => companyNames.includes(c.text))))
            ownersData.forEach(o => {
                o.titleData = o.titleData.filter(td => filteredTitleNumbers.includes(td.titleNumber))
                o.titleNumbers = o.titleNumbers.filter(tn => filteredTitleNumbers.includes(tn))
            })
            state.searchResultsLayer.updateSelectedTitles(selectedTitleNumbers)
            await dispatch(SEARCH_INITIALISE_SEARCH_RESULTS_LAYER, ownersData, selectedTitleNumbers)
            // NOTE: Only zoom to extent when filtering
            state.searchResultsLayer.zoomToExtent()
        }
    },

    async [SEARCH_INITIALISE_SEARCH_RESULTS_LAYER]({ commit, rootState, state }: { commit: any, dispatch: any, rootState: IState, state: ISearchState }, ownersData: any) {
        const isLayerVisible = !isNullOrEmpty(ownersData)
        if (rootState.map.nps.layer) {
            if (state.searchResultsLayer) {
                state.searchResultsLayer.getTitlesDataFn = () => ownersData
                await state.searchResultsLayer.reload()
            } else {
                state.searchResultsLayer = shallowRef(new SearchResultsLayer({
                    getTitlesDataFn: () => ownersData,
                    onPointClickFn: (titleNumbers) => {
                        // Clicked on a point, so will only ever be 1 title number to deal with
                        const titleNumber = titleNumbers[0]
                        const data = ownersData.find((item: ISearchResultOwner) => item.titleNumbers.includes(titleNumber))
                        const titleNumberWithData = {
                            titleNumber,
                            data,
                        }
                        commit(SEARCH_MUTATE_SET_MAP_SELECTED_TITLE_NUMBER, titleNumberWithData)
                    },
                    interactive: true,
                })).value
                await state.searchResultsLayer.setMap(rootState.map.map)
                await state.searchResultsLayer.reload()
            }
            state.searchResultsLayer.setVisible(isLayerVisible)
        }
    },

    async [SEARCH_HIGHLIGHT_TITLE_NUMBERS_ON_MAP]({ commit, state }: { commit: any, state: ISearchState }, titleNumbers: string[]) {
        commit(SEARCH_MUTATE_SELECTED_TITLE_NUMBERS, titleNumbers)
        state.searchResultsLayer.selectFeaturesByTitleNumber(titleNumbers)
    },
}
