// Getters

// Mutations
export const MATTER_HUB_MUTATE_IS_SUBSCRIBED = 'MATTER_HUB_MUTATE_IS_SUBSCRIBED'
export const MATTER_HUB_MUTATE_ERROR = 'MATTER_HUB_MUTATE_ERROR'
export const MATTER_HUB_MUTATE_HUB = 'MATTER_HUB  _MUTATE_HUB'
export const MATTER_HUB_MUTATE_MESSAGE = 'MATTER_HUB  _MUTATE_MESSAGE'
export const MATTER_HUB_MUTATE_CURRENTLY_SUBSCRIBED_TO_MATTER_ID = 'MATTER_HUB_MUTATE_CURRENTLY_SUBSCRIBED_TO_MATTER_ID'

// Actions
export const SUBSCRIBE_TO_MATTER_HUB = 'SUBSCRIBE_TO_MATTER_HUB'
export const UNSUBSCRIBE_FROM_MATTER_HUB = 'UNSUBSCRIBE_FROM_MATTER_HUB'
export const INITIALISE_MATTER_HUB_LISTENERS = 'INITIALISE_MATTER_HUB_LISTENERS'
export const UNSUBSCRIBE_MATTER_HUB_LISTENERS = 'UNSUBSCRIBE_MATTER_HUB_LISTENERS'

// Global actions sent when a message is received from the hub
export const ACTION_FROM_MATTER_HUB_LEASES_UPDATED_MESSAGE = 'ACTION_FROM_MATTER_HUB_LEASES_UPDATED_MESSAGE'
export const ACTION_FROM_MATTER_HUB_DOCUMENT_UPLOAD_STATUS_UPDATED_MESSAGE = 'ACTION_FROM_MATTER_HUB_DOCUMENT_UPLOAD_STATUS_UPDATED_MESSAGE'
export const ACTION_FROM_MATTER_HUB_TITLE_ANALYSIS_REPORT_GENERATED_MESSAGE = 'ACTION_FROM_MATTER_HUB_TITLE_ANALYSIS_REPORT_GENERATED_MESSAGE'
export const ACTION_FROM_MATTER_HUB_TITLE_REPORT_GENERATED_MESSAGE = 'ACTION_FROM_MATTER_HUB_TITLE_REPORT_GENERATED_MESSAGE'
export const ACTION_FROM_MATTER_HUB_SNAPSHOT_STATUS_CHANGED_MESSAGE = 'ACTION_FROM_MATTER_HUB_SNAPSHOT_STATUS_CHANGED_MESSAGE'
export const ACTION_FROM_MATTER_HUB_MATTER_SHARED_MESSAGE = 'ACTION_FROM_MATTER_HUB_MATTER_SHARED_MESSAGE'
