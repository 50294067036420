import DocumentOrderingApi from '@/api/document-ordering.api'
import { MAX_DOCUMENTS_TO_ORDER } from '@/consts/application'
import { DocumentOrderStatus } from '@/consts/document-order-status'
import { IHttpClientResponse } from '@/interfaces/http-client-response.interface'
import { IDocumentOrder } from '@/interfaces/store/document-ordering/document-order.interface'
import { DocumentOrderRequest } from '@/models/store/document-ordering/document-order-request.model'
import i18n from '@/plugins/i18n'
import {
    DOCUMENT_ORDERING_MUTATE_CURRENT_ORDERS,
    DOCUMENT_ORDERING_MUTATE_ERROR,
    DOCUMENT_ORDERING_MUTATE_ERROR_ORDERS,
    DOCUMENT_ORDERING_MUTATE_ORDER_STATUS,
    DOCUMENT_ORDERING_MUTATE_PROCESSING_ORDER,
    DOCUMENT_ORDERING_MUTATE_SUCCESSFUL_ORDERS,
    DOCUMENT_ORDERING_REMOVE_ORDER_FROM_CURRENT_ORDERS,
    DOCUMENT_ORDERING_REMOVE_ORDER_FROM_ERROR_ORDERS,
    FIND_ORDER_FROM_STATE_BY_ORDER_ID,
    ORDER_DOCUMENTS,
    UPDATE_DOCUMENT_ORDER_STATUS,
    UPDATE_DOCUMENT_ORDER_STATUS_DATA,
} from '@/store/modules/document-ordering/types'
import { MATTER_UPDATE_CURRENT_MATTER_CHARGES } from '@/store/modules/matter/types'
import {
    SUBSCRIBE_TO_ORGANISATION_HUB,
    UNSUBSCRIBE_FROM_ORGANISATION_HUB,
} from '@/store/modules/organisation-hub/types'
import { TITLE_MUTATE_OFFICIAL_COPY_PROPERTIES } from '@/store/modules/titles/types'
import {
    LOGGING_HEAP_TRACK_EVENT,
    USER_SHOW_POPUP,
} from '@/store/mutation-types'
import { getHighLevelDocumentTypeFromDocumentType } from '@/utils/document-ordering-utils'

export default {
    /**
     * order the title register for the title number provided
     * @param commit
     * @param dispatch
     * @param rootState
     * @param orderRequests an array of order requests.
     * @returns {Promise<void>}
     */
    async [ORDER_DOCUMENTS]({
        dispatch,
        commit,
        rootState,
    }, orderRequests: DocumentOrderRequest[]) {
        if (orderRequests.length > MAX_DOCUMENTS_TO_ORDER) {
            const { t } = i18n.global
            dispatch(USER_SHOW_POPUP, {
                title: t('error.bulkOrderLimitExceeded'),
                contentHTML: t('error.bulkOrderLimitExceededMessage', { count: MAX_DOCUMENTS_TO_ORDER }),
            }, { root: true })
            commit(DOCUMENT_ORDERING_MUTATE_ERROR, 'Too many documents requested')
            return
        }

        let response: IHttpClientResponse
        try {
            await dispatch(`organisationHub/${ UNSUBSCRIBE_FROM_ORGANISATION_HUB }`,
                {
                    // @ts-ignore
                    hub: rootState.organisationHub.hub,
                    organisationId: rootState.user.organisationId,
                },
                { root: true })
            await dispatch(`organisationHub/${ SUBSCRIBE_TO_ORGANISATION_HUB }`,
                {
                    // @ts-ignore
                    hub: rootState.organisationHub.hub,
                    organisationId: rootState.user.organisationId,
                }, { root: true })
            response = await DocumentOrderingApi.orderDocuments(orderRequests)
        } catch (error) {
            dispatch(USER_SHOW_POPUP, {
                title: 'Error',
                contentHTML: 'An error was encountered when ordering the documents',
            }, { root: true })
            await dispatch(LOGGING_HEAP_TRACK_EVENT, {
                type: 'DOCUMENT ORDERING: Error starting order',
                metadata: {
                    error: JSON.stringify(error),
                },
            }, { root: true })
            console.error(error)
        }
        if (response === undefined || response?.ok === false) {
            dispatch(USER_SHOW_POPUP, {
                title: 'Error',
                contentHTML: i18n.global.t('documentOrdering.genericOrderingError'),
            }, { root: true })
            commit(DOCUMENT_ORDERING_MUTATE_ERROR, response.message)
            await dispatch(LOGGING_HEAP_TRACK_EVENT, {
                type: 'DOCUMENT ORDERING: Error starting order',
                metadata: {
                    error: response.message,
                },
            }, { root: true })
        } else {
            const ordersWithOrderId: IDocumentOrder[] = orderRequests.map(order => {
                const matchingOrder = response.data.find(x => x.keyValue == order.keyValue && x.documentType === order.documentType)
                if (matchingOrder) {
                    return {
                        orderId: matchingOrder.orderId,
                        request: order,
                        keyValue: order.keyValue,
                        orderType: getHighLevelDocumentTypeFromDocumentType(matchingOrder.documentType),
                    }
                }
                return null
            }).filter(x => x !== null)
            commit(DOCUMENT_ORDERING_MUTATE_CURRENT_ORDERS, ordersWithOrderId)
        }

        return response
    },

    async [UPDATE_DOCUMENT_ORDER_STATUS]({ // TODO: retire this in favor of trackedTitles and getters
        commit,
        dispatch,
    }, {
        orderId,
        status,
        notificationData,
    }) {
        let order: IDocumentOrder = await dispatch(FIND_ORDER_FROM_STATE_BY_ORDER_ID, orderId)
        if (!order) {
            // The order could be coming from elsewhere (another user in the same organisation),
            // so ignore this status update.
            return
        }

        // Update the existing order with the new status to be saved.
        order = {
            ...order,
            status,
        }

        dispatch(UPDATE_DOCUMENT_ORDER_STATUS_DATA, { orderId, notificationData, status })
        switch (status) {
            case DocumentOrderStatus.AUGMENTED:
            case DocumentOrderStatus.DERIVED:
            case DocumentOrderStatus.COMPLETED:
                dispatch(MATTER_UPDATE_CURRENT_MATTER_CHARGES, null, { root: true })
                commit(DOCUMENT_ORDERING_MUTATE_SUCCESSFUL_ORDERS, order)
                commit(DOCUMENT_ORDERING_REMOVE_ORDER_FROM_CURRENT_ORDERS, order)
                commit(DOCUMENT_ORDERING_MUTATE_PROCESSING_ORDER, false)
                dispatch(LOGGING_HEAP_TRACK_EVENT, {
                    type: 'DOCUMENT ORDERING: Order completed',
                    metadata: {
                        status,
                        orderId: order.orderId,
                        order: JSON.stringify(order),
                    },
                }, { root: true })
                break

            case DocumentOrderStatus.FAILURE:
            case DocumentOrderStatus.UNAVAILABLE:
            case DocumentOrderStatus.COME_BACK_LATER:
                commit(DOCUMENT_ORDERING_MUTATE_ERROR_ORDERS, order)
                commit(DOCUMENT_ORDERING_REMOVE_ORDER_FROM_CURRENT_ORDERS, order)
                commit(DOCUMENT_ORDERING_MUTATE_PROCESSING_ORDER, false)
                dispatch(LOGGING_HEAP_TRACK_EVENT, {
                    type: 'DOCUMENT ORDERING: Order failed',
                    metadata: {
                        status,
                        orderId: order.orderId,
                        order: JSON.stringify(order),
                    },
                }, { root: true })
                break

            case DocumentOrderStatus.RETRYING:
                commit(DOCUMENT_ORDERING_REMOVE_ORDER_FROM_ERROR_ORDERS, order)
                commit(DOCUMENT_ORDERING_MUTATE_CURRENT_ORDERS, [order])
                dispatch(LOGGING_HEAP_TRACK_EVENT, {
                    type: 'DOCUMENT ORDERING: Retrying order',
                    metadata: {
                        status,
                        orderId: order.orderId,
                        order: JSON.stringify(order),
                    },
                }, { root: true })
                break

            case DocumentOrderStatus.ORDERING:
                commit(DOCUMENT_ORDERING_MUTATE_CURRENT_ORDERS, [order])
                commit(DOCUMENT_ORDERING_MUTATE_ORDER_STATUS, { order, status })

                dispatch(LOGGING_HEAP_TRACK_EVENT, {
                    type: 'DOCUMENT ORDERING: Started order',
                    metadata: {
                        status,
                        orderId: order.orderId,
                        order: JSON.stringify(order),
                    },
                }, { root: true })
                break

            default:
                commit(DOCUMENT_ORDERING_MUTATE_CURRENT_ORDERS, [order])
                commit(DOCUMENT_ORDERING_MUTATE_ORDER_STATUS, { order, status })
                dispatch(LOGGING_HEAP_TRACK_EVENT, {
                    type: 'DOCUMENT ORDERING: Order status updated',
                    metadata: {
                        status,
                        orderId: order.orderId,
                        order: JSON.stringify(order),
                    },
                }, { root: true })
                break
        }
    },

    async [UPDATE_DOCUMENT_ORDER_STATUS_DATA]({ // TODO: retire this in favor of trackedTitles and getters
        commit,
        dispatch,
    }, {
        orderId,
        status,
        notificationData,
    }) {
        const order: IDocumentOrder = await dispatch(FIND_ORDER_FROM_STATE_BY_ORDER_ID, orderId)
        if (!order) {
            // The order could be coming from elsewhere (another user in the same organisation),
            // so ignore this status update.
            return
        }
        let properties = {}

        switch (status) {
            case DocumentOrderStatus.ORDERED:
                // If a document is ordered, it is assumed that the download is available (as otherwise a different status would be returned)
                // which can be requested using the document Id provided in the message.
                properties = {
                    documentId: notificationData.documentId,
                    downloadUrl: `download/${ order.orderType }/${ notificationData.documentId }/download`, // TODO: return this from the server.
                }
                commit(TITLE_MUTATE_OFFICIAL_COPY_PROPERTIES, { order, properties }, { root: true })
                break
            case DocumentOrderStatus.AUGMENTED:
                // the augmented filename is returned in the message
                properties = {
                    augmentedFilename: notificationData?.message,
                }
                commit(TITLE_MUTATE_OFFICIAL_COPY_PROPERTIES, { order, properties }, { root: true })
                break
        }
    },

    async [FIND_ORDER_FROM_STATE_BY_ORDER_ID]({ state }, orderId: string): Promise<IDocumentOrder | null> { // TODO: retire this in favor of trackedTitles and getters
        let order: IDocumentOrder = state.currentOrders.find(order => order.orderId === orderId)

        // The order may have already failed, but this is a retry so need to check error array
        order = order ?? state.errorOrders.find(order => order.orderId === orderId)
        return order ?? null
    },
}
