import { USER_ROLE_TEXT,
    USER_ROLES } from '@/consts/users'
import { ApplicationRoles } from '@/enums/application-roles.enum'
import i18n from '@/plugins/i18n'

export const getFriendlyRoleName = (roles) => {
    if (roles.includes(USER_ROLES.SystemAdmin)) {
        return USER_ROLE_TEXT.SystemAdmin
    }
    if (roles.includes(USER_ROLES.OrgAdmin)) {
        return USER_ROLE_TEXT.OrgAdmin
    }
    return USER_ROLE_TEXT.User
}

/**
 * Returns the display roles based on the given roles string.
 *
 * @param {string} roles - The roles string to convert.
 * @returns {string} - The display roles string.
 */
export const getDisplayRoles = (roles: string): string => {
    const splitRoles = roles.split(',')
    const roleLabels = []

    // Doing it this way so they're always in the same order when displayed
    if (splitRoles.indexOf(ApplicationRoles.OwApp) !== -1) {
        roleLabels.push(i18n.global.t('applicationRoles.owUser'))
    }

    if (splitRoles.indexOf(ApplicationRoles.Copilot) !== -1) {
        roleLabels.push(i18n.global.t('applicationRoles.copilot'))
    }

    if (splitRoles.indexOf(ApplicationRoles.Residential) !== -1) {
        roleLabels.push(i18n.global.t('applicationRoles.residential'))
    }

    if (splitRoles.indexOf(ApplicationRoles.OrgAdmin) !== -1) {
        roleLabels.push(i18n.global.t('applicationRoles.orgAdmin'))
    }

    if (splitRoles.indexOf(ApplicationRoles.SystemAdmin) !== -1) {
        roleLabels.push(i18n.global.t('applicationRoles.sysAdmin'))
    }

    return roleLabels.join(', ')
}

export const isOwEmailDomain = (email) => {
    return email?.endsWith('@orbitalwitness.com') || email?.endsWith('@orbital.tech')
}

export const formatProfileAddress = (profile) => {
    return {
        addressHouseNumber: profile.houseNo,
        addressStreet: profile.street,
        addressLocality: profile.locality,
        addressTown: profile.posttown,
        addressPostcode: profile.postcode,
    }
}
