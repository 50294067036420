import { parse } from 'date-fns'
import uniqueId from 'lodash/uniqueId'

import { UploadedDocumentStatus } from '@/enums/uploaded-document-status.enum'
import {
    IDocument,
} from '@/interfaces/document.interface'
import {
    IDocumentRowData,
    IsDocumentExported,
    IsDocumentUploaded,
} from '@/interfaces/document-row-data.interface'
import i18n from '@/plugins/i18n'
import { useMatterStore } from '@/stores/matter'
import { format } from '@/utils/date-utils'
import { getDocumentStatus } from '@/utils/document-utils'

const documentName = (doc: IDocument): string => {
    const title = titleNumber(doc)
    const type = documentType(doc)

    let fileName = null
    if (IsDocumentExported(doc) || IsDocumentUploaded(doc)) {
        fileName = doc?.fileName ?? doc?.filename
    }
    return  fileName ?? `${ type } - ${ title ?? 'Unspecified' }`
}

const documentType = (doc: IDocument): string => {
    if (IsDocumentExported(doc)) {
        return doc.fileType
    }
    return doc?.isUnknownDocumentType ? 'Unknown' : doc?.type ?? doc?.documentType ?? doc?.highLevelDocumentType
}

export const documentStatus = (doc: IDocument): string => {
    if (IsDocumentUploaded(doc) && doc?.status) {
        switch (doc.status) {
            case UploadedDocumentStatus.duplicate: {
                return i18n.global.t('documentLibrary.status.duplicate')
            }
            case UploadedDocumentStatus.failedAntiVirusCheck: {
                return i18n.global.t('documentLibrary.status.virus')
            }
            case UploadedDocumentStatus.invalidFileContent: {
                return i18n.global.t('documentLibrary.status.corrupted')
            }
            case UploadedDocumentStatus.invalidFileType: {
                return i18n.global.t('documentLibrary.status.invalid')
            }
            case UploadedDocumentStatus.pending: {
                return i18n.global.t('documentLibrary.status.pending')
            }
            case UploadedDocumentStatus.scanningFailed: {
                return i18n.global.t('documentLibrary.status.virus')
            }
            case UploadedDocumentStatus.complete: {
                return i18n.global.t('documentLibrary.status.success')
            }
            case UploadedDocumentStatus.processing: {
                // User doesn't care about scanning, extracting/parsing/...
                return i18n.global.t('documentLibrary.status.processing')
            }
            case UploadedDocumentStatus.uploaded: {
                return i18n.global.t('documentLibrary.status.success')
            }
        }
    }
    return getDocumentStatus(doc) ?? i18n.global.t('documentLibrary.status.available')
}


const documentId = (doc: IDocument): string => {
    return doc?.documentId ?? doc?.id ?? doc?.keyValue ?? null
}

const titleNumber = (doc: IDocument): string => {
    return doc?.titleNumber ?? doc?.titleNo ?? null
}

export const parseTitleNumber = (titleNumber: string): string => {
    return titleNumber?.replace(/\s\(\+\d+\smore\)/, '')
}

const documentOrderDate = (doc: IDocument): Date => {
    let date = null
    if (doc?.createdOn) {
        date = doc.createdOn
    } else if (doc?.documentDownloadDate) {
        date = parse(doc?.documentDownloadDate, 'dd/MM/yyyy', new Date())
    } else if (doc?.documentDate) {
        date = new Date(doc.documentDate)
    } else if (doc?.updatedOn) {
        date = new Date(doc.updatedOn)
    }
    return date
}

/**
 * Maps and enriches a document object with additional metadata and transformations.
 *
 * This method takes a document object and performs several operations:
 * - Determines and assigns group-related metadata based on the document's title number.
 * - Extracts and formats document-related properties such as ID, type, date, and name.
 * - Compiles the enriched properties into a `rowData` structure for easier usage in tabular or display formats.
 *
 * If the document does not belong to any group, the group-related properties are set to null.
 *
 * @param {IDocument} doc - The document object to be mapped and transformed.
 * @returns {IDocument} - The enriched document object with additional metadata and transformations.
 */
export const mapDocumentRowData = (doc: IDocument): IDocumentRowData => {
    const matterStore = useMatterStore()
    const selectedTitle = matterStore.selectedTitles.find(title => title.titleNumber === doc.titleNo)
    const groupId = selectedTitle?.matterGroupId

    if (groupId) {
        const group = matterStore.groups.find(group => group.id === groupId)
        if (group) {
            doc.groupId = group.id
            doc.groupName = group.name
        }
    }

    if (!doc.groupId) {
        doc.groupId = null
        doc.groupName = null
    }

    const docId = documentId(doc)
    const docType = documentType(doc)
    const docDate = doc?.documentDate ?? format(doc.updatedOn)
    const typeTranslationKey = `documentLibrary.documentType.${ docType }`
    const type = i18n.global.te(typeTranslationKey) ? i18n.global.t(typeTranslationKey) : docType
    return {
        firstName: doc?.firstName ?? '',
        lastName: doc?.lastName ?? '',
        userName: doc?.userName ?? doc?.email ?? '',
        document: doc,
        documentId: docId,
        id: docId,
        key: uniqueId('document-row-'),
        titleNumber: titleNumber(doc),
        documentDate: docDate,
        type: type,
        entryNumbers: doc.entryNumbers ?? null,
        groupId: doc.groupId,
        groupName: doc.groupName,
        status: documentStatus(doc),
        documentName: documentName(doc),
        orderDate: documentOrderDate(doc),
    }
}
