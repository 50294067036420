<template>
    <div class="sidebar-header__container">
        <div class="sidebar-header d-flex align-center justify-content-between"
             :class="{
                 'sidebar-header--collapsed': collapsed,
                 'sidebar-header--divider': !subtitle && !collapsed && !hideDivider,
                 'sidebar-header--inline': inline,
                 'pl-0': hasIconSlot,
             }">
            <h1 :data-test="headerDataTest"
                class="headers-h1-page-title hide-in-percy d-flex flex-grow-1 align-center mx-3"
                :class="{
                    'ml-2': inline,
                }">
                <div v-if="hasIconSlot"
                     class="sidebar-header__icon mr-2 d-flex align-center">
                    <slot name="headerIcon" />
                </div>
                <div v-if="!collapsed"
                     class="sidebar-header__title">
                    {{ title }}
                </div>
            </h1>
            <div class="d-flex align-center flex-shrink-1">
                <slot />
                <ow-button-ds v-if="!hideCollapseButton"
                              icon
                              is-flat
                              small
                              class="sidebar-header__collapse-button"
                              :data-test="buttonDataTest"
                              :data-track="buttonDataTrack"
                              @click.stop="emit('toggle', !props.collapsed)">
                    <v-icon>
                        $chevron-double-left
                    </v-icon>
                </ow-button-ds>
            </div>
        </div>
        <div v-if="hasHeaderActionsSlot"
             class="sidebar-header__actions flex justify-space-between align-center pb-1 pt-3">
            <slot name="headerActions" />
        </div>
    </div>
</template>

<script setup lang="ts">
    import {
        computed,
        useSlots,
    } from 'vue'

    import OwButtonDs from '@/components/core/ow-button-ds.vue'

    const props = withDefaults(defineProps<{
        title?: string;
        subtitle?: string;
        headerDataTest?: string;
        buttonDataTest?: string;
        buttonDataTrack?: string;
        collapsed?: boolean;
        hideCollapseButton?: boolean;
        hideDivider?: boolean;
        inline?: boolean;
    }>(), {
        title: '',
        subtitle: '',
        headerDataTest: '',
        buttonDataTest: '',
        buttonDataTrack: '',
        collapsed: false,
        hideCollapseButton: false,
        hideDivider: false,
    })

    const emit = defineEmits<{
        (e: 'toggle', collapsed: boolean),
    }>()

    const slots = useSlots()

    const hasIconSlot = computed(() => slots?.headerIcon && slots.headerIcon())
    const hasHeaderActionsSlot = computed(() => slots?.headerActions && slots.headerActions())

</script>

<style lang="scss">
    @import './ow-sidebar-header';
</style>
