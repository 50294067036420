<template>
    <aside class="ow-sidebar d-flex flex-column"
           :class="{
               'ow-sidebar--collapsed': collapsed,
               'ow-sidebar--hide-divider': hideDivider,
               'ow-sidebar--inline': inline,
           }"
           :data-test="dataTestId"
           @click="toggleExpand">
        <ow-sidebar-header v-if="!hideHeader"
                           inline
                           :title="collapsed ? '' : title"
                           :header-data-test="`${dataTestId}-header`"
                           :button-data-test="`${dataTestId}-collapse-button`"
                           :collapsed="collapsed"
                           :subtitle="collapsed ? '' : subtitle"
                           :hide-divider="collapsed ? true : hideDivider"
                           :hide-collapse-button="hideCollapseButton"
                           @toggle="toggleExpandCollapse">
            <template #headerIcon>
                <slot v-if="!collapsed"
                      name="headerIcon" />
            </template>
            <template v-if="hasHeaderActionsSlot"
                      #headerActions>
                <div class="d-flex flex-column pa-3">
                    <slot v-if="!collapsed"
                          name="headerActions" />
                </div>
            </template>
        </ow-sidebar-header>
        <div v-else-if="!collapsed && hasHeaderActionsSlot"
             class="d-flex flex-column pa-3">
            <slot name="headerActions" />
        </div>
        <div v-if="!collapsed && !hideDivider"
             class="ow-sidebar__divider" />
        <div v-if="!collapsed"
             class="ow-sidebar__content d-flex"
             :class="{
                 'flex-grow-1': !inline,
             }">
            <slot />
        </div>
        <div v-if="!collapsed && !hideDivider"
             class="ow-sidebar__divider" />
        <div v-if="!collapsed && hasFooterSlot"
             class="ow-sidebar__footer px-3 d-flex flex-column flex-shrink-1">
            <slot name="footer" />
        </div>
    </aside>
</template>

<script setup lang="ts">
    import { useSlots } from 'vue'

    import OwSidebarHeader from '@/components/core/ow-sidebar-header.vue'

    const props = withDefaults(defineProps<{
        dataTestId?: string,
        title?: string,
        subtitle?: string,
        hideCollapseButton?: boolean,
        hideDivider?: boolean,
        hideHeader?: boolean,
        inline?: boolean,
    }>(), {
        dataTestId: 'ow-sidebar',
        hideCollapseButton: false,
        hideDivider: false,
        hideHeader: false,
    })

    const slots = useSlots()

    const hasHeaderActionsSlot = slots.headerActions !== undefined
    const hasFooterSlot = slots.footer !== undefined

    const collapsed = defineModel<boolean>('collapsed')

    const emit = defineEmits<{
        (e: 'toggle', collapsed: boolean),
    }>()

    const toggleExpand = () => {
        if (collapsed.value) {
            collapsed.value = false
            emit('toggle', false)
        }
    }

    const toggleExpandCollapse = () => {
        collapsed.value = !collapsed.value
        emit('toggle', collapsed.value)
    }
</script>

<style lang="scss">
    @import './ow-sidebar.scss';
</style>
