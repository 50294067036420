<template>
    Unpurchased
</template>

<script setup lang="ts">

</script>

<style scoped>

</style>