<template>
    <div class="document-library-grouped-table-header d-flex flex-column gr-3">
        <div class="document-library-table">
            <ow-data-grid v-model:sort-by="sortBy"
                          :show-pagination="false"
                          :has-pagination="false"
                          theme="light"
                          :hide-no-data="hideNoData"
                          :headers="internalHeaders">
                <template #header.data-table-select>
                    <ow-checkbox v-model="checked"
                                 :disabled="!hideNoData"
                                 class="document-library-grouped-table-header__checkbox"
                                 :indeterminate="indeterminate" />
                </template>
            </ow-data-grid>
        </div>
        <v-progress-linear class="document-library-grouped-table-header__progress"
                           :indeterminate="loading"
                           absolute
                           color="primary"
                           height="2" />
    </div>
</template>

<script setup lang="ts">
    import {
        onMounted,
        ref,
        watch,
    } from 'vue'
    import type { VDataTable } from 'vuetify/components'

    import OwCheckbox from '@/components/core/ow-checkbox.vue'
    import OwDataGrid from '@/components/core/ow-data-grid.vue'
    import { inject as useDocumentLibrary } from '@/composables/use-document-library'
    withDefaults(defineProps<{
        hideNoData?: boolean,
        indeterminate?: boolean,
    }>(), {
        indeterminate: false,
    })

    const loading = defineModel('loading', { default: false })
    const checked = defineModel<boolean>()
    const internalHeaders = ref<VDataTable['$props']['headers']>()
    const { getTableHeaders } = useDocumentLibrary()

    onMounted(() => {
        internalHeaders.value = getTableHeaders(true)
    })

    const sortBy = defineModel('sortBy', {
        default: [{ key: 'orderDate', order: 'desc' }],
    })
    const emit = defineEmits<{
        (e: 'checked', value: boolean),
    }>()

    watch(checked, (value) => {
        emit('checked', value)
    })
</script>

<style lang="scss">
@import './document-library-grouped-table-header.scss';
</style>
