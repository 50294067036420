// Configuration for the title analysis grid
import {
    benefitsFromDigitalRegisterHintColumnRenderer,
    informationIconRenderer,
} from '@/components/title-analysis/column-renderers/benefits-from-digital-register'
import { freeholdNotApplicableColumnRenderer } from '@/components/title-analysis/column-renderers/freehold-not-applicable'
import { parentTitleNumberColumnRenderer } from '@/components/title-analysis/column-renderers/parent-title-number'
import i18n from '@/plugins/i18n'
import { isNullOrEmpty } from '@/utils/array-utils'
import { format } from '@/utils/date-utils'
import { isNullOrWhitespace } from '@/utils/string-utils'

const getDateStringOrDefault = (dateValue) => {
    if (dateValue instanceof Date && !isNaN(dateValue)) {
        return format(dateValue)
    }
    return null
}

const getValueOrNA = (value, isExport) => value || value === 0 ? value : isExport ? null : 'N/A'
const getNumberedList = (list) => {
    if (isNullOrEmpty(list)) {
        return 'N/A'
    }
    if (list.length === 1) {
        return list[0]
    }
    return list.map((value, i) => `(${ i + 1 }) ${ value }`).join(', ')
}

const getColumnGroups = () => [
    {
        id: 'basic',
        label: i18n.global.t('tam.columnGroups.basic'),
    },
    {
        id: 'owner',
        label: i18n.global.t('tam.columnGroups.owner'),
    },
    {
        id: 'lease',
        label: i18n.global.t('tam.columnGroups.lease'),
    },
    {
        id: 'flags',
        label: i18n.global.t('tam.columnGroups.flags'),
    },
    {
        id: 'restrictions',
        label: i18n.global.t('tam.columnGroups.restrictions'),
    },
    {
        id: undefined,
        label: i18n.global.t('tam.columnGroups.other'),
    },
]

const getColumns = (options) => {
    let result = [
        {
            text: 'Title Number',
            field: 'titleNumber',
            type: 'title-number',
            group: 'basic',
            locked: true,
            mandatory: true, // Should always be enabled
            width: 150,
            htmlEncode: false,
            cellCls: 'grid-title-number',
            requires: [
                'titleNumber',
                'tenure', // tenure fields are used to style the title column accordingly
                'ccodOcodTitle.tenure',
                'editionDate', // edition date used to indicate if the digital register has been downloaded
            ],
            includeInTitleOrganiser: true,
        },
        {
            text: 'Title Label',
            field: 'titleLabel',
            group: 'basic',
            width: 150,
            htmlEncode: false,
            hidden: true,
            requires: [
                'titleNumber',
            ],
        },
        {
            text: 'Group',
            field: 'matterGroup',
            computed: true, // calculated on the fly, does not require data to be requested
            useDefaultComboFilter: true,
            group: 'basic',
            width: 150,
        },
        {
            text: 'Property Address',
            field: 'propertyAddresses',
            group: 'basic',
            width: 250,
            htmlEncode: false,
            benefitsFromDigitalRegister: true,
            renderer: ({
                isExport,
                value,
            }) => {
                if (isExport) {
                    let exportValue = ''
                    if (value) {
                        const addresses = value.split('\n')
                        addresses.forEach((addressItem, index) => {
                            if (addresses.length > 1) {
                                exportValue += `${ index + 1 }: `
                            }
                            exportValue += `${ addressItem }\n`
                        })
                        return exportValue
                    }
                }

                if (value) {
                    if (value.split('\n').length > 1) {
                        const addresses = value.split('\n')
                        return `${ addresses[0] } (+${ addresses.length - 1 } more address${ addresses.length > 2 ? 'es' : '' })`
                    }
                }

                return value
            },
            requires: [
                'propertyAddress.addressLine',
                'propertyAddress.postcodeZone',
                'ccodOcodTitle.propertyAddress',
            ],
        },
        {
            text: 'UPRNs',
            field: 'uprns',
            group: 'basic',
            width: 150,
            htmlEncode: false,
            hidden: true,
            renderer: ({
                value,
            }) => {
                return isNullOrEmpty(value) ? 'N/A' : value.join(', ')
            },
            requires: ['uprns'],
        },
        {
            text: 'Tenure',
            field: 'tenure',
            group: 'basic',
            useDefaultComboFilter: true,
            hidden: true,
            autoWidth: 200,
            htmlEncode: false,
            requires: ['tenure', 'ccodOcodTitle.tenure'],
        },
        {
            text: 'Quality of Tenure',
            field: 'qualityOfTenure',
            group: 'basic',
            useDefaultComboFilter: true,
            width: 150,
            htmlEncode: false,
            requires: [
                'qualityOfTenure',
                'tenure',
                'ccodOcodTitle.tenure',
            ],
            includeInTitleOrganiser: true,
        },
        {
            text: 'Tenure Type',
            field: 'tenureType',
            group: 'basic',
            useDefaultComboFilter: true,
            width: 150,
            htmlEncode: false,
            requires: [
                'tenureType',
                'tenure',
                'ccodOcodTitle.tenure',
            ],
        },
        {
            text: 'Estate Interest',
            field: 'estateInterest',
            group: 'basic',
            useDefaultComboFilter: true,
            hidden: true,
            autoWidth: true,
            htmlEncode: false,
            renderer: ({
                value,
            }) => {
                return value || 'N/A'
            },
            requires: ['ccodOcodTitle.estateInterest'],
        },
        {
            text: 'Edition Date',
            field: 'editionDate',
            group: 'basic',
            type: 'date',
            width: 150,
            htmlEncode: false,
            benefitsFromDigitalRegister: true,
            includeInTitleOrganiser: true,
            renderer: ({
                isExport,
                value,
            }) => {
                if (!isExport) {
                    return getDateStringOrDefault(value)
                }

                return value
            },
        },
        {
            text: 'Search From Date',
            field: 'searchedFrom',
            group: 'basic',
            type: 'date',
            hidden: true,
            width: 150,
            htmlEncode: false,
            benefitsFromDigitalRegister: true,
            includeInTitleOrganiser: true,
            renderer: ({
                isExport,
                value,
            }) => {
                if (!isExport) {
                    return getDateStringOrDefault(value)
                }

                return value
            },
            requires: ['searchedFrom'],
        },
        {
            text: 'Issued On Date',
            field: 'issuedOnDate',
            group: 'basic',
            type: 'date',
            hidden: true,
            width: 150,
            htmlEncode: false,
            benefitsFromDigitalRegister: true,
            includeInTitleOrganiser: true,
            renderer: ({
                isExport,
                value,
            }) => {
                if (!isExport) {
                    return getDateStringOrDefault(value)
                }

                return value
            },
            requires: ['issuedOnDate'],
        },
        {
            text: 'Property District',
            field: 'propertyDistrict',
            group: 'basic',
            hidden: true,
            useDefaultComboFilter: true,
            width: 200,
            htmlEncode: false,
            benefitsFromDigitalRegister: true,
            requires: ['ccodOcodTitle.district'],
            includeInTitleOrganiser: true,
        },
        {
            text: 'Property Region',
            field: 'propertyRegion',
            group: 'basic',
            hidden: true,
            useDefaultComboFilter: true,
            width: 200,
            htmlEncode: false,
            benefitsFromDigitalRegister: true,
            requires: ['ccodOcodTitle.region'],
            includeInTitleOrganiser: true,
        },
        {
            text: 'Property County',
            field: 'propertyCounty',
            group: 'basic',
            hidden: true,
            useDefaultComboFilter: true,
            width: 200,
            htmlEncode: false,
            benefitsFromDigitalRegister: true,
            requires: ['ccodOcodTitle.county'],
            includeInTitleOrganiser: true,
        },
        {
            // TODO: as with other address fields, needs to also consider postcode available from OS API, and digital
            // register.
            text: 'Property Postcode',
            field: 'propertyPostcode',
            group: 'basic',
            hidden: true,
            width: 200,
            htmlEncode: false,
            benefitsFromDigitalRegister: true,
            renderer: ({
                value,
                isExport,
            }) => {
                return getValueOrNA(value, isExport)
            },
            requires: [
                'propertyAddress.postcodeZone',
                'ccodOcodTitle.postcode',
            ],
            includeInTitleOrganiser: true,
        },
        {
            text: 'Proprietor',
            field: 'proprietorName',
            group: 'owner',
            width: 250,
            htmlEncode: false,
            renderer: ({
                isExport,
                record,
                value,
            }) => {
                if (isExport) {
                    if (record.proprietorNameArray) {
                        return record.proprietorNameArray.join('\n')
                    }
                }

                if (!value) {
                    return value
                }

                return value
            },
            benefitsFromDigitalRegister: true,
            requires: [
                'ccodOcodOwners.proprietorName',
                'proprietorshipParties.displayName',
                'proprietorshipParties.proprietorshipPartyType',
            ],
        },
        {
            text: 'Trading Name',
            field: 'tradingAs',
            group: 'owner',
            width: 250,
            htmlEncode: false,
            renderer: ({
                record,
                value,
            }) => {
                if (record.proprietorTradingNameArray) {
                    return record.proprietorTradingNameArray.join('\n')
                }

                return value
            },
            benefitsFromDigitalRegister: true,
            requires: [
                'proprietorshipParties.tradingName',
            ],
        },
        {
            text: 'TR Owner matches latest Public Ownership Data',
            field: 'trOwnerMatchesPublicOwner',
            group: 'basic',
            useDefaultComboFilter: true,
            width: 150,
            htmlEncode: false,
            requires: [
                'proprietorshipParties.index',
                'proprietorshipParties.tradingName',
                'proprietorshipParties.organizationName',
                'proprietorshipParties.privateIndividualForenames',
                'proprietorshipParties.privateIndividualSurname',
                'ccodOcodOwners.proprietorName',
            ],
            includeInTitleOrganiser: true,
            benefitsFromDigitalRegister: true,
            renderer: ({
                isExport,
                record,
            }) => {
                if (!isExport) {
                    if (record.trOwnerMatchesPublicOwner === undefined) {
                        return null
                    } else if (record.trOwnerMatchesPublicOwner !== false) {
                        return `
                            <div class="title-complexity-category-verysimple">
                                <span>Yes</span>
                            </div>
                        `
                    } else {
                        return `
                            <div class="title-complexity-category-extremelycomplex">
                                <span>No</span>
                            </div>
                        `
                    }
                }
                return record.trOwnerMatchesPublicOwner === undefined ? null : record.trOwnerMatchesPublicOwner !== false
            },
            sortable: (a, b) => {
                if (a.trOwnerMatchesPublicOwner === undefined && b.trOwnerMatchesPublicOwner !== undefined) {
                    return 1
                } else if (a.trOwnerMatchesPublicOwner !== undefined && b.trOwnerMatchesPublicOwner === undefined) {
                    return -1
                } else if (a.trOwnerMatchesPublicOwner !== false && b.trOwnerMatchesPublicOwner === false) {
                    return 1
                } else if (b.trOwnerMatchesPublicOwner !== false && a.trOwnerMatchesPublicOwner === false) {
                    return -1
                }
                return 0
            },
            groupable: false,
        },
        {
            text: 'Proprietor Entry in Title Register',
            field: 'proprietorEntryInTitleRegister',
            group: 'owner',
            hidden: true,
            autoWidth: true,
            htmlEncode: false,
            benefitsFromDigitalRegister: true,
            freeholdNotApplicable: false,
            requires: [
                'proprietorEntry.entry',
            ],
        },
        {
            text: 'Address of Proprietor',
            field: 'proprietorAddress',
            group: 'owner',
            width: 500,
            htmlEncode: false,
            benefitsFromDigitalRegister: true,
            requires: [
                'proprietorshipParties.proprietorshipPartyAddresses.proprietorshipPartyIndex',
                'proprietorshipParties.proprietorshipPartyAddresses.addressLines',
                'proprietorshipParties.proprietorshipPartyAddresses.proprietorshipPartyIndex',
                'proprietorshipParties.proprietorshipPartyAddresses.postcodeZone',
                'ccodOcodOwners.proprietorAddress1',
                'ccodOcodOwners.proprietorAddress2',
                'ccodOcodOwners.proprietorAddress3',
            ],
        },
        {
            text: 'Proprietor Company Reg. No.',
            field: 'proprietorCompanyRegNo',
            group: 'owner',
            htmlEncode: false,
            width: 100,
            renderer: ({
                isExport,
                value,
                includeInTitleOrganiser,
            }) => {
                if (!isExport) {
                    if (!isNullOrWhitespace(value)) {
                        const result = []
                        value.split(', ').forEach((no, i) => {
                            if (includeInTitleOrganiser) {
                                result.push(no)
                            } else {
                                result.push(`<div class="clickable" title="Search on Companies House" data-track="TAM - Search for owner on companies house" data-index="${ i }">
                            ${ no }<i class="b-fa-external-link-alt b-fa"></i>
                        </div>`)
                            }
                        })
                        return includeInTitleOrganiser ? result.join(', ') : result.join('<span> </span>')
                    }
                    return null
                }
                return value
            },
            benefitsFromDigitalRegister: true,
            requires: [
                'ccodOcodOwners.companyRegistrationNo',
                'proprietorshipParties.organizationCompanyRegistrationNumber',
            ],
            includeInTitleOrganiser: true,
        },
        {
            text: 'CH Listed Owner',
            field: 'companyName',
            group: 'owner',
            hidden: false,
            autoWidth: true,
            htmlEncode: false,
            benefitsFromDigitalRegister: true,
            freeholdNotApplicable: false,
            includeInTitleOrganiser: true,
            requires: [
                'ccodOcodOwners.companyRegistrationNo',
                'companiesHouseData.companyName',
            ],
        },
        {
            text: 'Company Status',
            field: 'companyStatus',
            group: 'owner',
            hidden: false,
            autoWidth: true,
            htmlEncode: false,
            benefitsFromDigitalRegister: true,
            freeholdNotApplicable: false,
            includeInTitleOrganiser: true,
            requires: [
                'ccodOcodOwners.companyRegistrationNo',
                'companiesHouseData.companyStatus',
            ],
        },
        {
            text: 'Company Status Active?',
            field: 'companyStatusActive',
            group: 'owner',
            hidden: true,
            autoWidth: true,
            htmlEncode: false,
            benefitsFromDigitalRegister: true,
            freeholdNotApplicable: false,
            requires: [
                'ccodOcodOwners.companyRegistrationNo',
                'companiesHouseData.companyStatus',
            ],
        },
        {
            text: 'CH / Title Owner Match?',
            field: 'chTitleOwnerMatch',
            group: 'owner',
            hidden: true,
            autoWidth: true,
            htmlEncode: false,
            benefitsFromDigitalRegister: true,
            freeholdNotApplicable: false,
            requires: [
                'ccodOcodOwners.companyRegistrationNo',
                'proprietorshipParties.organizationCompanyRegistrationNumber',
                'companiesHouseData.companyRegistrationNumber',
                'ccodOcodOwners.proprietorName',
                'companiesHouseData.companyName',
            ],
        },
        {
            text: 'CH Registered Office Address',
            field: 'companyAddress',
            group: 'owner',
            hidden: true,
            autoWidth: true,
            htmlEncode: false,
            benefitsFromDigitalRegister: true,
            freeholdNotApplicable: false,
            requires: [
                'ccodOcodOwners.companyRegistrationNo',
                'companiesHouseData.displayAddress',
            ],
        },
        {
            text: 'CH Nature of business (SIC)',
            field: 'companyNatureOfBusiness',
            group: 'owner',
            hidden: true,
            autoWidth: true,
            htmlEncode: false,
            benefitsFromDigitalRegister: true,
            freeholdNotApplicable: false,
            includeInTitleOrganiser: true,
            requires: [
                'ccodOcodOwners.companyRegistrationNo',
                'companiesHouseData.displayNatureOfBusiness',
            ],
        },
        {
            text: 'CH Incorporated on',
            field: 'companyIncorporatedOn',
            group: 'owner',
            hidden: true,
            autoWidth: true,
            htmlEncode: false,
            benefitsFromDigitalRegister: true,
            freeholdNotApplicable: false,
            requires: [
                'ccodOcodOwners.companyRegistrationNo',
                'companiesHouseData.incorporatedOn',
            ],
        },
        {
            text: 'CH Company type',
            field: 'companyType',
            group: 'owner',
            hidden: true,
            autoWidth: true,
            htmlEncode: false,
            benefitsFromDigitalRegister: true,
            freeholdNotApplicable: false,
            includeInTitleOrganiser: true,
            requires: [
                'ccodOcodOwners.companyRegistrationNo',
                'companiesHouseData.companyType',
            ],
        },
        {
            text: 'Overseas Proprietor\'s Country of Incorporation',
            field: 'countryIncorporated',
            group: 'owner',
            width: 280,
            htmlEncode: false,
            hidden: true,
            benefitsFromDigitalRegister: true,
            requires: ['proprietorshipParties.countryIncorporated'],
            renderer: ({ isExport, value }) => {
                if (value === undefined) {
                    return null
                }
                if (isExport && value.length === 0) {
                    return null
                }
                return getNumberedList(value)
            },
            includeInTitleOrganiser: true,
        },
        {
            text: 'Overseas Proprietor',
            field: 'overseas',
            group: 'owner',
            width: 150,
            align: 'center',
            htmlEncode: false,
            useDefaultComboFilter: true,
            benefitsFromDigitalRegister: true,
            requires: ['proprietorshipParties.isOverseas'],
            includeInTitleOrganiser: true,
        },
        {
            text: 'Price Paid',
            field: 'pricePaid',
            group: 'basic',
            width: 150,
            hidden: true,
            htmlEncode: false,
            benefitsFromDigitalRegister: true,
            requires: ['pricePaid.pricePaid'],
        },
        {
            text: 'No. of Documents Listed',
            min: 0,
            max: 10000,
            field: 'documentDetailsCount',
            group: 'basic',
            width: 200,
            align: 'center',
            htmlEncode: false,
            benefitsFromDigitalRegister: true,
            requires: ['documentDetailsCount'],
            includeInTitleOrganiser: true,
        },
        {
            text: 'Parent Title',
            type: 'parent-title-number',
            field: 'parentTitleNumber',
            group: 'lease',
            width: 150,
            requires: [
                'parentTitleNumber',
            ],
            htmlEncode: false,
            freeholdNotApplicable: true,
            includeInTitleOrganiser: true,
            renderer: ({
                isExport,
                value,
                record,
                includeInTitleOrganiser,
            }) => {
                if (!isExport && includeInTitleOrganiser) {
                    return parentTitleNumberColumnRenderer({
                        value,
                        record,
                        includeInTitleOrganiser,
                        isExport,
                    })
                }

                return value
            },
        },
        {
            text: 'Date of Lease',
            field: 'leaseDate',
            group: 'lease',
            type: 'date',
            width: 150,
            htmlEncode: false,
            benefitsFromDigitalRegister: true,
            renderer: ({
                isExport,
                value,
            }) => {
                if (!isExport) {
                    return getDateStringOrDefault(value)
                }

                return value
            },
            requires: ['lease.leaseDate'],
            freeholdNotApplicable: true,
            includeInTitleOrganiser: true,
        },
        {
            text: 'Lease Term',
            field: 'term',
            group: 'lease',
            width: 250,
            htmlEncode: false,
            benefitsFromDigitalRegister: true,
            requires: ['lease.leaseTerm'],
            freeholdNotApplicable: true,
            includeInTitleOrganiser: true,
        },
        {
            text: 'Lease Start Date',
            field: 'leaseStartDate',
            group: 'lease',
            hidden: true,
            type: 'date',
            width: 150,
            htmlEncode: false,
            benefitsFromParentDigitalRegister: true,
            renderer: ({
                isExport,
                value,
            }) => {
                if (!isExport) {
                    return getDateStringOrDefault(value) ?? informationIconRenderer({ recommendParentDigitalRegister: true })
                }

                return value
            },
            freeholdNotApplicable: true,
            requires: ['lease.startDate'],
        },
        {
            text: 'Lease End Date',
            field: 'leaseEndDate',
            group: 'lease',
            hidden: true,
            type: 'date',
            width: 150,
            htmlEncode: false,
            benefitsFromParentDigitalRegister: true,
            renderer: ({
                isExport,
                value,
            }) => {
                if (!isExport) {
                    return getDateStringOrDefault(value) ?? informationIconRenderer({ recommendParentDigitalRegister: true })
                }

                return value
            },
            freeholdNotApplicable: true,
            requires: ['lease.endDate'],
        },
        {
            text: 'Remaining Lease Term',
            field: 'remainingLeaseTerm',
            group: 'lease',
            hidden: true,
            width: 150,
            htmlEncode: false,
            benefitsFromParentDigitalRegister: true,
            renderer: ({ value }) => {
                return value?.label || informationIconRenderer({ recommendParentDigitalRegister: true })
            },
            groupable: false,
            sortable: (a, b) => {
                if (a.remainingLeaseTerm === undefined) {
                    return -1
                }
                if (b.remainingLeaseTerm === undefined) {
                    return -1
                }
                return a.remainingLeaseTerm.value - b.remainingLeaseTerm.value
            },
            freeholdNotApplicable: true,
            requires: ['lease.endDate'],
            includeInTitleOrganiser: true,
        },
        {
            text: 'Remaining Lease Term (in Days)',
            field: 'remainingLeaseTermDays',
            group: 'lease',
            hidden: true,
            width: 150,
            htmlEncode: false,
            benefitsFromParentDigitalRegister: true,
            freeholdNotApplicable: true,
            requires: ['lease.endDate'],
            renderer: ({
                isExport,
                value,
            }) => {
                if (!isExport) {
                    return value ?? informationIconRenderer({ recommendParentDigitalRegister: true })
                }

                return value
            },
        },
        {
            text: 'Lease Rent',
            field: 'rent',
            group: 'lease',
            hidden: true,
            htmlEncode: false,
            width: 150,
            benefitsFromDigitalRegister: true,
            freeholdNotApplicable: true,
            requires: ['lease.rent'],
            includeInTitleOrganiser: true,
        },
        {
            text: 'Lease Party',
            field: 'leaseParty',
            group: 'lease',
            hidden: true,
            width: 150,
            htmlEncode: false,
            benefitsFromDigitalRegister: true,
            freeholdNotApplicable: true,
            requires: [
                'lease.leaseParties.charityName',
                'lease.leaseParties.leaseIndex',
                'lease.leaseParties.organizationCompanyRegistrationNumber',
                'lease.leaseParties.organizationName',
                'lease.leaseParties.partyDescription',
                'lease.leaseParties.partyNumber',
                'lease.leaseParties.privateIndividualForeNames',
                'lease.leaseParties.privateIndividualSurname',
                'lease.leaseParties.tradingName',
            ],
            includeInTitleOrganiser: true,
        },
        {
            text: 'No. of Leases',
            field: 'scheduleOfNoticesOfLeaseCount',
            group: 'lease',
            width: 300,
            align: 'center',
            htmlEncode: false,
            benefitsFromDigitalRegister: true,
            requires: ['scheduleOfNoticesOfLeaseCount'],
        },
        {
            text: 'Schedule of Notice of Lease',
            field: 'scheduleOfNoticesOfLease',
            group: 'lease',
            hidden: true,
            width: 250,
            htmlEncode: false,
            benefitsFromDigitalRegister: true,
            renderer: ({
                isExport,
                value,
            }) => {
                if (!isExport) {
                    // If there's something in the array, then process it
                    if (value?.length > 0) {
                        return `${ value.filter(l => l.leaseTitleNumber !== null).map(l => {
                            const url = location.href.replace('analysis', `titles/${ l.leaseTitleNumber }`)
                            return `<a href="${ url }" target="_blank">${ l.leaseTitleNumber }</a>`
                        }).join(',&nbsp;') }`
                    }
                    return 'N/A'
                }

                // When exporting, just concatenate the title numbers together in the cell.
                return value
                    ? value.filter(l => l.leaseTitleNumber !== null)
                        .map(l => l.leaseTitleNumber)
                        .join(', ')
                    : null
            },
            requires: ['scheduleOfNoticesOfLease.titleNumber', 'scheduleOfNoticesOfLease.leaseTitleNumber'],
        },
        {
            text: 'Restrictions (All)',
            field: 'allRestrictions',
            group: 'restrictions',
            width: 250,
            hidden: true,
            htmlEncode: false,
            benefitsFromDigitalRegister: true,
            requires: [
                'restrictionDetails.typeCode',
                'restrictionDetails.entryText',
            ],
        },
        {
            text: 'Charge Restrictions',
            field: 'chargeRestrictions',
            group: 'restrictions',
            width: 250,
            hidden: true,
            htmlEncode: false,
            benefitsFromDigitalRegister: true,
            requires: [
                'restrictionDetails.typeCode',
                'restrictionDetails.entryText',
            ],
        },
        {
            text: 'Non-Charge Restrictions',
            field: 'nonChargeRestrictions',
            group: 'restrictions',
            width: 250,
            hidden: true,
            htmlEncode: false,
            benefitsFromDigitalRegister: true,
            requires: [
                'restrictionDetails.typeCode',
                'restrictionDetails.entryText',
            ],
        },
        {
            text: 'No. of Restrictions (All)',
            field: 'allRestrictionsCount',
            group: 'restrictions',
            width: 170,
            hidden: true,
            align: 'center',
            htmlEncode: false,
            benefitsFromDigitalRegister: true,
            requires: [
                'restrictionDetails.typeCode',
            ],
            includeInTitleOrganiser: true,
        },
        {
            text: 'No. of Charge Restrictions',
            field: 'chargeRestrictionsCount',
            group: 'restrictions',
            width: 150,
            hidden: true,
            align: 'center',
            htmlEncode: false,
            benefitsFromDigitalRegister: true,
            requires: [
                'restrictionDetails.typeCode',
            ],
            includeInTitleOrganiser: true,
        },
        {
            text: 'No. of Non-Charge Restrictions',
            field: 'nonChargeRestrictionsCount',
            group: 'restrictions',
            width: 150,
            hidden: true,
            align: 'center',
            htmlEncode: false,
            benefitsFromDigitalRegister: true,
            requires: [
                'restrictionDetails.typeCode',
            ],
            includeInTitleOrganiser: true,
        },
        {
            text: 'Bank Charge Holders',
            field: 'bankChargeHolders',
            width: 250,
            hidden: true,
            htmlEncode: false,
            benefitsFromDigitalRegister: true,
            requires: ['bankChargeHolders.entryText'],
        },
        {
            text: 'Schedule of Restrictive Covenants',
            field: 'restrictiveCovenants',
            width: 250,
            hidden: true,
            htmlEncode: false,
            benefitsFromDigitalRegister: true,
            requires: [
                'restrictiveCovenants.entryNumber',
                'restrictiveCovenants.entryText',
            ],
        },
        {
            text: 'Notices',
            field: 'agreedNotices',
            width: 250,
            hidden: true,
            htmlEncode: false,
            benefitsFromDigitalRegister: true,
            requires: ['agreedNotices.entryText'],
        },
        {
            text: 'Cautions',
            field: 'cautions',
            width: 250,
            hidden: true,
            htmlEncode: false,
            benefitsFromDigitalRegister: true,
            requires: ['cautions.entryText'],
        },
        {
            text: 'Complexity',
            field: 'complexityLabel',
            group: 'basic',
            width: 170,
            useDefaultComboFilter: true,
            benefitsFromDigitalRegister: true,
            renderer: ({
                isExport,
                record,
            }) => {
                if (!isExport) {
                    if (record.complexity !== undefined) {
                        return `
                            <div class="title-complexity-category-${ record.complexity.category.replace('_', '').toLowerCase() }">
                                <span title="${ record.complexity.value }">${ record.complexity.label }</span>
                            </div>
                        `
                    } else {
                        return null
                    }
                }
                return record.complexity?.label
            },
            groupable: false,
            sortable: (a, b) => {
                if (a.complexity?.value === b.complexity?.value) {
                    return 0
                } else if (a.complexity?.value === null) {
                    return 1
                } else if (b.complexity?.value === null) {
                    return -1
                } else {
                    return a.complexity?.value < b.complexity?.value ? -1 : 1
                }
            },
            htmlEncode: false,
            requires: [
                'complexity.category',
                'complexity.value',
                'titleComplexityRegisterIndicators.documentsNotLeasesCount',
                'titleComplexityRegisterIndicators.leaseCount',
                'titleComplexityRegisterIndicators.allFlagsCount',
                'titleComplexityRegisterIndicators.qualityOfTenure',
                'titleComplexityRegisterIndicators.registersEntryCount',
                'titleComplexityRegisterIndicators.tenureType',
                'titleComplexityRegisterIndicators.yearOfOldestEntry',
            ],
        },

        // Debug mode and title complexity feature flag
        ...(options?.debugMode && options?.includeTitleComplexity
            ? [
                {
                    text: 'Complexity Indicator - No. of Non-Lease Documents',
                    field: 'complexityIndicatorDocumentsNotLeasesCount',
                    group: 'basic',
                    width: 120,
                    hidden: true,
                    align: 'center',
                    benefitsFromDigitalRegister: true,
                    requires: ['titleComplexityRegisterIndicators.documentsNotLeasesCount'],
                },
                {
                    text: 'Complexity Indicator - No. of Leases',
                    field: 'complexityIndicatorLeaseCount',
                    group: 'basic',
                    width: 120,
                    hidden: true,
                    align: 'center',
                    benefitsFromDigitalRegister: true,
                    requires: ['titleComplexityRegisterIndicators.leaseCount'],
                },
                {
                    text: 'Complexity Indicator - All Flags Count',
                    field: 'complexityIndicatorAllFlagsCount',
                    group: 'basic',
                    width: 120,
                    hidden: true,
                    align: 'center',
                    benefitsFromDigitalRegister: true,
                    requires: ['titleComplexityRegisterIndicators.allFlagsCount'],
                },
                {
                    text: 'Complexity Indicator - Quality of Tenure',
                    field: 'complexityIndicatorQualityOfTenure',
                    group: 'basic',
                    width: 120,
                    hidden: true,
                    align: 'center',
                    benefitsFromDigitalRegister: true,
                    requires: ['titleComplexityRegisterIndicators.qualityOfTenure'],
                },
                {
                    text: 'Complexity Indicator - No. of Entries in Registers',
                    field: 'complexityIndicatorRegistersEntryCount',
                    group: 'basic',
                    width: 120,
                    hidden: true,
                    align: 'center',
                    benefitsFromDigitalRegister: true,
                    requires: ['titleComplexityRegisterIndicators.registersEntryCount'],
                },
                {
                    text: 'Complexity Indicator - Tenure Type',
                    field: 'complexityIndicatorTenureType',
                    group: 'basic',
                    width: 120,
                    hidden: true,
                    align: 'center',
                    benefitsFromDigitalRegister: true,
                    requires: ['titleComplexityRegisterIndicators.tenureType'],
                },
                {
                    text: 'Complexity Indicator - Year of Oldest Entry',
                    field: 'complexityIndicatorYearOfOldestEntry',
                    group: 'basic',
                    width: 120,
                    hidden: true,
                    align: 'center',
                    benefitsFromDigitalRegister: true,
                    requires: ['titleComplexityRegisterIndicators.yearOfOldestEntry'],
                },
            ]
            : []),

        ...(options?.includeMlTags
            ? [
                {
                    text: 'Rights of light',
                    field: 'rightsOfLight',
                    group: 'flags',
                    width: 120,
                    hidden: true,
                    align: 'center',
                    benefitsFromDigitalRegister: true,
                    requires: ['titleRegisterMlTags.topic'],
                },
            ]
            : []),
        // Counts / numbers of '...'
        {
            text: 'No. of Charges',
            field: 'chargeCount',
            group: 'flags',
            width: 120,
            hidden: true,
            align: 'center',
            htmlEncode: false,
            benefitsFromDigitalRegister: true,
            requires: ['chargeCount'],
            includeInTitleOrganiser: true,
        },
        {
            text: 'No. of Agreed Notices',
            field: 'agreedNoticeCount',
            group: 'flags',
            width: 200,
            hidden: true,
            align: 'center',
            htmlEncode: false,
            benefitsFromDigitalRegister: true,
            requires: ['agreedNoticeCount'],
            includeInTitleOrganiser: true,
        },
        {
            text: 'No. of Unilateral Notices',
            field: 'unilateralNoticeDetailsCount',
            group: 'flags',
            width: 200,
            hidden: true,
            align: 'center',
            htmlEncode: false,
            benefitsFromDigitalRegister: true,
            requires: ['unilateralNoticeDetailsCount'],
            includeInTitleOrganiser: true,
        },
        {
            text: 'No. of Cautions',
            field: 'cautionCount',
            group: 'flags',
            width: 150,
            hidden: true,
            align: 'center',
            htmlEncode: false,
            benefitsFromDigitalRegister: true,
            requires: ['cautionCount'],
            includeInTitleOrganiser: true,
        },
        {
            text: 'No. of Pre-emption Rights',
            field: 'rightOfPreEmptionCount',
            group: 'flags',
            width: 200,
            hidden: true,
            align: 'center',
            htmlEncode: false,
            benefitsFromDigitalRegister: true,
            requires: ['rightOfPreEmptionCount'],
            includeInTitleOrganiser: true,
        },
        {
            text: 'No. of Bankruptcies',
            field: 'bankruptcyCount',
            group: 'flags',
            hidden: true,
            width: 150,
            align: 'center',
            htmlEncode: false,
            benefitsFromDigitalRegister: true,
            requires: ['bankruptcyCount'],
            includeInTitleOrganiser: true,
        },
        {
            text: 'No. of CCBI',
            field: 'ccbiEntryCount',
            group: 'flags',
            hidden: true,
            width: 100,
            align: 'center',
            htmlEncode: false,
            benefitsFromDigitalRegister: true,
            requires: ['cCBIEntryCount'],
            includeInTitleOrganiser: true,
        },
        {
            text: 'No. of Creditor Notices',
            field: 'creditorsNoticeCount',
            group: 'flags',
            hidden: true,
            width: 200,
            align: 'center',
            htmlEncode: false,
            benefitsFromDigitalRegister: true,
            requires: ['creditorsNoticeCount'],
            includeInTitleOrganiser: true,
        },
        {
            text: 'No. of Proprietor Deaths',
            field: 'deathOfProprietorCount',
            group: 'flags',
            hidden: true,
            width: 200,
            align: 'center',
            htmlEncode: false,
            benefitsFromDigitalRegister: true,
            requires: ['deathOfProprietorCount'],
            includeInTitleOrganiser: true,
        },
        {
            text: 'No. of Deed of Postponements',
            field: 'deedOfPostponementCount',
            group: 'flags',
            hidden: true,
            width: 250,
            align: 'center',
            htmlEncode: false,
            benefitsFromDigitalRegister: true,
            requires: ['deedOfPostponementCount'],
            includeInTitleOrganiser: true,
        },
        {
            text: 'No. of Discount Charges',
            field: 'discountChargeCount',
            group: 'flags',
            hidden: true,
            width: 200,
            align: 'center',
            htmlEncode: false,
            benefitsFromDigitalRegister: true,
            requires: ['discountChargeCount'],
            includeInTitleOrganiser: true,
        },
        {
            text: 'No. of Equitable Charges',
            field: 'equitableChargeCount',
            group: 'flags',
            type: 'number',
            hidden: true,
            width: 200,
            align: 'center',
            htmlEncode: false,
            benefitsFromDigitalRegister: true,
            requires: ['equitableChargeCount'],
            includeInTitleOrganiser: true,
        },
        {
            text: 'No. of Green Out Entries',
            field: 'greenOutEntryCount',
            group: 'flags',
            hidden: true,
            width: 250,
            align: 'center',
            htmlEncode: false,
            benefitsFromDigitalRegister: true,
            requires: ['greenOutEntryCount'],
            includeInTitleOrganiser: true,
        },
        {
            text: 'No. of Home Rights',
            field: 'homeRightsCount',
            group: 'flags',
            hidden: true,
            width: 150,
            align: 'center',
            htmlEncode: false,
            benefitsFromDigitalRegister: true,
            requires: ['homeRightsCount'],
            includeInTitleOrganiser: true,
        },
        {
            text: 'No. of Constituent Leases',
            field: 'leaseCount',
            group: 'flags',
            hidden: true,
            width: 150,
            align: 'center',
            htmlEncode: false,
            benefitsFromDigitalRegister: true,
            requires: ['leaseCount'],
            includeInTitleOrganiser: true,
        },
        {
            text: 'No. of Noted Charges',
            field: 'notedChargeCount',
            group: 'flags',
            type: 'number',
            hidden: true,
            width: 150,
            align: 'center',
            htmlEncode: false,
            benefitsFromDigitalRegister: true,
            requires: ['notedChargeCount'],
            includeInTitleOrganiser: true,
        },
        {
            text: 'No. of Property Addresses',
            field: 'propertyAddressCount',
            group: 'flags',
            hidden: true,
            width: 200,
            align: 'center',
            htmlEncode: false,
            benefitsFromDigitalRegister: true,
            requires: ['propertyAddressCount'],
            includeInTitleOrganiser: true,
        },
        {
            text: 'No. of Rent Charges',
            field: 'rentChargeCount',
            group: 'flags',
            hidden: true,
            width: 150,
            align: 'center',
            htmlEncode: false,
            benefitsFromDigitalRegister: true,
            requires: ['rentChargeCount'],
            includeInTitleOrganiser: true,
        },
        {
            text: 'No. of Unidentified Entries',
            field: 'unidentifiedEntryCount',
            group: 'flags',
            hidden: true,
            width: 200,
            align: 'center',
            htmlEncode: false,
            benefitsFromDigitalRegister: true,
            requires: ['unidentifiedEntryCount'],
            includeInTitleOrganiser: true,
        },
        {
            text: 'No. of Vendors Lien',
            field: 'vendorsLienCount',
            group: 'flags',
            hidden: true,
            width: 150,
            align: 'center',
            htmlEncode: false,
            benefitsFromDigitalRegister: true,
            requires: ['vendorsLienCount'],
            includeInTitleOrganiser: true,
        },
        {
            text: 'Total No. of Charges',
            field: 'chargesTotalCount',
            group: 'flags',
            hidden: true,
            width: 200,
            align: 'center',
            htmlEncode: false,
            benefitsFromDigitalRegister: true,
            requires: [
                'chargeCount',
                'discountChargeCount',
                'equitableChargeCount',
                'notedChargeCount',
                'rentChargeCount',
            ],
            includeInTitleOrganiser: true,
        },

        // Register entries
        {
            text: 'Register Section',
            field: 'registerSection',
            excludeFromColumnSelectionPanel: true,
            hidden: true,
            requires: [
                'genericEntries.registerSection',
            ],
        },
        {
            text: 'Entry No.',
            field: 'entryNumber',
            excludeFromColumnSelectionPanel: true,
            hidden: true,
            requires: [
                'genericEntries.entryNumber',
            ],
        },
        {
            text: 'Entry Date',
            field: 'entryDate',
            excludeFromColumnSelectionPanel: true,
            hidden: true,
            type: 'date',
            width: 180,
            renderer: ({
                isExport,
                value,
            }) => {
                if (!isExport) {
                    return getDateStringOrDefault(value)
                }
                return value
            },
            requires: [
                'genericEntries.entryDate',
            ],
        },
        {
            text: 'Entry Type',
            field: 'entryType',
            excludeFromColumnSelectionPanel: true,
            useDefaultComboFilter: true,
            hidden: true,
            width: 315,
            requires: [
                'genericEntries.entryType',
            ],
        },
        {
            text: 'Title Entry',
            field: 'entryText',
            excludeFromColumnSelectionPanel: true,
            hidden: true,
            autoWidth: true,
            requires: [
                'genericEntries.entryText',
            ],
        },
    ]

    // filter out columns that are not includeInTitleOrganiser when option is set
    if (options?.includeInTitleOrganiser) {
        result = result.filter(column => column.includeInTitleOrganiser)
    }

    // Apply logic to columns based on the properties defined on each.
    result.forEach(column => {
        // Apply combo filter settings to applicable columns.
        if (column.useDefaultComboFilter) {
            const combo = {
                type: 'combo',
                valueField: 'name',
                displayField: 'name',
                store: {
                    idField: 'name',
                    data: [],
                },
            }
            column.editor = combo
            column.filterable = {
                filterField: Object.assign(combo, { multiSelect: true }),
            }
        }

        // Apply numeric column filter settings to applicable columns.
        if (column.type === 'number') {
            column.filterable = ({
                value,
                record,
            }) => {
                return record[column.field] === value
            }
        }

        // Hint that the digital register is required when no value can otherwise be derived for applicable columns.
        if (column.benefitsFromDigitalRegister) {
            const baseRenderer = column.renderer
            column.renderer = (options) => {
                const value = baseRenderer ? baseRenderer(options) : options.record[column.field]
                return benefitsFromDigitalRegisterHintColumnRenderer({
                    record: options.record,
                    includeInTitleOrganiser: options.includeInTitleOrganiser,
                    value,
                    isExport: options.isExport,
                })
            }
        }

        if (column.benefitsFromParentDigitalRegister) {
            const baseRenderer = column.renderer
            column.renderer = (options) => {
                const value = baseRenderer ? baseRenderer(options) : options.record[column.field]
                return benefitsFromDigitalRegisterHintColumnRenderer({
                    record: options.record,
                    includeInTitleOrganiser: options.includeInTitleOrganiser,
                    value,
                    isExport: options.isExport,
                    recommendParentDigitalRegister: true,
                })
            }
        }

        // Hint that this column is not applicable to freehold titles.
        if (column.freeholdNotApplicable) {
            const baseRenderer = column.renderer
            column.renderer = (options) => {
                const value = baseRenderer ? baseRenderer(options) : options.record[column.field]
                return freeholdNotApplicableColumnRenderer({
                    record: options.record,
                    includeInTitleOrganiser: options.includeInTitleOrganiser,
                    value,
                    isExport: options.isExport,
                })
            }
        }

        const baseGroupRenderer = column.groupRenderer
        column.groupRenderer = (options) => {
            let value = baseGroupRenderer ? baseGroupRenderer(options) : options.groupRowFor
            if (value === '!!novalue!!') {
                value = 'N/A'
            }

            return `${ value } (${ options.count })`
        }
    })

    return result
}

// Set up the Bryntum grid config object
const getGridConfig = (options) => {
    return {
        // Increment to indicate breaking changes to configuration, so that the grid state in local storage be
        // discarded if it exists for a previous version.
        version: '6',

        adopt: 'container',
        minHeight: '25px',

        // Headers will ripple on tap in Material theme
        ripple: {
            delegate: '.b-grid-header',
        },

        selectionMode: {
            row: true,
        },

        cellTooltip: true,
        cellEdit: false,
        columnLines: true,
        columnPicker: true,
        dragColumns: true,
        emptyText: 'No matching titles...',
        excelExporter: true,
        fillLastColumn: true,
        regionSize: true, // draggable locked columns bar if true
        search: true,
        tree: false,
        columns: getColumns(options),
        features: {
            headerMenu: {
                items: {
                    selectColumns: {
                        text: 'Edit columns...',
                        icon: 'b-fa-columns',
                    },
                },
            },
            columnPickerFeature: false,
            filterBar: true,
            group: true,
        },
    }
}

export {
    getColumnGroups,
    getColumns,
    getGridConfig,
}
