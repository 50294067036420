<template>
    <div class="document-library-grouped-table d-flex flex-column">
        <div v-if="groupedTableData.size > 0 && store.isGrouped && loaded"
             class="d-flex justify-end gc-1">
            <a v-if="allGroupsCollapsed"
               v-t="'action.expandAll'"
               class="body-regular-xx-small"
               href="#"
               @click="expandAllGroups" />
            <a v-else
               v-t="'action.collapseAll'"
               class="body-regular-xx-small"
               href="#"
               @click="collapseAllGroups" />
        </div>
        <document-library-no-data v-if="groupedTableData.size === 0 && !loadingGroups"
                                  class="document-library-grouped-table__no-data"
                                  @clear-filter="store.clearGroupFilter()" />
        <template v-for="item in groupedTableData.keys()"
                  v-else
                  :key="item">
            <v-lazy v-model="loaded"
                    :options="{ threshold: .25}">
                <document-library-grouped-sub-table :items="groupedTableData.get(item) ?? []"
                                                    class="mb-3"
                                                    :group="item" />
            </v-lazy>
        </template>
    </div>
</template>

<script setup lang="ts">
    import { debounce } from 'lodash'
    import {
        computed,
        reactive,
        ref,
        watch,
    } from 'vue'
    import { useI18n } from 'vue-i18n'

    import DocumentLibraryGroupedSubTable from '@/components/document-library/document-library-grouped-sub-table.vue'
    import DocumentLibraryGroupedTableHeader
        from '@/components/document-library/document-library-grouped-table-header.vue'
    import DocumentLibraryNoData from '@/components/document-library/document-library-no-data.vue'
    import { inject as useDocumentLibrary } from '@/composables/use-document-library'
    import { IDocumentRowData } from '@/interfaces/document-row-data.interface'
    import {
        useDocumentLibraryStore,
    } from '@/stores/document-library'
    import { parseTitleNumber } from '@/stores/document-library/util'
    import {
        dateSort,
        isNullOrEmpty,
    } from '@/utils/array-utils'

    const store = useDocumentLibraryStore()
    const { t } = useI18n()
    const sortBy = defineModel('sort-by', {
        default: [
            { key: 'groupName', order: 'asc' },
        ],
    })

    const groupedTableData = reactive<Map<string, IDocumentRowData[]>>(new Map())
    const loadingGroups = defineModel('loading-groups', { default: false })
    const loaded = ref(false)

    const expandAllGroups = () => {
        store.collapsedGroups.clear()
    }

    const collapseAllGroups = () => {
        store.collapsedGroups = new Set(groupedTableData.keys())
    }

    const allGroupsCollapsed = computed(() => store.collapsedGroups.size === groupedTableData.size)

    const populateGroupedTableData = () => {
        if (!store.isGrouped || isNullOrEmpty(store.tableData)) {
            loadingGroups.value = false
            groupedTableData.clear()
            return
        }

        groupedTableData.clear()
        const data = [...store.tableData] // Clone the array to avoid side effects
        const { groupByTitle } = store.filterOptions
        data.sort(dateSort('orderDate', 'desc'))

        data.forEach((doc) => {
            const titleNumber = parseTitleNumber(doc?.titleNumber)
            const key = groupByTitle ? titleNumber ?? t('label.ungrouped') : doc?.groupName ?? t('label.ungrouped')
            if (!groupedTableData.has(key)) {
                groupedTableData.set(key, [])
            }
            groupedTableData.get(key)?.push(doc)
        })

        if (sortBy.value?.some((sort) => sort.key === 'groupName' || sort.key === 'titleNumber')) {
            const sortedKeys = Array.from(groupedTableData.keys()).sort((a, b) =>
                sortBy.value?.[0]?.order === 'desc' ? b.localeCompare(a) : a.localeCompare(b),
            )
            const sortedGroupedTableData = new Map(sortedKeys.map(key => [key, groupedTableData.get(key)]))
            groupedTableData.clear()
            sortedGroupedTableData.forEach((value, key) => groupedTableData.set(key, value))
        }

        if (groupedTableData.has('Ungrouped')) {
            const ungrouped = groupedTableData.get('Ungrouped')
            groupedTableData.delete('Ungrouped')
            groupedTableData.set('Ungrouped', ungrouped)
        }
        loadingGroups.value = false
    }

    const debouncedPopulateGroupedTableData = debounce(populateGroupedTableData, 500)

    watch(() => store.filterOptions, () => {
        loadingGroups.value = true
        sortBy.value = [
            { key: store.filterOptions.groupByGroup ? 'groupName' : 'titleNumber', order: 'asc' },
        ]
        debouncedPopulateGroupedTableData()
    }, {
        deep: true,
        immediate: true,
    })

    watch(() => sortBy.value, () => {
        debouncedPopulateGroupedTableData()
    }, {
        deep: true,
        immediate: true,
    })
</script>

<style lang="scss">
@import './document-library-grouped-table.scss';
</style>
