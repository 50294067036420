import { provide as provideUser } from '@/composables/use-user';
import { provide as provideLicencing } from '@/composables/use-licence-controller';
import { provide as provideTitlePanel } from '@/composables/use-title-panel';
import { provide as provideMap } from '@/composables/use-map';
import { provide as provideFeatureFlags } from '@/composables/use-feature-flags';
import { provide as provideDocument } from '@/composables/use-document';

const provideGlobalComposables = () => {
    provideUser()
    provideLicencing()
    provideTitlePanel()
    provideMap()
    provideFeatureFlags()
    provideDocument()
}

export default provideGlobalComposables