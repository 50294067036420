<template>
    <component :is="planningComponent"
               v-model="showLock"
               :feature-id="FeatureId.Planning"
               :scroll-element-container="`#${props.scrollElementId}`" />
</template>

<script setup lang="ts">
    import {
        computed,
        onBeforeMount,
        ref,
        watch,
    } from "vue"
    import {useRoute} from "vue-router"
    import {useStore} from "vuex"

    import OwFullPageLock from "@/components/core/ow-full-page-lock.vue"
    import PlanningContainer from '@/components/planning/planning-container.vue'
    import PlanningContainerWithMap from "@/components/title-panel/v2/planning/planning-container-with-map.vue"
    import {FeatureId} from "@/composables/use-licence-controller"
    import { inject as useMap } from '@/composables/use-map'
    import {useSelectedTitle} from "@/composables/use-selected-title"
    import {inject as titlePanelProvider} from "@/composables/use-title-panel"
    import  {inject as useUser} from "@/composables/use-user"
    import {Route} from "@/enums/route.enum"
    import {TitlePanelTabName} from "@/enums/title-panel-tab-name"
    import {LINK_SHARED_CLIENT_GET_IS_SHARED_LINK_VIEW} from "@/store/modules/link-share-client/types"
    import {MAP_UPDATE_SIZE} from "@/store/modules/map/types"
    import {
        PLANNING_CLEAR,
        PLANNING_SEARCH_BY_TITLE_NUMBERS,
    } from "@/store/modules/planning/types"
    import {USER_MUTATE_SHOW_MAP_TITLES_NAV} from "@/store/mutation-types"

    const { titlePanelFullScreen, activeTabId, isViewingMap } = titlePanelProvider()
    const { selectedTitleNumber } = useSelectedTitle()
    const { hasAccessToPlanning, isPremiumUser } = useUser()
    const store = useStore()
    const route = useRoute()
    const { isUrban } = useMap()
    const props = defineProps<{
        scrollElementId: string,
    }>()

    const showLock = ref<boolean>(true)
    const planningComponent = computed(() => {
        return showLock.value? OwFullPageLock : (titlePanelFullScreen.value || !isViewingMap.value) ? PlanningContainerWithMap : PlanningContainer
    })
    const isMapLoaded = computed<boolean>(() => store.state.map.loaded)

    const showingPlanningApplications = computed<boolean>((): boolean => {
        return activeTabId.value === TitlePanelTabName.Planning &&
            showTitlePanel.value === true && !titlePanelFullScreen.value
    })

    const isSharedLinkView = computed(() => store.getters[`linkShareClient/${ LINK_SHARED_CLIENT_GET_IS_SHARED_LINK_VIEW }`])
    const isValidRoute = computed(() => {
        return ![Route.MatterSketches, Route.OverlaysList, Route.Overlay].includes(route.name as Route)
    })
    const isWalkthrough = computed<boolean>((): boolean => store.state.walkthrough.enabled)
    const isOwnerTitlesPanelOpen = computed<boolean>((): boolean => store.state.search.isOwnerTitlesPanelOpen)

    const showTitlePanel = computed<boolean>((): boolean => {
        return isValidRoute.value &&
            !isSharedLinkView.value &&
            !isWalkthrough.value &&
            !isOwnerTitlesPanelOpen.value &&
            Boolean(selectedTitleNumber.value)
    })

    watch(() => ({
              showingPlanningApplications: showingPlanningApplications.value,
              isMapLoaded: isMapLoaded.value,
              isUrban: isUrban.value,
          }),
          async ({ showingPlanningApplications, isMapLoaded, isUrban }) => {
              if (!isMapLoaded) {
                  return
              }

              if (showingPlanningApplications) {
                  store.commit(USER_MUTATE_SHOW_MAP_TITLES_NAV, false)
                  await store.dispatch(PLANNING_CLEAR)
                  await store.dispatch(PLANNING_SEARCH_BY_TITLE_NUMBERS, {
                      titleNumbers: [selectedTitleNumber.value || route.params.titleNumber],
                      isUrban: isPremiumUser.value ? isUrban : true, // The rural distance is only available to premium users
                  })
              } else {
                  store.commit(USER_MUTATE_SHOW_MAP_TITLES_NAV, true)
                  await store.dispatch(PLANNING_CLEAR)
              }
              await store.dispatch(MAP_UPDATE_SIZE)
          }, { immediate: true })

    onBeforeMount(async () => {
        const hasAccess = await hasAccessToPlanning()
        showLock.value = !hasAccess
    })
</script>

<style scoped lang="scss">

</style>
