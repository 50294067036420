import {
    provide as VueProvide,
} from 'vue'
import {
    inject as VueInject,
} from 'vue'

import { checkFlag } from '@/feature-flags'

export const FEATURE_FLAGS_PROVIDER_KEY = Symbol('featureFlagsProvider')

export const inject = (): ReturnType<typeof useFeatureFlags> => {
    if (!VueInject(FEATURE_FLAGS_PROVIDER_KEY)) {
        throw new Error(`${ FEATURE_FLAGS_PROVIDER_KEY.toString() } has not been provided`)
    }
    return VueInject(FEATURE_FLAGS_PROVIDER_KEY) as ReturnType<typeof useFeatureFlags>
}

export const provide = () => {
    if ((import.meta as any).env.DEV) {
        // eslint-disable-next-line no-console
        console.trace(`providing: ${ FEATURE_FLAGS_PROVIDER_KEY.toString() }`)
    }
    const featureFlags = useFeatureFlags()
    VueProvide(FEATURE_FLAGS_PROVIDER_KEY, featureFlags)

    return featureFlags
}

export enum FeatureFlag {
    DocumentLibraryV2 = 'document-library-v-2',
    DocumentLibraryV2UnpurchasedDocuments = 'doc-library-v-2-unpurchased-documents',
    DocumentLibraryV2OrderHistory = 'document-library-v-2-order-history',
}

/**
 * Where possible use the exported inject function to access the composable
 */
const useFeatureFlags = () => {
    const checkLDFlag = (flag: FeatureFlag | string, defaultValue: boolean = false) => {
        return checkFlag(flag, defaultValue)
    }

    return {
        checkFlag: checkLDFlag,
    }
}
