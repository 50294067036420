<template>
    <component :is="component"
               v-bind="$attrs" />
</template>

<script setup lang="ts">
    import {
        FeatureFlag,
        inject as useFeatureFlags,
    } from '@/composables/use-feature-flags'
    import DocumentLibraryV1 from '@/views/document-library.vue'
    import DocumentLibraryV2 from '@/views/document-library/document-library.vue'

    const { checkFlag } = useFeatureFlags()
    const component = checkFlag(FeatureFlag.DocumentLibraryV2, false) ? DocumentLibraryV2 : DocumentLibraryV1
</script>

<style scoped>

</style>