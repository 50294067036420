import { QuickOptionType } from '@/enums/quick-option-type.enum'

export enum QuickOptionVisibility {
    Empty = 'empty',
    Always = 'always',
}


export interface IQuickOption {
    id?: string
    count?: number
    title?: string | ((args: unknown) => string)
    description?: string | ((args: unknown) => string)
    icon?: string | ((args: unknown) => string)
    action?: ((args: unknown) => void)
    visible?: boolean | ((data: any[]) => boolean) | QuickOptionVisibility
    priority?: number

    /**
     * The type of quick option, if not provided it will default to 'button'
     */
    type?: QuickOptionType
}