<template>
    <li class="body-regular-small"
        data-test="planning-application-changed">
        {{ t('assetMonitoring.summaryCard.planningApplicationChange', numberOfChanges) }}

        <ul v-for="(diff, index) in differences"
            :key="index"
            v-dompurify-html="getDiffText(diff)"
            class="sub-list"
            data-test="planning-application-changed-list" />
    </li>
</template>

<script lang="ts" setup>
    import { computed } from 'vue'
    import { useI18n } from 'vue-i18n'

    import { IAssetMonitoringNotificationDetail } from '@/components/asset-monitoring/asset-monitoring-notification.interface'
    import { PlanningApplicationsDatapoint } from '@/components/asset-monitoring/datapoint-interfaces'
    import type {
        Difference,
        UpdatedProperty,
    } from '@/components/asset-monitoring/difference-generic'

    const { t } = useI18n()

    const props = defineProps<{
        items: IAssetMonitoringNotificationDetail[]
    }>()

    const differences = computed(() => {
        const differences: Difference<PlanningApplicationsDatapoint>[] = []
        props.items?.forEach((item) => {
            if (item.difference) {
                differences.push(JSON.parse(item.difference))
            }
        })

        return differences
    })
    const numberOfChanges = computed<number>(() => {
        return differences.value.reduce((acc, diff) => {
            return acc + diff.added.length + diff.removed.length + diff.updated.length
        }, 0)
    })

    const getDiffText = (diff: Difference<PlanningApplicationsDatapoint>): string => {
        const { added, removed, updated } = diff
        const output = []

        added.forEach((item: PlanningApplicationsDatapoint) => {
            output.push(`<li>${ t('label.added') }: <span class="changes__to">${ item.identifier }</span>  (${ t(`assetMonitoring.subType.planningApplication.type.${ item.type }`) }) <span class="changes__to">${ t(`assetMonitoring.subType.planningApplication.decision.${ item.decision }`) }</span> ${ item.address }</li>`)
        })
        removed.forEach((item: PlanningApplicationsDatapoint) => {
            output.push(`<li>${ t('label.removed') }: <span class="changes__from">${ item.identifier } (${ t(`assetMonitoring.subType.planningApplication.type.${ item.type }`) }) <span class="changes__to">${ t(`assetMonitoring.subType.planningApplication.decision.${ item.decision }`) }</span> ${ item.address }</span></li>`)
        })

        updated.forEach((item: UpdatedProperty<PlanningApplicationsDatapoint>) => {
            const { before, after } = item

            const hasDecisionChanged = before.decision !== after.decision
            const beforeDecision = t(`assetMonitoring.subType.planningApplication.decision.${ before.decision }`)
            const afterDecision = t(`assetMonitoring.subType.planningApplication.decision.${ after.decision }`)
            const decisionFrom = hasDecisionChanged ? `<span class="changes__from">${ beforeDecision }</span>` : beforeDecision
            const decisionTo = hasDecisionChanged ? `<span class="changes__to">${ afterDecision }</span>` : afterDecision

            output.push(`<li>${ t('assetMonitoring.summaryCard.updated', {
                from: `<span class="changes__from">${ before.identifier } (${ t(`assetMonitoring.subType.planningApplication.type.${ before.type }`) }) ${ decisionFrom } ${ before.address }</span>`,
                to: `<span class="changes__to">${ after.identifier } (${ t(`assetMonitoring.subType.planningApplication.type.${ after.type }`) })</span> ${ decisionTo } ${ after.address }`,
            }) }</li>`)
        })

        return output.join('')
    }
</script>
