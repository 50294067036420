import {
    computed,
    inject as VueInject,
    onBeforeUnmount,
    onMounted,
    provide as VueProvide,
    ref,
    toRaw,
    watch,
} from 'vue'
import {
    useStore,
} from 'vuex'

import { IMapState } from '@/store/modules/map'
import {
    MAP_VALIDATE_NPS_DISPLAY,
} from '@/store/modules/map/types'
export const MAP_PROVIDER_KEY = Symbol('mapProvider')

import booleanPointInPolygon from '@turf/boolean-point-in-polygon'
import {
    Feature,
    MapEvent,
} from 'ol'
import GeoJSON from 'ol/format/GeoJSON'
import { Geometry } from 'ol/geom'
import MultiPolygon from 'ol/geom/MultiPolygon'
import {
    Vector as VectorLayer} from 'ol/layer'
import olMap from 'ol/Map'
import { Vector as VectorSource } from 'ol/source'
import {
    Fill,
    Stroke,
    Style,
} from 'ol/style'
import { useI18n } from 'vue-i18n'

import { CoordinateSystemCode } from '@/enums/coordinate-systems'
import scotlandGeojson from '@/media/scotland.geojson?raw'
import urbanGeojson from '@/media/urban-v1.geojson?raw'
import { hexToRGBArray } from '@/utils/colour-utils'

export const inject = (): ReturnType<typeof useMap> => {
    if (!VueInject(MAP_PROVIDER_KEY)) {
        throw new Error(`${ MAP_PROVIDER_KEY.toString() } has not been provided`)
    }
    return VueInject(MAP_PROVIDER_KEY) as ReturnType<typeof useMap>
}

export const provide = () => {
    if ((import.meta as any).env.DEV) {
        // eslint-disable-next-line no-console
        console.trace(`providing: ${ MAP_PROVIDER_KEY.toString() }`)
    }
    VueProvide(MAP_PROVIDER_KEY, useMap())
}

/**
 * Where possible use the exported inject function to access the composable
 */
const useMap = () => {
    const store = useStore()
    const scotlandGeometry = ref(null)
    const urbanGeometry = ref(null)
    const extentTypeIcon = ref(null)
    const extentTypeText = ref('')
    const urbanLayer = ref<VectorLayer>(null)
    const urbanLayerVisible = ref(false)
    const { t } = useI18n()
    const extents = computed<IMapState['extents']>({
        get: () => store.state.map.extents,
        set: (value) => {
            store.state.map.extents = value
        },
    })
    const map = computed<IMapState>(() => store.state.map)
    const overriddenMaxResolution = computed({
        get: () => map.value.nps.overriddenMaxResolution,
        set: (value) => {
            store.state.map.nps.overriddenMaxResolution = value
        },
    })
    const mapResolution = computed({
        get: () => map.value.map.getView().getResolution(),
        set: (value) => {
            store.state.map.map.getView().setResolution(value)
        },
    })
    const isRural = computed(() => {
        return !extents.value.isUrban
    })
    const isUrban = computed(() => {
        return extents.value.isUrban
    })

    const dynamicZoomEnabled = computed(() => store.state.config.featureFlags?.mapDynamicZoom)

    const updateExtentTypeText = () => {
        if (extents.value.isScotland || mapResolution.value > 30) {
            extentTypeText.value = ''
        } else if (isUrban.value) {
            extentTypeText.value = t('map.options.urbanTooltip')
        } else {
            extentTypeText.value = t('map.options.ruralTooltip')
        }
    }

    const updateExtentTypeIcon = () => {
        if (extents.value.isScotland || (mapResolution.value > 30)) {
            extentTypeIcon.value = null
        } else if (isUrban.value) {
            extentTypeIcon.value = '$urban'
        } else {
            extentTypeIcon.value = '$rural'
        }
    }

    onMounted(() => {
        initialiseExtents()
    })

    onBeforeUnmount(() => {
        const map = store.state.map.map
        map.un('moveend', moveEndEventHandler)
    })

    const initialiseExtents = () => {
        scotlandGeometry.value = JSON.parse(scotlandGeojson)
        urbanGeometry.value = JSON.parse(urbanGeojson)
    }

    const checkExtents = async (olMap: olMap) => {
        checkExtent(olMap, scotlandGeometry.value, 'isScotland')

        if (dynamicZoomEnabled.value) {
            checkLayerVisibleInExtent(olMap, urbanLayer.value, 'isUrban')
            overriddenMaxResolution.value = (isRural.value && !isUrban.value) ? 6 : null

            updateExtentTypeIcon()
            updateExtentTypeText()
        }

        setTimeout(async () => {
            await store.dispatch(MAP_VALIDATE_NPS_DISPLAY)
        }, 100)
    }

    const checkExtent = (map: olMap, geometry: any, key: string) => {
        if (!geometry) {
            return
        }
        const extent = map.getView().calculateExtent(map.getSize())
        const coordinates = [
            [ extent[0], extent[1] ], // Bottom left (west, south)
            [ extent[0], extent[3] ], // Top left (west, north)
            [ extent[2], extent[3] ], // Top right (east, north)
            [ extent[2], extent[1] ], // Bottom right (east, south)
        ]
        const coordinateConditions = coordinates.map((coordinate: number[]) => {
            const [ x, y ] = coordinate
            return booleanPointInPolygon([ x, y ], geometry.features[0])
        })
        extents.value = {
            ...extents.value,
            [key]: coordinateConditions.some((condition: boolean) => condition),
        }
    }

    const checkLayerVisibleInExtent = (map: olMap, layer: VectorLayer, key: string) => {
        const extent = map.getView().calculateExtent(map.getSize())

        const features = layer.getSource().getFeatures()
        for (const feature of features) {
            const geometry = feature.getGeometry()
            if (geometry instanceof MultiPolygon) {
                // does geometry exist within the extent
                if (geometry.getPolygons().some((polygon) => polygon.intersectsExtent(extent))) {
                    extents.value = {
                        ...extents.value,
                        [key]: true,
                    }
                    return
                }
            }
        }
        extents.value = {
            ...extents.value,
            [key]: false,
        }
    }


    const setExtentMaxResolution = () => {
        mapResolution.value = !isUrban.value ? 5.99 : 2.99
    }

    const moveEndEventHandler = (e: MapEvent)  => {
        checkExtents(e.map)
    }


    watch(() => store.state.map.map, async (map: olMap) => {
        map.on('moveend', moveEndEventHandler)

        addUrbanLayer(map)
    })

    const addUrbanLayer = (map: olMap) => {
        const style = new Style({
            zIndex: 1,
            stroke: new Stroke({
                color: hexToRGBArray('#000'),
                width: 3,
                lineDash: [ 10, 10 ],
            }),
            fill: new Fill({
                color: `rgba(${ hexToRGBArray('#000') },0.1)`,
            }),
        })

        // add the urban layer to the map, the urbanGeometry is not available until the map is loaded
        urbanLayer.value = new VectorLayer({
            zIndex: 1,
            source: new VectorSource(),
            style,
        })

        urbanLayer.value.setProperties({ name: 'urban' })
        const selectedFeatures = new GeoJSON().readFeatures(urbanGeojson, {
            featureProjection: CoordinateSystemCode.EPSG27700,
            dataProjection: CoordinateSystemCode.EPSG27700,
        }) as Feature<Geometry>[]
        urbanLayer.value.getSource()?.addFeature(selectedFeatures[0])
        urbanLayer.value.getSource()?.changed()
        urbanLayer.value.setVisible(false)
        map.addLayer(urbanLayer.value)
    }

    const setUrbanLayerVisible = (visible: boolean) => {
        urbanLayerVisible.value = visible
        urbanLayer.value.setVisible(visible)
    }

    return {
        extents,
        extentTypeText,
        extentTypeIcon,
        setExtentMaxResolution,
        dynamicZoomEnabled,
        setUrbanLayerVisible,
        isUrban,
        isRural,
    }
}

export default useMap
