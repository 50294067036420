<template>
    <section class="unauthorised">
        <nav class="unauthorised__nav">
            <div class="unauthorised__nav--logo"
                 data-global-nav-item="home"
                 data-test="global-nav-unauthorised-logo"
                 data-track="GLOBAL-NAV - Unauthorised logo">
                <ow-icon-ow-logo-negative data-test="global-nav-unauthorised-selected" />
            </div>
        </nav>
        <section class="unauthorised__content">
            <article>
                <ow-icon-ow-logo class="pr-4"
                                 data-test="global-nav-unauthorised-selected" />
                <div>
                    <h3 class="headers-display">
                        {{ t('unauthorised.title') }}
                    </h3>
                    <p class="body-regular">
                        {{ t('unauthorised.paragraph1') }}
                    </p>
                    <p class="body-regular">
                        {{ t('unauthorised.paragraph2') }}
                    </p>
                    <div class="unauthorised__content--actions">
                        <div class="buttons--row">
                            <ow-button data-test="unauthorised-witness-btn"
                                       data-track="Unauthorised Page: Witness button"
                                       is-primary
                                       @click="onWitnessBtnClick">
                                {{ t('buttons.witness') }}
                            </ow-button>
                            <ow-button data-test="unauthorised-copilot-btn"
                                       data-track="Unauthorised Page: Copilot button"
                                       is-secondary
                                       @click="onCopilotBtnClick">
                                {{ t('buttons.copilot') }}
                            </ow-button>
                        </div>
                        <div class="buttons--row">
                            <ow-button data-test="unauthorised-support-btn"
                                       data-track="Unauthorised Page: Support button"
                                       is-link
                                       @click="onSupportBtnClick">
                                {{ t('buttons.support') }}
                            </ow-button>
                            <ow-button data-test="unauthorised-support-btn"
                                       data-track="Unauthorised Page: Support button"
                                       is-link
                                       @click="onLogoutClick">
                                {{ t('buttons.logout') }}
                            </ow-button>
                        </div>
                    </div>
                </div>
            </article>
        </section>
    </section>
</template>

<script lang="ts" setup>
    import { useI18n } from 'vue-i18n'
    import { useRouter } from 'vue-router'

    import OwIconOwLogo from '@/components/core/icons/ow-icon-ow-logo.vue'
    import OwIconOwLogoNegative from '@/components/core/icons/ow-icon-ow-logo-negative.vue'
    import OwButton from '@/components/core/ow-button-ds.vue'
    import { Route } from '@/enums/route.enum'

    const { t } = useI18n()
    const router = useRouter()

    const onCopilotBtnClick = () => {
        window.location.replace('https://copilot.orbitalwitness.com')
    }

    const onWitnessBtnClick = async () => {
        await router.push({
            name: Route.Homepage,
        })
    }

    const onSupportBtnClick = () => {
        if (window.Intercom) {
            window.Intercom('showNewMessage')
        }
    }

    const onLogoutClick = async () => {
        await router.push({
            name: Route.Logout,
        })
    }
</script>

<style lang="scss" scoped>
@import './unauthorised';
</style>
