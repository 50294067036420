// @/stores/matter/getters.ts

import { IMatterState } from '@/stores/matter/index'

export default {

    /**
     * Get the current matter ID
     */
    currentMatterId(state: IMatterState): string {
        return state.currentMatter?.id
    },

    /**
     * Get the current matter selected titles
     */
    selectedTitles(state: IMatterState): any {
        return state.currentMatter?.selectedTitles
    },

    /**
     * Get the current matter groups
     */
    groups(state: IMatterState): any {
        return state.currentMatter?.groups
    },

}
