// @/stores/document-library/index.ts

import {
    acceptHMRUpdate,
    defineStore,
} from 'pinia'

import { DocumentLibraryTab } from '@/enums/document-library-tab.enum'
import {
    IDocument,
    IDocumentOfficialCopy,
    IDocumentRowData,
    IDocumentSearches,
    IDocumentUploaded,
} from '@/interfaces/document.interface'

import actions from './actions'
import getters from './getters'
/**
 * Represents the state of the document library store.
 */
export interface IDocumentLibraryState {
    paging: {
        page: number
        pageSize: number
    }

    filterOptions: {
        filter: string
        groupFilters: { [key: string]: string }
        groupByTitle: boolean
        groupByGroup: boolean
        selectedTitles: Set<string>
        selectedTypes: Set<string>
        selectedGroups: Set<string>
        selectedStatuses: Set<number>
        sortBy: {
            key: string
            order: 'asc' | 'desc'
        }[]
    }

    quickOptions: {
        expanded: boolean
    }

    data: IDocumentRowData[]
    selected: (number | string)[]
    collapsedGroups: Set<string>
    scrollPosition: number

    childNavName: string
    library: {
        initialised: boolean
        loading: boolean
        documents: IDocument[]
        uploadedDocuments: IDocument[]
        lastUploadedDocument: IDocument | null
    },

    orderHistory: {
        loading: boolean
        documents: IDocument[]
    }
}

export const documentLibraryOptions = {
    state: (): IDocumentLibraryState => {
        return {
            childNavName: null,
            scrollPosition: 0,

            orderHistory: {
                loading: false,
                documents: [],
            },

            library: {
                loading: false,
                initialised: false,
                documents: [],
                uploadedDocuments: [],
                lastUploadedDocument: null,
            },

            data: [],
            selected: [],
            collapsedGroups: new Set<string>(),

            paging: {
                page: 0,
                pageSize: 50,
            },

            filterOptions: {
                filter: '',
                groupFilters: {},
                groupByTitle: false,
                groupByGroup: false,
                selectedTitles: new Set<string>(),
                selectedGroups: new Set<string>(),
                selectedTypes: new Set<string>(),
                selectedStatuses: new Set<number>(),
                sortBy: [],
            },

            quickOptions: {
                expanded: true,
            },
        }
    },
    getters: { ...getters },
    actions: { ...actions },
}

/**
 * The `useDocumentLibraryStore` variable is a store created using the `defineStore` function.
 * It is used to manage state relating to the document library
 */
export const useDocumentLibraryStore = defineStore('document-library', documentLibraryOptions)

// Enables Hot Module Replacement in Pinia
if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useDocumentLibraryStore, import.meta.hot))
}
