import i18n from '@/plugins/i18n'
import store from '@/store'
import { IMatterSharedNotification } from '@/store/modules/matter-hub/interfaces/matter-shared-notification.interface'
import { IAssetMonitoringNotificationMessage } from '@/store/modules/matter-hub/interfaces/out-of-date-title-register-notification'
import { ISnapshotStatusChangedNotification } from '@/store/modules/matter-hub/interfaces/snapshot-status-changed-notification.interface'
import { ITitleAnalysisReportGeneratedNotification,
    ITitleReportGeneratedNotification } from '@/store/modules/matter-hub/interfaces/title-report-generated.notification.interface'
import { IUploadedDocumentStatusUpdatedNotification } from '@/store/modules/matter-hub/interfaces/uploaded-document-status-updated-notification.interface'
import { MatterHubEvents } from '@/store/modules/matter-hub/matter-hub-events'
import {
    ACTION_FROM_MATTER_HUB_DOCUMENT_UPLOAD_STATUS_UPDATED_MESSAGE,
    ACTION_FROM_MATTER_HUB_TITLE_ANALYSIS_REPORT_GENERATED_MESSAGE,
    ACTION_FROM_MATTER_HUB_TITLE_REPORT_GENERATED_MESSAGE,
    INITIALISE_MATTER_HUB_LISTENERS,
    MATTER_HUB_MUTATE_CURRENTLY_SUBSCRIBED_TO_MATTER_ID,
    MATTER_HUB_MUTATE_ERROR,
    MATTER_HUB_MUTATE_HUB,
    MATTER_HUB_MUTATE_IS_SUBSCRIBED,
    MATTER_HUB_MUTATE_MESSAGE,
    SUBSCRIBE_TO_MATTER_HUB,
    UNSUBSCRIBE_FROM_MATTER_HUB,
    UNSUBSCRIBE_MATTER_HUB_LISTENERS,
} from '@/store/modules/matter-hub/types'
import { NOTIFICATIONS_MUTATE_ADD_NOTIFICATION } from '@/store/modules/notifications/types'
import { LOGGING_HEAP_TRACK_EVENT } from '@/store/mutation-types'
import { useAssetMonitoringStore } from '@/stores/asset-monitoring'
import { getToasterMessageForSubType } from '@/stores/asset-monitoring/utils'
import { useDocumentLibraryStore } from '@/stores/document-library'

const matterHubDocumentStatusUpdatedAction = async (matterId: Number, data: IUploadedDocumentStatusUpdatedNotification) => {
    store.commit(MATTER_HUB_MUTATE_MESSAGE, data)
    await store.dispatch(ACTION_FROM_MATTER_HUB_DOCUMENT_UPLOAD_STATUS_UPDATED_MESSAGE, data, { root: true })

    const documentLibraryStore = useDocumentLibraryStore()
    await documentLibraryStore.handleNotification(ACTION_FROM_MATTER_HUB_DOCUMENT_UPLOAD_STATUS_UPDATED_MESSAGE, data)

}

const matterHubTitleAnalysisReportGeneratedAction = async (matterId: Number, data: ITitleReportGeneratedNotification) => {
    store.commit(MATTER_HUB_MUTATE_MESSAGE, data)
    await store.dispatch(ACTION_FROM_MATTER_HUB_TITLE_ANALYSIS_REPORT_GENERATED_MESSAGE, data, { root: true })
}

const matterHubTitleReportGeneratedAction = async (matterId: Number, data: ITitleAnalysisReportGeneratedNotification) => {
    store.commit(MATTER_HUB_MUTATE_MESSAGE, data)
    await store.dispatch(ACTION_FROM_MATTER_HUB_TITLE_REPORT_GENERATED_MESSAGE, data, { root: true })
}
const matterHubSnapshotStatusChangedAction = async (matterId: Number, data: ISnapshotStatusChangedNotification) => {
    store.commit(MATTER_HUB_MUTATE_MESSAGE, data)
    // TODO: Fix functionality when implementing notifications
    // await store.dispatch(ACTION_FROM_MATTER_HUB_SNAPSHOT_STATUS_CHANGED_MESSAGE, data, { root: true })
}

const matterHubMatterSharedAction = async (matterId: Number, data: IMatterSharedNotification) => {
    store.commit(MATTER_HUB_MUTATE_MESSAGE, data)
    // TODO: Fix functionality when implementing notifications
    // await store.dispatch(ACTION_FROM_MATTER_HUB_MATTER_SHARED_MESSAGE, data, { root: true })
}

const assetMonitoringNotification = async (matterId: Number, data: IAssetMonitoringNotificationMessage) => {
    //Use pinia store to commit newly received message
    const assetMonitoringStore = useAssetMonitoringStore()
    assetMonitoringStore.addReceivedNotification(data.notification)

    if (assetMonitoringStore.hasAccessToAlerts) {
        let toasterMessage = getToasterMessageForSubType(data.notification.type)
        store.commit(NOTIFICATIONS_MUTATE_ADD_NOTIFICATION, {
            ...data,
            toaster: {
                heading: i18n.global.t('assetMonitoring.toaster.heading'),
                isSuccess: true,
                message: toasterMessage,
            },
        })
    }
    store.commit(MATTER_HUB_MUTATE_MESSAGE, data)
}

export default {
    async [SUBSCRIBE_TO_MATTER_HUB]({ commit, dispatch }, {
        hub,
        matterId,
    }) {
        if (!hub) {
            console.error('No hub provided!')
            return
        }

        commit(MATTER_HUB_MUTATE_HUB, hub)
        try {
            await hub.subscribeToMatter(matterId)
            commit(MATTER_HUB_MUTATE_IS_SUBSCRIBED, true)
            commit(MATTER_HUB_MUTATE_CURRENTLY_SUBSCRIBED_TO_MATTER_ID, matterId)
            dispatch(INITIALISE_MATTER_HUB_LISTENERS)
            await dispatch(LOGGING_HEAP_TRACK_EVENT, {
                type: 'MATTER HUB: subscribed',
                metadata: {
                    matterId,
                },
            }, { root: true })
        } catch (error) {
            console.error(error)
            commit(MATTER_HUB_MUTATE_IS_SUBSCRIBED, false)
            commit(MATTER_HUB_MUTATE_ERROR, error)
            await dispatch(LOGGING_HEAP_TRACK_EVENT, {
                type: 'MATTER HUB: error subscribing',
                metadata: {
                    matterId,
                    error,
                },
            }, { root: true })
        }
    },
    async [UNSUBSCRIBE_FROM_MATTER_HUB]({ commit, dispatch, state }) {
        const matterId = state.currentlySubscribedToMatterId
        const hub = state.hub
        if (!hub) {
            return
        }

        try {
            await dispatch(UNSUBSCRIBE_MATTER_HUB_LISTENERS)
            await hub.unsubscribeFromMatter(matterId)
            commit(MATTER_HUB_MUTATE_IS_SUBSCRIBED, false)
            commit(MATTER_HUB_MUTATE_CURRENTLY_SUBSCRIBED_TO_MATTER_ID, null)
            await dispatch(LOGGING_HEAP_TRACK_EVENT, {
                type: 'MATTER HUB: unsubscribed',
                metadata: {
                    matterId,
                },
            }, { root: true })
        } catch (error) {
            console.error(error)
            commit(MATTER_HUB_MUTATE_IS_SUBSCRIBED, false)
            commit(MATTER_HUB_MUTATE_ERROR, error)
            await dispatch(LOGGING_HEAP_TRACK_EVENT, {
                type: 'MATTER HUB: error unsubscribing',
                metadata: {
                    matterId,
                    error,
                },
            }, { root: true })
        }
    },

    [INITIALISE_MATTER_HUB_LISTENERS]({ state }) {
        state.hub.on(MatterHubEvents.DocumentUploadStatusUpdated, matterHubDocumentStatusUpdatedAction)
        state.hub.on(MatterHubEvents.TitleAnalysisReportGenerated, matterHubTitleAnalysisReportGeneratedAction)
        state.hub.on(MatterHubEvents.TitleReportGenerated, matterHubTitleReportGeneratedAction)
        state.hub.on(MatterHubEvents.SnapshotStatusChanged, matterHubSnapshotStatusChangedAction)
        state.hub.on(MatterHubEvents.MatterShared, matterHubMatterSharedAction)
        state.hub.on(MatterHubEvents.AssetMonitoringNotification, assetMonitoringNotification)
    },

    [UNSUBSCRIBE_MATTER_HUB_LISTENERS]({ state }) {
        state.hub.off(MatterHubEvents.DocumentUploadStatusUpdated, matterHubDocumentStatusUpdatedAction)
        state.hub.off(MatterHubEvents.SnapshotStatusChanged, matterHubSnapshotStatusChangedAction)
        state.hub.off(MatterHubEvents.MatterShared, matterHubMatterSharedAction)
        state.hub.off(MatterHubEvents.AssetMonitoringNotification, assetMonitoringNotification)
        state.hub.off(MatterHubEvents.TitleAnalysisReportGenerated, matterHubTitleAnalysisReportGeneratedAction)
        state.hub.off(MatterHubEvents.TitleReportGenerated, matterHubTitleReportGeneratedAction)
    },

    // /**
    //  * After a message is received from the hub, we need to perform various actions, which is handled here.
    //  */
    // [MATTER_HUB_ACTION_DOCUMENT_UPLOAD_STATUS_UPDATED_MESSAGE]: ({ commit, dispatch, state }, message: IMatterHubMessageContent) => {
    //     console.info('MATTER_HUB_ACTION_DOCUMENT_UPLOAD_STATUS_UPDATED_MESSAGE', message)
    // },
    // [MATTER_HUB_ACTION_LEASES_UPDATED_MESSAGE]: ({ commit, dispatch, state }, message: IMatterHubMessageContent) => {
    //     console.info('MATTER_HUB_ACTION_LEASES_UPDATED_MESSAGE', message)
    // },
    // [MATTER_HUB_ACTION_SNAPSHOT_STATUS_CHANGED_MESSAGE]: ({ commit, dispatch, state }, message: IMatterHubMessageContent) => {
    //     console.info('MATTER_HUB_ACTION_SNAPSHOT_STATUS_CHANGED_MESSAGE', message)
    // },
    // [MATTER_HUB_ACTION_MATTER_SHARED_MESSAGE]: ({ commit, dispatch, state }, message: IMatterHubMessageContent) => {
    //     console.info('MATTER_HUB_ACTION_MATTER_SHARED_MESSAGE', message)
    // },
}
