import httpClient from '@/api/http-client'
import { STORY_TEST_TITLE_NUMBER } from '@/consts/story-testing'
import { isNullOrWhitespace } from '@/utils/string-utils'

import { titleNumberSearchResult } from './mock-responses/land-registry/title-number-search.results.js'

export default class LandRegistryApi {
    static END_POINT = 'landregistry'

    /**
     * Get title number from land registry using the UPRN
     * @static
     * @param uprn
     * @param controller
     * @returns {Promise<T | *>}
     */
    static async getTitleNumberByUprn(uprn, controller = new AbortController()) {
        const uri = `${ this.END_POINT }/titleforuprn/${ uprn }`
        return httpClient
            .get(uri, {
                signal: controller.signal,
            })
            .then(response => response.data)
    }

    /**
     * Searches the land registry for matching companies (owners)
     * @static
     * @param searchText {String} - the name of the company/registration number to search for
     * @param controller Signal controller to cancel the request
     * @returns {Promise<T | *>}
     */
    static async searchByCorporateOwner(searchText, controller) {
        const uri = `hmlr/corporate-owners/v2/${ searchText.trim() }`

        const options = {
            requestId: 'owners',
            signal: controller.signal,
        }

        return httpClient
            .get(uri, options)
            .then(response => response.data)
    }

    /**
     * Get the titles for the selected companies (owners)
     * @static
     * @param data {Array}
     * @example
     *  The data request object should be an array of:
     *  {
     *      name: 'Tesco',
     *      titleNumbers: ['ABC123', XYZ321']
     *  }
     * @returns {Promise<T | *>}
     */
    static async getTitlesForOwners(data) {
        const uri = 'hmlr/corporate-owner-titles'
        return httpClient
            .post(uri, data)
            .then(response => response.data)
    }

    /**
     * Get the title data for a specific title number
     * @static
     * @param titleNumber {String}
     * @returns {Promise<T | *>}
     */
    static async getTitleByTitleNumber(titleNumber) {
        if (isNullOrWhitespace(titleNumber)) {
            return {
                ok: false,
                message: 'Title number is required',
            }
        }

        if (titleNumber.toLowerCase() === STORY_TEST_TITLE_NUMBER) {
            return titleNumberSearchResult
        }

        const uri = `${ this.END_POINT }/titlenumbers/${ titleNumber }/1.json`

        return httpClient
            .get(uri)
            .then(response => response.data)
    }

    /**
     * Returns freeholds intersecting the given title numbers. This can be used as a starting point to construct a lease hierarchy.
     * @static
     * @param titleNumbers {String[]} Title numbers to use a starting point, these may be freeholds, in which case they will be returned in the results.
     * @returns {Promise<T | *>} A list of freehold title numbers if found
     */
    static async getFreeholdsIntersectingTitleNumbers(titleNumbers) {
        const uri = 'hmlr/titles/to-intersecting-freeholds'
        return await httpClient
            .post(uri, titleNumbers)
            .then(response => response.data)
    }
}
