import { DOCUMENT_SOURCE } from '@/consts/document-source'
import { ExportDocumentTypes } from '@/consts/document-type'
import { UploadedDocumentStatus } from '@/enums/uploaded-document-status.enum'
import { IDocument } from '@/interfaces/document.interface'
import { IUploadedDocument } from '@/interfaces/uploaded-document.interface'

export interface IDocumentRowData {
    key: string,
    firstName: string,
    lastName: string,
    userName: string,
    documentName: string,
    documentId: string,
    id: string,
    titleNumber: string,
    documentDate: string,
    type: string,
    entryNumbers: string[],
    groupId: number,
    groupName: string,
    status: string | UploadedDocumentStatus,
    orderDate: Date,
    document: IDocument
}

export const IsDocumentUploaded = (doc: IDocumentRowData | IDocument): boolean => {
    const validate = (doc: IDocument) => {
        return doc?.documentUploadRequestId !== undefined || doc?.documentSource === DOCUMENT_SOURCE.UPLOADED
    }
    if ('document' in doc) {
        return validate((doc as IDocumentRowData).document)
    }

    return validate(doc as IDocument)
}

export const IsDocumentOwned = (doc: IDocumentRowData | IDocument): boolean => {
    const validate = (doc: IDocument) => {
        return (doc.documentDownloadDate !== undefined)
    }

    if ('document' in doc) {
        return validate((doc as IDocumentRowData).document)
    }

    return validate(doc as IDocument)
}

export const IsDocumentExported = (doc: IDocumentRowData | IDocument): boolean => {
    const validate = (doc: IDocument) => {
        return [ExportDocumentTypes.SPREADSHEET, ExportDocumentTypes.WORD_REPORT].includes(doc?.documentType)
    }
    if ('document' in doc) {
        return validate((doc as IDocumentRowData).document)
    }

    return validate(doc as IDocument)
}
