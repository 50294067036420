<template>
    <li class="body-regular-small">
        {{ t('assetMonitoring.summaryCard.boundaryChange', items.length) }}

        <div v-for="(details, index) in items"
             :key="index"
             class="boundary-map">
            <boundary-updated :item="details"
                              showing-details-panel />
        </div>
    </li>
</template>

<script lang="ts" setup>
    import { watch } from 'vue'
    import { useI18n } from 'vue-i18n'

    import { IAssetMonitoringNotificationDetail } from '@/components/asset-monitoring/asset-monitoring-notification.interface'
    import BoundaryUpdated from '@/components/asset-monitoring/grid/sub-type-items/boundary-updated.vue'

    const { t } = useI18n()

    const props = defineProps<{
        items: IAssetMonitoringNotificationDetail[]
    }>()
</script>

<style lang="scss" scoped>
.boundary-map {
    padding: 8px 16px;
}
</style>


