<template>
    <ow-splash-page class="order-history-splash-page"
                    :title="title"
                    :subtitle="subtitle"
                    icon="ow-icon-document-history"
                    data-track-prefix="Order History"
                    data-test-prefix="order-history">
        <template #footer>
            <div class="w-100 d-flex flex-column mt-2">
                <ow-card class="order-history-splash-page__card"
                         border
                         has-outline
                         data-test="add-title-button">
                    <div class="d-flex justify-center w-100">
                        <span v-t="'orderHistory.splashPage.noItems'"
                              class="body-regular-small-italic" />
                    </div>
                </ow-card>
            </div>
        </template>
    </ow-splash-page>
</template>

<script lang="ts" setup>
    import {
        computed,
    } from 'vue'
    import { useI18n } from 'vue-i18n'

    import OwCard from '@/components/core/ow-card.vue'
    import OwSplashPage from "@/components/core/ow-splash-page.vue"

    const { t } = useI18n()

    const subtitle = computed(() => {
        return t('orderHistory.splashPage.description')
    })

    const title = computed(() => {
        return t('orderHistory.splashPage.title')
    })
</script>

<style scoped lang="scss">
@import './order-history-splash-page.scss';
</style>

