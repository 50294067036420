<template>
    <article class="summary-card">
        <div class="header">
            <div class="header__top-line">
                <ow-title-number-tooltip v-if="isIdentifierATitleNumber"
                                         :title-number="identifier"
                                         @click="emits('click', identifier)" />
                <h2 v-else
                    class="body-highlight header__top-line--title">
                    {{ identifier }}
                </h2>

                <p class="body-regular-small header__top-line--changes">
                    {{ t('assetMonitoring.summaryCard.totalChanges', totalChanges) }}
                </p>
            </div>
            <p v-if="address"
               class="body-regular-small header__address">
                {{ address }}
            </p>
        </div>
        <ul class="changes">
            <pending-applications-changed v-if="hasPendingApplicationChanges"
                                          :items="pendingApplicationChangeNotifications" />

            <planning-application-changed v-if="hasPlanningApplicationChanges"
                                          :items="planningApplicationChangeNotifications" />

            <edition-date-mismatch-changed v-if="hasEditionDateMismatchedChanges"
                                           :items="editionDateMismatchedChangeNotifications" />

            <ownership-changed v-if="hasOwnershipChanges"
                               :items="ownershipChangeNotifications" />

            <ocda-changed v-if="hasOCDAChanges"
                          :items="ocdaChangeNotifications" />

            <epc-changed v-if="hasEpcChanges"
                         :items="epcChangeNotifications" />

            <companies-house-changed v-if="hasCompaniesHouseChanges"
                                     :items="companiesHouseChangeNotifications" />

            <boundary-changed v-if="hasBoundaryChanges"
                              :items="boundaryChangeNotifications" />
        </ul>
    </article>
</template>

<script lang="ts" setup>
    import {
        computed,
        ref,
        watch,
    } from 'vue'
    import { useI18n } from 'vue-i18n'

    import {
        IGetNotificationResponseItem,
        INotificationDetailResponseItem,
    } from '@/api/notifications.api'
    import { IAssetMonitoringNotificationDetail } from '@/components/asset-monitoring/asset-monitoring-notification.interface'
    import CompletedPendingApplication
        from '@/components/asset-monitoring/grid/sub-type-items/completed-pending-application.vue'
    import BoundaryChanged from '@/components/asset-monitoring/grid/summary-card/boundary-changed.vue'
    import CompaniesHouseChanged from '@/components/asset-monitoring/grid/summary-card/companies-house-changed.vue'
    import EditionDateMismatchChanged
        from '@/components/asset-monitoring/grid/summary-card/edition-date-mismatch-changed.vue'
    import EpcChanged from '@/components/asset-monitoring/grid/summary-card/epc-changed.vue'
    import OcdaChanged from '@/components/asset-monitoring/grid/summary-card/ocda-changed.vue'
    import OwnershipChanged from '@/components/asset-monitoring/grid/summary-card/ownership-changed.vue'
    import PendingApplicationsChanged
        from '@/components/asset-monitoring/grid/summary-card/pending-applications-changed.vue'
    import PlanningApplicationChanged
        from '@/components/asset-monitoring/grid/summary-card/planning-application-changed.vue'
    import { NotificationSubType } from '@/components/asset-monitoring/notification-sub-type.enum'
    import OwTitleNumberTooltip from '@/components/core/ow-title-number-tooltip.vue'
    import { isNullOrEmpty } from '@/utils/array-utils'
    import { capitalise } from '@/utils/string-utils'

    const { t } = useI18n()

    const props = defineProps({
        notifications: Array<IGetNotificationResponseItem>,
        address: String,
    })
    const emits = defineEmits<{
        (event: 'click', titleNumber: string): void
    }>()

    const identifier = computed<string>(() => {
        return props.notifications[0].message // the message property has been set to the title number/company name
    })
    const isIdentifierATitleNumber = computed<boolean>(() => {
        // All notifications are grouped by the title number or the company number.
        // If the first notification is a title number, then all notifications are title numbers.
        return props.notifications[0].titleNumber === identifier.value
    })

    const totalChanges = computed<number>(() => {
        let totalDifferences = 0

        props.notifications.forEach(notification => {
            notification.notificationDetails.forEach((detail: any) => {
                const difference = JSON.parse(detail.difference)
                totalDifferences += difference.added.length
                totalDifferences += difference.removed.length
                totalDifferences += difference.updated.length
            })
        })

        return totalDifferences
    })

    // Get details and convert to the IAssetMonitoringNotificationDetail model
    const notificationDetails = computed<IAssetMonitoringNotificationDetail[]>(() => {
        return props.notifications.flatMap((notification: IGetNotificationResponseItem) => {
            return notification.notificationDetails.map((details: INotificationDetailResponseItem) => ({
                difference: details.difference,
                notificationSubType: NotificationSubType[capitalise(details.notificationSubType)],
                priority: details.priority,
                notificationId: notification.notificationId,
                titleNumber: notification.titleNumber,
                isShowingDetails: false,
            } as IAssetMonitoringNotificationDetail))
        })
    })

    // Group the notifications by their subtype. The card reports based on the sub-types.
    const groupedNotifications = computed<{ [key: string]: IAssetMonitoringNotificationDetail[] }>(() => {
        const groups: { [key: string]: IAssetMonitoringNotificationDetail[] } = {}
        for (const notification of notificationDetails.value) {
            if (!groups[notification.notificationSubType]) {
                groups[notification.notificationSubType] = []
            }
            groups[notification.notificationSubType].push(notification)
        }

        return groups
    })

    const boundaryChangeNotifications = ref<IAssetMonitoringNotificationDetail[]>([])
    const ownershipChangeNotifications = ref<IAssetMonitoringNotificationDetail[]>([])
    const pendingApplicationChangeNotifications = ref<IAssetMonitoringNotificationDetail[]>([])
    const ocdaChangeNotifications = ref<IAssetMonitoringNotificationDetail[]>([])
    const planningApplicationChangeNotifications = ref<IAssetMonitoringNotificationDetail[]>([])
    const companiesHouseChangeNotifications = ref<IAssetMonitoringNotificationDetail[]>([])
    const editionDateMismatchedChangeNotifications = ref<IAssetMonitoringNotificationDetail[]>([])
    const epcChangeNotifications = ref<IAssetMonitoringNotificationDetail[]>([])

    const hasBoundaryChanges = computed<boolean>(() => !isNullOrEmpty(boundaryChangeNotifications.value))
    const hasOwnershipChanges = computed<boolean>(() => !isNullOrEmpty(ownershipChangeNotifications.value))
    const hasPendingApplicationChanges = computed<boolean>(() => !isNullOrEmpty(pendingApplicationChangeNotifications.value))
    const hasOCDAChanges = computed<boolean>(() => !isNullOrEmpty(ocdaChangeNotifications.value))
    const hasPlanningApplicationChanges = computed<boolean>(() => !isNullOrEmpty(planningApplicationChangeNotifications.value))
    const hasCompaniesHouseChanges = computed<boolean>(() => !isNullOrEmpty(companiesHouseChangeNotifications.value))
    const hasEditionDateMismatchedChanges = computed<boolean>(() => !isNullOrEmpty(editionDateMismatchedChangeNotifications.value))
    const hasEpcChanges = computed<boolean>(() => !isNullOrEmpty(epcChangeNotifications.value))

    watch(groupedNotifications, (newGroup: { [key: string]: IAssetMonitoringNotificationDetail[] }) => {
        Object.keys(newGroup).forEach((key: string) => {
            if (key == NotificationSubType.BoundaryChange.toString()) {
                boundaryChangeNotifications.value = newGroup[NotificationSubType.BoundaryChange]
            } else if (key == NotificationSubType.OwnershipChange.toString()) {
                ownershipChangeNotifications.value = newGroup[NotificationSubType.OwnershipChange]
            } else if (key == NotificationSubType.CompletedPendingApplication.toString()) {
                pendingApplicationChangeNotifications.value = newGroup[NotificationSubType.CompletedPendingApplication]
            } else if (key == NotificationSubType.NewDocumentInOCDA.toString()) {
                ocdaChangeNotifications.value = newGroup[NotificationSubType.NewDocumentInOCDA]
            } else if (key == NotificationSubType.PlanningApplication.toString()) {
                planningApplicationChangeNotifications.value = newGroup[NotificationSubType.PlanningApplication]
            } else if (key == NotificationSubType.CompaniesHouse.toString()) {
                companiesHouseChangeNotifications.value = newGroup[NotificationSubType.CompaniesHouse]
            } else if (key == NotificationSubType.EditionDateDoesntMatchOcdaResponse.toString()) {
                editionDateMismatchedChangeNotifications.value = newGroup[NotificationSubType.EditionDateDoesntMatchOcdaResponse]
            } else if (key == NotificationSubType.Epc.toString()) {
                epcChangeNotifications.value = newGroup[NotificationSubType.Epc]
            } else {
                throw new Error(`Unknown notification sub-type: ${ key }`)
            }
        })
    }, { immediate: true, deep: true })
</script>

<style lang="scss">
    @import './summary-card';
</style>
