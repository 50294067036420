<template>
    <div v-if="!isDelete"
         class="organisation-section-form">
        <v-text-field v-model="selectedOrganisationSection.name"
                      :label="$t('admin.organisation.label.sectionName')"
                      data-test="organisation-section-form-input-organisation-name"
                      required />
        <h3>Section Address</h3>
        <v-text-field v-model="selectedOrganisationSection.addressHouseNumber"
                      autocomplete="ow"
                      :label="$t('admin.organisation.label.addressHouseNumber')"
                      data-test="organisation-section-form-input-house-number"
                      required />
        <v-text-field v-model="selectedOrganisationSection.addressStreet"
                      autocomplete="ow"
                      :label="$t('admin.organisation.label.addressStreet')"
                      data-test="organisation-section-form-input-street-address"
                      required />
        <v-text-field v-model="selectedOrganisationSection.addressLocality"
                      autocomplete="ow"
                      data-test="organisation-section-form-input-locality"
                      :label="$t('admin.organisation.label.addressLocality')" />
        <v-text-field v-model="selectedOrganisationSection.addressTown"
                      autocomplete="ow"
                      data-test="organisation-section-form-input-town"
                      :label="$t('admin.organisation.label.addressTown')" />
        <v-text-field v-model="selectedOrganisationSection.addressPostcode"
                      autocomplete="ow"
                      :label="$t('admin.organisation.label.addressPostcode')"
                      data-test="organisation-section-form-input-postcode"
                      required />
        <p v-if="isError"
           class="organisation-section-form__error">
            {{ $t('admin.organisation.organisationSectionCreateError', { errorMessage }) }}
        </p>
        <div class="organisation-section-form__actions">
            <v-btn color="primary"
                   variant="text"
                   data-test="organisation-section-form-button-cancel"
                   @click.stop="onCancelClick">
                {{ $t('action.cancel') }}
            </v-btn>
            <div class="organisation-section-form__form-actions">
                <v-btn :disabled="isDefaultOrganisationSection || isDeleting || isSubmitting"
                       :loading="isDeleting"
                       color="red"
                       theme="dark"
                       data-test="organisation-section-form-button-remove"
                       @click="openDeleteMode">
                    {{ $t('action.remove') }}
                </v-btn>
                <v-btn :disabled="!isOrganisationSectionValid || isSubmitting"
                       :loading="isSubmitting"
                       data-test="organisation-section-form-button-submit"
                       color="primary"
                       @click="submitForm">
                    {{ isEdit ? $t('action.update') :$t('action.add') }}
                </v-btn>
            </div>
        </div>
    </div>
    <div v-else>
        <strong>Are you sure that you want to delete this section? Any users assigned to it will be
            added to the organisations default section.</strong>
        <div class="organisation-section-form__actions">
            <v-btn color="primary"
                   variant="text"
                   @click.stop="closeDeleteMode">
                {{ $t('action.cancel') }}
            </v-btn>
            <v-btn :disabled="!isOrganisationSectionValid || isDeleting"
                   :loading="isDeleting"
                   color="red"
                   theme="dark"
                   data-test="organisation-section-form-button-confirm-delete"
                   @click="deleteOrganisationSectionClick">
                {{ $t('action.confirm') }}
            </v-btn>
        </div>
    </div>
</template>

<script>
    import {
        mapActions,
        mapState,
    } from 'vuex'

    import {
        ADD_ORGANISATION_SECTION,
        DELETE_ORGANISATION_SECTION,
        UPDATE_ORGANISATION_SECTION,
    } from '@/store/modules/organisation/types'
    import { isNullOrWhitespace } from '@/utils/string-utils'

    export default {
        name: 'OrganisationSectionForm',

        props: {
            isEdit: {
                type: Boolean,
                required: false,
            },
        },
        emits: ['on-cancel-click', 'after-submit'],

        data() {
            return {
                isDeleting: false,
                isSubmitting: false,
                isError: false,
                errorMessage: null,
                isDelete: false,
            }
        },

        computed: {
            ...mapState({
                selectedOrganisationSection: state => state.organisation.selectedOrganisationSection,
            }),

            isOrganisationSectionValid() {
                return !isNullOrWhitespace(this.selectedOrganisationSection.name) &&
                    !isNullOrWhitespace(this.selectedOrganisationSection.addressHouseNumber) &&
                    !isNullOrWhitespace(this.selectedOrganisationSection.addressStreet) &&
                    !isNullOrWhitespace(this.selectedOrganisationSection.addressPostcode)
            },

            isDefaultOrganisationSection() {
                return this.selectedOrganisationSection.organisationDefaultSectionid === this.selectedOrganisationSection.id
            },
        },

        methods: {
            ...mapActions('organisation', {
                addNewOrganisationSection: ADD_ORGANISATION_SECTION,
                updateExistingOrganisationSection: UPDATE_ORGANISATION_SECTION,
                deleteOrganisationSection: DELETE_ORGANISATION_SECTION,
            }),

            onCancelClick() {
                this.$emit('on-cancel-click')
            },

            openDeleteMode() {
                this.isDelete = true
            },

            closeDeleteMode() {
                this.isDelete = false
            },

            async deleteOrganisationSectionClick() {
                this.isDeleting = true
                const response = await this.deleteOrganisationSection(this.selectedOrganisationSection.id)
                this.handleResponse(response)
            },

            async submitForm() {
                this.isSubmitting = true

                const request = {
                    organisationId: this.isEdit ? undefined : this.selectedOrganisationSection.organisationId,
                    id: this.isEdit ? this.selectedOrganisationSection.id : undefined,
                    name: this.selectedOrganisationSection.name,
                    industry: this.selectedOrganisationSection.industry,
                    status: this.selectedOrganisationSection.status,
                    addressHouseNumber: this.selectedOrganisationSection.addressHouseNumber,
                    addressStreet: this.selectedOrganisationSection.addressStreet,
                    addressLocality: this.selectedOrganisationSection.addressLocality,
                    addressTown: this.selectedOrganisationSection.addressTown,
                    addressPostcode: this.selectedOrganisationSection.addressPostcode,
                }

                const response = this.isEdit
                    ? await this.updateExistingOrganisationSection(request)
                    : await this.addNewOrganisationSection(request)

                this.handleResponse(response)
            },

            handleResponse(response) {
                if (response?.ok) {
                    this.isDelete = false
                    this.isDeleting = false
                    this.isSubmitting = false
                    this.$emit('after-submit')
                } else if (response?.data) {
                    this.isError = true
                    this.errorMessage = response.data.join('. ')
                } else {
                    this.isError = true
                }
            },
        },
    }
</script>

<style lang="scss">
    @import './organisation-section-form.scss';
</style>
