let env = 'dev'
if (window.location?.href.includes('app.orbitalwitness.com') ||
    window.location?.href.includes('witness.orbital.tech') ||
    window.location?.href.includes('white-bush-0eb8a3e03')) {
    env = 'prod'
}

const loadScript = (src, async = true, type = 'text/javascript') => {
    return new Promise((resolve, reject) => {
        try {
            const el = document.createElement('script')
            const container = document.head || document.body

            el.type = type
            el.async = async
            el.src = src

            el.addEventListener('load', () => {
                if (window.Cypress) {
                    // Let the app know it's running under test conditions when Cypress is controlling it
                    window.owvars = {
                        underTest: true,
                    }
                }

                resolve({ status: true })
            })

            el.addEventListener('error', () => {
                reject(new Error(`Failed to load the script ${ src }`))
            })

            container.appendChild(el)
        } catch (err) {
            reject(err)
        }
    })
}
if (window?.owvars?.underTest !== true) {
    loadScript(`/legacy/${ env }/heap.js`)
}
loadScript(`/legacy/${ env }/ow-vars.js`)

if (env === 'prod') {
    loadScript('https://www.googletagmanager.com/gtag/js?id=UA-109423448-1')
        .then(() => {
            loadScript(`/legacy/${ env }/google-tags.js`)
        })
        .catch((err) => {
            console.error(err)
        })
}
