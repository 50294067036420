<template>
    <div class="ow-register-status-icon"
         :class="{'ow-register-status-icon--small': isSmall,
                  'ow-register-status-icon--warning': issuedOverThreeMonthsAgo && !showTitleAddedToMatter,
                  'ow-register-status-icon--pulse': issuedOverThreeMonthsAgo && shouldPulse || trOwnerMismatch,
                  'ow-register-status-icon--critical': trOwnerMismatch && !showTitleAddedToMatter,}"
         data-test="ow-register-status-icon">
        <v-icon v-if="showTitleAddedToMatter"
                class="mr-1"
                data-test="ow-register-status-icon__title-added-to-matter-icon"
                width="16px"
                height="16px">
            $matters
        </v-icon>
        <v-icon v-else-if="isRegisterUploaded"
                data-test="ow-register-status-icon__uploaded-icon">
            $uploaded-register
        </v-icon>
        <ow-icon-register-ordered v-else-if="isRegisterOrdered"
                                  data-test="ow-register-status-icon__purchased-icon"
                                  :width="isSmall ? undefined : '24px'"
                                  :height="isSmall ? undefined : '24px'" />
        <ow-tooltip :position="OwTooltipPosition.Right"
                    activator="parent">
            <span>
                {{ tooltipMessage }}
            </span>
        </ow-tooltip>
    </div>
</template>

<script setup lang="ts">
    import { computed } from 'vue'

    import OwIconRegisterOrdered from '@/components/core/icons/ow-icon-register-ordered.vue'
    import OwTooltip from '@/components/core/ow-tooltip.vue'
    import { OwTooltipPosition } from '@/enums/ow-tooltip-position'
    import i18n from "@/plugins/i18n"
    import { differenceInMonths } from '@/utils/date-utils'

    const props = defineProps<{
        isRegisterOrdered?: boolean;
        isRegisterUploaded?: boolean;
        isSmall?: boolean;
        responseDateValue?: string;
        shouldPulse?: boolean;
        showTitleAddedToMatter?: boolean;
        trOwnerMismatch?: boolean;
    }>()

    const issuedOverThreeMonthsAgo = computed(() => {
        if (props.responseDateValue) {
            const issuedOnDate = new Date(props.responseDateValue)
            return differenceInMonths(new Date(), issuedOnDate) >= 3
        }

        return false
    })

    const tooltipMessage = computed(() => {
        if (props.showTitleAddedToMatter) {
            return i18n.global.t('matter.titleAdded')
        } else if (props.isRegisterUploaded) {
            return `${ i18n.global.t('documents.registerUploaded') }${ issuedOverThreeMonthsAgo.value ? i18n.global.t('documents.oldRegister') : '' } ${ props.trOwnerMismatch ? i18n.global.t('documents.registerOwnerMismatchesPublicData') : '' }`
        } else if (props.isRegisterOrdered) {
            return `${ i18n.global.t('documents.registerPurchased') }${ issuedOverThreeMonthsAgo.value ? i18n.global.t('documents.oldRegister') : '' } ${ props.trOwnerMismatch ? i18n.global.t('documents.registerOwnerMismatchesPublicData') : '' }`
        }
        return ''
    })
</script>

<style lang="scss">
    @import './ow-register-status-icon';
</style>
