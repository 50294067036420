
// @/stores/application/getters.ts
import { DEFAULT_HMLR_DOCUMENT_COST } from "@/consts/application"

import { IApplicationState } from "."

export default {
    /**
     * Get document cost based on localilty as a string with currency symbol
     *
     * @returns {Function} cost of ordering a document.
     */
    getDocumentCostAsCurrencyString: (state: IApplicationState) => (source: string) => {
        const cost = state.documentCosts[source]
        return `£${ cost ?? DEFAULT_HMLR_DOCUMENT_COST }`
    },
    /**
     * Get document cost based on localilty as a number
     *
     * @returns {Function} cost of ordering a document.
     */
    getDocumentCost: (state: IApplicationState) => (source: string) => {
        const cost = state.documentCosts[source]
        return cost ?? DEFAULT_HMLR_DOCUMENT_COST
    },
}
