<template>
    <ow-icon-uploaded-file v-if="item?.document.documentSource === DOCUMENT_SOURCE.UPLOADED || item?.document.documentUploadRequestId"
                           class="document-library-icon" />
    <ow-icon-hmlr v-else-if="item?.document.documentSource === DOCUMENT_SOURCE.HMLR"
                  class="document-library-icon"
                  height="16px"
                  width="16px" />
    <ow-icon-scot-l-i-s v-else-if="item?.document.documentSource === DOCUMENT_SOURCE.SCOTLAND"
                        class="document-library-icon"
                        height="16px"
                        width="16px" />
    <ow-icon-ow-logo-positive v-else-if="documentExported"
                              class="document-library-icon"
                              height="16px"
                              width="16px" />
</template>

<script setup lang="ts">

    import { computed } from 'vue'

    import OwIconHmlr from '@/components/core/icons/ow-icon-hmlr.vue'
    import OwIconOwLogoPositive from '@/components/core/icons/ow-icon-ow-logo.vue'
    import OwIconScotLIS from '@/components/core/icons/ow-icon-scotlis.vue'
    import OwIconUploadedFile from '@/components/core/icons/ow-icon-uploaded-file.vue'
    import { inject as useDocument } from '@/composables/use-document'
    import { DOCUMENT_SOURCE } from '@/consts/document-source'
    import { IDocumentRowData } from '@/interfaces/document-row-data.interface'

    const { isDocumentExported } = useDocument()

    const props = defineProps<{
        item?: IDocumentRowData
    }>()

    const documentExported = computed<boolean>(() => isDocumentExported(props.item))

</script>

<style scoped lang="scss">
    @import './document-library-icon.scss';
</style>
