<template>
    <ow-loading-slot :loading="store.orderHistory.loading">
        <order-history-splash-page v-if="isNullOrEmpty(store.orderHistory.documents)" />
    </ow-loading-slot>
</template>

<script setup lang="ts">

    import OwLoadingSlot from '@/components/core/ow-loading-slot.vue'
    import OrderHistorySplashPage from '@/components/document-library/order-history/order-history-splash-page.vue'
    import { useDocumentLibraryStore } from '@/stores/document-library'
    import { isNullOrEmpty } from '@/utils/array-utils'
    const store = useDocumentLibraryStore()
</script>

<style scoped>

</style>
