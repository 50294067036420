import { IDocumentState } from '@/interfaces/store/documents/document-state.interface'
import actions from '@/store/modules/documents/documents-actions'
import getters from '@/store/modules/documents/documents-getters'
import mutations from '@/store/modules/documents/documents-mutations'
import { DOCUMENTS_MUTATE_MOCK_BEHAVIOUR } from '@/store/modules/documents/documents-types'

const state: IDocumentState = {

    // Settings
    BASE_DOCUMENT_URL: 'BusinessGateway/GetBGDocumentFileByFilename?filename=',
    OCR_RETRY_TIMEOUT_SECONDS: 300,

    useMockBehaviour: false, // Indicates whether the document ordering behaviour should be mocked. Used for testing.

    // Polling
    pollingTimeout: null,

    titleReportRequests: [],

    // Document Library
    library: {
        documents: [],
        uploadedDocuments: [],
        searchesDocuments: [],
        lastUploadedDocument: null,
        loading: true,
        loadingUploadedDocuments: false,
    },

    loadingState: {
        generateDOCXAndDownload: false,
        isOrderingDocuments: false,
    },

    orderErrors: [],

    viewer: {
        logging: {
            hasScrolledDocument: false, // scrolling at least one page
            hasInteractedWithDocument: false, // search, scroll, zoom etc
            hasDownloadedDocument: false,
        },
        originalEntryPoint: null,
    },

    showUploadDocumentsDialog: false,
}

const DocumentsModule = {
    namespaced: false,
    state,
    getters,
    mutations,
    actions,
}
globalThis.mockDocumentOrdering = (val: boolean) => {
    mutations[DOCUMENTS_MUTATE_MOCK_BEHAVIOUR](state, val)
    globalThis.sessionStorage.setItem('mockDocumentOrdering', val.toString())
    console.info(`Mock document ordering behaviour set to: ${ val }`)
}
globalThis.getMockDocumentOrdering = (): boolean => globalThis.sessionStorage.getItem('mockDocumentOrdering') === 'true'

export default DocumentsModule
