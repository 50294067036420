<template>
    <ow-modal v-model="removeDocumentsFromMatterDialog.show"
              content-class="dialog-remove-documents-from-matter"
              :title="$t('dialog.removeDocumentsFromMatter.title', {
                  count: documentsToRemove?.length ?? 0,
              })"
              persistent>
        <ow-loading-slot :loading="loading"
                         small
                         :indeterminate="false"
                         :max="documentsToRemove?.length"
                         :model-value="documentIndex"
                         offset-y="0px"
                         :fixed="false">
            <template #label>
                <span class="dialog-remove-documents-from-matter__label body-regular-small">
                    {{ $t('dialog.removeDocumentsFromMatter.removingDocuments', {
                        index: documentIndex,
                        count: documentsToRemove.length,
                    }) }}
                </span>
            </template>
            <span class="dialog-remove-documents-from-matter__content">
                {{ $t('dialog.removeDocumentsFromMatter.confirmation', {
                    count: documentsToRemove.length,
                }) }}
            </span>
        </ow-loading-slot>
        <template #actions>
            <div class="dialog-remove-documents-from-matter__controls">
                <ow-button data-test="remove-documents-from-matter-cancel-button"
                           :disabled="loading"
                           @click="handleCancel">
                    {{ $t('action.cancel') }}
                </ow-button>
                <ow-button is-danger
                           :disabled="loading"
                           data-test="remove-documents-from-matter-remove-button"
                           @click="handleConfirm">
                    {{ $t('action.remove') }}
                </ow-button>
            </div>
        </template>
    </ow-modal>
</template>

<script setup lang="ts">
    import {
        computed,
        nextTick,
        ref,
    } from 'vue'
    import { useI18n } from 'vue-i18n'
    import { useStore } from 'vuex'

    import OwButton from '@/components/core/ow-button-ds.vue'
    import OwLoadingSlot from '@/components/core/ow-loading-slot.vue'
    import OwModal from '@/components/core/ow-modal.vue'
    import {
        FeatureFlag,
        inject as useFeatureFlags,
    } from '@/composables/use-feature-flags'
    import { IDocument } from '@/interfaces/document.interface'
    import {
        REMOVE_SINGLE_DOCUMENT_FROM_MATTER,
        REMOVE_UPLOADED_DOCUMENTS_FROM_MATTER,
    } from '@/store/modules/documents/documents-types'
    import { MATTER_GET_REMOVE_DOCUMENTS_PROMPT_STATE } from '@/store/modules/matter/types'
    import { useDocumentLibraryStore } from '@/stores/document-library'
    import { isNullOrEmpty } from '@/utils/array-utils'

    const { t } = useI18n()
    const store = useStore()
    const documentLibraryStore = useDocumentLibraryStore()
    const loading = ref(false)
    const documentIndex = ref(0)
    const { checkFlag } = useFeatureFlags()

    const removeDocumentsFromMatterDialog = computed(() => store.getters[MATTER_GET_REMOVE_DOCUMENTS_PROMPT_STATE])
    const removeDocumentFromMatter = (val) => {
        if (checkFlag(FeatureFlag.DocumentLibraryV2)) {
            return documentLibraryStore.removeSingleDocumentFromMatter(val)
        }
        return store.dispatch(REMOVE_SINGLE_DOCUMENT_FROM_MATTER, val)
    }
    const removeUploadedDocumentsFromMatter = (val) => {
        if (checkFlag(FeatureFlag.DocumentLibraryV2)) {
            return documentLibraryStore.removeUploadedDocumentsFromMatter(val)
        }
        return store.dispatch(REMOVE_UPLOADED_DOCUMENTS_FROM_MATTER, val)
    }

    const documentsToRemove = computed<IDocument[]>(() => removeDocumentsFromMatterDialog.value.documentsToRemove)

    const handleConfirm = async () => {
        loading.value = true
        documentIndex.value = 0
        try {
            const matterId = removeDocumentsFromMatterDialog.value.matterId

            // remove uploaded documents from matter
            const uploadedDocumentsToRemove = documentsToRemove.value.filter(document => document.documentUploadRequestId)
            if (!isNullOrEmpty(uploadedDocumentsToRemove)) {
                await removeUploadedDocumentsFromMatter({
                    documentIds: uploadedDocumentsToRemove.map(document => document.id),
                    matterId,
                })
                await nextTick(() => {
                    documentIndex.value = uploadedDocumentsToRemove.length
                })
            }

            // remove documents from matter
            const highLevelDocumentTypesToRemove = documentsToRemove.value.filter(document => 'highLevelDocumentType' in document)
            for (const document of highLevelDocumentTypesToRemove) {
                await removeDocumentFromMatter({
                    matterId,
                    documentId: document.documentId,
                    documentType: document.highLevelDocumentType,
                })

                await nextTick(() => {
                    documentIndex.value++
                })
            }
        } finally {
            loading.value = false
        }
        removeDocumentsFromMatterDialog.value.show = false
    }

    const handleCancel = () => {
        removeDocumentsFromMatterDialog.value.show = false
    }

    defineExpose({
        removeDocumentsFromMatterDialog,
    })

</script>

<style lang="scss">
    @import './dialog-remove-documents-from-matter';
</style>
