import {
    mapActions,
    mapGetters,
    mapMutations,
    mapState,
} from 'vuex'

import {MAIN_API_REPORT_ENUM} from "@/enums/reporting-api.enum"
import { Route } from '@/enums/route.enum'
import { IExportTemplateOptions } from '@/interfaces/export-template-options.interface'
import { IState } from '@/interfaces/store/state.interface'
import {
    DOCUMENTS_ASYNC_GENERATE_REPORT,
    DOCUMENTS_ASYNC_GENERATE_TAR,
    DOCUMENTS_MUTATE_ADD_TITLE_ANALYSIS_REPORT_REQUEST,
    GENERATE_DOCX_AND_DOWNLOAD,
    GENERATE_REPORT_DOCX_AND_DOWNLOAD,
    GENERATE_REPORT_DOCX_AND_DOWNLOAD_ASYNC} from '@/store/modules/documents/documents-types'
import {MATTER_EXPORT_TITLE_ANALYSIS_REPORT} from "@/store/modules/matter/types"
import {
    GET_EXPORT_OPTIONS,
    LOAD_EXPORT_OPTIONS,
    MUTATE_SELECTED_EXPORT_OPTION,
} from '@/store/modules/reports/types'
import { LOGGING_HEAP_TRACK_EVENT } from '@/store/mutation-types'
import { isNullOrEmpty } from '@/utils/array-utils'
import { isNullOrWhitespace } from '@/utils/string-utils'

export default {
    data() {
        return {
            isExportStatusDialogVisible: false,
            hasError: false,
            isExportingData: false,
            isExportAsync: false,
        }
    },

    computed: {
        ...mapState({
            disabledReportingFeatures: (state: IState) => state.reports.disabledFeatures,
            asyncReportingEnabled: (state: IState) => state.config.featureFlags?.asyncTitleReports,
        }),

        ...mapGetters('reports', {
            exportOptions: GET_EXPORT_OPTIONS,
        }),
    },

    async created() {
        // @ts-ignore
        await this.loadExportOptions()
    },

    methods: {
        ...mapActions({
            generateDocxAndDownload: GENERATE_DOCX_AND_DOWNLOAD,
            generateReportDocxAndDownload: GENERATE_REPORT_DOCX_AND_DOWNLOAD,
            generateReportDocxAndDownloadAsync: GENERATE_REPORT_DOCX_AND_DOWNLOAD_ASYNC,
            logHeapEvent: LOGGING_HEAP_TRACK_EVENT,
            exportTitleAnalysisReport: MATTER_EXPORT_TITLE_ANALYSIS_REPORT,
            showTitleAnalysisReportNotification: DOCUMENTS_ASYNC_GENERATE_TAR,
            showAsyncReportNotification: DOCUMENTS_ASYNC_GENERATE_REPORT,
        }),
        ...mapMutations({
            updateReportRequests: DOCUMENTS_MUTATE_ADD_TITLE_ANALYSIS_REPORT_REQUEST,
        }),
        ...mapActions('reports', {
            loadExportOptions: LOAD_EXPORT_OPTIONS,
        }),
        ...mapMutations('reports', {
            setSelectedExportOption: MUTATE_SELECTED_EXPORT_OPTION,
        }),

        async linkClickHandler(
            selectedTitleNumbers: Array<string>,
            matterId: string,
            heapTrackerPrefix: string,
            chosenTemplate: IExportTemplateOptions = null,
        ): Promise<void> {
            // if already exporting, then don't want to start again.
            // The button/link should be disabled, so shouldn't be allowed anyhow.
            if (this.isExportingData) {
                return
            }

            this.hasError = false
            this.isExportStatusDialogVisible = true
            this.isExportingData = true

            try {
                switch (chosenTemplate.reportingAPIEnum) {
                    case (MAIN_API_REPORT_ENUM.TAR):
                    case (MAIN_API_REPORT_ENUM.TAR_UNMERGED): {
                        const shouldMergeCellsByTitleNumber = chosenTemplate.reportingAPIEnum != MAIN_API_REPORT_ENUM.TAR_UNMERGED
                        await this.logHeapEvent({
                            type: 'Title Analysis Report',
                            metadata: {
                                matterId: matterId,
                                titleNumberCount: selectedTitleNumbers.length,
                                location: heapTrackerPrefix,
                            },
                        })
                        const result = await this.exportTitleAnalysisReport({ titleNumbers: selectedTitleNumbers, shouldMergeCellsByTitleNumber: shouldMergeCellsByTitleNumber })
                        if (result) {
                            this.isExportStatusDialogVisible = false
                            await this.updateReportRequests(result)
                            await this.showTitleAnalysisReportNotification()
                        }
                        break
                    }
                    default: {
                        if (!isNullOrWhitespace(chosenTemplate.template)) {
                            if (this.asyncReportingEnabled) {
                                const result = await this.generateReportDocxAndDownloadAsync({
                                    template: chosenTemplate,
                                    matterId,
                                    titleNumbers: selectedTitleNumbers,
                                    disabledFeatures: this.disabledReportingFeatures,
                                })
                                if (result) {
                                    this.isExportStatusDialogVisible = false
                                    await this.updateReportRequests(result)
                                    await this.showAsyncReportNotification()
                                }
                            } else {
                                await this.generateReportDocxAndDownload({
                                    template: chosenTemplate,
                                    matterId,
                                    titleNumbers: selectedTitleNumbers,
                                    disabledFeatures: this.disabledReportingFeatures,
                                })
                            }
                        } else {
                            await this.generateDocxAndDownload(selectedTitleNumbers)
                        }
                    }
                }

                const titleNumbers = isNullOrEmpty(selectedTitleNumbers) ? '' : selectedTitleNumbers.join(', ')
                await this.logHeapEvent({
                    type: 'EXPORT-BUTTON - Export title register reports',
                    metadata: {
                        matterId,
                        titleNumbers,
                        template: chosenTemplate?.template ?? 'null',
                        clickedFrom: heapTrackerPrefix,
                    },
                })
                this.isExportingData = false
                this.isExportAsync = false
            } catch (e) {
                this.isExportingData = false
                this.hasError = true
            }
        },

        async viewAllTemplatesClickHandler(
            selectedTitleNumbers: Array<string>,
            matterId: string,
            heapTrackerPrefix: string,
        ): Promise<void> {
            const titleNumbers = isNullOrEmpty(selectedTitleNumbers) ? '' : selectedTitleNumbers.join(', ')
            await this.logHeapEvent({
                type: 'EXPORT-BUTTON - View all reports',
                metadata: {
                    matterId,
                    titleNumbers,
                    clickedFrom: heapTrackerPrefix,
                },
            })
            await this.$router.push({
                name: Route.MatterReports,
                params: {
                    matterId,
                },
                query: {
                    selectedTitleNumbers: selectedTitleNumbers,
                    origin: heapTrackerPrefix,
                },
            })
        },
    },
}
