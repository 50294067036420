<template>
    <div class="matter-searches-details-header invisible-scrollbar d-flex justify-space-between">
        <div v-if="loading" />
        <matter-searches-card v-else
                              class="matter-searches-details-header__card flex-grow-1 flex-shrink-1"
                              :map-options="mapOptions"
                              :loading="loading"
                              :card-layout="false"
                              :title-numbers="order?.associatedTitleNumbers ? order?.associatedTitleNumbers : [order?.titleNumber]"
                              :geo-json="order?.geoJson"
                              v-bind="order">
            <div class="d-flex flex-column justify-space-between h-100">
                <div class="d-flex flex-column gr-1">
                    <div class="content__heading text-no-wrap matter-searches-details-header__header-address"
                         :title="address">
                        {{ address }}
                    </div>
                    <div class="d-flex flex-row flex-no-wrap">
                        <i18n-t keypath="searches.ordered"
                                scope="global"
                                tag="span"
                                class="body-regular text-no-wrap">
                            <template #value>
                                <span class="body-highlight"> {{ gbpCurrencyFormatter.format(totalGrossPrice) }}</span>
                            </template>
                            <template #status>
                                {{ OrderState[order?.state] }}
                            </template>
                        </i18n-t>
                    </div>
                    <div v-if="order?.orderId"
                         class="d-flex flex-row flex-no-wrap accents-small">
                        Order id: {{ order?.orderId }}
                    </div>
                </div>
                <ow-alert v-if="contactDetails"
                          class="mt-3 align-center matter-searches-details-header__contact"
                          type="info">
                    <div class="d-flex flex-wrap">
                        <span class="text-no-wrap mr-2">{{ contactDetails.title }}</span>
                        <a class="accents-highlight text-no-wrap"
                           :href="contactDetails.contactHref"><span class="mdi mdi-bullhorn matter-searches-details-header__contact-icon" />{{ contactDetails.contactLabel }}</a>
                        <v-divider class="mx-1 matter-searches-details-header__divider"
                                   vertical />
                        <a class="accents-highlight text-no-wrap"
                           :href="contactDetails.phoneHref"><span class="matter-searches-details-header__problem-icon" /> {{ contactDetails.phoneNumber }}</a>
                    </div>
                </ow-alert>
            </div>
        </matter-searches-card>
        <div class="matter-searches-details-header__promo d-flex flex-shrink-1">
            <matter-searches-promo-card :title="t('searches.promoCards.overlay.title')"
                                        :description="t('searches.promoCards.overlay.description')"
                                        :coming-soon="false"
                                        @click="emit('overlay-click', order.id)">
                <template #icon>
                    <ow-icon-searches-overlay />
                </template>
            </matter-searches-promo-card>
        </div>
    </div>
</template>

<script setup lang="ts">
    import { computed } from 'vue'
    import { useI18n } from 'vue-i18n'
    import { useStore } from 'vuex'

    import OwIconCopilot from '@/components/core/icons/ow-icon-copilot.vue'
    import OwIconSearchesOverlay from '@/components/core/icons/ow-icon-searches-overlay.vue'
    import OwAlert from '@/components/core/ow-alert.vue'
    import OwButton from '@/components/core/ow-button-ds.vue'
    import MatterSearchesCard from '@/components/matter-searches/matter-searches-card.vue'
    import MatterSearchesPromoCard from '@/components/matter-searches/matter-searches-promo-card.vue'
    import { OrderState,
             Provider } from '@/enums/searches.enum'
    import { SEARCHES_GET_STRUCTURED_ADDRESS_AS_STRING,
             SearchOrderDetails } from '@/store/modules/searches/types'
    import { gbpCurrencyFormatter } from '@/utils/number-utils'
    const { t } = useI18n()

    const store = useStore()
    const props = defineProps<{
        order: SearchOrderDetails
        mapOptions: {
            boundaryColour: string;
            npsLayer: string
            layerAttribution: string
            osDataHubApiKey: string
        }
        loading: boolean
    }>()

    const emit = defineEmits<{
        (e: 'overlay-click', orderId: string): void
    }>()
    const contactDetails = computed(() => {
        let email = ''
        let tel = ''
        switch (props.order?.providerId) {
            case Provider.TmGroup:
                email = 'helpdesk@tmgroup.co.uk'
                tel = '0800 840 5571'
                break
            default:
                return null
        }
        const providerId = Provider[props.order?.providerId].toString() ?? ''
        if (providerId) {
            const titleKey = `searches.contact.title`
            const contactLabelKey = `searches.contact.${ providerId }.label`
            return {
                title: t('searches.contact.title'),
                contactLabel: t(contactLabelKey),
                phoneNumber: tel,
                emailAddress: email,
                contactHref: `mailto:${ email }`,
                phoneHref: `tel:${ tel }`,
            }
        }
        return null
    })
    const totalGrossPrice = computed(() => props?.order?.searchOrderItems?.reduce((acc, item) => acc + item.totalGrossPrice, 0) ?? 0)
    const address = computed<string>(() => store.getters[SEARCHES_GET_STRUCTURED_ADDRESS_AS_STRING])
</script>

<style lang="scss">
    @import "./matter-searches-details-header.scss";
</style>
