<template>
    <base-row :backdated="titlePlanAvailability?.hmlrAvailableVersionIsBackdated"
              :current-matter-id="currentMatterId"
              :custom-actions="customActions"
              :dates="dates"
              :disable-export-button="!isTitlePlanAvailable"
              :disable-view-button="!isTitlePlanAvailable"
              :document-availability-code="documentAvailabilityCode"
              :document-id="titlePlanAvailability?.documentId"
              :help-icon-link="$t('reviewAssistant.titlePlan.helpLink')"
              :help-icon-text="$t('reviewAssistant.titlePlan.helpText')"
              :info-content="infoContent"
              :is-loading="titlePlanAvailability?.loadingOrder"
              :selected-title-number="selectedTitleNumber"
              :show-export-button="isTitlePlanAvailable ? true : false"
              :show-help-icon="true"
              :title="$t('documents.titlePlan')"
              data-test="title-plan-row"
              disable-export-button-menu
              export-button-data-test="order-title-plan-export-as-pdf"
              export-button-data-track="TITLE-DETAILS-PANEL - Export title plan as word document from summary panel"
              view-button-data-test="order-title-plan-button-view"
              view-button-data-track="TITLE-DETAILS-PANEL - View title plan from summary panel"
              @export="exportAsPdf"
              @order="orderDocument"
              @view="viewDocument" />
</template>

<script lang="ts" setup>
    import { differenceInMonths,
             formatDistance } from 'date-fns'
    import {
        computed,
        onMounted,
        ref,
        watch,
    } from 'vue'
    import { useI18n } from 'vue-i18n'
    import { useRouter } from 'vue-router'
    import { useStore } from 'vuex'

    import DocumentsApi from '@/api/documents.api'
    import OverlaysApi from '@/api/overlays.api'
    import BaseRow, {
        CustomActionType,
        DateType,
        InfoContentType,
    } from '@/components/title-panel/v2/cards/order-documents-card/sub-type-renderer/base-row.vue'
    import useDates from '@/composables/use-dates'
    import { HmlrDocumentAvailabilityCode } from '@/consts/document-availability'
    import { HighLevelDocumentType } from '@/consts/document-high-level-type'
    import { DocumentOrderStatus } from '@/consts/document-order-status'
    import {
        DOCUMENT_SOURCE,
        getDataProviderText,
    } from '@/consts/document-source'
    import { ColorClass } from '@/enums/color-class.enum'
    import { Route } from '@/enums/route.enum'
    import { DocumentOrderRequest } from '@/models/store/document-ordering/document-order-request.model'
    import { ORDER_DOCUMENTS } from '@/store/modules/document-ordering/types'
    import { ADD_DOCUMENT_TO_MATTER } from '@/store/modules/documents/documents-types'
    import { MATTER_ADD_TITLE } from '@/store/modules/matter/types'
    import {
        TITLE_CLEAR,
        TITLE_MUTATE_SELECTED_TITLE_RECORD_AFTER_BACKDATED_TITLE_PLAN,
        TITLE_MUTATE_TITLE_PLAN_ORDER_LOADING,
    } from '@/store/modules/titles/types'
    import {
        LOGGING_HEAP_TRACK_EVENT,
    } from '@/store/mutation-types'
    import { useApplicationStore } from '@/stores/application'

    const props = defineProps<{
        selectedTitle?: any,
        isOrderingAllowed: boolean,
        selectedTitleNumber?: string,
        currentMatterId?: number,
    }>()

    const store = useStore()
    const router = useRouter()
    const { formatDateShort } = useDates()
    const { t } = useI18n()
    const applicationStore = useApplicationStore()

    const formatDate = (value: Date): string => {
        return formatDateShort(value)
    }

    const dates = ref<DateType[]>([])
    watch(() => props.selectedTitle?.officialCopiesAvailability?.results?.titlePlan, (newValue) => {
        if (newValue) {
            // edition date
            dates.value = []
            if (newValue?.responseDateValue) {
                dates.value.push({
                    displayValue: 'Issued: ' + formatDate(newValue.responseDateValue),
                    dataTest: 'order-title-plan-label-issued-on-date',
                    alert: false,
                    emptyValue: '',
                })
            }
        }
    }, {
        immediate: true,
        deep: true,
    })

    const isExportPdfLoading = ref(false)
    const isLoadingOverlays = ref(false)
    const existingOverlay = ref(null)

    const sentInPost = computed(() => {
        return Boolean(titlePlanAvailability?.value.currentVersionHasBeenSentByPost) || Boolean(titlePlanAvailability?.value.hmlrAvailabilityCode === HmlrDocumentAvailabilityCode.NotImmediatelyAvailable)
    })

    const isDocumentUnderInvestigation = computed(() => {
        return titlePlanAvailability.value.orderStatus === DocumentOrderStatus.UNDER_INVESTIGATION
    })

    const titlePlanAvailability = computed(() => {
        return props.selectedTitle?.officialCopiesAvailability?.results?.titlePlan
    })

    const isFailedOrder = computed(() => {
        return Boolean(titlePlanAvailability?.value.failedOrder)
    })

    const isTitlePlanAvailable = computed(() => {
        return !sentInPost.value && (titlePlanAvailability?.value?.documentId ||
            titlePlanAvailability?.value?.responseDateValue)
    })

    const responseDateValue = computed(() => {
        return titlePlanAvailability?.value?.responseDateValue
    })

    const documentCharge = computed(() => applicationStore.getDocumentCostAsCurrencyString(documentSource.value))

    const documentSource = computed<string>(() => props.selectedTitle?.record?.source)

    const documentAvailabilityCode = computed(() => {
        return titlePlanAvailability?.value?.hmlrAvailabilityCode
    })

    const customActions = computed<CustomActionType[]>(() => {
        if (sentInPost.value) {
            return []
        }
        return [
            {
                label: existingOverlay.value ? t('orderTitlePlan.viewOverlay') : t('orderTitlePlan.overlayPlan'),
                dataTrack: existingOverlay.value ? 'TITLE-DETAILS-PANEL - Overlay Title Plan' : 'TITLE-DETAILS-PANEL - View Overlay of Title Plan',
                dataTest: existingOverlay.value ? 'order-title-plan-button-view-overlay' : 'order-title-plan-button-create-overlay',
                clickHandler: goToOverlays,
            },
        ]
    })

    const refreshText = computed(() => {
        return t('action.refreshWithCost', { cost: documentCharge.value })
    })

    const refreshSubtitleText = computed(() => {
        if (!documentSource.value) {
            ''
        }
        // if response date less than or equal to 1 day, show 'today'
        const oneday = 60 * 60 * 24 * 1000
        if (responseDateValue.value && new Date(responseDateValue.value).getTime() >= new Date().getTime() - oneday) {
            return t(`documents.message.${ documentSource.value }.oldTitlePlanWithDate`, { date: t('documents.message.today') })
        }
        return t(`documents.message.${ documentSource.value }.oldTitlePlanWithDate`, {
            date: formatDistance(new Date(responseDateValue.value), new Date(), {
                addSuffix: true,
            }),
        })
    })

    const infoContent = computed<InfoContentType[]>(() => {
        const defaultRefreshInfo = {
            dataTest: 'order-title-plan-button-refresh',
            dataTrack: 'TITLE-DETAILS-PANEL - Refresh Title Plan',
            subtitle: refreshSubtitleText.value,
            ctaText: refreshText.value,
            clickHandler: () => orderDocument(),
            show: props.isOrderingAllowed,
            class: ColorClass.Information,
        }
        const info = []
        if (sentInPost.value) {
            info.push({
                dataTest: 'order-title-plan-button-refresh',
                dataTrack: 'TITLE-DETAILS-PANEL - Sent in Post - Refresh Title Plan',
                title: t('documents.message.sentInPost'),
                class: ColorClass.Primary,
                ctaText: t('action.refreshWithCost', { cost: documentCharge.value }),
                clickHandler: () => orderDocument(true),
                show: props.isOrderingAllowed,
            })
        }

        // if issued on date is over three months old
        if ((responseDateValue.value && differenceInMonths(new Date(), new Date(responseDateValue.value)) >= 3)) {
            info.push({
                dataTest: 'order-register-button-refresh',
                dataTrack: 'TITLE-DETAILS-PANEL - Outdated - Refresh Title Title Plan',
                title: '',
                subtitle: refreshSubtitleText.value,
                ctaText: t('action.refreshWithCost', { cost: documentCharge.value }),
                clickHandler: () => orderDocument(true),
                class: ColorClass.Warning,
                show: props.isOrderingAllowed,
            })
        }

        if (isDocumentUnderInvestigation.value) {
            info.push({
                title: t('documents.message.underInvestigation'),
                class: ColorClass.Primary,
            })
        }

        // If the document is not under investigation, show the default refresh info
        // if the document source is not Scotland
        if (documentSource.value !== DOCUMENT_SOURCE.SCOTLAND &&
            info.length === 0 &&
            !isDocumentUnderInvestigation.value) {
            info.push(defaultRefreshInfo)
        }

        return info
    })

    onMounted(async () => {
        if (props.currentMatterId) {
            await loadOverlays()
        }
    })

    const exportAsPdf = async () => {
        isExportPdfLoading.value = true
        const documentId = titlePlanAvailability.value.documentId
        await DocumentsApi.downloadDocumentByTypeAndId(HighLevelDocumentType.TitlePlan, documentId)
        store.dispatch(LOGGING_HEAP_TRACK_EVENT, {
            type: 'Title Plan - Export document',
            metadata: {
                titleNumber: props.selectedTitleNumber,
                matterId: props.currentMatterId,
                docFormat: '.pdf',
                documentSource: getDataProviderText(documentSource.value),
            },
        })
        isExportPdfLoading.value = false
    }

    const viewDocument = async () => {
        logHeapEvent('Plan - View document')
        const documentId = titlePlanAvailability.value.documentId
        if (documentId) {
            await addDocumentsToMatter(documentId)
        }
        if (documentId !== null) {
            await router.push({
                name: Route.DocumentViewer,
                params: {
                    documentType: HighLevelDocumentType.TitlePlan,
                    documentId,
                },
                query: {
                    fromMatterId: props.currentMatterId,
                },
            })
        }
    }

    const addDocumentsToMatter = async (documentId) => {
        await store.dispatch(MATTER_ADD_TITLE, {
            titleNumber: props.selectedTitleNumber,
            show: true,
        })
        await store.dispatch(ADD_DOCUMENT_TO_MATTER, {
            documentType: HighLevelDocumentType.TitlePlan,
            documentId,
            matterId: props.currentMatterId,
        })
    }

    const loadOverlays = async () => {
        isLoadingOverlays.value = true
        const documentId = titlePlanAvailability?.value.documentId
        if (documentId) {
            const overlays = await OverlaysApi.getOverlays(props.currentMatterId)
            existingOverlay.value = overlays.data.find(x =>
                (x.bgOc2DocumentId === documentId ||
                    x.bgTitlePlanId === documentId ||
                    x.documentUploadId === documentId))
        }
        isLoadingOverlays.value = false
    }

    const goToOverlays = async () => {
        logHeapEvent('Plan - View/Create Overlay Button Clicked')

        const documentId = titlePlanAvailability.value.documentId
        if (documentId) {
            await addDocumentsToMatter(documentId)
            await store.dispatch(TITLE_CLEAR)
            // Not sure how else to do this. The clear title above updates the selected title number in the store
            // to null. The map view watches this and redirects to the map page. Without waiting, then the below
            // comes before that redirect to the map page and the user just stays where they are.
            setTimeout(() => {
                if (existingOverlay.value) {
                    router.push({
                        name: Route.OverlaysList,
                        params: { matterId: props.currentMatterId },
                        query: { overlayId: existingOverlay.value.overlayId },
                    })
                } else {
                    router.push({
                        name: Route.OverlaysCreate,
                        params: { matterId: props.currentMatterId },
                        query: { documentId },
                    })
                }
            }, 500)
        }
    }

    const logHeapEvent = (type) => {
        store.dispatch(LOGGING_HEAP_TRACK_EVENT, {
            type: type,
            metadata: {
                titleNumber: props.selectedTitleNumber,
                matterId: props.currentMatterId,
                documentSource: getDataProviderText(documentSource.value),
            },
        })
    }

    const orderDocument = async (forceRefresh = false) => {
        store.dispatch(MATTER_ADD_TITLE, {
            titleNumber: props.selectedTitleNumber,
            show: true,
        })
        store.commit(TITLE_MUTATE_TITLE_PLAN_ORDER_LOADING, true)
        const force = isFailedOrder.value || forceRefresh
        const request = DocumentOrderRequest.createTitlePlanRequest(
            props.selectedTitleNumber,
            props.currentMatterId,
            force,
            titlePlanAvailability.value.hmlrAvailableVersionIsBackdated)
        await store.dispatch(`documentOrdering/${ ORDER_DOCUMENTS }`, [ request ])
        logHeapEvent('Title Plan Ordered')
        store.commit(TITLE_MUTATE_SELECTED_TITLE_RECORD_AFTER_BACKDATED_TITLE_PLAN, titlePlanAvailability.value.hmlrAvailableVersionIsBackdated)
    }
</script>

<style scoped lang="scss">
@import './base-row.scss';
</style>
