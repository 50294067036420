
<template>
    <div class="document-library-table-header-context-actions d-flex align-center">
        <span v-if="!isNullOrEmpty(documentLibraryStore.tableData)"
              class="body-regular-small text-no-wrap mr-3">
            {{ showingText }}
        </span>
    </div>
</template>

<script setup lang="ts">
    import { computed } from 'vue'
    import { useI18n } from 'vue-i18n'

    import { useDocumentLibraryStore } from '@/stores/document-library'
    import { isNullOrEmpty } from '@/utils/array-utils'
    const documentLibraryStore = useDocumentLibraryStore()
    const { t } = useI18n()

    const showingText = computed(() => {
        if (documentLibraryStore.selected.length) {
            return t('documentLibrary.table.selected', { total: documentLibraryStore.selected.length, titles: documentLibraryStore.selectedDocumentsTitleNumbers?.length })
        }
        // return paging info
        if (documentLibraryStore.paging.pageSize === Number.MAX_SAFE_INTEGER || documentLibraryStore.isGrouped) {
            return t('documentLibrary.table.showingAll', {
                total: documentLibraryStore.tableData.length,
            })
        }

        // work out the range of documents being shown
        const start = (documentLibraryStore.paging.page - 1) * documentLibraryStore.paging.pageSize + 1
        const end = Math.min(documentLibraryStore.paging.page * documentLibraryStore.paging.pageSize, documentLibraryStore.tableData.length)
        return t('documentLibrary.table.showingRange', { start, end, total: documentLibraryStore.tableData.length })
    })
</script>
