<template>
    <div v-if="loading"
         data-test="ow-loading-slot"
         class="ow-loading-slot d-flex fill-height align-center justify-center flex-column ">
        <div class="ow-loading-slot__logo d-flex flex-column"
             :style="{
                 position: fixed ? 'fixed' : 'inherit',
                 top: offsetY,
                 left: offsetX,
                 marginTop: offsetY ? '0' : '-50px',
             }"
             :class="{
                 'ow-loading-slot__logo--small': small,
             }">
            <ow-icon-ow-logo v-if="!hideLogo"
                             data-test="ow-loading-slot-image"
                             class="ow-loading-slot__logo" />
            <v-progress-linear v-model="modelValue"
                               class="ow-loading-slot__progress"
                               :indeterminate="indeterminate"
                               :max="max"
                               rounded-bar
                               :height="small ? '6px' : '8px'"
                               rounded
                               :bg-color="Colors.LogoWhite"
                               :color="Colors.LogoBlue" />
            <div v-if="hasLabelSlot"
                 class="mt-2">
                <slot name="label" />
            </div>
            <span v-else-if="label"
                  class="ow-loading-slot__label body-regular-small text-center mt-2">
                {{ label }}
            </span>
        </div>
    </div>
    <slot />
</template>

<script setup lang="ts">
    import {
        computed,
        useSlots,
    } from "vue"

    import OwIconOwLogo from '@/components/core/icons/ow-icon-ow-logo.vue'
    import { Colors } from "@/enums/colors.enum"

    const modelValue = defineModel<number>()
    const slots = useSlots()

    withDefaults(defineProps<{
        loading: boolean
        small?: boolean
        indeterminate?: boolean
        max?: number
        fixed?: boolean
        offsetY?: string
        offsetX?: string
        label?: string
        hideLogo?: boolean
    }>(), {
        small: true,
        indeterminate: true,
        max: 100,
        fixed: true,
        offsetY: 'calc((100vh / 2) - 50px)',
        hideLogo: false,
    })

    const hasLabelSlot = computed(() => !!slots.label)
</script>

<style scoped lang="scss">
@import './ow-loading-slot';
</style>
