<template>
    <section class="overlays-page-selection"
             :class="{ selected: Boolean(focusedPage) }">
        <div v-if="isLoading"
             class="overlays-page-selection__loading">
            <ow-loading-text :text="i18n.global.t('mapOverlays.loadingDocument')"
                             is-loading />
            <ow-loading-skeleton width="300" />
        </div>
        <pdf-page-selection v-show="!isLoading"
                            ref="pageList"
                            :class="{
                                'overlays-page-selection__selected': Boolean(focusedPage),
                            }"
                            :document-url="documentUrl"
                            :preselect-page-numbers="preSelectedPageNumbers"
                            :allow-multiple-selection="allowMultipleSelection"
                            @change="handleChange"
                            @loading="isLoading = true"
                            @loaded="isLoading = false" />
        <section class="overlays-page-selection__focused-page"
                 :class="{
                     'overlays-page-selection__focused-page--selected': Boolean(focusedPage),
                     'overlays-page-selection__focused-page--hidden': !Boolean(focusedPage),
                 }">
            <div v-if="props.allowMultipleSelection && selectedPages.length > 1"
                 class="overlays-page-selection__focused-page__controls">
                 <!-- TODO: controls to cycle between selected pages. -->
            </div>
            <selected-page-and-scale :page="focusedPage"
                                     :scale="selectedScale"
                                     @select-scale="onSelectScale" />
        </section>
    </section>
</template>
<script setup lang="ts">
    import { PDFPageProxy } from 'pdfjs-dist'
    import * as pdfjsLib from 'pdfjs-dist/build/pdf.mjs'
    import { ref } from 'vue'

    import OwLoadingSkeleton from '@/components/core/ow-loading-skeleton.vue'
    import OwLoadingText from '@/components/core/ow-loading-text.vue'
    import PdfPageSelection from '@/components/documents/pdf-page-selection.vue'
    import SelectedPageAndScale from '@/components/map/overlays/components/selected-page-and-scale.vue'
    import { SelectedPage } from '@/components/map/overlays/overlays-types'
    import i18n from '@/plugins/i18n'

    interface Props {
        documentUrl: string
        preSelectedPageNumbers?: Array<number>,
        allowMultipleSelection?: boolean,
    }
    const props = withDefaults(defineProps<Props>(), {
        preSelectedPageNumbers: () => [] as Array<number>,
        allowMultipleSelection: false,
    })
    const emit = defineEmits<{
        (e: 'select', value: Array<SelectedPage>): void,
    }>()
    // Internal state.
    const isLoading = ref(true)

    // Page selection.
    const pageList = ref<HTMLDivElement | null>(null)
    const selectedPages = ref<pdfjsLib.PDFDocumentProxy[]>([])
    const focusedPageIndex = ref(0)
    const focusedPage = ref<PDFPageProxy>(null)

    const handleChange = (pages: pdfjsLib.PDFDocumentProxy[]) => {
        if (pages.length) {
            focusedPageIndex.value = 0 // TODO: update when supporting multiple pages.
            focusedPage.value = pages[focusedPageIndex.value]
            setTimeout(() => {
                // Wait for animations to complete before scrolling to the selected page.
                const element = document.querySelector(`[data-page-number="${ focusedPage.value.pageNumber }"]`)
                element?.scrollIntoView({ behavior: 'smooth', block: 'center' })
            }, 200)
            emit('select', [{ page: focusedPage.value as PDFPageProxy, scale: selectedScale.value }])
        }
    }

    // Map scale selection.
    const selectedScale = ref<number>(1250)
    const onSelectScale = (scale: number) => {
        selectedScale.value = scale
        emitUpdate()
    }

    const emitUpdate = () => {
        // TODO: update when supporting multiple pages.
        emit('select', [{ page: focusedPage.value as PDFPageProxy, scale: selectedScale.value }])
    }

</script>
<style scoped lang="scss">
    @import 'page-selection.scss';
</style>
