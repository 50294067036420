<template>
    <div class="document-ordering-progress">
        <div v-if="!complete && !completeWithFailures">
            <div v-t="'documents.ordering.orderingDocumentsInProgress'"
                 class="content__subheading" />
            <p v-t="'documents.ordering.bulkOrderHint'" />
            <v-progress-linear color="#73c5e6"
                               stream
                               height="35"
                               :model-value="percentComplete"
                               :buffer-value="percentComplete">
                <template #default="{ value }">
                    <strong>{{ Math.ceil(value) }}%</strong>
                </template>
            </v-progress-linear>
            <ow-icon-text v-if="orderingTimedOut"
                          icon="warning"
                          data-test="document-ordering-progress-slow-warning"
                          :text="$t('documents.ordering.bulkOrderSlowWarning')"
                          :is-single-line="false"
                          class="d-flex pa-4 document-ordering-progress__timeout" />
        </div>
        <div v-if="loadingBulkDownload">
            <div v-t="'documents.ordering.generatingDownloadInProgress'"
                 class="content__subheading" />
            <v-progress-linear indeterminate
                               color="#73c5e6"
                               stream
                               height="25" />
        </div>
        <div v-if="completeWithFailures"
             data-test="document-ordering-progress-failures"
             class="d-flex flex-column document-ordering-progress__incomplete-orders">
            <div v-t="'documents.ordering.someDocumentsWereNotImmediatelyAvailable'"
                 class="content__subheading" />

            <!-- Being investigated by HMLR -->
            <p v-if="titleNumbersForBeingInvestigatedRegisters.length > 0"
               class="incomplete-orders__item">
                <span v-t="'documents.ordering.beingInvestigatedRegisters'"
                      class="caption-regular" />
                <span class="caption-highlight ml-1">{{ titleNumbersForBeingInvestigatedRegisters.join(', ') }}</span>
            </p>
            <p v-if="titleNumbersForBeingInvestigatedTitlePlans.length > 0"
               class="incomplete-orders__item">
                <span v-t="'documents.ordering.beingInvestigatedTitlePlans'"
                      class="caption-regular" />
                <span class="caption-highlight ml-1">{{ titleNumbersForBeingInvestigatedTitlePlans.join(', ') }}</span>
            </p>
            <p v-if="titleNumbersForBeingInvestigatedOC2s.length > 0"
               class="incomplete-orders__item">
                <span v-t="'documents.ordering.beingInvestigatedOC2s'"
                      class="caption-regular" />
                <span class="caption-highlight ml-1">{{ titleNumbersForBeingInvestigatedOC2s.join(', ') }}</span>
            </p>

            <!-- Sent in post -->
            <p v-if="titleNumbersForSentInPostRegisters.length > 0"
               class="incomplete-orders__item">
                <span v-t="'documents.ordering.sentInPostRegisters'"
                      class="caption-regular" />
                <span class="caption-highlight ml-1">{{ titleNumbersForFailedRegisters.join(', ') }}</span>
            </p>
            <p v-if="titleNumbersForSentInPostTitlePlans.length > 0"
               class="incomplete-orders__item">
                <span v-t="'documents.ordering.sentInPostTitlePlans'"
                      class="caption-regular" />
                <span class="caption-highlight ml-1">{{ titleNumbersForSentInPostTitlePlans.join(', ') }}</span>
            </p>
            <p v-if="titleNumbersForSentInPostOC2s.length > 0"
               class="incomplete-orders__item">
                <span v-t="'documents.ordering.sentInPostOC2s'"
                      class="caption-regular" />
                <span class="caption-highlight ml-1">{{ titleNumbersForSentInPostOC2s.join(', ') }}</span>
            </p>

            <!-- Failed, usually with HMLR -->
            <p v-if="titleNumbersForFailedRegisters.length > 0"
               class="incomplete-orders__item">
                <span v-t="'documents.ordering.failedRegisters'"
                      class="caption-regular" />
                <span class="caption-highlight ml-1"> {{ titleNumbersForFailedRegisters.join(', ') }} </span>
            </p>
            <p v-if="titleNumbersForFailedTitlePlans.length > 0"
               class="incomplete-orders__item">
                <span v-t="'documents.ordering.failedTitlePlans'"
                      class="caption-regular" />
                <span class="caption-highlight ml-1"> {{ titleNumbersForFailedTitlePlans.join(', ') }} </span>
            </p>
            <p v-if="titleNumbersForFailedOC2s.length > 0"
               class="incomplete-orders__item">
                <span v-t="'documents.ordering.failedOC2s'"
                      class="caption-regular" />
                <span class="caption-highlight ml-1">{{ titleNumbersForFailedOC2s.join(', ') }} </span>
            </p>
        </div>
    </div>
</template>

<script lang="ts">
    import { PropType } from 'vue'
    import { mapActions } from 'vuex'

    import OwIconText from '@/components/core/ow-icon-text.vue'
    import { HighLevelDocumentType } from '@/consts/document-high-level-type'
    import { DocumentType } from '@/consts/document-type'
    import { IOrganisationHubMessageContent } from '@/interfaces/store/organisation-hub/IOrganisationHubMessageContent'
    import { IOrganisationHubMessage } from '@/interfaces/store/organisation-hub/organisation-hub-message.interface'
    import { DocumentOrderRequest } from '@/models/store/document-ordering/document-order-request.model'
    import { DOWNLOAD_MANY } from '@/store/modules/documents/documents-types'
    import {
        getHighLevelDocumentTypeFromDocumentType,
        isDocumentDownloadable,
        isDocumentOrderBeingInvestigated,
        isDocumentOrderDoneForNowButNotDownloadable,
        isDocumentOrderFailure,
        isDocumentOrderSentInPost,
    } from '@/utils/document-ordering-utils'

    export default {
        name: 'DocumentOrderingProgress',
        components: {
            OwIconText,
        },
        props: {
            orderRequests: {
                type: Array as PropType<Array<DocumentOrderRequest>>,
                required: true,
            },
            orderUpdates: {
                type: Array as PropType<Array<IOrganisationHubMessage>>,
                required: true,
            },
        },
        emits: [
            'error',
            'dismiss',
        ],
        data() {
            return {
                loadingBulkDownload: false,
                orderingTimeout: null,
                orderingTimedOut: false,
            }
        },
        computed: {

            complete():boolean {
                return (this.orderRequests.length ===
                    this.completedOrderRequestCount) &&
                    (this.failedOrderRequestCount === 0 && this.pendingOrderRequestCount === 0) &&
                    this.orderingTimedOut === false
            },

            completeWithFailures(): boolean {
                return (this.orderRequests.length <=
                    this.completedOrderRequestCount +
                    this.pendingOrderRequestCount +
                    this.failedOrderRequestCount) &&
                    (this.failedOrderRequestCount > 0 || this.pendingOrderRequestCount > 0) &&
                    this.orderingTimedOut === false
            },

            completedOrderRequestCount():number {
                return this.getOrdersByStatusFilter(isDocumentDownloadable).length
            },

            pendingOrderRequestCount():number {
                return this.getOrdersByStatusFilter(isDocumentOrderDoneForNowButNotDownloadable).length
            },

            failedOrderRequestCount():number {
                return this.getOrdersByStatusFilter(isDocumentOrderFailure).length
            },

            percentComplete():number {
                return Math.min((this.completedOrderRequestCount + this.pendingOrderRequestCount + this.failedOrderRequestCount) /
                    this.orderRequests.length * 100, 100) // orders may complete later, after having initially failed.
            },

            titleNumbersForBeingInvestigatedRegisters(): Array<string> {
                return this.getTitleNumbersByStatusAndType(HighLevelDocumentType.Register,
                                                           isDocumentOrderBeingInvestigated)
            },

            titleNumbersForBeingInvestigatedTitlePlans(): Array<string> {
                return this.getTitleNumbersByStatusAndType(HighLevelDocumentType.TitlePlan,
                                                           isDocumentOrderBeingInvestigated)
            },

            titleNumbersForBeingInvestigatedOC2s(): Array<string> {
                return this.getTitleNumbersByStatusAndType(HighLevelDocumentType.OC2Document,
                                                           isDocumentOrderBeingInvestigated)
            },

            titleNumbersForSentInPostRegisters(): Array<string> {
                return this.getTitleNumbersByStatusAndType(HighLevelDocumentType.Register,
                                                           isDocumentOrderSentInPost)
            },

            titleNumbersForSentInPostTitlePlans(): Array<string> {
                return this.getTitleNumbersByStatusAndType(HighLevelDocumentType.TitlePlan,
                                                           isDocumentOrderSentInPost)
            },

            titleNumbersForSentInPostOC2s(): Array<string> {
                return this.getTitleNumbersByStatusAndType(HighLevelDocumentType.OC2Document,
                                                           isDocumentOrderSentInPost)
            },

            titleNumbersForFailedRegisters(): Array<string> {
                return this.getTitleNumbersByStatusAndType(HighLevelDocumentType.Register,
                                                           isDocumentOrderFailure)
            },

            titleNumbersForFailedTitlePlans(): Array<string> {
                return this.getTitleNumbersByStatusAndType(HighLevelDocumentType.TitlePlan,
                                                           isDocumentOrderFailure)
            },

            titleNumbersForFailedOC2s(): Array<string> {
                return this.getTitleNumbersByStatusAndType(HighLevelDocumentType.OC2Document,
                                                           isDocumentOrderFailure)
            },

            orderUpdatesCount(): number {
                return this.orderUpdates.length
            },

            downloadableDocuments(): Array<{ documentType: number, documentId:number, responseType: string }> {
                const result = []
                const documentIdsAndTypesToDownload = []
                this.orderUpdates.forEach((orderUpdate: IOrganisationHubMessage) => {
                    if (isDocumentDownloadable(orderUpdate.message.status) &&
                        orderUpdate.message.notificationData.documentId !== null) {
                        if (!documentIdsAndTypesToDownload.some(x =>
                            x.documentId === orderUpdate.message.notificationData.documentId &&
                            x.documentType === orderUpdate.message.notificationData.documentType)) {
                            documentIdsAndTypesToDownload.push({
                                documentId: orderUpdate.message.notificationData.documentId,
                                documentType: orderUpdate.message.notificationData.documentType,
                            })
                        }
                    }
                })
                documentIdsAndTypesToDownload.forEach((item: { documentId: number, documentType: number }) => {
                    if (!result.some((document) => document.id === item.documentId)) {
                        let documentTypeForBulkDownloadEndpoint = 'Abstract' // arbitrary OC2 document type for bulk download endpoint
                        switch (item.documentType) {
                            case DocumentType.REGISTER:
                                documentTypeForBulkDownloadEndpoint = 'Register'
                                break
                            case DocumentType.PLAN:
                                documentTypeForBulkDownloadEndpoint = 'Title Plan'
                                break
                        }

                        result.push({
                            documentType: documentTypeForBulkDownloadEndpoint,
                            documentId: item.documentId,
                            responseType: 'blob',
                        })
                    }
                })
                return result
            },
        },

        watch: {
            orderRequests() {
                this.orderUpdates.length = 0
                this.resetOrderingTimeout()
            },

            orderUpdatesCount: {
                handler() {
                    this.resetOrderingTimeout()
                },
                immediate: true,
            },

            complete(val) {
                if (val === true) {
                    this.downloadDocuments()
                }
            },

            completeWithFailures(val) {
                if (val === true) {
                    if (this.completedOrderRequestCount > 0) {
                        this.downloadDocuments()
                    }

                    this.handleError(this.$t('report.modals.failure.completeWithFailures'))
                }
            },

            orderingTimedOut(val) {
                if (val === true) {
                    if (this.completedOrderRequestCount > 0) {
                        this.downloadDocuments()
                    }

                    this.handleError(this.$t('report.modals.failure.timeout'))
                }
            },

            // Uncomment for diagnostic purposes.
            // completedOrderRequestCount(val) {
            //     console.info('completedOrderRequestCount', val)
            // },
            // pendingOrderRequestCount(val) {
            //     console.info('pendingOrderRequestCount', val)
            // },
            // failedOrderRequestCount(val) {
            //     console.info('failedOrderRequestKeyValues', val)
            // },
            // percentComplete(val) {
            //     console.info('percentComplete', val)
            // },
            // percentPending(val) {
            //     console.info('percentPending', val)
            // },
            // titleNumbersForBeingInvestigatedRegisters(val) {
            //     console.info('titleNumbersForBeingInvestigatedRegisters', val)
            // },
            // titleNumbersForBeingInvestigatedTitlePlans(val) {
            //     console.info('titleNumbersForBeingInvestigatedTitlePlans', val)
            // },
            // titleNumbersForBeingInvestigatedOC2s(val) {
            //     console.info('titleNumbersForBeingInvestigatedOC2s', val)
            // },
            // titleNumbersForSentInPostRegisters(val) {
            //     console.info('titleNumbersForSentInPostRegisters', val)
            // },
            // titleNumbersForSentInPostTitlePlans(val) {
            //     console.info('titleNumbersForSentInPostTitlePlans', val)
            // },
            // titleNumbersForSentInPostOC2s(val) {
            //     console.info('titleNumbersForSentInPostOC2s', val)
            // },
            // titleNumbersForFailedRegisters(val) {
            //     console.info('titleNumbersForFailedRegisters', val)
            // },
            // titleNumbersForFailedTitlePlans(val) {
            //     console.info('titleNumbersForFailedTitlePlans', val)
            // },
            // titleNumbersForFailedOC2s(val) {
            //     console.info('titleNumbersForFailedOC2s', val)
            // },
        },

        methods: {
            ...mapActions({
                bulkDownload: DOWNLOAD_MANY,
            }),

            getOrdersByStatusFilter(statusFilter:(status:number) => boolean):Array<IOrganisationHubMessageContent> {
                return this.orderRequests.filter((order: DocumentOrderRequest) => {
                    const correspondingMessages = this.orderUpdates.filter((orderUpdate: IOrganisationHubMessage) => {
                        return orderUpdate.message.notificationData.documentType === order.documentType &&
                            orderUpdate.message.keyValue === order.keyValue
                    })
                    const result = correspondingMessages.some((orderUpdate: IOrganisationHubMessage) => {
                        return statusFilter(orderUpdate.message.status)
                    })
                    return result
                })
            },

            getTitleNumbersByStatusAndType(highLevelDocumentType: string, statusFilter:(status:number) => boolean): Array<string> {
                const orders = this.getOrdersByStatusFilter(statusFilter).filter((order: DocumentOrderRequest) => {
                    return getHighLevelDocumentTypeFromDocumentType(order.documentType) === highLevelDocumentType
                })
                return orders.filter((order: DocumentOrderRequest) => {
                    return this.orderUpdates.some((orderUpdate: IOrganisationHubMessage) => {
                        return orderUpdate.message.keyValue === order.keyValue &&
                            statusFilter(orderUpdate.message.status)
                    })
                }).map((x: DocumentOrderRequest) => x.titleNumber)
            },

            downloadDocuments(): void {
                this.loadingBulkDownload = true
                this.bulkDownload(this.downloadableDocuments)
                this.loadingBulkDownload = false
                if (!this.completeWithFailures) {
                    this.close()
                }
            },

            handleError(message: string): void {
                this.$emit('error')
            },

            close(): void {
                this.$emit('dismiss')
            },

            // Resets a timer that will display text and the close button if no messages have been received for a while and yet orders remain incomplete.
            resetOrderingTimeout(): void {
                this.orderingTimedOut = false
                if (this.orderingTimeout !== null) {
                    clearTimeout(this.orderingTimeout)
                }
                this.orderingTimeout = setTimeout(() => {
                    this.orderingTimeout = null
                    this.orderingTimedOut = true
                }, 60_000)
            },
        },
    }
</script>
<style lang="scss">
    @import './document-ordering-progress.scss';
</style>
