<template>
    <div class="ow-quick-option-card"
         :class="{
             'ow-quick-option-card--small': small,
         }">
        <ow-card class="ow-quick-option-card__card"
                 border
                 has-outline>
            <template v-if="!option.type">
                <div class="d-flex flex-row align-center gc-3 w-100">
                    <component :is="iconComponent"
                               v-if="iconComponent"
                               class="ow-quick-option-card__icon" />
                    <v-icon v-else>
                        {{ icon }}
                    </v-icon>
                    <div class="ow-quick-option-card__text w-100 d-flex flex-column">
                        <div class="d-flex justify-space-between align-center gc-1">
                            <span class="ow-quick-option-card__title body-highlight">{{ title }}</span>
                            <ow-colored-label v-if="option?.count"
                                              is-rounded
                                              :is-small="false"
                                              theme="primary">
                                {{ option.count }}
                            </ow-colored-label>
                        </div>
                        <span class="ow-quick-option-card__description body-regular-small">{{ description }}</span>
                    </div>
                </div>
            </template>
            <template v-else-if="option.type === QuickOptionType.Upload">
                <slot name="upload-drop-zone" />
            </template>
        </ow-card>
    </div>
</template>

<script setup lang="ts">

    import {
        computed,
        defineAsyncComponent,
        onMounted,
        ref,
    } from 'vue'

    import OwCard from '@/components/core/ow-card.vue'
    import OwChip from '@/components/core/ow-chip.vue'
    import OwColoredLabel from '@/components/core/ow-colored-label.vue'
    import { QuickOptionType } from '@/enums/quick-option-type.enum'
    import { IQuickOption } from '@/interfaces/quick-option.interface'

    const props = defineProps<{
        option: IQuickOption
        optionData?: any
        small?: boolean
    }>()

    const icon = computed(() => {
        if (typeof props.option.icon === 'function') {
            return props.option.icon(props.optionData)
        }
        return props.option.icon
    })

    const title = computed(() => {
        if (typeof props.option.title === 'function') {
            return props.option.title(props.optionData)
        }
        return props.option.title
    })

    const description = computed(() => {
        if (typeof props.option.description === 'function') {
            return props.option.description(props.optionData)
        }
        return props.option.description
    })

    const iconComponent = computed(() => !icon?.value?.includes('$') && icon?.value &&
        defineAsyncComponent(() => import(`@/components/core/icons/${ icon?.value }.vue`)))
</script>

<style lang="scss">
@import './ow-quick-option-card.scss';
</style>
