import 'requestidlecallback-polyfill'

import booleanPointInPolygon from '@turf/boolean-point-in-polygon'
import buffer from '@turf/buffer'
import { degreesToRadians } from '@turf/helpers'
import union from '@turf/union'
import throttle from 'lodash.throttle'
import { Feature } from 'ol'
import { type Map } from 'ol'
import GeoJSON from 'ol/format/GeoJSON'
import {
    Point,
    Polygon,
} from 'ol/geom'
import { Layer } from 'ol/layer'
import Overlay from 'ol/Overlay'
import { register } from 'ol/proj/proj4'
import {
    Fill,
    Stroke,
    Style,
    Text,
} from 'ol/style'
import proj4 from 'proj4'
import { toRaw } from 'vue'
import { Store } from 'vuex'

import { getMarkerKeyGraphicSvg } from '@/components/snapshots/common/snapshot-enums-consts'
import { MAX_ZOOM_TO_TITLE_ZOOM_LEVEL } from '@/consts/map'
import { CoordinateSystemCode } from '@/enums/coordinate-systems'
import { SketchType } from '@/enums/sketches-enums'
import { IState } from '@/interfaces/store/state.interface'
import scotlandGeojson from '@/media/scotland.geojson?raw'
import { GeoImage } from '@/store/modules/map/layers/geo-image/geo-image-layer'
import { GeoImageSource } from '@/store/modules/map/layers/geo-image/geo-image-source'
import { SEARCH_MUTATE_POPUP } from '@/store/modules/search/types'
import {
    IOwSketchOrStyleProps,
    IOwStyle,
} from '@/store/modules/sketches/types/style'
import { hexToRGBArray } from '@/utils/colour-utils'
import { isNullOrWhitespace } from '@/utils/string-utils'

/**
 * Read the vector bounding box of the feature. Uses GeoJSON.readFeature(bbox)
 * @param bbox - the bounding box of the feature
 * @returns {*}
 */
export const readGeoFeature = (bbox) => (new GeoJSON()).readFeature(bbox)

/**
 * Returns a pattern for use in a hatch style.
 * @param {String} colour a CSS colour value.
 */
export const hatchStyleFunction = (colour) => {
    const canvas = document.createElement('canvas')
    const context = canvas.getContext('2d')
    return (function() {
        canvas.width = 16
        canvas.height = 8
        const x0 = 18
        const x1 = -2
        const y0 = -1
        const y1 = 9
        const offset = 16

        context.strokeStyle = colour
        context.lineWidth = 2
        context.beginPath()
        context.moveTo(x0, y0)
        context.lineTo(x1, y1)
        context.moveTo(x0 - offset, y0)
        context.lineTo(x1 - offset, y1)
        context.moveTo(x0 + offset, y0)
        context.lineTo(x1 + offset, y1)
        context.stroke()
        return context.createPattern(canvas, 'repeat')
    }())
}

/**
 * Returns an OpenLayers Style for a given title/sketch.
 * @param {Object} source - title/sketch style metadata.
 * @param scalingMultiplier - a multiplier to apply to the style to make it appear as intended on a page layout. E.g. 2 will double the text/stroke size.
 * TODO: Remove the same function from style-utils.ts and use this one instead.
 */
export const getOLStyleForOWStyleDefinition = (source, scalingMultiplier = 1) => {

    if (source == null) {
        return null
    }

    let fillColour = null
    if (source.fill === true) {
        if (source.colour == null) {
            source.colour = '#f44e3b'
        }
        if (source.colour.indexOf('#') > -1) {
            const rgb = hexToRGBArray(source.colour ?? '#f44e3b')
            fillColour = `rgba(${ rgb.toString() },${ source.fillOpacity })`
        } else if (source.colour.indexOf('rgb') > -1) {
            fillColour = source.colour.substr(0, source.colour.length - 2) + ',' + source.fillOpacity + ')'
        }
    }

    let fillStyle = null

    if (source.fill === true) {
        if (source.hatch === true) {
            fillStyle = new Fill({
                color: hatchStyleFunction(fillColour),
            })
        } else {
            fillStyle = new Fill({
                color: fillColour,
            })
        }
    }

    if (source.show) {
        const style = new Style({
            stroke: new Stroke({
                color: source.colour,
                width: source.strokeWidth * scalingMultiplier,
                lineCap: source.dashed ? 'square' : 'round',
                lineDash: source.dashed ? [ source.strokeWidth, source.strokeWidth * 2.5 * scalingMultiplier ] : null,
            }),
            fill: fillStyle,
            text: new Text({
                font: `${ 14 * scalingMultiplier }px Calibri,sans-serif`,
                overflow: true,
                fill: new Fill({ color: 'rgb(255, 255, 255)' }),
                stroke: new Stroke({
                    color: source.colour,
                    width: 8 * scalingMultiplier,
                }),
            }),
            zIndex: source.zIndex,
        })

        // Label the boundary
        let labelText = null
        if (!isNullOrWhitespace(source.label)) {
            labelText = source.label
            if (source.showTitleNumber === true) {
                labelText = `${ labelText } (${ source.titleNumber })`
            }
        } else if (source.showTitleNumber === true) {
            labelText = source.titleNumber
        }

        style.getText().setText(labelText)

        return style
    }
    return null
}

/**
 * Returns a CSS style object for use in Vue templates based on an OW style definition e.g. from a title.
 * @param {Object} source - OW style definition.
 * @param scalingMultiplier
 * TODO: Remove the same function from style-utils.ts and use this one instead.
 */
export const getCSSStyleForOWStyleDefinition = (source: Partial<IOwSketchOrStyleProps>, scalingMultiplier = 1): Partial<CSSStyleDeclaration> => {
    const result: Partial<CSSStyleDeclaration> = {}
    const strokeColour = source.colour ?? source.strokeColour

    // Sketch Markers are a special case.
    if (source.sketchType === SketchType.Marker) {
        const markerColour = source.colour ?? source.fillColour ?? source.strokeColour
        result.backgroundImage = getMarkerKeyGraphicSvg(markerColour)
        return result
    }
    /** Everything else - boundaries, lines etc. */

    // Border
    const borderStyle = source.dashed || source.dash ? 'dashed' : 'solid'
    // If it's not a marker or line, apply the border
    if (![ SketchType.Marker, SketchType.Line ].includes(source.sketchType)) {
        result.border = `${ (source.strokeWidth ?? 3) * scalingMultiplier }px ${ borderStyle } ${ strokeColour }`
    } else if (source.sketchType === SketchType.Line) {
        // Use a diagonal line for lines
        result.borderBottom = `${ (source.strokeWidth ?? 3) * scalingMultiplier }px ${ borderStyle } ${ strokeColour }`
        result.transform = 'translateY(-0.4em) translateX(0.3em) rotate(45deg)'
    }
    // Background
    if (source.fill === true || source.fillOpacity > 0 || source.fillColour) {
        let fillColour = source.colour ?? source.fillColour ?? source.strokeColour
        // If transparency is set, use rgba
        if (fillColour.includes('rgba') || (source.fillOpacity > 0 && source.fillOpacity < 1)) {
            if (!fillColour.includes('rgba') && fillColour.includes('rgb')) {
                fillColour = fillColour.replace('rgb', 'rgba')
                fillColour = fillColour.replace(')', `, ${ source.fillOpacity })`)
            } else if (fillColour.includes('#')) {
                const rgb = hexToRGBArray(fillColour)
                fillColour = `rgba(${ rgb.toString() },${ source.fillOpacity })`
            }
        }
        if (source.hatch === true) {
            result.background = `repeating-linear-gradient(150deg, ${ fillColour }, ${ fillColour } 2px, #fff 2px, #fff 5px)`
        } else {
            result.background = fillColour
        }
    }

    // Point
    if (source.sketchType === SketchType.Point) {
        result.borderRadius = '50%'
    }
    return result
}

/**
 * Returns a OpenLayers Feature buffered by the given distance in metres.
 * @param {Object} features - an array of OpenLayers features.
 * @param {Object} distance - metres radius to use for the buffer.
 */
export const bufferFeatures = (features, distance) => {
    const format = new GeoJSON()

    // Get GeoJSON features in 4326 for use by turf.js
    let unionFeatures = null
    features.forEach((olFeature) => {
        const turfFeature = format.writeFeatureObject(olFeature, {
            dataProjection: 'EPSG:4326',
            featureProjection: 'EPSG:27700',
        })

        if (unionFeatures == null) {
            unionFeatures = turfFeature
        } else {
            // Union the features together in to one large one
            // @ts-ignore
            unionFeatures = union(unionFeatures, turfFeature)
        }
    })

    // Buffer the feature (kilometres)
    const bufferedFeature = buffer(unionFeatures, distance / 1000)

    // Convert back to OpenLayers feature
    return format.readFeature(bufferedFeature)
}

// Initialises EPSG:27700 with proj4js if needed, to be called from functionality where this may not already be the
// case.
export const initBritishNationalGridProjection = () => {
    if (!proj4.defs('EPSG:27700')) {
        proj4.defs(
            'EPSG:27700',
            '+proj=tmerc +lat_0=49 +lon_0=-2 +k=0.9996012717 +x_0=400000 +y_0=-100000 +ellps=airy ' +
            '+towgs84=446.448,-125.157,542.06,0.15,0.247,0.842,-20.489 +units=m +no_defs',
        )
        register(proj4)
    }
}

// Returns a boolean indicating whether the browser supports WebGL or not.
export const supportsWebGL = () => {
    try {
        const canvas = document.createElement('canvas')
        return Boolean((window.WebGLRenderingContext) &&
            (canvas.getContext('webgl') || canvas.getContext('experimental-webgl')))
    } catch (e) {
        return false
    }
}

/**
 * To address an issue whereby the background layer goes blank after returning to an idle session,
 * we have some workarounds - these cover the scenario whereby the web gl context is lost due to browser
 * optimisations. There may also be others as reported whereby the map doesn't load, but then shortly
 * after neither does anything else and they are logged out, which is likely a separate auth issue.
 */
export const enableWebGlLayerReloadWorkaround = (layer, getTargetMapFn) => {
    const postRenderCallback = (event) => { // Once the layer is initialised.
        const targetCanvas = event.context.canvas
        // Listen for webgl context being lost, add and remove the layer; which seems to fix it.
        targetCanvas.addEventListener('webglcontextlost', () => {
            reloadLayer()
        })
        // Do the same when the tab is made visible.
        document.addEventListener('visibilitychange', () => {
            if (!document.hidden) {
                reloadLayer()
            }
        })
        layer.un('postrender', postRenderCallback)
    }

    // Reload the layer, not excessively, when the browser isn't doing anything else.
    const reloadLayer = throttle(() => {
        // @ts-ignore
        requestIdleCallback(() => {
            const targetMap = getTargetMapFn()
            if (targetMap?.getLayers().getArray().includes(layer) && layer.getVisible()) {
                // Prompt the layer to redraw, thoroughly.
                targetMap.removeLayer(layer)
                targetMap.addLayer(layer)
                layer.getSource().changed()
            }
        })
    }, 1000)

    layer.on('postrender', postRenderCallback)
}

export const getOWStyleFromOLStyle = (style: Style): Partial<IOwSketchOrStyleProps> => {
    const result: Partial<IOwStyle> = {}
    // Stroke
    if (style?.getStroke && style.getStroke()) {
        result.strokeColour = style.getStroke().getColor().toString()
        if (style.getStroke().getWidth()) {
            result.strokeWidth = style.getStroke().getWidth()
        }
        result.dash = !!style.getStroke().getLineDash()
    }
    // Fill
    if (style?.getFill && style.getFill()) {
        result.fillColour = style.getFill()?.getColor()?.toString()
        result.fillOpacity = result.fillColour?.includes('rgba')
            ? parseFloat(result.fillColour?.replace(/^.*,(.+)\)/, '$1'))
            : 1
    }
    // ZIndex
    if (style?.getZIndex && style.getZIndex()) {
        result['z-index'] = style.getZIndex()
    }

    return result
}

export const validBoundingBox = (boundingBox: number[]) => {
    // Basic validation, check for 4 numbers
    if (!boundingBox || boundingBox.length !== 4) {
        return false
    }
    // Check for NaN
    if (boundingBox.some((value) => isNaN(value))) {
        return false
    }
    // Check for Infinity
    return !boundingBox.some((value) => !isFinite(value))
}

export const validCentrePoint = (centrePoint: number[]) => {
    // Basic validation, check for 2 numbers
    if (!centrePoint || centrePoint.length !== 2) {
        return false
    }
    // Check for NaN
    if (centrePoint.some((value) => isNaN(value))) {
        return false
    }
    // Check for Infinity
    return !centrePoint.some((value) => !isFinite(value))
}


export const layerEquals = (currentLayer: Layer, layer: Layer) => {
    return toRaw(layer) === toRaw(currentLayer)
}

/**
 * Returns the rotated coordinates by angle rad
 * @param {any[]} coordinates Extent coordinates in the order of bl, br, tl, tr
 * @param {number} angleRadians angle in rad
 * @returns {any[]} rotated extent coordinates
 */
export const rotateCoordinates = (coordinates: any[], angleRadians: number): any => {
    const centre = midpoint(coordinates[0], coordinates[3])
    return coordinates.map(x => rotatePoint(x, [ centre[0], centre[1] ], angleRadians))
}

/**
 * Returns the rotated coordinates around centre
 * @param {any[]} coordinates Extent coordinates in the order of bl, br, tl, tr
 * @param {number[]} centre X and Y coordinates of the centre
 * @param {number} angleRadians angle in rad
 * @returns {any[]} rotated extent coordinates
 */
export const rotateCoordinatesAroundCentre = (coordinates: any[], centre: number[], angleRadians: number): any => {
    return coordinates.map(x => rotatePoint(x, [ centre[0], centre[1] ], angleRadians))
}

export const midpoint = (point1: [ number, number ], point2: [ number, number ]) => {
    return [ (point1[0] + point2[0]) / 2, (point1[1] + point2[1]) / 2 ]
}

// get max extent of a rotated vector
export const rotateExtent = (extent: number[], angleRadians: number): [ number, number, number, number ] => {
    // Calculate the centre of the extent
    const centreX = (extent[0] + extent[2]) / 2
    const centreY = (extent[1] + extent[3]) / 2
    const centre: [ number, number ] = [ centreX, centreY ]

    // Rotate each corner of the extent
    const bottomLeft = rotatePoint([ extent[0], extent[1] ], centre, angleRadians)
    const bottomRight = rotatePoint([ extent[2], extent[1] ], centre, angleRadians)
    const topLeft = rotatePoint([ extent[0], extent[3] ], centre, angleRadians)
    const topRight = rotatePoint([ extent[2], extent[3] ], centre, angleRadians)

    // Calculate new bounding box (extent) after rotation
    const minX = Math.min(bottomLeft[0], bottomRight[0], topLeft[0], topRight[0])
    const minY = Math.min(bottomLeft[1], bottomRight[1], topLeft[1], topRight[1])
    const maxX = Math.max(bottomLeft[0], bottomRight[0], topLeft[0], topRight[0])
    const maxY = Math.max(bottomLeft[1], bottomRight[1], topLeft[1], topRight[1])

    return [ minX, minY, maxX, maxY ]
}

export const findMaximumExtentOfRotatedRectangle = (extent: number[], centre: [ number, number ], angleRadians: number): [ number, number, number, number ] => {
    // Rotate each corner of the extent
    const bottomLeft = rotatePoint([ extent[0], extent[1] ], centre, angleRadians)
    const bottomRight = rotatePoint([ extent[2], extent[1] ], centre, angleRadians)
    const topLeft = rotatePoint([ extent[0], extent[3] ], centre, angleRadians)
    const topRight = rotatePoint([ extent[2], extent[3] ], centre, angleRadians)

    // Calculate new bounding box (extent) after rotation
    const minX = Math.min(bottomLeft[0], bottomRight[0], topLeft[0], topRight[0])
    const minY = Math.min(bottomLeft[1], bottomRight[1], topLeft[1], topRight[1])
    const maxX = Math.max(bottomLeft[0], bottomRight[0], topLeft[0], topRight[0])
    const maxY = Math.max(bottomLeft[1], bottomRight[1], topLeft[1], topRight[1])

    return [ minX, minY, maxX, maxY ]
}

// get extent of a rotated vector
export const getRotatedExtent = (extent: number[], angleRadians: number): number[] => {
    // Calculate the centre of the extent
    const centreX = (extent[0] + extent[2]) / 2
    const centreY = (extent[1] + extent[3]) / 2
    const centre: [ number, number ] = [ centreX, centreY ]

    // Rotate each corner of the extent
    const bottomLeft = rotatePoint([ extent[0], extent[1] ], centre, angleRadians)
    const bottomRight = rotatePoint([ extent[2], extent[1] ], centre, angleRadians)
    const topLeft = rotatePoint([ extent[0], extent[3] ], centre, angleRadians)
    const topRight = rotatePoint([ extent[2], extent[3] ], centre, angleRadians)
    return [ bottomLeft[0], bottomRight[1], topLeft[0], topRight[1] ]
}

export const getExtentOfRotatedVector = (extent: number[], centre: [ number, number ], angleRadians: number): [ number, number ][] => {
    // Rotate each corner of the extent
    const bottomLeft = rotatePoint([ extent[0], extent[1] ], centre, angleRadians)
    const bottomRight = rotatePoint([ extent[2], extent[1] ], centre, angleRadians)
    const topLeft = rotatePoint([ extent[0], extent[3] ], centre, angleRadians)
    const topRight = rotatePoint([ extent[2], extent[3] ], centre, angleRadians)
    return [ bottomLeft, bottomRight, topLeft, topRight ]
}

export const findRectangleExtent = (points: [ number, number ][], rotationAngle: number, centre: [ number, number ]) => {
    // Rotate the points back
    const unrotatedPoints = points.map(point => rotatePoint(point, centre, degreesToRadians(rotationAngle)))

    // Find minX, minY, maxX, maxY
    const xs = unrotatedPoints.map(p => p[0])
    const ys = unrotatedPoints.map(p => p[1])
    const minX = Math.min(...xs)
    const minY = Math.min(...ys)
    const maxX = Math.max(...xs)
    const maxY = Math.max(...ys)

    return [ minX, minY, maxX, maxY ]
}

// get coordinates of a rotated extent
export const rotateExtentAroundCentre = (extent: number[], angleRadians: number): [ number, number ][] => {
    // Calculate the centre of the extent
    const centreX = (extent[0] + extent[2]) / 2
    const centreY = (extent[1] + extent[3]) / 2

    const centre: [ number, number ] = [ centreX, centreY ]

    // Rotate each corner of the extent
    const bottomLeft = rotatePoint([ extent[0], extent[1] ], centre, angleRadians)
    const bottomRight = rotatePoint([ extent[2], extent[1] ], centre, angleRadians)
    const topLeft = rotatePoint([ extent[0], extent[3] ], centre, angleRadians)
    const topRight = rotatePoint([ extent[2], extent[3] ], centre, angleRadians)

    return [ bottomLeft, bottomRight, topLeft, topRight ]
}

export const rotateExtentAroundFixedCentre = (extent: number[], centre: [ number, number ], angleRadians: number): [ number, number ][] => {
    // Rotate each corner of the extent
    const bottomLeft = rotatePoint([ extent[0], extent[1] ], centre, angleRadians)
    const bottomRight = rotatePoint([ extent[2], extent[1] ], centre, angleRadians)
    const topLeft = rotatePoint([ extent[0], extent[3] ], centre, angleRadians)
    const topRight = rotatePoint([ extent[2], extent[3] ], centre, angleRadians)

    return [ bottomLeft, bottomRight, topLeft, topRight ]
}

export const getCoordinatesFromExtent = (extent: number[]): any => {
    // Rotate each corner of the extent
    const bottomLeft = [ extent[0], extent[1] ]
    const bottomRight = [ extent[2], extent[1] ]
    const topLeft = [ extent[0], extent[3] ]
    const topRight = [ extent[2], extent[3] ]

    return [ bottomLeft, bottomRight, topLeft, topRight ]
}

export const calculateCentrePoint = (boundingBox: number[]): [ number, number ] => {
    const [ minX, minY, maxX, maxY ] = boundingBox
    const centerX = (minX + maxX) / 2
    const centerY = (minY + maxY) / 2
    return [ centerX, centerY ]
}

export const scaleRectangleKeepPointFixed = (rect: number[], scale: number, fixedPoint: [ number, number ]) => {
    function transformVertex(vertex) {
        return {
            x: scale * (vertex.x - fixedPoint[0]) + fixedPoint[0],
            y: scale * (vertex.y - fixedPoint[1]) + fixedPoint[1],
        }
    }

    // Decompose the rectangle into its vertices
    const bottomLeft = { x: rect[0], y: rect[1] }
    const topRight = { x: rect[2], y: rect[3] }

    // Apply the transformation to each vertex
    const newBottomLeft = transformVertex(bottomLeft)
    const newTopRight = transformVertex(topRight)

    // Return the coordinates of the scaled rectangle
    return [ newBottomLeft.x, newBottomLeft.y, newTopRight.x, newTopRight.y ]
}

// Rotate a single point around a centre point
export const rotatePoint = (point: [ number, number ], centre: [ number, number ], angleRadians: number): [ number, number ] => {
    const cos = Math.cos(angleRadians)
    const sin = Math.sin(angleRadians)
    const dx = point[0] - centre[0]
    const dy = point[1] - centre[1]

    let rotatedX = cos * dx - sin * dy + centre[0]
    let rotatedY = sin * dx + cos * dy + centre[1]

    const tolerance = 1e-10
    rotatedX = Math.abs(rotatedX) < tolerance ? 0 : rotatedX
    rotatedY = Math.abs(rotatedY) < tolerance ? 0 : rotatedY

    return [
        rotatedX,
        rotatedY,
    ]
}

export const getRotatedPoint = (
    x: number,
    y: number,
    centre: number[],
    rotationInDegrees) => {
    if (rotationInDegrees === 0) {
        return new Point([ x, y ])
    }

    const rotationInRadians = -rotationInDegrees * (Math.PI / 180)
    return new Point(rotatePoint([ x, y ], [ centre[0], centre[1] ], rotationInRadians))
}

export const getRectangleFeatureFromCoordinates = (extent: [ number, number ][]) => {
    return new Feature({
        geometry: new Polygon([ [
            extent[0],
            extent[2],
            extent[3],
            extent[1],
            extent[0],
        ] ]),
    })
}

export const getGeoImage = (
    url: string,
    bbox: number[],
    opacity: number = 1,
    angleInDegrees: number = 0,
    className: string = '',
    visible: boolean = true,
    projection: CoordinateSystemCode = CoordinateSystemCode.EPSG27700,
    zIndex: number = 300,
    name: string = 'GeoImage') => {
    return new GeoImage({
        name,
        opacity,
        visible,
        zIndex,
        className, // ensures crop mask does not impact other layers
        source: new GeoImageSource({
            url,
            imageExtent: bbox,
            projection,
            imageRotate: degreesToRadians(angleInDegrees),
        }),
    })
}

/**
 * Returns the conversion of val from domain A to domain B
 * @param {number} minA minimum of domain A
 * @param {number} maxA maximum of domain A
 * @param {number} minB minimum of domain B
 * @param {number} maxB minimum of domain B
 * @param {number} val value to be converted
 * @returns {number} converted value to domain B
 */
export const convertValueToDomain = (minA: number, maxA: number, minB: number, maxB: number, val: number): number => {
    return minB + ((val - minA) * (maxB - minB)) / (maxA - minA)
}

/**
 * Returns if the coordinate is in Scotland
 * @param {number[]} coordinate coordinate in EPSG:27700
 * @returns {boolean} Flag indicating if the coordinate is in Scotland
 */
export const coordinateInScotland = (coordinate: number[]): boolean => {
    const [ x, y ] = coordinate
    const scotland = JSON.parse(scotlandGeojson)
    return booleanPointInPolygon([ x, y ], scotland.features[0])
}

/**
 * Returns if the majority of the coordinates are in Scotland
 * @param {number[][]} extent extent coordinates in EPSG:27700
 * @returns {boolean} Flag indicating if the coordinate is in Scotland
 */
export const isExtentInScotland = (extent: number[]): boolean => {
    const coordinates = [
        [ extent[0], extent[1] ], // Bottom left (west, south)
        [ extent[0], extent[3] ], // Top left (west, north)
        [ extent[2], extent[3] ], // Top right (east, north)
        [ extent[2], extent[1] ], // Bottom right (east, south)
    ]
    const coordinateConditions = coordinates.map((coordinate) => {
        return coordinateInScotland(coordinate)
    })
    return coordinateConditions.filter(Boolean).length >= 3
}

/**
 * Updates the center of the map view to the specified coordinates and adjusts the zoom level.
 *
 * @param coordinates - A tuple containing the latitude and longitude to set as the center of the map.
 * @param map - The map instance whose view will be updated.
 */
export const setMapCentre = (coordinates: [ number, number ], map: Map): void => {
    map.getView().setCenter(coordinates)
    map.getView().setZoom(MAX_ZOOM_TO_TITLE_ZOOM_LEVEL)
}

/**
 * Opens a popup on the map at the specified coordinates.
 * @param popupSelector
 * @param popupHtml
 * @param coordinate
 * @param store
 */
export const openPopupOnMap = (
    popupSelector: string = 'searchPopupContainer',
    popupHtml: string,
    coordinate: [ number, number ],
    store: Store<IState>,
): void => {
    const map = store.state.map?.map
    if (map == null) {
        return
    }

    let popupOverlay = store.state.search.popupOverlay

    if (popupOverlay == null) {
        popupOverlay = new Overlay({
            element: document.getElementById(popupSelector),
            autoPan: false,
        })
        map.addOverlay(popupOverlay)
    }

    store.commit(`search/${ SEARCH_MUTATE_POPUP }`, {
        coordinate,
        popupHtml,
        popupOverlay,
        showPopup: true,
    })
}
