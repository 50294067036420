<template>
    <div v-show="showResultsPanel"
         class="search-results-container"
         data-test="search-results-container">
        <v-tabs v-model="selectedTab"
                slider-color="primary"
                color="black"
                grow>
            <v-tab :key="0"
                   data-test="search-results-tab-titles"
                   data-track="SEARCH - Title number search tab">
                Titles ({{ titleCount }})
            </v-tab>
            <v-tab :key="1"
                   data-test="search-results-tab-uprns"
                   data-track="SEARCH - UPRN search tab">
                UPRN ({{ uprnCount }})
            </v-tab>
            <v-tab :key="2"
                   data-test="search-results-tab-places"
                   data-track="SEARCH - Places search tab">
                Places ({{ placeCount }})
            </v-tab>
            <v-tab :key="3"
                   data-test="search-results-tab-companies"
                   data-track="SEARCH - Corporate owners search tab">
                Corporate Owners ({{ companyCount }})
            </v-tab>
        </v-tabs>

        <v-progress-linear v-if="loading"
                           color="primary"
                           indeterminate />

        <v-window v-else
                  v-model="selectedTab"
                  class="result-tabs">
            <v-window-item :key="0"
                           :transition="false"
                           data-test="search-results-titles">
                <title-number-results :count="titleCount"
                                      :title-numbers="results.titleNumbers"
                                      @selected="selectedHandler" />
            </v-window-item>

            <v-window-item :key="1"
                           :transition="false"
                           data-test="search-results-uprn">
                <uprn-results :uprns="results.uprns"
                              @selected="selectedHandler" />
            </v-window-item>

            <v-window-item :key="2"
                           :transition="false"
                           data-test="search-results-places">
                <place-results :count="placeCount"
                               :places="results.places"
                               @selected="selectedHandler" />
            </v-window-item>

            <v-window-item :key="3"
                           :transition="false"
                           data-test="search-results-owners">
                <owner-results :count="companyCount"
                               :owners="results.companies"
                               @selected="selectedHandler"
                               @selection-changed="selectionChangedHandler" />
            </v-window-item>
        </v-window>
    </div>
</template>

<script>
    import debounce from 'lodash.debounce'
    import {
        mapActions,
        mapMutations,
    } from 'vuex'

    import UprnResults from '@/components/map/search/tab-uprn-results.vue'
    import FeatureFlagsMixin from '@/feature-flags/feature-flags-mixin'
    import {
        SEARCH_INITIALISE_SEARCH_RESULTS_LAYER,
        SEARCH_MUTATE_SEARCH_LAYER_VISIBILITY,
    } from '@/store/modules/search/types'
    import { LOGGING_LOG_FEATURE_USAGE } from '@/store/mutation-types'
    import { isNullOrEmpty } from '@/utils/array-utils'

    import OwnerResults from './tab-owner-results.vue'
    import PlaceResults from './tab-place-results.vue'
    import TitleNumberResults from './tab-title-number-results.vue'

    const TABS = {
        TITLES: 0,
        UPRN: 1,
        PLACES: 2,
        COMPANIES: 3,
    }

    export default {
        name: 'MapSearchResults',

        components: {
            UprnResults,
            OwnerResults,
            PlaceResults,
            TitleNumberResults,
        },

        mixins: [FeatureFlagsMixin],

        props: {
            loading: {
                type: Boolean,
                default: false,
            },

            results: {
                type: Object,
                required: true,
            },

            showResultsPanel: {
                type: Boolean,
                default: false,
            },

            searchText: {
                type: String,
                default: '',
            },
        },
        emits: ['selected', 'selection-changed'],

        data() {
            return {
                selectedTab: null,
                debouncedLogSearchUsage: debounce(async (type, text) => {
                    if (this.hasResults) {
                        await this.logFeatureUsage({
                            type,
                            description: text,
                        })
                    }
                    // assume a user does not intentionally initiate a new search more than once every 4 seconds
                }, 4000),
            }
        },

        computed: {
            titleCount() {
                return this.results.titleNumbers?.length ?? 0
            },

            placeCount() {
                return this.results.places?.length ?? 0
            },

            companyCount() {
                return this.results.companies?.length ?? 0
            },

            uprnCount() {
                return this.results.uprns?.length ?? 0
            },

            hasResults() {
                return this.titleCount > 0 ||
                    this.placeCount > 0 ||
                    this.companyCount > 0 ||
                    this.uprnCount > 0
            },
        },

        watch: {
            results() {
                setTimeout(this.setCurrentTab, 500)
            },

            selectedTab(val) {
                this.setSearchLayerVisibility(val === TABS.COMPANIES)

                switch (val) {
                    case TABS.TITLES:
                        this.logSearchUsage('search-title-number', this.searchText)
                        break
                    case TABS.PLACES:
                        this.logSearchUsage('search-place', this.searchText)
                        break
                    case TABS.COMPANIES:
                        this.logSearchUsage('search-owner', this.searchText)
                        break
                    case TABS.UPRN:
                        this.logSearchUsage('search-uprn', this.searchText)
                        break
                }
            },
        },

        methods: {
            ...mapActions({
                logFeatureUsage: LOGGING_LOG_FEATURE_USAGE,
            }),

            ...mapActions('search', {
                addCompaniesToMap: SEARCH_INITIALISE_SEARCH_RESULTS_LAYER,
            }),

            ...mapMutations('search', {
                setSearchLayerVisibility: SEARCH_MUTATE_SEARCH_LAYER_VISIBILITY,
            }),

            selectedHandler(item) {
                this.$emit('selected', item)
            },

            selectionChangedHandler(item) {
                this.addCompaniesToMap(item)
                this.$emit('selection-changed', item)
            },

            setCurrentTab() {
                const text = this.searchText.toLocaleLowerCase()
                const matchedTitleNumbers = this.results.titleNumbers.filter(t => t.number.toLocaleLowerCase().includes(text))
                const matchedPlaces = this.results.places.filter(p => {
                    // Remove any punctuation in the result text for the comparison
                    const testText = p.text.replace(/[,.]/g, '').toLocaleLowerCase()
                    return testText.includes(text)
                })
                const matchedCompanies = this.results.companies.filter(c => c.text.toLocaleLowerCase().includes(text))
                const matchedUprns = this.results.uprns.filter(u => u.value.includes(text))

                // First check to see which tabs contain exact matches of the search string
                // Trying to surface the most relevant tab as the one shown
                if (!isNullOrEmpty(matchedTitleNumbers)) {
                    this.selectedTab = TABS.TITLES
                } else if (!isNullOrEmpty(matchedPlaces)) {
                    this.selectedTab = TABS.PLACES
                } else if (!isNullOrEmpty(matchedCompanies)) {
                    this.selectedTab = TABS.COMPANIES
                } else if (!isNullOrEmpty(matchedUprns)) {
                    this.selectedTab = TABS.UPRN
                } else {
                    // Whichever has any results
                    if (!isNullOrEmpty(this.results.places)) {
                        this.selectedTab = TABS.PLACES
                    } else if (!isNullOrEmpty(this.results.companies)) {
                        this.selectedTab = TABS.COMPANIES
                    } else {
                        this.selectedTab = TABS.TITLES
                    }
                }
            },

            logSearchUsage(type, text) {
                this.debouncedLogSearchUsage(type, text)
            },
        },
    }
</script>

<style lang="scss">
    @import './map-search-results';
</style>
