// @/stores/document-library/getters.ts
import { DOCUMENT_SOURCE } from '@/consts/document-source'
import { ExportDocumentTypes } from '@/consts/document-type'
import { DocumentLibraryTab } from '@/enums/document-library-tab.enum'
import { uploadDocumentSuccessResponses } from '@/enums/uploaded-document-status.enum'
import { UploadedDocumentType } from '@/enums/uploaded-document-type.enum'
import { IBasicTitleInformation } from '@/interfaces/basic-title-information.interface'
import { IDocument } from '@/interfaces/document.interface'
import { IDocumentRowData } from '@/interfaces/document-row-data.interface'
import { IMatterGroup } from '@/interfaces/matter-group.interface'
import { IDocumentLibraryState } from '@/stores/document-library/index'
import { useMatterStore } from '@/stores/matter'
import {
    isNullOrEmpty,
    unique,
} from '@/utils/array-utils'

import {
    mapDocumentRowData,
    parseTitleNumber,
} from './util'

export default {

    /**
     * Filters, enriches, and maps the document list from the provided document library state.
     *
     * @param {IDocumentLibraryState} state - The state of the document library containing documents and related configurations.
     * @return {IDocument[]} Returns an array of enriched and filtered documents, formatted for further use.
     */
    libraryDocuments(state: IDocumentLibraryState): IDocumentRowData[] {
        const matterStore = useMatterStore()

        return state.library.documents
            .filter(doc =>
                doc.documentSource !== DOCUMENT_SOURCE.UPLOADED &&
                doc.documentType !== null &&
                doc.documentType !== ExportDocumentTypes.SPREADSHEET &&
                doc.documentType !== ExportDocumentTypes.WORD_REPORT,
            )
            .map(doc => {
                const title = matterStore.selectedTitles.find((title: IBasicTitleInformation) => title.titleNumber === doc.titleNo)
                const group = title ? matterStore.groups.find((group: IMatterGroup) => group.id === title.matterGroupId) : null
                doc.groupId = group?.id ?? null
                doc.groupName = group?.name ?? null
                return mapDocumentRowData(doc)
            })
    },

    /**
     * Filters and processes exported documents from the document library state.
     *
     * @param {IDocumentLibraryState} state - The state of the document library containing documents and metadata.
     * @return {IDocument[]} An array of processed document objects that meet the criteria for exported documents.
     */
    exportedDocuments(state: IDocumentLibraryState): IDocumentRowData[] {
        return state.library.documents
            .filter(doc =>
                doc.documentSource !== DOCUMENT_SOURCE.UPLOADED &&
                doc.documentType !== null &&
                (doc.documentType === ExportDocumentTypes.SPREADSHEET || doc.documentType === ExportDocumentTypes.WORD_REPORT),
            )
            .map(mapDocumentRowData)
    },

    /**
     * Retrieves a list of uploaded documents from the document library state
     * and maps them to enrich their properties if necessary.
     *
     * @param {IDocumentLibraryState} state - The state object representing the document library.
     * @return {IDocumentUploaded[]} An array of uploaded documents with potentially augmented properties.
     */
    uploadedDocuments(state: IDocumentLibraryState): IDocumentRowData[] {
        return state.library.uploadedDocuments
            .map((doc:IDocument) => {
                if (doc.type === UploadedDocumentType.OfficialCopy) {
                    const oc2 = state.library.documents.find(d => d.documentId === doc.externalId)
                    if (oc2) {
                        doc.augmentedFilename = oc2.augmentedFilename
                    }
                }
                return mapDocumentRowData(doc)
            })
    },

    /**
     * Filters and retrieves the list of pending documents from the document library state.
     *
     * @param {IDocumentLibraryState} state The state object containing the document library's current data.
     * @return {IDocument[]} An array of documents that are in a pending status or are still loading.
     */
    pendingDocuments(state: IDocumentLibraryState): IDocumentRowData[] {
        return state.library.documents.filter(doc =>
            (doc.documentStatus === 'Pending...' || doc.augmentedFilename == null) ||
            doc.loading === true).map((doc => mapDocumentRowData(doc)))
    },

    /**
     * Retrieves the list of documents owned by the user, including both
     * library documents and uploaded documents.
     *
     * @param {IDocumentLibraryState} state - The current state of the document library.
     * @return {IDocumentRowData[]} An array of owned documents.
     */
    ownedDocuments(state: IDocumentLibraryState): IDocumentRowData[] {
        return [...this.libraryDocuments, ...this.uploadedDocuments]
    },

    /**
     * Calculates and returns the count of exported documents from the current state.
     *
     * @param {IDocumentLibraryState} state - The current state of the document library.
     * @return {number} The number of exported documents, or 0 if none exist.
     */
    exportCount(state: IDocumentLibraryState): number {
        return this.exportedDocuments.length ?? 0
    },

    /**
     * Calculates the count of documents owned by the user.
     *
     * @param {IDocumentLibraryState} state - The state object representing the document library.
     * @return {number} The number of documents owned by the user.
     */
    ownedCount(state: IDocumentLibraryState): number {
        return this.ownedDocuments.length ?? 0
    },

    /**
     * Calculates the total count by summing owned, unpurchased, and exported counts.
     *
     * @param {IDocumentLibraryState} state - The current state of the document library.
     * @return {number} The total count of owned, unpurchased, and exported documents.
     */
    totalCount(state: IDocumentLibraryState): number {
        return this.ownedCount + this.exportCount
    },

    /**
     * Determines whether the document library is currently loading.
     *
     * @param {IDocumentLibraryState} state - The current state of the document library.
     * @return {boolean} Returns true if the document library is in a loading state, otherwise false.
     */
    isLoading(state: IDocumentLibraryState) {
        return state.library.loading
    },

    /**
     * Determines the current tab based on the childNavName in the document library state.
     * @param state
     */
    currentTab(state: IDocumentLibraryState): DocumentLibraryTab {
        switch (state.childNavName) {
            case 'owned':
                return DocumentLibraryTab.Owned
            case 'unpurchased':
                return DocumentLibraryTab.Unpurchased
            case 'exports':
                return DocumentLibraryTab.Exports
            default:
                return DocumentLibraryTab.Owned
        }
    },

    /**
     * Determines whether any filters have been applied in the document library state.
     *
     * @param {IDocumentLibraryState} state - The current state of the document library containing filter options.
     * @return {boolean} True if filters have been applied; otherwise, false.
     */
    filterApplied(state: IDocumentLibraryState): boolean {
        return state.filterOptions?.filter !== '' ||
            state.filterOptions?.selectedTitles.size > 0 ||
            state.filterOptions?.selectedTypes.size > 0 ||
            state.filterOptions?.selectedGroups.size > 0 ||
            state.filterOptions?.selectedStatuses.size > 0 ||
            Object.values(state.filterOptions?.groupFilters).some(filter => filter)
    },

    /**
     * Filters and retrieves table data based on the given state and filter options.
     *
     * @param {IDocumentLibraryState} state - The state containing document data and filtering criteria.
     * @return {IDocument[]} The filtered list of documents that match the specified criteria.
     */
    tableData(state: IDocumentLibraryState): IDocumentRowData[] {
        if (isNullOrEmpty(state.data)) {
            return []
        }

        const { filter, selectedTitles, selectedTypes, selectedGroups } = state.filterOptions
        return state.data.filter(doc => {

            // Check if the title number matches the selected titles
            let titleNumberMatched = true
            if (selectedTitles?.size > 0) {
                let titleNumber = parseTitleNumber(doc.titleNumber)
                titleNumberMatched = selectedTitles.has(titleNumber)
            }

            // Check if the group and type match the selected groups and types
            let selectedGroupsMatched = true
            if (selectedGroups?.size > 0) {
                selectedGroupsMatched = selectedGroups.has(doc.groupId)
            }

            // Check if the type matches the selected types
            let selectedTypesMatched = true
            if (selectedTypes?.size > 0) {
                selectedTypesMatched = selectedTypes.has(doc.type)
            }

            // Check if the status matches the selected statuses
            let selectedStatusesMatched = true
            if (state.filterOptions.selectedStatuses.size > 0) {
                selectedStatusesMatched = state.filterOptions.selectedStatuses.has(doc.status)
            }

            if (
                !selectedGroupsMatched ||
                !titleNumberMatched ||
                !selectedTypesMatched ||
                !selectedStatusesMatched
            ) {
                return false
            }

            if (filter) {
                return Object.values(doc).some(value =>
                    value?.toString().toLowerCase().includes(filter.toLowerCase()),
                )
            }

            return true
        })
    },

    /**
     * Retrieves an array of selected document data by matching document IDs from the state.
     *
     * @param {IDocumentLibraryState} state - An object representing the current state of the document library, containing the selected document IDs.
     * @return {IDocumentRowData[]} An array of document row data objects corresponding to the selected document IDs.
     */
    selectedDocuments(state: IDocumentLibraryState): IDocumentRowData[] {
        return state.selected.map(id => this.tableData.find(doc => doc.id === id))
    },

    /**
     * Retrieves a list of unique title numbers from the selected documents.
     *
     * @param {IDocumentLibraryState} state - The current state of the document library.
     * @return {IDocumentRowData[]} An array containing unique title numbers from the selected documents.
     */
    selectedDocumentsTitleNumbers(state: IDocumentLibraryState): IDocumentRowData[] {
        return unique(this.selectedDocuments.map(doc => doc?.titleNumber))
    },

    /**
     * Retrieves all unique title numbers of selected downloadable documents
     * that belong to the 'Register' document type.
     *
     * @param {IDocumentLibraryState} state - The current state of the document library.
     * @return {string[]} An array of unique title numbers for the selected downloadable documents.
     */
    selectedDownloadableTitles(state: IDocumentLibraryState): string[] {
        const selectedDownloadableTitleRegisters = this.selectedDocuments.filter(doc => doc?.document?.documentType === 'Register')

        const downloadableTitles = new Set<string>(selectedDownloadableTitleRegisters.map(doc => doc.titleNumber))
        return Array.from(downloadableTitles)
    },

    /**
     * Retrieves an array of unique title numbers from the selected documents that meet specific criteria
     * such as having the type "Title Register" and containing valid title numbers.
     *
     * @param {IDocumentLibraryState} state - The current document library state containing selected documents.
     * @return {string[]} An array of unique reportable title numbers.
     */
    selectedReportableTitles(state: IDocumentLibraryState): string[] {
        const selectedUploadedReportTitleRegisters = this.selectedDocuments.filter(doc => doc?.type === 'Title Register' &&
            doc?.titleNumber != null &&
            uploadDocumentSuccessResponses.includes(doc?.status))

        const selectedPurchaseReportTitleRegisters = this.selectedDocuments.filter(doc => doc?.type === 'Title Register' &&
            doc?.titleNumber != null)

        const reportableTitles = new Set<string>([...selectedUploadedReportTitleRegisters, ...selectedPurchaseReportTitleRegisters].map(doc => doc?.titleNumber))
        return Array.from(reportableTitles)
    },

    /**
     * Determines whether the document library is grouped by a specific criterion.
     *
     * @param {IDocumentLibraryState} state - The current state of the document library, containing filter options.
     * @return {boolean} Returns true if the document library is grouped by title or group, otherwise false.
     */
    isGrouped(state: IDocumentLibraryState): boolean {
        return state.filterOptions.groupByTitle || state.filterOptions.groupByGroup
    },
}
