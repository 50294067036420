<template>
    <div class="document-library-table-header">
        <div class="document-library-table-header__quick-options">
            <ow-quick-options ref="quickOptionsRef"
                              v-model="store.quickOptions.expanded"
                              :items="quickOptions"
                              :selected="store.selected"
                              :small="store.scrollPosition > 0"
                              :data="store.tableData">
                <template #upload-drop-zone>
                    <document-library-upload quick-option />
                </template>
            </ow-quick-options>
        </div>
        <div class="document-library-table-header d-flex align-center justify-space-between">
            <div class="d-flex align-center gc-4">
                <div class="document-library-table-header__filter">
                    <ow-textfield v-model="internalFilter"
                                  clearable
                                  :read-only="isNullOrEmpty(store.data)"
                                  class="text-no-wrap"
                                  :placeholder="t('action.filter')">
                        <template #iconPrefix>
                            <v-icon size="small">
                                $filter
                            </v-icon>
                        </template>
                    </ow-textfield>
                </div>
                <div class="document-library-table-header__group-by-title">
                    <ow-checkbox id="group-by-title"
                                 v-model="store.filterOptions.groupByTitle"
                                 class="text-no-wrap"
                                 :disabled="isNullOrEmpty(store.tableData)"
                                 :label="t('label.groupByTitle')" />
                </div>
                <div class="document-library-table-header__group-by-title">
                    <ow-checkbox id="group-by-group"
                                 v-model="store.filterOptions.groupByGroup"
                                 :disabled="isNullOrEmpty(store.tableData)"
                                 class="text-no-wrap"
                                 :label="t('label.groupByMatterGroup')" />
                </div>
            </div>
            <div class="document-library-table-header__actions d-flex align-center">
                <document-library-table-header-context-actions v-model:selected="selected" />
                <div v-if="!isNullOrEmpty(store.tableData) && !store.isGrouped"
                     class="document-library-table-header__pagination body-regular-small text-no-wrap">
                    <ow-pagination include-all
                                   :result-count="store.tableData.length"
                                   :page-sizes="[25, 50, 100, 250]"
                                   :external-items-per-page="50"
                                   @page-change="setActivePage"
                                   @page-size-change="setItemsPerPage" />
                </div>
            </div>
        </div>
        <document-library-grouped-table-header v-if="store.isGrouped"
                                               v-model:sort-by="sortBy"
                                               v-model="groupsChecked"
                                               v-model:loading="loadingGroups"
                                               :indeterminate="isIndeterminate"
                                               hide-no-data />
    </div>
</template>

<script setup lang="ts">
    import { debounce } from 'lodash'
    import {
        computed,
        ref,
        useTemplateRef,
        watch,
    } from 'vue'
    import { useI18n } from 'vue-i18n'

    import OwCheckbox from '@/components/core/ow-checkbox.vue'
    import OwPagination from '@/components/core/ow-pagination.vue'
    import OwQuickOptions from '@/components/core/ow-quick-options.vue'
    import OwTextfield from '@/components/core/ow-textfield.vue'
    import DocumentLibraryGroupedTableHeader
        from '@/components/document-library/document-library-grouped-table-header.vue'
    import DocumentLibraryTableHeaderContextActions
        from '@/components/document-library/document-library-table-header-context-actions.vue'
    import DocumentLibraryUpload from '@/components/document-library/document-library-upload.vue'
    import { inject as useDocumentLibrary } from '@/composables/use-document-library'
    import { useDocumentLibraryStore } from '@/stores/document-library'
    import { isNullOrEmpty } from '@/utils/array-utils'

    const filter = defineModel<string>('filter')
    const selected = defineModel<any[]>('selected')
    const store = useDocumentLibraryStore()
    const sortBy = defineModel('sort-by', {
        default: [
            { key: 'groupName', order: 'asc' },
        ],
    })
    const loadingGroups = defineModel('loading-groups', { default: false })

    const internalFilter = ref<string>('')

    const { quickOptions } = useDocumentLibrary()
    const { t } = useI18n()
    const quickOptionsRef = useTemplateRef('quickOptionsRef')

    const setActivePage = (page: number) => {
        store.paging.page = page
    }

    const setItemsPerPage = (pageSize: number) => {
        store.paging.pageSize = pageSize
    }

    const groupsChecked = computed({
        get: () => !isNullOrEmpty(store.tableData) && store.selected.length === store.tableData.length,
        set: (value) => {
            if (value) {
                store.selected = store.tableData.map((item) => item.id)
            } else {
                store.selected = []
            }
        },
    })

    const isIndeterminate = computed(() => {
        return store.selected.length > 0 && store.selected.length < store.tableData.length
    })

    watch(() => store.filterOptions.groupByTitle, (value: boolean) => {
        if (value) {
            store.filterOptions.groupByGroup = false
        }
    })

    watch(() => store.filterOptions.groupByGroup, (value:boolean) => {
        if (value) {
            store.filterOptions.groupByTitle = false
        }
    })

    watch(() => store.scrollPosition, () => {
        quickOptionsRef.value?.resize()
    })

    watch(() => filter.value, (value: string) => {
        internalFilter.value = value
    })

    const debounceUpdateFilter = debounce(() => {
        filter.value = internalFilter.value
    }, 1000)

    watch(() => internalFilter.value, () => {
        debounceUpdateFilter()
    })
</script>

<style lang="scss">
    @import './document-library-table-header.scss';
</style>