<template>
    <div class="d-block align-center gc-2">
        <span v-dompurify-html="exportedDate" />
    </div>
</template>

<script setup lang="ts">
    import { computed } from 'vue'
    import { useI18n } from 'vue-i18n'

    import { IDocumentRowData } from '@/interfaces/document-row-data.interface'
    import { format } from '@/utils/date-utils'
    import { isNullOrWhitespace } from '@/utils/string-utils'

    const { t } = useI18n()

    const props = defineProps<{
        item?: IDocumentRowData
    }>()

    const exportedDate = computed(() => {
        const formattedDate = format(props?.item?.document?.updatedOn)
        const date = !isNullOrWhitespace(formattedDate) ? formattedDate : props?.item?.document?.documentDownloadDate
        return t('documentLibrary.table.rowDetail.exportedDate', {
            date,
            user: !isNullOrWhitespace(props.item?.userName) ? props.item?.userName : 'N/A',
        })
    })
</script>

<style scoped>

</style>