<template>
    <div class="ow-splash-page"
         :data-test="`${ dataTestPrefix }-splash-page`">
        <div class="ow-splash-page__content">
            <div class="ow-splash-page__content--icon">
                <ow-icon :icon="icon" />
            </div>

            <div class="ow-splash-page__content--text">
                <h3 v-dompurify-html="title"
                    class="headers-display" />
                <p v-dompurify-html="subtitle"
                   class="body-regular" />
                <slot name="footer" />

                <ow-button v-if="buttonTitle"
                           data-button-type="Enable"
                           class="ow-splash-page__content--cta"
                           :data-test="`${dataTestPrefix}-splash-page-cta`"
                           :data-track="`${dataTrackPrefix}: Splash page CTA`"
                           is-primary
                           @click="ctaHandler">
                    <span> {{ buttonTitle }}</span>
                </ow-button>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
    import OwButton from '@/components/core/ow-button-ds.vue'
    import OwIcon from '@/components/core/ow-icon.vue'

    defineProps<{
        icon: string,
        title: string,
        subtitle: string,
        dataTrackPrefix: string,
        dataTestPrefix: string,
        buttonTitle?: string
    }>()

    const emit = defineEmits(['button-click'])
    const ctaHandler = async () => {
        emit('button-click')
    }
</script>

<style lang="scss" scoped>
    @import './ow-splash-page.scss';
</style>
