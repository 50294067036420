<template>
    <div class="document-library-grouped-sub-table">
        <div class="document-library-grouped-sub-table__header d-flex align-center justify-space-between"
             @click="groupCollapsed = !groupCollapsed">
            <div class="d-flex align-center gc-2">
                <ow-checkbox id="groupChecked"
                             v-model="groupChecked"
                             class="document-library-grouped-sub-table__header-checkbox"
                             :indeterminate="isIndeterminate"
                             @click.stop />
                <span class="body-highlight document-library-grouped-sub-table__header-title">{{ group }}</span>
                <span v-if="!ungrouped"
                      class="body-regular-small document-library-grouped-sub-table__header-subtitle">{{ subtitle }}</span>
            </div>
            <div class="d-flex align-center mr-3">
                <v-icon class="document-library-grouped-sub-table__header-expand-collapse-icon">
                    {{ !groupCollapsed ? 'mdi-menu-down' : 'mdi-menu-up' }}
                </v-icon>
            </div>
        </div>
        <div v-show="!groupCollapsed">
            <div class="document-library-table">
                <document-library-table v-model:items="items"
                                        v-model:selected="store.selected"
                                        v-model:sort-by="store.filterOptions.sortBy"
                                        hide-column-headers
                                        :paging="false" />
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
    import {
        computed,
    } from 'vue'
    import { useI18n } from 'vue-i18n'
    import type { VDataTable } from 'vuetify/components'
    import { useStore } from 'vuex'

    import OwCheckbox from '@/components/core/ow-checkbox.vue'
    import DocumentLibraryTable from '@/components/document-library/document-library-table.vue'
    import { IDocumentRowData } from '@/interfaces/document-row-data.interface'
    import { MATTER_GET_BY_TITLE_NUMBER } from '@/store/modules/matter/types'
    import { useDocumentLibraryStore } from '@/stores/document-library'
    import { isNullOrWhitespace } from '@/utils/string-utils'

    const props = defineProps<{
        group?: string
    }>()

    const store = useDocumentLibraryStore()
    const vuexStore = useStore()
    const { t } = useI18n()
    const items = defineModel<IDocumentRowData[]>('items', {
        default: [],
    })
    const isIndeterminate = computed<boolean>(() => {
        return items.value.some((item) => store.selected.includes(item.id)) &&
            !items.value.every((item) => store.selected.includes(item.id))
    })
    const subtitle = computed<string>(() => {
        if (store.filterOptions.groupByGroup) {
            return `${ items.value.length } ${ items.value.length === 1 ? 'document' : 'documents' }`
        } else if (store.filterOptions.groupByTitle) {
            return addressText.value
        }
        return ''
    })

    const titleData = computed(() => vuexStore.getters[MATTER_GET_BY_TITLE_NUMBER](props.group))
    const ungrouped = computed(() => props.group === t('label.ungrouped'))

    const addressText = computed<string>(() =>
        isNullOrWhitespace(titleData.value?.addresses?.[0])
            ? t('label.unknown')
            : titleData.value.addresses[0])

    const groupChecked = computed({
        get: () => {
            return items.value.every((item) => store.selected.includes(item.id))
        },
        set: (value: boolean) => {
            if (value) {
                store.selected = store.selected.concat(items.value.map((item) => item.id))
            } else {
                store.selected = store.selected.filter((id) => !items.value.some((item) => item.id === id))
            }
        },
    })

    const groupCollapsed = computed<boolean>({
        get: () => {
            return store.collapsedGroups.has(props.group)
        },
        set: (value: boolean) => {
            if (value) {
                store.collapsedGroups.add(props.group)
            } else {
                store.collapsedGroups.delete(props.group)
            }
        },
    })

</script>

<style lang="scss">
@import './document-library-grouped-sub-table.scss';
</style>
