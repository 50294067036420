import gql from 'graphql-tag'

const GET_TITLE_INFORMATION_QUERY = () => {
    const query = `query titleRegisters(
            $matterId: Long!,
            $titleNumbers: [String!]
        ) {
            titleRegisters(
                matterId: $matterId,
                titleNumbers: $titleNumbers
            ) {
                qualityOfTenure
                tenure
                tenureType
                titleNumber
                ccodOcodOwners {
                    companyRegistrationNo
                    proprietorAddress1
                    proprietorAddress2
                    proprietorAddress3
                    proprietorName
                    __typename
                }
                ccodOcodTitle {
                    propertyAddress
                    tenure
                    __typename
                }
                editionDate
                lease {
                    leaseDate
                    leaseTerm
                    __typename
                }
                propertyAddress {
                    addressLine
                    postcodeZone
                    __typename
                }
                uprns
                proprietorshipParties {
                    index
                    organizationCompanyRegistrationNumber
                    organizationName
                    privateIndividualForenames
                    privateIndividualSurname
                    displayName
                    proprietorshipPartyAddresses {
                        addressLines
                        postcodeZone
                        proprietorshipPartyIndex
                        __typename
                    }
                    proprietorshipPartyType
                    tradingName
                    __typename
                }
            }
        }`

    return gql`${ query }`
}

const GET_TITLE_AVAILABILITY_QUERY = () => {
    const query = `query titleRegisters(
            $matterId: Long!,
            $titleNumbers: [String!]
        ) {
            titleRegisters(
                matterId: $matterId,
                titleNumbers: $titleNumbers
            ) {
                registerEntryIndicators {
                    titleNumber
                }
            }
        }`

    return gql`${ query }`
}

const GET_INTERSECTION_WITH_UNREG_LAND = () => {
    const query = `query titleRegisters(
        $matterId: Long
        $titleNumbers: [String!]!
    ) {
        titleRegisters(
            matterId: $matterId,
            titleNumbers: $titleNumbers,
        ) {
            titleNumber
            unregLandIntersection{
                intersectsUnregLand
                __typename
            }
        }
    }`

    return gql`${ query }`
}

const GET_OWNER_NAMES = () => {
    const query = `query titleRegisters(
            $matterId: Long
            $titleNumbers: [String!]!
        ) {
            titleRegisters(
                matterId: $matterId,
                titleNumbers: $titleNumbers,
            ) {
                titleNumber
                ccodOcodOwners {
                    proprietorName
                }
                proprietorshipParties {
                      organizationName
                      privateIndividualForenames
                      privateIndividualSurname
                      tradingName
                      displayName
                }
                titleNumber
            }
        }`

    return gql`${ query }`
}

/** This gets ML tags for presentation purposes, currently just for testing. It may be that the tags are too low-level
 * to be useful, and instead the back-end will need to provide some derived properties that may be based on the
 * tag data instead. */
const GET_TITLE_REGISTER_ML_TAGS = () => {
    const query = `query titleRegisters(
            $titleNumbers: [String!]!
        ) {
            titleRegisters(
                titleNumbers: $titleNumbers,
            ) {
                titleNumber
                titleRegisterMlTags{
                  topic
                  family
                  type
                  section
                  entryNumber
                  entryText
                  startPosition
                  endPosition
                }
            }
        }`

    return gql`${ query }`
}

const GET_TITLE_TENURES = () => {
    const query = `query titleRegisters(
            $matterId: Long!,
            $titleNumbers: [String!]!
        ) {
            titleRegisters(
                matterId: $matterId,
                titleNumbers: $titleNumbers,
            ) {
                titleNumber
                tenureType
                tenure
                ccodOcodTitle {
                    tenure
                }
            }
        }`

    return gql`${ query }`
}

const GET_TITLE_SCHEDULE_OF_NOTICE_OF_LEASES = () => {
    const query = `query titleRegisters(
            $matterId: Long!,
            $titleNumbers: [String!]
        ) {
            titleRegisters(
                matterId: $matterId,
                titleNumbers: $titleNumbers
            ) {
                scheduleOfNoticesOfLease {
                    dateOfLease
                    notes
                    registrationDateAndPlanRef
                    propertyDescription
                    lesseesTitle
                    term
                    entryText
                }
            }
        }`

    return gql`${ query }`
}

const GET_OWNERS_ADDRESS_AND_TENURE = () => {
    const query = `query titleRegisters(
            $titleNumbers: [String!]!
        ) {
            titleRegisters(
                titleNumbers: $titleNumbers,
            ) {
                titleNumber
                tenureType
                tenure
                propertyAddress {
                    addressLine
                    postcodeZone
                }
                ccodOcodTitle {
                    tenure
                    isOcod
                    additionalProprietor
                    propertyAddress
                }
                ccodOcodOwners {
                    proprietorAddress1
                    proprietorAddress2
                    proprietorAddress3
                    proprietorName
                }

            }
        }`

    return gql`${ query }`
}

export {
    GET_INTERSECTION_WITH_UNREG_LAND,
    GET_OWNER_NAMES,
    GET_OWNERS_ADDRESS_AND_TENURE,
    GET_TITLE_AVAILABILITY_QUERY,
    GET_TITLE_INFORMATION_QUERY,
    GET_TITLE_REGISTER_ML_TAGS,
    GET_TITLE_SCHEDULE_OF_NOTICE_OF_LEASES,
    GET_TITLE_TENURES,
}
