import { DOCUMENT_SOURCE } from '@/consts/document-source'
import { ExportDocumentTypes } from '@/consts/document-type'
import { UploadedDocumentType } from '@/enums/uploaded-document-type.enum'
import { IUploadedDocument } from '@/interfaces/uploaded-document.interface'
import { getSortableDocumentDateString } from '@/utils/document-utils'

import {
    GET_LIBRARY_DOCUMENTS,
    GET_LIBRARY_EXPORTED_DOCUMENTS,
    GET_LIBRARY_PENDING_DOCUMENTS,
    GET_LIBRARY_SEARCH_DOCUMENTS,
    GET_LIBRARY_UPLOADED_DOCUMENTS,
    GET_UPLOAD_DOCUMENTS_DIALOG_STATE,
} from './documents-types'

export default {

    [GET_LIBRARY_DOCUMENTS](state, _, rootState) {
        // Add library specific helper properties
        return state.library.documents
            .filter(doc => doc.documentSource !== DOCUMENT_SOURCE.UPLOADED)
            .filter(doc => doc.documentType !== null)
            .filter(doc => doc.documentType !== 'Spreadsheet')
            .map(doc => {
            // Get and assign group information based on title
                const matterTitles = rootState.matter.currentMatter.selectedTitles
                const matterGroups = rootState.matter.currentMatter.groups
                const groupId = matterTitles.find(title => title.titleNumber === doc.titleNo)?.matterGroupId
                if (groupId) {
                    const group = matterGroups.find(group => group.id === groupId)
                    if (group) {
                        doc.groupId = group.id
                        doc.groupName = group.name
                    }
                }
                if (!doc.groupId) {
                    doc.groupId = null
                    doc.groupName = null
                }
                // Create sortable datestamp properties
                if (doc.documentDate) {
                    doc.documentDateSortable = getSortableDocumentDateString(doc.documentDate)
                }
                if (doc.documentDownloadDate) {
                    doc.documentDownloadDateSortable = getSortableDocumentDateString(doc.documentDownloadDate)
                }
                return doc
            })
    },

    [GET_LIBRARY_EXPORTED_DOCUMENTS](state) {
        return state.library.documents
            .filter(doc => doc.documentSource !== DOCUMENT_SOURCE.UPLOADED
                && doc.documentType !== null
                && doc.documentType === ExportDocumentTypes.SPREADSHEET
                    || doc.documentType === ExportDocumentTypes.WORD_REPORT)
            .map(doc => {
                doc.documentDateSortable = getSortableDocumentDateString(doc.documentDate)
                return doc
            })
    },

    [GET_LIBRARY_UPLOADED_DOCUMENTS](state) {
        return state.library.uploadedDocuments
            .map((doc: IUploadedDocument) => {
                if (doc.type === UploadedDocumentType.OfficialCopy) {
                    const oc2 = state.library.documents.find(d => d.documentId === doc.externalId)
                    if (oc2) {
                        return {
                            ...doc,
                            augmentedFilename: oc2.augmentedFilename,
                        }
                    }
                }

                return doc
            })
    },

    [GET_LIBRARY_PENDING_DOCUMENTS](state) {
        return state.library.documents.filter(doc =>
            (doc.documentStatus === 'Pending...' || doc.augmentedFilename == null) ||
            doc.loading === true)
    },

    [GET_LIBRARY_SEARCH_DOCUMENTS](state) {
        return state.library.searchesDocuments
    },

    [GET_UPLOAD_DOCUMENTS_DIALOG_STATE](state) {
        return state.showUploadDocumentsDialog
    },
}
