<template>
    <ow-base-icon :width="width"
                  :height="height"
                  viewBox="0 0 89.9 49.7"
                  desc="OW Logo">
        <path :style="{
                  fill: Colors.LogoOrange,
              }"
              d="M61.2,49.7L89.9,0H32.5L61.2,49.7z" />
        <path :style="{
                  fill: Colors.LogoBlue,
              }"
              d="M0,0l28.7,49.7l11.6-20L23.1,0H0z" />
    </ow-base-icon>
</template>

<script lang="ts">
    import OwBaseIcon from '@/components/core/icons/ow-base-icon.vue'
    import { Colors } from '@/enums/colors.enum'

    export default {
        name: 'IconOwLogo',
        components: {
            OwBaseIcon,
        },
        props: {
            width: {
                type: String,
                default: '48px',
            },
            height: {
                type: String,
                default: '48px',
            },
        },
        computed: {
            Colors() {
                return Colors
            },
        },
    }
</script>
