<template>
    <li class="body-regular-small"
        data-test="pending-applications-changed">
        {{ t('assetMonitoring.summaryCard.pendingApplicationsChange', numberOfChanges) }}

        <ul v-for="(diff, index) in differences"
            :key="index"
            v-dompurify-html="getDiffText(diff)"
            class="sub-list"
            data-test="pending-applications-changed-list" />
    </li>
</template>

<script lang="ts" setup>
    import { computed } from 'vue'
    import { useI18n } from 'vue-i18n'

    import { IAssetMonitoringNotificationDetail } from '@/components/asset-monitoring/asset-monitoring-notification.interface'
    import { DaylistDatapoint } from '@/components/asset-monitoring/datapoint-interfaces'
    import type {
        Difference,
        UpdatedProperty,
    } from '@/components/asset-monitoring/difference-generic'
    import { isNullOrWhitespace } from '@/utils/string-utils'

    const { t } = useI18n()

    const props = defineProps<{
        items: IAssetMonitoringNotificationDetail[]
    }>()

    const differences = computed(() => {
        const differences: Difference<DaylistDatapoint>[] = []
        props.items?.forEach((item) => {
            if (item.difference) {
                differences.push(JSON.parse(item.difference))
            }
        })

        return differences
    })
    const numberOfChanges = computed<number>(() => {
        return differences.value.reduce((acc, diff) => {
            return acc + diff.added.length + diff.removed.length + diff.updated.length
        }, 0)
    })
    const reference = (item: DaylistDatapoint) => isNullOrWhitespace(item.applicationReference) ? item.reference : item.applicationReference

    const getDiffText = (diff: Difference<DaylistDatapoint>) => {
        const { added, removed, updated } = diff
        const output: string[] = []

        added.forEach((item: DaylistDatapoint) => {
            output.push(`<li>${ t('label.added') }: <span class="changes__to">${ reference(item) }</span></li>`)
        })

        removed.forEach((item: DaylistDatapoint) => {
            output.push(`<li>${ t('label.removed') }: <span class="changes__from">${ reference(item) }</span></li>`)
        })

        updated.forEach((item: UpdatedProperty<DaylistDatapoint>) => {
            output.push(`<li>${ t('label.updated') }: <span class="changes__to">${ reference(item.before) }</span></li>`)
        })

        return output.join('')
    }
</script>

