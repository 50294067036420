import {
    computed,
    provide as VueProvide,
} from 'vue'
import {
    inject as VueInject,
} from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'

import DocumentsApi from '@/api/documents.api'
import { HighLevelDocumentType } from '@/consts/document-high-level-type'
import {
    Route as OwRoutes,
    Route,
} from '@/enums/route.enum'
import {
    uploadDocumentFailureResponses,
    UploadedDocumentStatus,
} from '@/enums/uploaded-document-status.enum'
import { UploadedDocumentType } from '@/enums/uploaded-document-type.enum'
import {
    IDocumentRowData,
    IsDocumentExported,
    IsDocumentOwned,
    IsDocumentUploaded,
} from '@/interfaces/document-row-data.interface'
import i18n from '@/plugins/i18n'
import {
    ADD_DOCUMENT_TO_MATTER,
    DOCUMENTS_DOWNLOAD_UPLOADED_DOCUMENT,
    DOWNLOAD_MANY,
} from '@/store/modules/documents/documents-types'
import { MATTER_GET_REMOVE_DOCUMENTS_PROMPT_STATE } from '@/store/modules/matter/types'
import {
    LOGGING_HEAP_TRACK_EVENT,
    USER_SHOW_POPUP,
} from '@/store/mutation-types'
import { useDocumentLibraryStore } from '@/stores/document-library'
import { useMatterStore } from '@/stores/matter'
import {
    DocumentTypes,
} from '@/utils/document-utils'
import { isNullOrWhitespace } from '@/utils/string-utils'

export const DOCUMENT_PROVIDER_KEY = Symbol('documentProvider')

export const inject = (): ReturnType<typeof useDocument> => {
    if (!VueInject(DOCUMENT_PROVIDER_KEY)) {
        throw new Error(`${ DOCUMENT_PROVIDER_KEY.toString() } has not been provided`)
    }
    return VueInject(DOCUMENT_PROVIDER_KEY) as ReturnType<typeof useDocument>
}

export const provide = () => {
    if ((import.meta as any).env.DEV) {
        // eslint-disable-next-line no-console
        console.trace(`providing: ${ DOCUMENT_PROVIDER_KEY.toString() }`)
    }
    const documentComposable = useDocument()
    VueProvide(DOCUMENT_PROVIDER_KEY, documentComposable)

    return documentComposable
}

/**
 * Where possible use the exported inject function to access the composable
 */
const useDocument = () => {
    const router = useRouter()
    const store = useStore()
    const matterStore = useMatterStore()
    const documentLibraryStore = useDocumentLibraryStore()
    const { t } = useI18n()
    const removeDocumentsFromMatterDialog = computed(() => store.getters[MATTER_GET_REMOVE_DOCUMENTS_PROMPT_STATE])

    const selectedDownloadableDocuments = computed(() => documentLibraryStore.selectedDocuments.filter(doc => getDocumentDownloadAvailability(doc)))

    const isSentInPost = (doc: IDocumentRowData) => doc?.status === "Sent in post"
    const isDocumentUploaded = (doc: IDocumentRowData) => IsDocumentUploaded(doc)
    const isDocumentOwned = (doc: IDocumentRowData) => IsDocumentOwned(doc)
    const isDocumentExported = (doc: IDocumentRowData) => IsDocumentExported(doc)

    const isDocumentUploadSuccess = (doc: IDocumentRowData): boolean => {
        return !uploadDocumentFailureResponses.includes(doc?.document?.status as UploadedDocumentStatus)
    }

    const isDocumentUploadAvailable = (doc: IDocumentRowData): boolean => {
        return (doc.type !== UploadedDocumentType.OfficialCopy) || (doc.status === UploadedDocumentStatus.uploaded && (doc.document.externalId === null || !isNullOrWhitespace(doc.document.augmentedFilename)))
    }

    const getDocumentType = (doc: IDocumentRowData): string => {
        if (uploadDocumentFailureResponses.includes(doc?.document?.status as UploadedDocumentStatus)) {
            return '-'
        }
        return doc.type
    }

    const documentStatus = (doc: IDocumentRowData): string => {
        const document = doc.document
        if (document.documentError === true) {
            return 'Error'
        } else if (document?.status === UploadedDocumentStatus.duplicate) {
            return 'Duplicate'
        } else if (document?.status === UploadedDocumentStatus.uploaded) {
            return 'Uploaded'
        } else if (document.documentStatus === 'Unavailable') {
            return 'Unavailable'
        } else if (document.documentStatus === 'Sent in post') {
            return 'Sent in post'
        } else if (document.documentPending === true) {
            return 'Pending with HMLR...'
        } else if (document.loading === true && document.filename === null && !(document.downloadUrl || document.documentDownloadUrl)) {
            return 'Requesting from HMLR...'
        } else if (!(DocumentTypes.NO_VIEW_IN_APP.has(document.documentType)) &&
                (document.augmentedFilename == null && document.filename != null && document.documentPending !== true)) {
            return 'Making searchable...'
        } else {
            return null
        }
    }

    const showViewButton = (doc: IDocumentRowData) => {
        if (doc.document?.documentError === true) {
            return false
        } else if (doc.document?.status === UploadedDocumentStatus.duplicate) {
            return false
        } else if (DocumentTypes.DOWNLOAD_ONLY.has(doc.document?.documentType)) {
            return false
        } else {
            if (DocumentTypes.NOT_COPY_FILED.has(doc.document?.documentType)) {
                return (doc.document?.downloadUrl || doc.document?.documentDownloadUrl) &&
                    isNullOrWhitespace(documentStatus(doc))
            } else {
                return !isNullOrWhitespace(doc.document?.augmentedFilename) || doc.document?.documentUploadRequestId || isNullOrWhitespace(documentStatus(doc))
            }
        }
    }

    const getDocumentDownloadAvailability = (doc: IDocumentRowData) => {
        if (doc.document?.documentStatus === 'Unavailable') {
            return false
        } else if (DocumentTypes.NOT_COPY_FILED.has(doc.document?.documentType)) {
            return (doc.document?.documentDownloadUrl || doc.document?.downloadUrl) &&
                doc.document?.showDownload === true
        } else {
            return (doc.document?.filename !== null || doc.document?.fileName !== null) &&
                !doc.document?.documentPending
        }
    }

    const showDownloadButton = (doc: IDocumentRowData) => {
        if (doc.document?.documentError === true || doc.document?.status === UploadedDocumentStatus.duplicate) {
            return false
        } else {
            return getDocumentDownloadAvailability(doc)
        }
    }

    const downloadDocument = async (doc: IDocumentRowData) => {
        const isDocumentUploaded = IsDocumentUploaded(doc.document)

        if (isDocumentUploaded) {
            await downloadUploadedDocument(doc)
        } else {
            await downloadPurchasedDocument(doc)
        }
    }

    const getDocumentRoute = (doc: IDocumentRowData) => {
        return {
            name: Route.DocumentViewer,
            params: {
                documentType: doc.document.highLevelDocumentType,
                documentId: doc.documentId,
            },
            query: {
                fromMatterId: matterStore.currentMatterId,
            },
        }
    }

    const viewDocument = async (doc: IDocumentRowData) => {
        if (isSentInPost(doc)) {
            return
        }
        if (isDocumentUploaded(doc)) {
            return await viewUploadedDocument(doc)
        }
        return await viewPurchasedDocument(doc)
    }

    const viewPurchasedDocument = async (doc: IDocumentRowData) => {
        const route = getDocumentRoute(doc)
        await router.push(route)
    }

    const viewUploadedDocument = async (doc: IDocumentRowData) => {
        let type = HighLevelDocumentType.Uploaded
        let id: string | number = doc.id
        if (doc.type == UploadedDocumentType.OfficialCopy && doc.document.externalId != null) {
            type = HighLevelDocumentType.UploadedOC2
            id = doc.document.externalId
        }
        await router.push({
            name: OwRoutes.DocumentViewer,
            params: {
                documentType: type,
                documentId: id,
            },
            query: {
                fromMatterId: matterStore.currentMatterId,
            },
        })
    }

    const downloadUploadedDocument = async (doc: IDocumentRowData) => {
        await store.dispatch(DOCUMENTS_DOWNLOAD_UPLOADED_DOCUMENT, {
            matterId: matterStore.currentMatterId,
            documentId: doc.id,
        })
    }

    const downloadPurchasedDocument = async (doc: IDocumentRowData) => {
        try {
            await DocumentsApi.downloadDocumentByTypeAndId(doc.document.highLevelDocumentType, doc.document.documentId)
            await store.dispatch(ADD_DOCUMENT_TO_MATTER, {
                documentType: doc.document.documentType,
                document: doc.document,
            })
        } catch (error) {
            await store.dispatch(USER_SHOW_POPUP, {
                title: 'Error',
                contentHTML: 'An error was encountered when downloading the document',
            })
            console.error(error)
        }
    }

    const generateReportWithSelected = async () => {
        await router.push({
            name: Route.MatterReports,
            params: {
                matterId: matterStore.currentMatterId,
            },
            query: {
                selectedTitleNumbers: documentLibraryStore.selectedReportableTitles,
                origin: 'DOCS',
            },
        })
    }

    const downloadSelected = async () => {
        const request = selectedDownloadableDocuments.value.map((doc: IDocumentRowData) => {
            return {
                documentType: doc.document.documentType,
                documentId: doc.document.documentId,
                responseType: 'blob',
            }
        })
        console.info('request', request)
        await store.dispatch(DOWNLOAD_MANY, request)
        await store.dispatch(LOGGING_HEAP_TRACK_EVENT, {
            type: 'DOCS - Export as PDF',
            metadata: {
                option: 'Separate .pdf files',
                numberOfTitles: selectedDownloadableDocuments.value.length,
                matterId: matterStore.currentMatterId,
                titleNumbers: selectedDownloadableDocuments.value.map(doc => doc.document.titleNo).join(','),
            },
        })
    }

    const removeDocuments = (documents: IDocumentRowData[]) => {
        removeDocumentsFromMatterDialog.value.matterId = matterStore.currentMatterId
        removeDocumentsFromMatterDialog.value.documentsToRemove = documents.map(doc => doc.document)
        removeDocumentsFromMatterDialog.value.show = true
    }

    return {
        document,

        isSentInPost,
        isDocumentUploaded,
        isDocumentOwned,

        isDocumentUploadSuccess,
        isDocumentUploadAvailable,
        isDocumentExported,
        getDocumentType,

        showViewButton,
        showDownloadButton,
        documentStatus,

        viewDocument,
        downloadDocument,
        removeDocuments,

        generateReportWithSelected,
        downloadSelected,

        selectedDownloadableDocuments,

    }
}
