<template>
    <li class="body-regular-small"
        data-test="ownership-changed">
        {{ t('assetMonitoring.summaryCard.ownershipChange', numberOfChanges) }}

        <ul v-for="(diff, index) in differences"
            :key="index"
            v-dompurify-html="getDiffText(diff)"
            class="sub-list"
            data-test="ownership-changed-list" />
    </li>
</template>

<script lang="ts" setup>
    import { computed } from 'vue'
    import { useI18n } from 'vue-i18n'

    import { IAssetMonitoringNotificationDetail } from '@/components/asset-monitoring/asset-monitoring-notification.interface'
    import { OwnershipDatapoint } from '@/components/asset-monitoring/datapoint-interfaces'
    import { type Difference } from '@/components/asset-monitoring/difference-generic'

    const { t } = useI18n()

    const props = defineProps<{
        items: IAssetMonitoringNotificationDetail[]
    }>()

    const differences = computed(() => {
        const differences: Difference<OwnershipDatapoint>[] = []
        props.items?.forEach((item) => {
            if (item.difference) {
                differences.push(JSON.parse(item.difference))
            }
        })

        return differences
    })
    const numberOfChanges = computed<number>(() => {
        return differences.value.reduce((acc, diff) => {
            return acc + diff.added.length + diff.removed.length + diff.updated.length
        }, 0)
    })
    const displayAddress = (address: string): string => {
        // Remove any trailing spaces and extra commas that may be on the end
        return address.trim().replace(/[,\s]*$/gi, '')
    }

    const getDiffText = (diff: Difference<OwnershipDatapoint>) => {
        const { added, removed, updated } = diff
        const output: string[] = []

        removed.forEach((item) => {
            output.push(`<li>${ t('label.removed') }: <span class="changes__from">${ item.name } ${ displayAddress(item.address) }</span></li>`)
        })

        added.forEach((item) => {
            output.push(`<li>${ t('label.added') }: <span class="changes__to">${ item.name } ${ displayAddress(item.address) }</span></li>`)
        })

        updated.forEach((item) => {
            const { before, after } = item
            const hasNameChanged = before?.name !== after?.name
            const hasAddressChanged = before?.address !== after?.address

            if (hasNameChanged) {
                output.push(`<li>${ t('assetMonitoring.summaryCard.updated', {
                    from: `<span class="changes__from">${ before.name }</span>`,
                    to: `<span class="changes__to">${ after.name }</span> ${ hasAddressChanged ? displayAddress(after.address) : '' }`,
                }) }</li>`)
            } else {
                output.push(`<li>${ t('assetMonitoring.summaryCard.updated', {
                    from: `<span class="changes__from">${ displayAddress(before.address) }</span>`,
                    to: `<span class="changes__to">${ displayAddress(after.address) }</span>`,
                }) }</li>`)
            }
        })

        return output.join('')
    }

</script>

