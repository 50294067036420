<template>
    <li class="body-regular-small"
        data-test="epc-changed">
        {{ t('assetMonitoring.summaryCard.epcChange', numberOfChanges) }}

        <ul v-for="(diff, index) in differences"
            :key="index"
            v-dompurify-html="getDiffText(diff)"
            class="sub-list"
            data-test="epc-changed-list" />
    </li>
</template>

<script lang="ts" setup>
    import { computed } from 'vue'
    import { useI18n } from 'vue-i18n'

    import { IAssetMonitoringNotificationDetail } from '@/components/asset-monitoring/asset-monitoring-notification.interface'
    import { EpcDatapoint } from '@/components/asset-monitoring/datapoint-interfaces'
    import type {
        Difference,
        UpdatedProperty,
    } from '@/components/asset-monitoring/difference-generic'
    import { format } from '@/utils/date-utils'

    const { t } = useI18n()

    const props = defineProps<{
        items: IAssetMonitoringNotificationDetail[]
    }>()

    const differences = computed(() => {
        const differences: Difference<EpcDatapoint>[] = []
        props.items?.forEach((item) => {
            if (item.difference) {
                differences.push(JSON.parse(item.difference))
            }
        })

        return differences
    })
    const numberOfChanges = computed<number>(() => {
        return differences.value.reduce((acc, diff) => {
            return acc + diff.added.length + diff.removed.length + diff.updated.length
        }, 0)
    })

    const getDiffText = (diff: Difference<EpcDatapoint>): string => {
        const { added, removed, updated } = diff
        const output = []

        const expiredText = (expired: boolean) => expired ? 'Expired' : 'Valid'

        added.forEach((item: EpcDatapoint) => {
            output.push(`<li>${ t('label.added') }: ${ item.address }, ${ item.postcode }, rating: <span class="changes__to">${ `${ item?.energyBand }/${ item?.energyRating }` }</span>, expires: <span class="changes__to">${ format(item.expiryDate) }</span> (${ expiredText(item.expired) })</li>`)
        })
        removed.forEach((item: EpcDatapoint) => {
            output.push(`<li>${ t('label.added') }: ${ item.address }, ${ item.postcode }, rating: <span class="changes__from">${ `${ item?.energyBand }/${ item?.energyRating }` }</span>, expires: <span class="changes__from">${ format(item.expiryDate) }</span> (<span class="changes__from">${ expiredText(item.expired) })</span></li>`)
        })

        updated.forEach((item: UpdatedProperty<EpcDatapoint>) => {
            const { before, after } = item

            const hasExpired = before.expired !== after.expired
            const expiryFrom = hasExpired ? `<span class="changes__from">${ expiredText(before.expired) }</span>` : expiredText(before.expired)
            const expiryTo = hasExpired ? `<span class="changes__to">${ expiredText(after.expired) }</span>` : expiredText(after.expired)


            output.push(`<li>${ t('label.updated') }: ${ after.address }, ${ after.postcode } - rating: ${ after?.energyBand }/${ after?.energyRating }, expires: ${ format(after.expiryDate) } ${ expiryFrom }/${ expiryTo }</li>`)
        })

        return output.join('')
    }
</script>

