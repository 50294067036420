import { saveAs } from "file-saver"

import DocumentsApi from "@/api/documents.api"
import httpClient from "@/api/http-client"
import { IReportExportRequestBody } from "@/interfaces/report-request.interface"
import { format } from '@/utils/date-utils'

enum ReportType {
    Default,
    WithoutExternalData
}

export default class ReportingApi {

    static END_POINT = 'reporting'

    /**
     * Generate TAR
     * @param matterId {number}
     * @param titleNumbers
     */
    static async downloadTitleAnalysisReport(matterId: number, titleNumbers, shouldMergeCellsByTitleNumber: boolean = true) : Promise<any> {
        const resultUrl = `${ this.END_POINT }/excel/title-analysis`
        const response = await httpClient.post(resultUrl, {matterId, titleNumbers, mergeCellsByTitleNumber: shouldMergeCellsByTitleNumber, reportType: ReportType.Default})
        if (response.status === 201) {
            const documentId = response.data
            await DocumentsApi.downloadDocumentByTypeAndId('Spreadsheet', documentId)
        } else if (response.status === 202) {
            return response.data
        }
    }

    /**
     * Request a Title Report
     * @param matterId {number}
     * @param titleNumbers
     */
    static async requestTitleReport(request: IReportExportRequestBody) : Promise<any> {
        const url = `${ this.END_POINT }/title-report`
        const requestBody = {
            matterId: request.matterId,
            createdAt: format(Date(), 'yyyy-MM-dd'),
            template: request.template,
            disabledFeatures: request.disabledFeatures ?? [],
            titleNumbers: request.titleNumbers,
            minimumUnexpiredLeaseTermYears: 80,
        }

        const response = await httpClient.post(url, requestBody)

        if (response.status === 202) {
            return response.data
        }
    }
}