<template>
    <div class="document-library-no-data d-flex align-center flex-column justify-center w-100">
        <span v-t="'label.noData'"
              class="caption-regular" />
        <a v-if="store.filterApplied"
           v-t="'action.clearFilter'"
           class="caption-highlight"
           href="#"
           @click="emit('clear-filter')" />
    </div>
</template>

<script setup lang="ts">
    import { useDocumentLibraryStore } from '@/stores/document-library'

    const emit = defineEmits<{
        (e: 'clear-filter')
    }>()

    const store = useDocumentLibraryStore()
</script>

<style lang="scss">
@import './document-library-no-data.scss';
</style>