<template>
    <ow-sidebar v-model:collapsed="collapsed"
                class="document-library-sidebar"
                :title="t('label.filters')"
                inline
                hide-divider>
        <template #footer>
            <ow-button v-if="store.filterApplied"
                       class="my-3"
                       @click="handleClearFilter">
                {{ t('action.clearFilter') }}
            </ow-button>
        </template>
        <document-library-filter-groups v-model:groups="groups"
                                        v-model:group-filters="store.filterOptions.groupFilters"
                                        v-model:expanded="expanded" />
    </ow-sidebar>
</template>

<script setup lang="ts">
    import {
        computed,
        ref,
        watch,
    } from 'vue'
    import { useI18n } from 'vue-i18n'

    import OwButton from '@/components/core/ow-button-ds.vue'
    import OwSidebar from '@/components/core/ow-sidebar.vue'
    import DocumentLibraryFilterGroups from '@/components/document-library/document-library-filter-groups.vue'
    import { inject as useDocument } from '@/composables/use-document'
    import { IDocumentLibraryFilterGroup } from '@/interfaces/document-library-filter-group.interface'
    import { IDocumentRowData } from '@/interfaces/document-row-data.interface'
    import { useDocumentLibraryStore } from '@/stores/document-library'
    import {
        parseTitleNumber,
    } from '@/stores/document-library/util'
    import {
        dynamicSort,
        isNullOrEmpty,
        unique,
    } from '@/utils/array-utils'
    import { isNullOrWhitespace } from '@/utils/string-utils'

    const collapsed = ref<boolean>(false)
    const groups = ref<IDocumentLibraryFilterGroup[]>([])
    const expanded = ref<number[]>([])
    const store = useDocumentLibraryStore()
    const { documentStatus } = useDocument()
    const { t } = useI18n()

    const handleClearFilter = () => {
        store.clearFilter()
    }

    const selectedItems = computed(() => {
        return groups.value
            .map((group) => group.items.filter((item) => item.selected))
            .flat()
    })

    watch(() => selectedItems.value, async () => {
        const updateSelectedItems = (groupId: string, filterOption: string) => {
            store.filterOptions[filterOption] = new Set(groups.value
                .find((group) => group.id === groupId)
                ?.items
                .filter((item) => item.selected)
                .map((item) => item.id))
        }

        updateSelectedItems('titles', 'selectedTitles')
        updateSelectedItems('type', 'selectedTypes')
        updateSelectedItems('group', 'selectedGroups')
        updateSelectedItems('status', 'selectedStatuses')
    }, {
        immediate: true,
        deep: true,
    })

    const populateFilterGroups = (data: IDocumentRowData[]) => {
        const filter: IDocumentLibraryFilterGroup[] = []

        if (isNullOrEmpty(data)) {
            groups.value = filter
            return
        }

        // get the types
        const documentTypes = data.map((item: IDocumentRowData) => {
            const ignoredTypes = ['Detecting', 'Extracting', 'Processing', 'Uploading']
            return ignoredTypes.includes(item.type) ? null : item.type
        }).filter((type) => type !== null)
        if (!isNullOrEmpty(documentTypes)) {
            filter.push({
                id: 'type',
                label: t('label.type'),
                filter: false,
                count: computed(() => documentTypes.length),
                items: unique(documentTypes).map((type) => ({
                    id: type,
                    label: type,
                    selected: store.filterOptions.selectedTypes?.has(type) ?? false,
                    count: computed(() => data.filter((item) => item.type === type).length),
                })).sort(dynamicSort('label')),
            })
        }

        // get the groups
        let matterGroups = data
            .filter((item: IDocumentRowData) => !isNullOrWhitespace(item.groupId?.toString()))
            .map((item: IDocumentRowData) => item)

        // unique groups
        matterGroups = matterGroups.map((item: IDocumentRowData) => item.groupId)
            .map((groupId) => matterGroups.find((item: IDocumentRowData) => item.groupId === groupId))

        if (!isNullOrEmpty(matterGroups)) {
            filter.push({
                id: 'group',
                label: t('label.matterGroup'),
                filter: false,
                count: computed(() => matterGroups.length),
                items: unique(matterGroups).map((group) => ({
                    id: group.groupId,
                    label: group.groupName,
                    selected: store.filterOptions.selectedGroups?.has(group.groupId) ?? false,
                    count: computed(() => matterGroups.filter((item: IDocumentRowData) => item.groupId === group.groupId).length),
                })).sort(dynamicSort('label')),
            })
        }

        // get the status
        const documentStatuses = data.map((item: IDocumentRowData) =>  documentStatus(item) ?? null).filter((status) => status)
        if (!isNullOrEmpty(documentStatuses)) {
            filter.push({
                id: 'status',
                label: t('label.status'),
                filter: false,
                count: computed(() => documentStatuses.length),
                items: unique(documentStatuses).map((status) => ({
                    id: status,
                    label: status,
                    selected: store.filterOptions.selectedStatuses?.has(status) ?? false,
                    count: computed(() => documentStatuses.filter((documentStatus) => documentStatus === status).length),
                })).sort(dynamicSort('label')),
            })
        }

        // get the title numbers
        const titleNumbers = data
            .filter((item: IDocumentRowData) => {
                // remove (+n more) from title number to avoid duplicates using regex
                let titleNumber = parseTitleNumber(item?.titleNumber)

                return !isNullOrWhitespace(titleNumber) && matchesTitleNumberFilter(titleNumber)
            })
            .map((item: IDocumentRowData) => {
                return parseTitleNumber(item?.titleNumber) ?? ''
            })

        filter.push({
            id: 'titles',
            label: t('label.titles'),
            filter: true,
            count: computed(() => titleNumbers.length),
            items: unique(titleNumbers).map((titleNumber) => ({
                id: titleNumber,
                label: titleNumber,
                selected: store.filterOptions.selectedTitles?.has(titleNumber) ?? false,
                hidden: computed(() =>!matchesTitleNumberFilter(titleNumber)),
                count:  computed(() => titleNumbers.filter((itemTitleNumber) => itemTitleNumber === titleNumber).length),
            })).sort(dynamicSort('label')),
        })
        groups.value = filter

        // expand all filter groups
        expanded.value = groups.value.map((item, index) => index)
    }

    const matchesTitleNumberFilter = (titleNumber: string) => {
        const filter = store.filterOptions.groupFilters?.titles
        return filter ? titleNumber.toLowerCase().includes(store.filterOptions.groupFilters.titles.toLowerCase()) : true
    }

    watch(() => store.data, async (val) => {
        populateFilterGroups(val)
    }, {
        immediate: true,
    })

    watch(() => store.filterOptions, async () => {
        groups.value.forEach((group) => {
            group.items.forEach((item) => {
                item.selected = store.filterOptions[group.id]?.has(item.id)
            })
        })
    })

    watch(() => store.filterOptions, async () => {
        store.selected = []
    }, {
        deep: true,
    })

    defineExpose({
        collapsed,
        groups,
    })
</script>

<style lang="scss">
@import './document-library-sidebar.scss';
</style>