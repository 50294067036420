<template>
    <div class="ow-quick-options d-flex w-100"
         :class="{
             '--expanded': expanded,
             '--collapsed': !expanded,
             '--small': small,
         }">
        <div class="w-100 d-flex flex-column">
            <div class="ow-quick-options__header d-flex flex-row align-center gc-2 py-2">
                <div class="d-flex w-100 align-center gc-2">
                    <span v-t="'label.quickOptions'"
                          class="ow-quick-options__title label-caps-small text-no-wrap" />
                    <v-divider />
                </div>
                <ow-button v-if="canExpandCollapse"
                           class="ow-quick-options__expand-collapse body-regular-small text-no-wrap"
                           is-link
                           small
                           @click="handleViewAll">
                    {{ viewAllText }}
                </ow-button>
            </div>
            <div ref="quickOptionsGridRef"
                 class="ow-quick-options__grid">
                <div v-for="option in quickOptionItems"
                     :key="option.id"
                     class="ow-quick-options__card">
                    <template v-if="option.type === QuickOptionType.Upload">
                        <slot name="upload-drop-zone" />
                    </template>
                    <ow-quick-option-card v-else
                                          v-bind="option"
                                          :option-data="data"
                                          :option="option"
                                          :small="small"
                                          @click="handleOptionClick(option)" />
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
    import {
        computed,
        onMounted,
        ref,
        useTemplateRef,
        watch,
    } from 'vue'
    import { useI18n } from 'vue-i18n'

    import OwButton from '@/components/core/ow-button-ds.vue'
    import OwQuickOptionCard from '@/components/core/ow-quick-option-card.vue'
    import useWindowResize from '@/composables/use-window-resize'
    import { QuickOptionType } from '@/enums/quick-option-type.enum'
    import {
        IQuickOption,
        QuickOptionVisibility,
    } from '@/interfaces/quick-option.interface'
    import {
        dynamicSort,
        isNullOrEmpty,
    } from '@/utils/array-utils'

    const expanded = defineModel()
    const quickOptionItems = ref<IQuickOption[]>([])
    const { t } = useI18n()
    const quickOptionsGridRef = useTemplateRef('quickOptionsGridRef')
    const canExpandCollapse = ref(false)
    const { debouncedResize } = useWindowResize({
        onResize: () => {
            const quickOptionsGridHeight = quickOptionsGridRef.value.clientHeight
            const quickOptionCardHeight = quickOptionsGridRef.value.querySelector('.ow-quick-options__card')?.clientHeight

            // can more than 1 card fit vertically in the grid?
            const n = Math.floor(quickOptionsGridHeight / quickOptionCardHeight)

            // if there are more cards than fit vertically, we show the expand/collapse button
            canExpandCollapse.value = n > 1
        },
    })

    const props = defineProps<{
        items: IQuickOption[]
        data?: any
        selected?: any
        small?: boolean
    }>()

    const viewAllText = computed(() => {
        return expanded.value ? t('action.collapseAll') : t('action.viewAllCount', {
            count: quickOptionItems.value.length,
        })
    })

    const handleViewAll = () => {
        expanded.value = !expanded.value
    }

    const handleOptionClick = (option: IQuickOption) => {
        if (option.action) {
            option.action(props.data)
        }
    }

    const filter = () => {
        if (isNullOrEmpty(props.items)) {
            quickOptionItems.value = []
            return
        }
        let result: IQuickOption[]
        if (isNullOrEmpty(props.data)) {
            result = props.items.filter(x => x?.visible === true || x?.visible === QuickOptionVisibility.Empty || x?.visible === QuickOptionVisibility.Always)
        } else {
            result = props.items.filter(x => {
                if (x?.visible === true || x?.visible === QuickOptionVisibility.Always || x?.visible === QuickOptionVisibility.Empty) {
                    return x
                }
                return (typeof x?.visible === 'function') ? x?.visible(props.data) : false
            })
        }
        quickOptionItems.value = result.sort(dynamicSort('priority'))
    }

    watch(() => [props.items, props.data, props.selected, expanded.value], () => {
        filter()
        debouncedResize()
    }, {
        immediate: true,
    })

    watch(() => props.selected, () => {
        filter()
    }, {
        immediate: true,
        deep: true,
    })

    onMounted(() => {
        filter()
        debouncedResize()
    })

    defineExpose({
        filter,
        resize: debouncedResize,
    })
</script>

<style scoped lang="scss">
    @import './ow-quick-options.scss';
</style>
