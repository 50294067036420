import {datadogRum} from '@datadog/browser-rum'
import * as LDClient from 'launchdarkly-js-client-sdk'
import camelCase from 'lodash.camelcase'

import {getLaunchDarklyKey} from '@/utils/environment.utils'

const apiKey = getLaunchDarklyKey()

export let ldClient

export const initLDClient = (user, options) => {
    if (!user) {
        console.error('Launch Darkly requires a user to be initialised', user)
        return
    }

    ldClient = LDClient.initialize(apiKey, user, options)

    return new Promise(resolve => ldClient.on('ready', () => {
        const rawFlags = ldClient.allFlags()

        const flags = {}
        for (const rawFlag in rawFlags) {
            datadogRum.addFeatureFlagEvaluation(rawFlag, rawFlags[rawFlag])
            const camelCasedKey = camelCase(rawFlag)
            flags[camelCasedKey] = rawFlags[rawFlag]
        }
        resolve(flags)
    }))
}

/**
 * @deprecated for alternative, see example
 * @example
 * import { inject as useFeatureFlags } from '@/composables/use-feature-flags'
 * const { checkFlag } = useFeatureFlags()
 * checkFlag('my-flag')
 */
export const checkFlag = (flagName, defaultValue) => {
    if (!ldClient) {
        console.error('Launch Darkly client not initialised')
        return defaultValue
    }
    return ldClient.variation(flagName, defaultValue)
}

export default  {
    initLDClient,
    checkFlag,
}