<template>
    <ow-splash-page class="document-library-splash-page"
                    :title="title"
                    :subtitle="subtitle"
                    icon="ow-icon-document-library"
                    data-track-prefix="Document Library"
                    data-test-prefix="document-library">
        <template #footer>
            <div class="splash-page__content d-flex flex-column gr-4">
                <p v-t="'documentLibrary.splashPage.toGetStarted'"
                   class="body-regular" />
                <ow-card class="document-library-splash-page__card"
                         border
                         has-outline
                         data-test="add-title-button"
                         @click="handleAddTitleClick">
                    <div class="d-flex gc-4">
                        <ow-icon-map />
                        <div class="d-flex flex-column">
                            <span v-t="'action.addTitles'"
                                  class="caption-highlight" />
                            <span v-t="'documentLibrary.splashPage.populateMatter'"
                                  class="caption-regular" />
                        </div>
                    </div>
                </ow-card>
                <p v-t="'documentLibrary.splashPage.orUploadDocuments'"
                   class="body-regular" />
                <slot name="upload-drop-zone" />
            </div>
        </template>
    </ow-splash-page>
</template>

<script lang="ts" setup>
    import {
        computed,
        ref,
    } from 'vue'
    import { useI18n } from 'vue-i18n'

    import OwIconMap from '@/components/core/icons/ow-icon-map.vue'
    import OwCard from '@/components/core/ow-card.vue'
    import OwSplashPage from "@/components/core/ow-splash-page.vue"

    const { t } = useI18n()

    const emit = defineEmits<{
        (e: 'add-title'): void
    }>()

    const subtitle = computed(() => {
        return t('documentLibrary.splashPage.description')
    })

    const title = computed(() => {
        return t('documentLibrary.splashPage.title')
    })

    const handleAddTitleClick = () => {
        emit('add-title')
    }
</script>

<style scoped lang="scss">
@import './document-library-splash-page.scss';
</style>

