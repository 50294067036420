import { compareAsc,
    compareDesc } from 'date-fns'

/**
 * Sorts an array based on the property name passed in.
 * By default will sort upper case alphabetically, followed by lower case alphabetically.
 * Set {caseSensitive} to false to ignore the case when sorting.
 * @example
 * const people = [
 *  {name: 'AAAA', surname: 'BBBBBBBBBB'},
 *  {name: 'CCCC', surname: 'DDDDDDDDDD'},
 *  {name: 'EEEE', surname: 'FFFFFFFFFF'},
 *  {name: 'GGGG', surname: 'HHHHHHHHHH'}
 * ];
 * people.sort(Utils.dynamicSort('name'));
 * people.sort(Utils.dynamicSort('-surname'));
 * @param property {String}
 * @param caseSensitive {Boolean}
 * @returns Array sorted by the property
 */
export const dynamicSort = (property, caseSensitive = true) => {
    let sortOrder = 1
    if (property[0] === '-') {
        sortOrder = -1
        property = property.substr(1)
    }

    return (a, b) => {
        const comparisonA = caseSensitive ? a[property] : a[property].toLowerCase()
        const comparisonB = caseSensitive ? b[property] : b[property].toLowerCase()
        const result = (comparisonA < comparisonB)
            ? -1
            : (comparisonA > comparisonB)
                ? 1
                : 0
        return result * sortOrder
    }
}

/**
 * A function to create a comparator for sorting objects by a date property.
 *
 * @param {string} property - The property name containing date values to sort by.
 * @param {string} sortOrder - The order of sorting, either "asc" for ascending or "desc" for descending.
 * @returns {Function} - A comparator function that can be used in array sort methods.
 */
export const dateSort = (property, sortOrder) => {
    return (a, b) => {
        const leftDate = new Date(a[property] || 0)
        const rightDate = new Date(b[property] || 0)

        return sortOrder === 'asc' ? compareAsc(leftDate, rightDate) : compareDesc(leftDate, rightDate)
    }
}

/***
 * Sorts based on the sortOrder property.
 * @param a
 * @param b
 */
export const sortBySortOrderFn = (a, b) => {
    const sortOrderA = a.sortOrder || 0
    const sortOrderB = b.sortOrder || 0
    return sortOrderA - sortOrderB
}

/**
 * Sorts an array of objects based on the multiple properties passed in.
 * @param properties {String[]}
 * @returns Array sorted by the properties
 * @example
 * const people = [
 *  {name: 'AAAA', surname: 'BBBBBBBBBB'},
 *  {name: 'CCCC', surname: 'DDDDDDDDDD'},
 *  {name: 'EEEE', surname: 'FFFFFFFFFF'},
 *  {name: 'GGGG', surname: 'HHHHHHHHHH'}
 * ];
 * const sorted = people.sort(dynamicSortMultipleFields(['surname', '-name']))
 */
export const dynamicSortMultipleFields = (properties) => (a, b) => properties.map(o => {
    let dir = 1
    if (o[0] === '-') {
        dir = -1; o = o.substring(1)
    }
    return a[o] > b[o] ? dir : a[o] < b[o] ? -(dir) : 0
}).reduce((p, n) => p || n, 0)

/**
 * Returns an array of unique elements
 * @param array {Array} -- the array that may contain duplicate values.
 * @example
 *      unique(['TGL50538', 'TGL12345', 'TGL50538'])
 *      return ['TGL50538', 'TGL12345']
 *@returns {array}
 */
export const unique = (array) => {
    return [...new Set(array)]
}

/**
 * Check if an array is either null/undefined or has no items in it.
 * @param array {Array}
 * @return {boolean}
 */
export const isNullOrEmpty = (array) => {
    if (!array || !Array.isArray(array)) {
        return true
    }
    return array.length === 0
}

/**
 * Generates a CSV string.
 * CSV is based on an array of flat objects.
 * @param {any} array an array of flat objects.
 */
export const toCSVString = (array) => {
    let csv = ''
    // Loop the array of objects
    for (let row = 0; row < array.length; row++) {
        const keysAmount = Object.keys(array[row]).length
        let keysCounter = 0

        // If this is the first row, generate the headings.
        if (row === 0) {
            // Loop each property of the object.
            for (const key in array[row]) {
                // This is to not add a comma at the last cell.
                // The '\r\n' adds a new line
                csv += key + (keysCounter + 1 < keysAmount ? ',' : '\r\n')
                keysCounter++
            }
        } else {
            for (const key in array[row]) {
                // Determine the value to use for the cell.
                let value = array[row][key] ?? null
                if (typeof (value) === 'string' && value.replaceAll) {
                    value = value?.replaceAll('"', '""') ?? ''
                    value = `"${ value }"`
                }
                csv += value + (keysCounter + 1 < keysAmount ? ',' : '\r\n')
                keysCounter++
            }
        }
        keysCounter = 0
    }
    return csv
}

/**
 * Compares two arrays out of order with shallow search
 * @param {any[]} a
 * @param {any[]} b
 * @returns {boolean} true if the arrays contain the same elements, false otherwise
 */
export const equalsIgnoreOrder = (a, b) => {
    if (a.length !== b.length) {
        return false
    }

    const sortedA = [...a].map(elem => JSON.stringify(elem)).sort()
    const sortedB = [...b].map(elem => JSON.stringify(elem)).sort()

    return JSON.stringify(sortedA) === JSON.stringify(sortedB)
}

/**
 * Splits array into smaller chunks
 * @param {any[]} array
 * @param {number} chunkSize
 * @returns {any[][]} An array of array with the size of chunkSize or the remaining items to the array length
 */
export const chunkArray = (array, chunkSize) => {
    let result = []
    for (let i = 0; i < array.length; i += chunkSize) {
        let chunk = array.slice(i, i + chunkSize)
        result.push(chunk)
    }
    return result
}

/**
 * Checks if the array is sorted in ascending order
 * @param arr {Array} -- the array to check
 * @returns {boolean} -- true if the array is sorted in ascending order, false otherwise
 */
export const isAscending = (arr) => {
    return arr
        .slice(1)
        .every((num,i) => num >= arr[i])
}

/**
 * Checks if the array is sorted in descending order
 * @param arr {Array} -- the array to check
 * @returns {boolean} -- true if the array is sorted in descending order, false otherwise
 */
export const isDescending = (arr) => {
    return arr
        .slice(1)
        .every((num,i) => num <= arr[i])
}
