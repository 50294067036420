import {
    SEARCH_MUTATE_ALL_RESULTS,
    SEARCH_MUTATE_CLEAR_ALL_RESULTS,
    SEARCH_MUTATE_IS_ACTIVE,
    SEARCH_MUTATE_IS_OWNER_TITLES_PANEL_OPEN,
    SEARCH_MUTATE_IS_RESULTS_PANEL_OPEN,
    SEARCH_MUTATE_LOADING,
    SEARCH_MUTATE_LOADING_TITLES,
    SEARCH_MUTATE_MAP_SEARCH_INPUT,
    SEARCH_MUTATE_OWNER_TITLES,
    SEARCH_MUTATE_POPUP,
    SEARCH_MUTATE_SEARCH_LAYER_VISIBILITY,
    SEARCH_MUTATE_SEARCH_TEXT,
    SEARCH_MUTATE_SELECTED_TITLE_NUMBERS,
    SEARCH_MUTATE_SET_MAP_SELECTED_TITLE_NUMBER,
    SEARCH_MUTATE_SHOW_OWNER_SEARCH,
    SEARCH_MUTATE_SHOW_POPUP,
} from '@/store/modules/search/types'
import { ISearchResultOwner } from '@/store/modules/search/types/search-result-interface'
import { ISearchState } from '@/store/modules/search/types/search-state-interface'


export default {
    [SEARCH_MUTATE_MAP_SEARCH_INPUT](state: ISearchState, input: any): void {
        state.mapSearchInput = input
    },

    [SEARCH_MUTATE_SHOW_POPUP](state: ISearchState, showPopup: boolean): void {
        state.showPopup = showPopup
    },

    [SEARCH_MUTATE_LOADING](state: ISearchState, isLoading: boolean): void {
        state.loading = isLoading
    },

    [SEARCH_MUTATE_SHOW_OWNER_SEARCH](state: ISearchState, show: boolean): void {
        state.showOwnerSearch = show
    },

    [SEARCH_MUTATE_ALL_RESULTS](state: ISearchState, results: any): void {
        if (!Array.isArray(results)) {
            // something went wrong!
            throw new Error('Search all has not returned an array from the search!')
        }

        results.forEach(item => {
            switch (item.type) {
                case 'company':
                    state.allResults.companies.push(item)
                    break
                case 'titlenumber':
                    state.allResults.titleNumbers.push(item)
                    break
                case 'place':
                    state.allResults.places.push(item)
                    break
                case 'address':
                    state.allResults.addresses.push(item)
                    break
                case 'postcode':
                    state.allResults.postcodes.push(item)
                    break
                case 'uprn':
                    state.allResults.uprns.push(item)
                    break
            }
        })
    },

    [SEARCH_MUTATE_CLEAR_ALL_RESULTS](state: ISearchState): void {
        state.allResults = {
            addresses: [],
            companies: [],
            places: [],
            postcodes: [],
            titleNumbers: [],
            uprns: [],
        }
    },

    [SEARCH_MUTATE_POPUP](state: ISearchState, payload: any): void {
        state.popupOverlay = payload.popupOverlay
        state.popupOverlay.setPosition(payload.coordinate)
        state.popupHTML = payload.popupHtml
        state.showPopup = payload.showPopup
    },

    [SEARCH_MUTATE_OWNER_TITLES](state: ISearchState, data: any): void {
        state.ownerTitles = []
        data.forEach((ownerItem: any) => {
            ownerItem.titles.forEach((titleItem: any) => {
                state.ownerTitles.push({
                    owner: ownerItem.owner,
                    ...titleItem,
                    sort: state.ownerTitles.length,
                })
            })
        })
    },

    [SEARCH_MUTATE_IS_ACTIVE](state: ISearchState, isActive: boolean): void {
        state.isActive = isActive
    },

    [SEARCH_MUTATE_IS_OWNER_TITLES_PANEL_OPEN](state: ISearchState, isOpen: boolean): void {
        state.isOwnerTitlesPanelOpen = isOpen
    },

    [SEARCH_MUTATE_LOADING_TITLES](state: ISearchState, isLoading: boolean): void {
        state.loadingTitles = isLoading
    },

    [SEARCH_MUTATE_SEARCH_TEXT](state: ISearchState, text: string): void {
        if (text !== state.searchText) {
            state.searchText = text
        }
    },

    [SEARCH_MUTATE_IS_RESULTS_PANEL_OPEN](state: ISearchState, isOpen: boolean): void {
        state.isResultsPanelOpen = isOpen
    },

    [SEARCH_MUTATE_SELECTED_TITLE_NUMBERS](state: ISearchState, titleNumbers: string[]): void {
        state.selectedTitleNumbers = titleNumbers
    },

    [SEARCH_MUTATE_SEARCH_LAYER_VISIBILITY](state: ISearchState, isVisible: boolean): void {
        if (state.searchResultsLayer) {
            state.searchResultsLayer.setVisible(isVisible)
        }
    },

    [SEARCH_MUTATE_SET_MAP_SELECTED_TITLE_NUMBER](state: ISearchState, titleNumberWithData: { titleNumber: string, data: ISearchResultOwner }): void {
        if (!titleNumberWithData) {
            return
        }

        state.ownerTitles.forEach((item) => {
            if (item.titleNumber === titleNumberWithData.titleNumber) {
                item.selected = !item.selected
            }
        })
    },
}
