import { compareAsc } from 'date-fns'
import {
    provide as VueProvide,
} from 'vue'
import {
    computed,
    inject as VueInject,
} from 'vue'
import type { VDataTable } from 'vuetify/components'

import { QuickOptionType } from '@/enums/quick-option-type.enum'
import { checkFlag } from '@/feature-flags'
import {
    IQuickOption,
    QuickOptionVisibility,
} from '@/interfaces/quick-option.interface'
import { useDocumentLibraryStore } from '@/stores/document-library'

import { inject as useDocument } from './use-document'

export const DOCUMENT_LIBRARY_PROVIDER_KEY = Symbol('documentLibraryProvider')

export const inject = (): ReturnType<typeof useDocumentLibrary> => {
    if (!VueInject(DOCUMENT_LIBRARY_PROVIDER_KEY)) {
        throw new Error(`${ DOCUMENT_LIBRARY_PROVIDER_KEY.toString() } has not been provided`)
    }
    return VueInject(DOCUMENT_LIBRARY_PROVIDER_KEY) as ReturnType<typeof useDocumentLibrary>
}

export const provide = () => {
    if ((import.meta as any).env.DEV) {
        // eslint-disable-next-line no-console
        console.trace(`providing: ${ DOCUMENT_LIBRARY_PROVIDER_KEY.toString() }`)
    }
    const documentLibrary = useDocumentLibrary()
    VueProvide(DOCUMENT_LIBRARY_PROVIDER_KEY, documentLibrary)

    return documentLibrary
}

/**
 * Where possible use the exported inject function to access the composable
 */
const useDocumentLibrary = () => {
    const documentLibraryV2 = computed(() => {
        return checkFlag('document-library-v-2', false)
    })

    const store = useDocumentLibraryStore()
    const { removeDocuments, downloadSelected, generateReportWithSelected } = useDocument()

    const quickOptions = computed<IQuickOption[]>(() => {
        return [
            {
                type: QuickOptionType.Upload,
                visible: QuickOptionVisibility.Empty,
                priority: 3,
            },
            {
                visible: () => store.selected.length > 0,
                id: 'delete',
                title: `Delete Selected`,
                description: 'Delete the selected documents',
                count: store.selected.length,
                icon: 'ow-icon-delete',
                priority: 2,
                action: () => {
                    removeDocuments(store.selectedDocuments)
                },
            },
            {
                visible: store.selectedDownloadableTitles.length > 0,
                id: 'downloadTitleRegisters',
                title: `Download Title Registers`,
                description: 'Download the selected Title Registers',
                count: store.selectedDownloadableTitles.length,
                icon: 'ow-icon-download',
                priority: 1,
                action: async () => {
                    await downloadSelected()
                },
            },
            {
                visible: store.selectedReportableTitles.length > 0,
                id: 'reportTitleRegisters',
                title: `Generate Report`,
                description: 'Generates a report with the selected Title Registers',
                count: store.selectedReportableTitles.length,
                icon: 'ow-icon-report',
                priority: 1,
                action: async () => {
                    await generateReportWithSelected()
                },
            },
        ]
    })

    const getTableHeaders = (groupedTableHeader: boolean = false): VDataTable['$props']['headers'] => {
        const DESKTOP = 1264
        const MOBILE = 700

        const className = (classes: string) => {
            const classesArray = []
            classesArray.push('field')

            if (store.isGrouped) {
                classesArray.push('--fixed')
            }
            if (groupedTableHeader) {
                classesArray.push('--grouped-header')
            }
            return classesArray.length ? `${ classes } ${ classesArray.join(' ') }` : classes

        }
        const columns = []

        const addColumn = ({ title, value, sortable, className, sortFn }: { title: string, value: string, sortable: boolean, className: string, sortFn?: (a: any, b: any) => number }) => {
            columns.push({
                title,
                value,
                sortable,
                cellProps: { class: 'field__' + className },
                headerProps: { class: 'field__' + className + ' --header' },
                ...(sortFn && { sort: sortFn }),
            })
        }

        addColumn({ title: 'Document Name', value: 'documentName', sortable: !store.isGrouped, className: className('document-name') })
        addColumn({ title: 'Type', value: 'type', sortable: !store.isGrouped, className: className('type') })
        addColumn({ title: 'Title', value: 'titleNumber', sortable: true, className: className('title-number') })
        addColumn({ title: 'Group', value: 'groupName', sortable: true, className: className('group-name') })
        addColumn({ title: 'Doc Date', value: 'orderDate', sortable: !store.isGrouped, className: className('order-date'), sortFn: (a, b) => compareAsc(new Date(a), new Date(b)) })
        addColumn({ title: '', value: 'details', sortable: false, className: className('details') })
        addColumn({ title: '', value: 'actions', sortable: false, className: className('actions') })
        return columns
    }

    return {
        documentLibraryV2,
        quickOptions,
        getTableHeaders,
    }
}
