/**
 * Formats a number value into a GBP currency including the pound sign.
 * @type {Intl.NumberFormat}
 * @return {string}
 * @example
 *      import { gbpCurrencyFormatter } from '@/utils/number-utils'
 *      ...
 *      gbpCurrencyFormatter.format(1000)
 *      // Will return £1,000.00
 */
export const gbpCurrencyFormatter = new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: 'GBP',
    minimumFractionDigits: 2,
})

/**
 * Formats a number value
 * @param value - the number to format
 * @param locale - the locale to use for formatting
 */
export const formatNumber = (value: number, locale = 'en-GB') => {
    return new Intl.NumberFormat(locale).format(value)
}

/**
 * Ensures the value is a valid number, using a default if necessary
 * @param {string | null} value - The input to validate
 * @param {number} defaultValue - The fallback value to use if input is invalid
 * @returns {number}
 */
export const getValidNumber = (value: any, defaultValue: number = 0): number => {
    // Specifically check for these as Number(null) and Number(undefined) return 0
    if (value === null || value === undefined) {
        return defaultValue
    }

    return isNaN(value) ? defaultValue : Number(value)
}
