<template>
    <ow-full-width :inline-step-flow="false"
                   class="document-library__container"
                   no-padding
                   page-name="document-library">
        <template #navigation>
            <top-nav :actions="topNavActions"
                     :header-title="t('documentLibrary.navigationHeadings.pageTitle')"
                     :items="topNavItems"
                     :side-nav-collapsed="true"
                     :value="route.name?.toString()"
                     class="document-library__top-nav"
                     has-beta-label
                     header-icon="$documents"
                     @action-click="onActionClick" />
        </template>
        <template #default>
            <div class="content-container">
                <router-view v-slot="{ Component }">
                    <keep-alive>
                        <component :is="Component" />
                    </keep-alive>
                </router-view>
            </div>
        </template>
    </ow-full-width>
</template>

<script lang="ts" setup>
    import {
        computed,
        ref,
        watch,
    } from 'vue'
    import { useI18n } from 'vue-i18n'
    import {
        useRoute,
        useRouter,
    } from 'vue-router'

    import OwFullWidth from '@/components/core/layout/full-width-white-header.vue'
    import TopNav from '@/components/top-nav/top-nav.vue'
    import {
        NavAction,
        NavItem,
    } from '@/components/top-nav/types'
    import {
        FeatureFlag,
        inject as useFeatureFlags,
    } from '@/composables/use-feature-flags'
    import { Route } from '@/enums/route.enum'
    import { useDocumentLibraryStore } from '@/stores/document-library'
    import { useMatterStore } from '@/stores/matter'
    import { isNullOrEmpty } from '@/utils/array-utils'

    const route = useRoute()
    const router = useRouter()
    const { t } = useI18n()
    const store = useDocumentLibraryStore()
    const matterStore = useMatterStore()
    const { checkFlag  } = useFeatureFlags()
    const topNavActions = ref<NavAction[]>([])

    watch(() => route.name, async (routeName) => {
        if (routeName === Route.DocumentsLibrary) {
            if (!route.params?.status) {
                await router.push({
                    name: Route.DocumentsLibraryOwned,
                    params: {
                        matterId: matterStore.currentMatterId,
                    },
                })
            }
        }

        if (routeName === Route.DocumentsLibraryUnpurchased) {
            if (!checkFlag(FeatureFlag.DocumentLibraryV2UnpurchasedDocuments)) {
                await router.push({
                    name: Route.DocumentsLibraryOwned,
                    params: {
                        matterId: matterStore.currentMatterId,
                    },
                })
            }
        }

        if (routeName === Route.DocumentsLibraryOrderHistory) {
            if (!checkFlag(FeatureFlag.DocumentLibraryV2OrderHistory)) {
                await router.push({
                    name: Route.DocumentsLibraryOwned,
                    params: {
                        matterId: matterStore.currentMatterId,
                    },
                })
            }
        }

    }, {
        immediate: true,
    })
    const onActionClick = async (action: NavAction) => {
        if (action.title === t('action.refreshList')) {
            await store.initialise()
            store.setData(route.meta?.childNavName ?? 'owned')
            store.filterOptions.selectedTypes = new Set<string>(['Title Plan', 'Title Register'])
        }
    }

    watch(() => matterStore.currentMatterId, async (matterId) => {
        if (matterId) {
            store.clearFilter()
            await store.initialise()
            store.setData(route.meta?.childNavName ?? 'owned')
            store.filterOptions.selectedTypes = new Set<string>(['Title Plan', 'Title Register'])
        }
    }, {
        immediate: true,
    })

    // Refresh documents when selected titles change
    watch(() => matterStore.currentMatter?.selectedTitles, async () => {
        await store.initialise()
    })

    const topNavItems = computed<NavItem[]>(() => {
        return [
            {
                title: t('documentLibrary.navigationHeadings.owned').toString(),
                route: Route.DocumentsLibraryOwned,
                childNavName: 'owned',
                dataTrackId: 'Document Library - Top Nav - Owned clicked',
                dataTestId: 'doc-library-top-nav-owned',
                alertCount: store.ownedDocuments.length ?? 0,
                loading: store.isLoading,
            },
            {
                title: t('documentLibrary.navigationHeadings.unpurchased').toString(),
                route: Route.DocumentsLibraryUnpurchased,
                childNavName: 'unpurchased',
                dataTrackId: 'Document Library - Top Nav - Unpurchased clicked',
                dataTestId: 'doc-library-top-nav-unpurchased',
                alertCount: 0,
                loading: store.isLoading,
                hidden: !checkFlag(FeatureFlag.DocumentLibraryV2UnpurchasedDocuments),
            },
            {
                title: t('documentLibrary.navigationHeadings.exports').toString(),
                route: Route.DocumentsLibraryExports,
                childNavName: 'exports',
                dataTrackId: 'Document Library - Top Nav - Exports clicked',
                dataTestId: 'doc-library-top-nav-exports',
                alertCount: store.exportedDocuments.length ?? 0,
                loading: store.isLoading,
            },
            {
                title: t('documentLibrary.navigationHeadings.orderHistory').toString(),
                route: Route.DocumentsLibraryOrderHistory,
                childNavName: 'order-history',
                dataTrackId: 'Document Library - Top Nav - Order History clicked',
                dataTestId: 'doc-library-top-nav-order-history',
                hidden: !checkFlag(FeatureFlag.DocumentLibraryV2OrderHistory),
            },
        ]
    })

    watch(() => store.data, (data) => {
        if (isNullOrEmpty(data)) {
            topNavActions.value = []
            return
        }
        topNavActions.value = [
            {
                title: t('action.refreshList'),
                dataTrackId: 'Document Library - Top Nav - Refresh clicked',
                dataTestId: 'doc-library-top-nav-refresh',
            },
            {
                title: t('documentLibrary.navigationActions.exportToExcel'),
                dataTrackId: 'Document Library - Top Nav - Export to Excel clicked',
                dataTestId: 'doc-library-top-nav-export-to-excel',
                icon: 'mdi-file-excel',
                disabled: true,
            },
        ]
    }, {
        immediate: true,
        deep: true,
    })
</script>

<style lang="scss" scoped>
    @import './document-library';
</style>
