<template>
    <div class="document-library-upload"
         :class="{
             'document-library-upload--quick-option': quickOption
         }">
        <upload-documents-button :current-matter-id="store.currentMatterId"
                                 data-test="uploaded-documents-summary-upload-documents-btn"
                                 data-track="DOCUMENTS - Upload documents"
                                 is-primary
                                 @upload-started="handleUploadStarted">
            <template #button>
                <ow-quick-option-card :option="{
                                          title: $t('documents.upload.uploadDocuments'),
                                          description: $t('documents.upload.uploadDocumentsDescription'),
                                          icon: 'ow-icon-document-upload',
                                      }"
                                      @click="handleUploadStarted" />
            </template>
        </upload-documents-button>
    </div>
</template>

<script setup lang="ts">
    import OwQuickOptionCard from '@/components/core/ow-quick-option-card.vue'
    import UploadDocumentsButton from '@/components/shared/upload-documents-button.vue'
    import { useMatterStore } from '@/stores/matter'

    defineProps<{
        quickOption?: boolean
    }>()

    const emit = defineEmits<{
        (e: 'upload-started'): void
    }>()

    const store = useMatterStore()

    const handleUploadStarted = () => {
        emit('upload-started')
    }
</script>

<style lang="scss">
@import './document-library-upload.scss';
</style>