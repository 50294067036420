import { DOCUMENT_SOURCE } from "@/consts/document-source"

// @/stores/application/actions.ts
export default {
    /**
     * Set the cost of documents for each source
     */
    setDocumentCosts(hmlrDocumentCost: number, scotLisDocumentCost: number): void {
        this.documentCosts[DOCUMENT_SOURCE.HMLR] = hmlrDocumentCost
        this.documentCosts[DOCUMENT_SOURCE.UPLOADED] = hmlrDocumentCost
        this.documentCosts[DOCUMENT_SOURCE.SCOTLAND] = scotLisDocumentCost
    },
}
