<template>
    <template v-if="isNullOrEmpty(filterGroups)">
        <p v-t="'error.noResults'"
           class="body-regular-small ml-4" />
    </template>
    <ow-expansion-panels v-else
                         v-model="expanded"
                         small>
        <ow-expansion-panel v-for="group in filterGroups"
                            :key="group.id"
                            small
                            :title="group.label">
            <div class="document-library-filter-groups__panel d-flex flex-column gr-1">
                <div v-if="group.filter"
                     class="document-library-filter-groups__filter">
                    <ow-textfield v-model="groupFilters[group.id]"
                                  class="dpy-1"
                                  clearable
                                  :data-test="`document-library-filter-groups-filter-${group.id}`"
                                  :placeholder="$t('action.filter')"
                                  small />
                </div>
                <template v-for="item in group.items.filter(item => item.selected)"
                          :key="item.id">
                    <div v-if="!item.hidden"
                         class="document-library-filter-groups__item d-flex justify-space-between align-center gc-2 text-no-wrap"
                         @click="item.selected = !item.selected">
                        <ow-checkbox :id="item.id"
                                     v-model="item.selected"
                                     data-test="document-library-filter-groups-checkbox"
                                     class="document-library-filter-groups__checkbox"
                                     :label="item.label"
                                     @click.stop />
                        <span class="filter-count body-regular-small">
                            {{ item.count }}
                        </span>
                    </div>
                </template>
                <v-divider v-if="group.items.some(item => item.selected) && !group.items.every(item => item.selected)"
                           class="mx-1" />
                <template v-for="item in group.items.filter(item => !item.selected)"
                          :key="item.id">
                    <div v-if="!item.hidden"
                         class="document-library-filter-groups__item d-flex justify-space-between align-center gc-2 text-no-wrap"
                         @click="item.selected = !item.selected">
                        <ow-checkbox :id="item.id"
                                     v-model="item.selected"
                                     :title="item.label"
                                     data-test="document-library-filter-groups-checkbox"
                                     class="document-library-filter-groups__checkbox"
                                     :label="item.label"
                                     @click.stop />
                        <span class="filter-count body-regular-small">
                            {{ item.count }}
                        </span>
                    </div>
                </template>
            </div>
        </ow-expansion-panel>
    </ow-expansion-panels>
</template>

<script setup lang="ts">
    import OwCheckbox from '@/components/core/ow-checkbox.vue'
    import OwExpansionPanel from '@/components/core/ow-expansion-panel.vue'
    import OwExpansionPanels from '@/components/core/ow-expansion-panels.vue'
    import OwTextfield from '@/components/core/ow-textfield.vue'
    import { IDocumentLibraryFilterGroup } from '@/interfaces/document-library-filter-group.interface'
    import {
        isNullOrEmpty,
    } from '@/utils/array-utils'

    const filterGroups = defineModel<IDocumentLibraryFilterGroup[]>('groups', {
        default: [],
    })

    const expanded = defineModel<number[]>('expanded', {
        default: [],
    })

    const groupFilters = defineModel<{ [key: string]: string }>('groupFilters', {
        default: {},
    })
</script>


<style scoped lang="scss">
    @import './document-library-filter-groups.scss';
</style>

