<template>
    <ow-base-icon :width="width"
                  :height="height"
                  viewBox="0 0 12 14"
                  desc="Uploaded document">
        <path fill-rule="evenodd"
              clip-rule="evenodd"
              d="M0.666626 12.3335L0.673282 1.66684C0.673282 0.930152 1.26328 0.333496 1.99994 0.333496H7.33328L11.3333 4.33349V12.3335C11.3333 13.0701 10.7366 13.6668 9.99993 13.6668H1.99328C1.25663 13.6668 0.666626 13.0701 0.666626 12.3335ZM1.99994 1.3335H6.91906L10.3333 4.74771V12.3335C10.3333 12.5179 10.1843 12.6668 9.99993 12.6668H1.99328C1.81553 12.6668 1.66675 12.5243 1.66663 12.3335L1.67328 1.66684C1.67328 1.47588 1.8221 1.3335 1.99994 1.3335ZM5.86461 4.3335C5.97886 4.3335 6.08489 4.3832 6.1624 4.46594L8.5572 7.06979C8.71222 7.23542 8.69997 7.49622 8.53674 7.65351C8.4552 7.72807 8.35724 7.76535 8.25942 7.76535C8.14921 7.76535 8.04318 7.71974 7.96149 7.6329L6.28485 5.79901V10.3195C6.28485 10.5473 6.10117 10.7335 5.87686 10.7335C5.6524 10.7335 5.46886 10.5473 5.46886 10.3195V5.79901L3.77984 7.6329C3.62482 7.79838 3.35974 7.81081 3.19652 7.65351C3.03329 7.50031 3.02104 7.23542 3.17606 7.06979L5.56683 4.46594C5.64434 4.3832 5.75037 4.3335 5.86461 4.3335Z"
              fill="#1F2E39" />
    </ow-base-icon>
</template>

<script lang="ts">
    import OwBaseIcon from '@/components/core/icons/ow-base-icon.vue'

    export default {
        name: 'IconOwLogo',
        components: {
            OwBaseIcon,
        },
        props: {
            width: {
                type: String,
                default: '16px',
            },
            height: {
                type: String,
                default: '16px',
            },
        },
    }
</script>
