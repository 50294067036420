<template>
    <div class="d-block align-center gc-2">
        <ow-colored-label v-if="!documentUploadSuccess"
                          is-extra-small
                          class="mr-2"
                          theme="warning">
            {{ documentStatusText }}
        </ow-colored-label>
        <span v-dompurify-html="uploadedDate" />
    </div>
</template>

<script setup lang="ts">
    import { computed } from 'vue'
    import { useI18n } from 'vue-i18n'

    import OwColoredLabel from '@/components/core/ow-colored-label.vue'
    import { inject as useDocument } from '@/composables/use-document'
    import { IDocumentRowData } from '@/interfaces/document-row-data.interface'
    import { format } from '@/utils/date-utils'
    import { isNullOrWhitespace } from '@/utils/string-utils'

    const { t } = useI18n()

    const props = defineProps<{
        item?: IDocumentRowData
    }>()

    const { documentStatus, isDocumentUploadSuccess } = useDocument()

    const documentStatusText = computed(() => documentStatus(props.item))
    const documentUploadSuccess = computed(() => isDocumentUploadSuccess(props.item))
    const uploadedDate = computed(() => {
        const formattedDate = format(props?.item?.document?.updatedOn)
        const date = !isNullOrWhitespace(formattedDate) ? formattedDate : props?.item?.document?.documentDownloadDate
        return t('documentLibrary.table.rowDetail.uploadedDate', {
            date,
            user: !isNullOrWhitespace(props.item?.userName) ? props.item?.userName : 'N/A',
        })
    })
</script>

<style scoped>

</style>