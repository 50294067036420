<template>
    <ow-loading-slot :loading="store.isLoading || !store.library.initialised">
        <document-library-splash-page v-if="store.totalCount === 0 "
                                      @add-title="handleAddTitle">
            <template #upload-drop-zone>
                <document-library-upload />
            </template>
        </document-library-splash-page>
        <template v-else>
            <div class="documents d-flex">
                <document-library-sidebar />
                <div ref="documentsContainer"
                     class="documents__container overflow-y-auto overflow-x-hidden w-100 d-flex flex-column pa-4">
                    <document-library-table-header v-model:filter="store.filterOptions.filter"
                                                   v-model:selected="store.selected"
                                                   v-model:sort-by="sortBy"
                                                   v-model:loading-groups="loadingGroups"
                                                   class="documents__table-header" />

                    <div class="documents__table"
                         :style="{
                             height: tableHeight,
                         }">
                        <template v-if="store.isGrouped">
                            <document-library-grouped-table v-model:sort-by="sortBy"
                                                            v-model:loading-groups="loadingGroups" />
                        </template>
                        <template v-else>
                            <document-library-table v-model:items="store.tableData"
                                                    v-model:active-page="store.paging.page"
                                                    v-model:page-size="store.paging.pageSize"
                                                    v-model:selected="store.selected"
                                                    class="pb-4" />
                        </template>
                    </div>
                </div>
            </div>
        </template>
    </ow-loading-slot>
</template>

<script setup lang="ts">
    import {
        nextTick,
        onBeforeUnmount,
        onMounted,
        onUpdated,
        ref,
        useTemplateRef,
        watch,
    } from 'vue'
    import {
        useRoute,
        useRouter,
    } from 'vue-router'

    import OwLoadingSlot from '@/components/core/ow-loading-slot.vue'
    import OwQuickOptions from '@/components/core/ow-quick-options.vue'
    import DocumentLibraryGroupedTable from '@/components/document-library/document-library-grouped-table.vue'
    import DocumentLibrarySidebar from '@/components/document-library/document-library-sidebar.vue'
    import DocumentLibrarySplashPage from '@/components/document-library/document-library-splash-page.vue'
    import DocumentLibraryTable from '@/components/document-library/document-library-table.vue'
    import DocumentLibraryTableHeader from '@/components/document-library/document-library-table-header.vue'
    import DocumentLibraryUpload from '@/components/document-library/document-library-upload.vue'
    import { provide as useDocumentLibrary } from '@/composables/use-document-library'
    import useWindowResize from '@/composables/use-window-resize'
    import { Route } from '@/enums/route.enum'
    import {
        useDocumentLibraryStore,
    } from '@/stores/document-library'

    const store = useDocumentLibraryStore()
    const documentsContainer = useTemplateRef('documentsContainer')
    const sortBy = defineModel('sort-by', {
        default: [
            { key: 'groupName', order: 'asc' },
        ],
    })
    const loadingGroups = defineModel('loading-groups', { default: false })
    const { quickOptions } = useDocumentLibrary()
    const router = useRouter()
    const route = useRoute()
    const { debouncedResize } = useWindowResize({
        onResize: async () => {
            await nextTick()
            const tableHeaderElement = document.querySelector('.documents__table-header')
            const quickOptionsElement = document.querySelector('.documents__quick-options')
            tableHeight.value = `calc(100vh - ${ quickOptionsElement?.clientHeight ?? 0 }px - ${ tableHeaderElement?.clientHeight ?? 0 }px - 32px - 100px)`

            // Add scroll event listener to keep track of scroll position
            if (documentsContainer.value) {
                documentsContainer.value.removeEventListener('scrollend', scrollHandler)
                documentsContainer.value.addEventListener('scrollend', scrollHandler)
            }
        },
        debounceInterval: 100,
    })
    const handleAddTitle = () => {
        router.push({ name: Route.MatterTitles })
    }
    const tableHeight = ref<string>('')

    watch(() => route.meta.childNavName, (value) => {
        if (value && store.childNavName !== value) {
            store.paging.page = 1
            store.selected = []
            store.setData(value)
        }
    }, {
        immediate: true,
    })

    watch(() => [store.quickOptions.expanded, store.selected], () => {
        debouncedResize()
    })

    watch(() => store.paging, () => {
        store.selected = []
    }, {
        deep: true,
    })

    onUpdated(() => {
        debouncedResize()
    })

    onBeforeUnmount(() => {
        documentsContainer.value.removeEventListener('scroll', scrollHandler)
    })

    const scrollHandler = () => {
        store.scrollPosition = documentsContainer.value.scrollTop
    }
</script>

<style lang="scss" scoped>
@import './documents.scss';
</style>