<template>
    <div class="document-library-table-row-detail-button-group align-center justify-end d-flex h-100 w-100">
        <template v-if="showViewButton(item)">
            <v-divider vertical />
            <ow-button full-width
                       full-height
                       class="document-library-table-row-detail-button-group__button"
                       @click="menu = !menu">
                <v-icon class="document-library-table-row-detail-button-group__button-menu-icon">
                    $menu-more-vertical
                </v-icon>
                <v-menu v-model="menu"
                        activator="parent"
                        :offset="[16, 20]">
                    <v-list>
                        <template v-for="(itm, index) in visibleMenuItems"
                                  :key="index">
                            <v-list-item :value="index">
                                <v-list-item-title @click="itm.action">
                                    <span class="document-library-table-row-detail-button-group__menu body-highlight"
                                          :class="{
                                              [itm.className]: itm.className,
                                          }">{{ itm.title }}</span>
                                </v-list-item-title>
                            </v-list-item>
                            <v-divider v-if="index !== visibleMenuItems.length -1"
                                       :vertical="false" />
                        </template>
                    </v-list>
                </v-menu>
            </ow-button>
        </template>
        <v-divider vertical />
        <ow-button v-if="showDownloadButton(item)"
                   full-width
                   full-height
                   :is-loading="isLoading"
                   class="document-library-table-row-detail-button-group__button"
                   @click="handleDownload">
            <v-icon v-if="!isLoading"
                    class="document-library-table-row-detail-button-group__button-primary-icon">
                $download
            </v-icon>
        </ow-button>
        <template v-else-if="sentInPost">
            <ow-button is-flat
                       icon
                       small
                       class="document-library-table-row-detail-button-group__info">
                <v-icon class="document-library-table-row-detail-button-group__button-primary-icon">
                    $info
                </v-icon>
                <ow-tooltip activator="parent"
                            max-width="400px">
                    <span v-dompurify-html="organisationAddress" />
                </ow-tooltip>
            </ow-button>
            <v-divider vertical />
            <ow-button full-width
                       full-height
                       class="document-library-table-row-detail-button-group__button"
                       @click="setUploadModalVisible()">
                <v-icon v-if="!isLoading"
                        class="document-library-table-row-detail-button-group__button-primary-icon">
                    $upload
                </v-icon>
            </ow-button>
        </template>
        <ow-button v-else
                   full-width
                   full-height
                   :is-loading="isLoading"
                   class="document-library-table-row-detail-button-group__button"
                   @click="removeDocuments([item])">
            <v-icon v-if="!isLoading"
                    class="document-library-table-row-detail-button-group__button-primary-icon">
                $delete
            </v-icon>
            <ow-tooltip activator="parent"
                        max-width="400px">
                <span v-t="'documentLibrary.table.rowDetail.remove'" />
            </ow-tooltip>
        </ow-button>
    </div>
</template>

<script setup lang="ts">
    import {
        computed,
        ref,
    } from 'vue'
    import { useI18n } from 'vue-i18n'
    import { useStore } from 'vuex'

    import OwButton from '@/components/core/ow-button-ds.vue'
    import OwTooltip from '@/components/core/ow-tooltip.vue'
    import { inject as useDocument } from '@/composables/use-document'
    import { IDocumentRowData } from '@/interfaces/document-row-data.interface'
    import { SET_UPLOAD_DOCUMENTS_DIALOG_STATE } from '@/store/modules/documents/documents-types'

    const props = defineProps<{
        item?: IDocumentRowData
    }>()

    const { isSentInPost,
            removeDocuments,
            viewDocument,
            showViewButton,
            showDownloadButton,
            downloadDocument } = useDocument()

    const isLoading = ref(false)
    const store = useStore()
    const sentInPost = computed(() => isSentInPost(props.item))
    const { t } = useI18n()
    const menu = ref(false)

    const displayAddress = (address) => {
        const output = []

        if (address.addressHouseNumber) {
            output.push(address.addressHouseNumber)
        }

        if (address.addressStreet) {
            output.push(address.addressStreet)
        }

        if (address.addressLocality) {
            output.push(address.addressLocality)
        }

        if (address.addressTown) {
            output.push(address.addressTown)
        }

        if (address.addressPostcode) {
            output.push(address.addressPostcode)
        }

        return output.join(', ')
    }

    const organisationAddress = computed(() => {
        const address = displayAddress(store.state.user.organisationAddress)
        return t('documentLibrary.table.rowDetail.sentInPost', {
            address,
        })
    })

    const handleDownload = async () => {
        isLoading.value = true
        try {
            await downloadDocument(props.item)
        } finally {
            isLoading.value = false
        }
    }

    const menuItems = computed(() => ([
        {
            title: 'View Document', className: '--success', action: () => {
                viewDocument(props.item)
            },
            visible: showViewButton(props.item),
        },
        {
            title: 'Remove Document', className: '--danger', action: () => {
                removeDocuments([props.item])
            },
            visible: true,
        },
    ]))
    const visibleMenuItems = computed(() => menuItems.value.filter((item) => item.visible))

    const setUploadModalVisible = () => store.commit(SET_UPLOAD_DOCUMENTS_DIALOG_STATE, true)
</script>

<style scoped lang="scss">
    @import './document-library-table-row-detail-button-group.scss';
</style>
