<template>
    <div class="document-library-table-row-detail w-100 d-flex gc-2 align-center justify-space-between">
        <div class="--collapsed">
            <ow-tooltip activator="parent">
                <template #activator>
                    <v-icon class="document-library-table-row-detail__info">
                        $account-user
                    </v-icon>
                </template>
                <document-library-table-row-detail-export v-if="isDocumentExported"
                                                          :item="item" />
                <document-library-table-row-detail-upload v-else-if="isDocumentUploaded"
                                                          :item="item" />
                <document-library-table-row-detail-purchased v-else-if="isDocumentOwned"
                                                             :item="item" />
                <document-library-table-row-detail-unpurchased v-else
                                                               :item="item" />
            </ow-tooltip>
        </div>
        <div class="--expanded d-flex">
            <document-library-table-row-detail-export v-if="isDocumentExported"
                                                      :item="item" />
            <document-library-table-row-detail-upload v-else-if="isDocumentUploaded"
                                                      :item="item" />
            <document-library-table-row-detail-purchased v-else-if="isDocumentOwned"
                                                         :item="item" />
            <document-library-table-row-detail-unpurchased v-else
                                                           :item="item" />
        </div>
    </div>
</template>

<script setup lang="ts">
    import OwTooltip from '@/components/core/ow-tooltip.vue'
    import DocumentLibraryTableRowDetailExport
        from '@/components/document-library/document-library-table-row-detail/document-library-table-row-detail-export.vue'
    import DocumentLibraryTableRowDetailPurchased
        from '@/components/document-library/document-library-table-row-detail/document-library-table-row-detail-purchased.vue'
    import DocumentLibraryTableRowDetailUnpurchased
        from '@/components/document-library/document-library-table-row-detail/document-library-table-row-detail-unpurchased.vue'
    import DocumentLibraryTableRowDetailUpload
        from '@/components/document-library/document-library-table-row-detail/document-library-table-row-detail-upload.vue'
    import {
        IDocumentRowData,
        IsDocumentExported,
        IsDocumentOwned,
        IsDocumentUploaded,
    } from '@/interfaces/document-row-data.interface'

    const props = defineProps<{
        item?: IDocumentRowData
    }>()

    const isDocumentUploaded = IsDocumentUploaded(props.item)
    const isDocumentOwned = IsDocumentOwned(props.item)
    const isDocumentExported = IsDocumentExported(props.item)

</script>

<style lang="scss">
@import './document-library-table-row-detail.scss';
</style>

